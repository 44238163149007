import React from "react";
import { Stack, Avatar, Icon, Typography } from "@mui/material";
import { TargetsIcon } from "routes";
import ReactMarkdown from "react-markdown";
import Input from "common/components/Input";
import ActionButton from "common/components/ActionButton";
import SidePanel from "common/components/SidePanel";
import useScopeAttributes from "common/hooks/useScopeAttributes";
import validate from "constants/validate";
import SelectPlatform from "pages/Targets/components/ModifyTarget/SelectPlatform";
import useFetcher from "common/hooks/useFetcher";
import toast from "react-hot-toast";
import useState from "common/hooks/useState";
import instructions from "../AddTenant/instructions";
import AssetImage from "common/components/AssetImage";
import FileUpload from "common/components/FileUpload";
import { setupInstructions } from "../AddTenant/instructions";
import { CustomInstruction } from "../AddTenant/instructions";
export default function ModifyTenant({ tenant, refresh, onClose }) {
  const fetcher = useFetcher();
  const [confirmed, setConfirmed] = React.useState(null);
  const { tenant: e1tenant, loading, error } = useState("/api/meta");
  const {
    tags,
    TagInput,
    setInit,
    loading: attrLoading,
  } = useScopeAttributes();
  const [panelView, setPanelView] = React.useState(false);
  const [platform, setPlatform] = React.useState(tenant.type);
  const [configuration, setConfiguration] = React.useState(null);
  React.useEffect(() => {
    if (!tenant) return;
    setInit(tenant.tags);
  }, [tenant]);
  const addTenant = async () => {
    await fetcher.post(
      "saving tenant",
      `/api/tenants`,
      () => {
        let form = new FormData();
        form.append("json", JSON.stringify({ ...tenant, tags }));
        if (configuration) form.append("File", configuration);
        return form;
      },
      ({ id }, toast) => {
        if (id) {
          toast.success("Tenant saved!");
          refresh();
          return onClose();
        }
        toast.error(
          "Looks like we had an issue. If the issue persists, please contact support."
        );
      },
      (e) => {}
    );
  };
  const confirmTenant = async () => {
    if (!configuration)
      return toast.error("Upload a configuration file first.");
    await fetcher.post(
      "confirming tenant access",
      `/api/tenants/confirm`,
      () => {
        let form = new FormData();
        form.append(
          "json",
          JSON.stringify({
            type: tenant.type,
            tenant: tenant.id,
          })
        );
        if (configuration) form.append("File", configuration);
        return form;
      },
      ({ success }) => setConfirmed(success),
      (e) => setConfirmed(false)
    );
  };
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          {platform?.pk && (
            <AssetImage
              path={`/api/assets/instance/platforms/${tenant.type}/logo.png`}
              style={{ height: "60px", width: "60px" }}
            />
          )}
        </Avatar>
        <Stack>
          <Typography variant="h4">
            {tenant?.name} ({tenant?.id})
          </Typography>
          <Typography variant="body2">modify tenant</Typography>
        </Stack>
      </Stack>
      {platform && platform !== "aws" ? (
        <Stack className="floating" gap={3}>
          <Typography variant="h5">Setup</Typography>
          <Stack gap={1}>
            <Typography>Upload new JSON configuration</Typography>
          </Stack>
          <Stack gap={1}>
            {platform !== "aws" ? (
              <FileUpload
                sizeInKb={300}
                file={configuration}
                handleFileChange={setConfiguration}
              >
                Upload a new configuration JSON file.
              </FileUpload>
            ) : null}
            {confirmed ? (
              <Stack gap={1}>
                <Typography sx={{ textAlign: "right" }} variant="body2">
                  Your tenant has been confirmed!
                </Typography>
                <div style={{ marginLeft: "auto", display: "flex" }}>
                  <ActionButton
                    icon="save"
                    dark
                    handler={async () => {
                      await addTenant();
                    }}
                  >
                    Modify Tenant
                  </ActionButton>
                </div>
              </Stack>
            ) : (
              <div style={{ marginLeft: "auto" }}>
                <ActionButton
                  icon="science"
                  dark
                  handler={async () => {
                    await confirmTenant();
                  }}
                >
                  Test
                </ActionButton>
              </div>
            )}
          </Stack>
        </Stack>
      ) : null}
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Tags</Typography>
        <TagInput />
        <div style={{ marginLeft: "auto" }}>
          <ActionButton
            icon="save"
            dark
            handler={async () => {
              await addTenant();
            }}
          >
            Update Tags
          </ActionButton>
        </div>
      </Stack>
      {platform && e1tenant ? (
        <Stack className="floating" gap={3}>
          <Typography variant="h5">Instructions</Typography>
          <Stack gap={1}>
            <ReactMarkdown
              linkTarget="_blank"
              className="react-markdown-custom"
            >
              {instructions[platform]
                ? instructions[platform](e1tenant)
                : CustomInstruction}
            </ReactMarkdown>
          </Stack>
        </Stack>
      ) : null}
      <div style={{ height: "100px" }} />
      {/* <Stack className="floating" gap={3}>
        <Typography variant="h5">Tenants</Typography>
        <Stack gap={1}>
          <Typography>Select tenants to assoicate with this target.</Typography>
        </Stack>
        <Stack gap={1}>
          <SidePanel
            width="700px"
            open={panelView === "tenants"}
            closeDrawer={() => setPanelView(false)}
            props={{ tenants, setTenants, platform }}
          >
            {SelectTenants}
          </SidePanel>
          <ActionButton
            disabled={!platform}
            icon="list"
            dark
            noload
            handler={async () => {
              setPanelView("tenants");
            }}
          >
            {!tenants.length
              ? "No tenants selected"
              : `${tenants.length} tenant${
                  tenants.length === 1 ? "" : "s"
                } selected`}
          </ActionButton>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Geographies</Typography>
        <Stack gap={1}>
          <Typography>Select geos to assoicate with this target.</Typography>
        </Stack>
        <Stack gap={1}>
          <SidePanel
            width="700px"
            open={panelView === "geos"}
            closeDrawer={() => setPanelView(false)}
            props={{ geos, setGeos, platform }}
          >
            {SelectGeos}
          </SidePanel>
          <ActionButton
            icon="location_on"
            disabled={!platform}
            dark
            noload
            handler={async () => {
              setPanelView("geos");
            }}
          >
            {!geos.length
              ? "No geographies selected"
              : `${geos.length} geograph${
                  geos.length === 1 ? "y" : "ies"
                } selected`}
          </ActionButton>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Access Control</Typography>
        <Stack gap={1}>
          <ScopeInputs />
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto", display: "flex" }}>
        <ActionButton
          icon="save"
          dark
          handler={async () => {
            await createTarget();
          }}
        >
          Save Target
        </ActionButton>
      </div> */}
    </Stack>
  );
}
