import React, { useState, useEffect } from "react";
import { CircularProgress, Box } from "@mui/material";
import { authFetch } from "auth";
const ImageLoader = ({
  apiPath,
  fallbackPath,
  alt = "Image",
  width = "100%",
  height = "100%",
}) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let mounted = true;

    const fetchImage = async () => {
      try {
        const response = await authFetch(apiPath);
        if (!response.ok) throw new Error("Failed to fetch image URL");
        let image = await response.blob();

        if (mounted) {
          setImageUrl(URL.createObjectURL(image)); // Assuming the API returns an object with a url property
        }
      } catch (err) {
        if (mounted) {
          setError(true);
          setImageUrl(fallbackPath);
        }
      }
    };

    fetchImage();

    return () => {
      mounted = false;
    };
  }, [apiPath, fallbackPath]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setError(true);
    setIsLoading(false);
    setImageUrl(fallbackPath);
  };

  return (
    <Box
      position="relative"
      width={width}
      height={height}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {isLoading && (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "50%",
            color: "#fff",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
      {imageUrl && (
        <img
          src={imageUrl}
          alt={alt}
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            display: isLoading ? "none" : "block",
          }}
        />
      )}
    </Box>
  );
};

export default ImageLoader;
