import React from "react";
import {
  Stack,
  IconButton,
  Icon,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import Loader from "common/components/Loader";
import useFetcher from "common/hooks/useFetcher";
import Input from "common/components/Input";
import Modal from "common/components/Modal";
import { MiniLoader } from "common/components/Loader";
import { nanoid } from "nanoid";
import DnDList from "common/components/DndList";
import Switch from "common/components/Switch";
import useScopeAttributes from "common/hooks/useScopeAttributes";
import MultiSelect from "common/components/MultiSelect";
import rootFetcher from "common/utils/fetcher";
import validate from "constants/validate";
import ActionButton from "common/components/ActionButton";
import useConfirm from "common/hooks/useConfirm";
import { theme } from "theme";
export default function ViewEdit({
  deleteView,
  view,
  refresh,
  onClose,
  toggleStar,
  starred,
}) {
  const { ScopeInputs, getScope, setInit } = useScopeAttributes();
  const [id, setId] = React.useState(null);
  const [name, setName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [systemOptions, setSystemOptions] = React.useState([]);
  const [systems, setSystems] = React.useState([]);
  const [stagedSystemWidgets, setStagedSystemWidgets] = React.useState([]);
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete View`,
    message: `Are you sure you want to delete ${name} view? This action can't be undone.`,
    callback: () => {
      deleteView(view?.pk);
      onClose();
    },
  });

  const [stagedSystem, setStagedSystem] = React.useState({
    system: null,
    type: "system",
    widgets: [],
  });
  const fetcher = useFetcher();
  const getResources = async () => {
    setLoading(true);
    let res = await rootFetcher(`/api/sys`);
    if (res?.systems) {
      setSystemOptions(res.systems);
    }
    setLoading(false);
  };
  const fetchSystem = async (id) => {
    setLoading(true);
    await fetcher.get(
      "fetching system",
      `/api/sys/${id}`,
      ({ system }) => {
        setStagedSystemWidgets(
          system?.graphs?.filter((g) => g.type !== "Text") || []
        );
      },
      () => {}
    );
    setLoading(false);
  };
  React.useEffect(() => {
    if (stagedSystem?.type === "widget" && stagedSystem?.system) {
      fetchSystem(stagedSystem.system.id);
    }
  }, [stagedSystem.type, stagedSystem.system]);
  React.useEffect(() => {
    if (!view) return;
    //load systems
    getResources();
    if (view === 1) return;
    let { pk, name, tiles, tags, acl } = view;
    setId(pk);
    setName(name);
    setSystems(tiles);
    setInit(tags, acl);
  }, [view]);

  const onSave = (close) => {
    const nv = {
      tiles: systems,
      name,
      ...getScope(),
    };
    if (id) {
      nv["id"] = id;
    }
    // return;
    if (!validate("nexus_general_edit", nv)) return;
    const payload = { view: nv, update: !!id };
    fetcher.post(
      "saving view",
      `/api/nexus/views`,
      payload,
      ({ error }) => {
        if (error) return toast.error(error);
        refresh();
        close();
      },
      () => {}
    );
  };
  return (
    <Stack p={2} gap={3}>
      <DeleteModal />
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <Icon
            style={{
              fontSize: "40px",
              color: theme.palette.primary.main,
            }}
          >
            dashboard
          </Icon>
        </Avatar>
        <Stack>
          <Typography variant="h4">
            {!id ? "Add View" : "Modify View"}
          </Typography>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">View Name</Typography>
        <Stack gap={1}>
          <Typography>
            Provide an effective name that describes the view.
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Add Tiles</Typography>
        <Stack gap={1}>
          <Typography>
            A tile can either display a system and its associated count or
            individual widgets from the selected system.
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Stack gap={1} direction={"row"}>
            <MultiSelect
              size="small"
              dark
              sx={{ width: "70%" }}
              options={systemOptions || []}
              loading={loading}
              disabled={loading}
              multiple={false}
              getOptionLabel={(e) =>
                e?.name || systemOptions?.find((s) => s.id === e.id)?.name || ""
              }
              isOptionEqualToValue={(o, v) => o.id === v.id}
              label="System"
              placeholder="Select a System"
              value={stagedSystem.system}
              setValue={(v) => {
                setStagedSystem((s) => ({ ...s, system: v, widgets: [] }));
              }}
            />
            <div style={{ width: "200px" }}>
              <Switch
                label={
                  stagedSystem.type === "system" ? "System Tile" : "Widget Tile"
                }
                checked={stagedSystem.type === "widget"}
                onChange={() => {
                  setStagedSystem((s) => ({
                    ...s,
                    type: stagedSystem.type === "widget" ? "system" : "widget",
                    widgets: [],
                  }));
                }}
              />
            </div>
          </Stack>
          {stagedSystem?.type === "widget" && !loading ? (
            <MultiSelect
              size="small"
              dark
              options={stagedSystemWidgets || []}
              loading={loading}
              getOptionLabel={(e) => e?.name}
              label="Widgets"
              placeholder="Select widgets to add"
              value={stagedSystem.widgets}
              setValue={(v) => {
                setStagedSystem((s) => ({ ...s, widgets: v }));
              }}
            />
          ) : null}
        </Stack>
        <Stack direction="row" gap={1} alignItems={"center"}>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              flex: 1,
            }}
          >
            {loading && <MiniLoader />}
          </div>
          <div style={{ marginLeft: "auto" }}>
            <ActionButton
              icon="add"
              dark
              noload
              disabled={loading}
              handler={async () => {
                let tiles = [];
                if (!stagedSystem.system) return;
                if (stagedSystem.type === "widget") {
                  stagedSystem.widgets.forEach((widget) => {
                    tiles.push({
                      type: "widget",
                      id: nanoid(),
                      name: `${stagedSystem.system.name} - ${widget.name}`,
                      system_id: stagedSystem.system.id,
                      widget_id: widget.id,
                    });
                  });
                } else {
                  tiles.push({
                    type: "system",
                    id: nanoid(),
                    name: stagedSystem.system.name,
                    system_id: stagedSystem.system.id,
                  });
                }
                setSystems((s) => [...s, ...tiles]);
                setStagedSystem({
                  system: null,
                  type: "system",
                  widgets: [],
                });
                setStagedSystemWidgets([]);
              }}
            >
              Add Tile
            </ActionButton>
          </div>
        </Stack>
      </Stack>
      {systems?.length ? (
        <Stack className="floating" gap={3}>
          <Typography variant="h5">Organize Tiles</Typography>
          <Stack gap={1}>
            <Typography>
              Organize your tiles to reflect how they are rendered to the view.
            </Typography>
          </Stack>
          <Stack gap={1}>
            <DnDList
              state={[systems, setSystems]}
              itemComponent={({ item }) => (
                <Stack
                  key={item.id}
                  sx={{ width: "100%" }}
                  ml={2}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <span
                    style={{
                      marginRight: "10px",
                      width: "420px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.name}
                  </span>
                  <IconButton
                    onClick={() =>
                      setSystems((s) => s.filter((s) => s.id !== item.id))
                    }
                    color="error"
                    sx={{ margin: 0, ml: "auto !important" }}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </Stack>
              )}
            />
          </Stack>
        </Stack>
      ) : null}
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Access Control</Typography>

        <Stack gap={1}>
          <ScopeInputs {...{ dark: true, size: "small" }} />
        </Stack>
      </Stack>
      {toggleStar ? (
        <Stack className="floating" gap={3}>
          <Typography variant="h5">Favorite View</Typography>
          <Stack gap={1}>
            <Typography>
              Set this view as favorite to load it immediately when navigating
              to the Nexus page.
            </Typography>
          </Stack>
          <div style={{ marginLeft: "auto" }}>
            <ActionButton
              icon="star"
              dark
              handler={async () => {
                await toggleStar();
              }}
            >
              {`${starred ? "Remove" : "Set"} as Favorite`}
            </ActionButton>
          </div>
        </Stack>
      ) : null}
      {deleteView && view !== 1 ? (
        <Stack className="floating" gap={3}>
          <Typography variant="h5">Delete View</Typography>
          <Stack gap={1}>
            <Typography>
              Deleting a view is permanent and cannot be undone.
            </Typography>
          </Stack>
          <div style={{ marginLeft: "auto" }}>
            <ActionButton
              danger
              noload
              icon="crisis_alert"
              dark
              handler={async () => {
                openDeleteModal(true);
              }}
            >
              Delete View
            </ActionButton>
          </div>
        </Stack>
      ) : null}
      <Stack mb={5}>
        <div style={{ marginLeft: "auto" }}>
          <ActionButton
            icon="save"
            dark
            disabled={loading}
            noload
            handler={async () => {
              onSave(onClose);
            }}
          >
            Save View
          </ActionButton>
        </div>
      </Stack>
    </Stack>
  );
}
