import React from "react";
import {
  Stack,
  IconButton,
  Grid,
  Icon,
  Typography,
  Card,
  Button,
} from "@mui/material";
import Checkbox from "common/components/Checkbox";
import Input from "common/components/Input";
import Modal from "common/components/Modal";
import Blurb from "constants/blurbs";
import Switch from "common/components/Switch";
import ActionButton from "common/components/ActionButton";
import IconPicker from "common/components/IconPicker";
export default function CommandGeneral({ data, setData, dataBacked }) {
  const [icon, setIcon] = React.useState("");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [updateData, setUpdateData] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  const [selectionBased, setSelectionBased] = React.useState(false);
  const [priority, setPriority] = React.useState(false);
  React.useEffect(() => {
    if (!data) return;
    setIcon(data?.icon || "");
    setName(data.name);
    setDescription(data.description);
    setSelectionBased(data?.selectionBased || false);
    setUpdateData(data?.updateData || false);
    setHidden(data?.hidden || false);
    setPriority(data?.priority || false);
  }, [data]);
  // const onSave = (close) => {
  //   setData({
  //     ...data,
  //     icon,
  //     name,
  //     description,
  //     updateData,
  //     selectionBased,
  //     hidden,
  //     priority,
  //   });
  //   close();
  // };
  return (
    <Stack m={0} gap={1} p={0}>
      <Stack gap={3}>
        <IconPicker
          value={icon}
          onChange={(icon) => setData({ ...data, icon })}
        />

        <Input
          size="small"
          dark
          sx={{ width: "100%" }}
          label="Description"
          defaultValue={description}
          onChange={(e) => setData({ ...data, description: e.target.value })}
          multiline={true}
          minRows={3}
          maxRows={3}
        />
        <Stack direction="row" alignItems="center" gap={3}>
          {dataBacked ? (
            <>
              <Checkbox
                text="Realtime"
                value={priority}
                onChange={(value) => {
                  setData({ ...data, priority: value });
                }}
                id="realtime-toggle"
              />
              <Checkbox
                // key={random}
                text="Update Dataset"
                value={updateData}
                onChange={(value) => {
                  setData({ ...data, updateData: value });
                }}
                id="update-dataset-toggle"
              />
              <Checkbox
                // key={random}
                text="Use Selection"
                value={selectionBased}
                onChange={(value) => {
                  setData({ ...data, selectionBased: value });
                }}
                id="selection-toggle"
              />
            </>
          ) : (
            <>
              <Checkbox
                text="Realtime"
                // key={random}
                value={priority}
                onChange={(value) => {
                  setData({ ...data, priority: value });
                }}
                id="realtime-toggle"
              />
              {/* <Switch
              label={"Realtime"}
              checked={priority}
              onChange={() => {
                setPriority(!priority);
              }}
            />
            <Typography variant="subtitle1">
              An action that runs within seconds and provides real time feedback
              on execution.
            </Typography> */}
            </>
          )}
          <Checkbox
            text="Hidden"
            value={hidden}
            onChange={(value) => {
              setData({ ...data, hidden: value });
            }}
            id="hidden-toggle"
          />
        </Stack>
      </Stack>

      {/* <Switch
          label={"Hidden"}
          checked={hidden}
          onChange={() => {
            setHidden(!hidden);
          }}
        />
        <Typography variant="subtitle1">
          Toggling this option hides the command from the command palette.
        </Typography> */}

      {/* <div style={{ marginLeft: "auto" }}>
        <ActionButton icon="save" handler={() => onSave(handleClose)}>
          Save Configurations
        </ActionButton>
      </div> */}
    </Stack>
    // <Card className="floating" sx={{ padding: 2, background: "#171717" }}>
    //   <Stack sx={{ position: "relative" }} spacing={1}>
    //     <Modal
    //       button={(props) => (
    //         <IconButton sx={{ position: "absolute", right: 0 }} {...props}>
    //           <Icon>edit</Icon>
    //         </IconButton>
    //       )}
    //     >
    //       {({ handleClose }) => (
    //         <Stack spacing={3}>
    //           <Typography variant="h5">General</Typography>
    //           <IconSelector {...{ icon, setIcon }} />
    //           <Input
    //             size="small"
    //             sx={{ width: "100%" }}
    //             label="Name"
    //             value={name}
    //             onChange={(e) => setName(e.target.value)}
    //           />
    //           <Input
    //             size="small"
    //             sx={{ width: "100%" }}
    //             label="Description"
    //             value={description}
    //             onChange={(e) => setDescription(e.target.value)}
    //             multiline={true}
    //             minRows={3}
    //             maxRows={3}
    //           />
    //           {dataBacked ? (
    //             <Stack spacing={1}>
    //               <Stack spacing={1}>
    //                 <Switch
    //                   label={"Update Dataset"}
    //                   checked={updateData}
    //                   onChange={() => {
    //                     setUpdateData(!updateData);
    //                   }}
    //                 />
    //                 <Typography variant="subtitle1">
    //                   The return of the handler function should be a list and
    //                   will overwrite the current dataset.
    //                 </Typography>
    //               </Stack>
    //               <Stack spacing={1}>
    //                 <Switch
    //                   label={"Use Selection"}
    //                   checked={selectionBased}
    //                   onChange={() => {
    //                     setSelectionBased(!selectionBased);
    //                   }}
    //                 />
    //                 <Typography variant="subtitle1">
    //                   The action requires a selection of resources to be made
    //                   before executing.
    //                 </Typography>
    //               </Stack>
    //               <Stack spacing={1}>
    //                 <Switch
    //                   label={"Realtime"}
    //                   checked={priority}
    //                   onChange={() => {
    //                     setPriority(!priority);
    //                   }}
    //                 />
    //                 <Typography variant="subtitle1">
    //                   An action that runs within seconds and provides real time
    //                   feedback on execution.
    //                 </Typography>
    //               </Stack>
    //             </Stack>
    //           ) : (
    //             <Stack spacing={1}>
    //               <Stack spacing={1}>
    //                 <Switch
    //                   label={"Realtime"}
    //                   checked={priority}
    //                   onChange={() => {
    //                     setPriority(!priority);
    //                   }}
    //                 />
    //                 <Typography variant="subtitle1">
    //                   An action that runs within seconds and provides real time
    //                   feedback on execution.
    //                 </Typography>
    //               </Stack>
    //             </Stack>
    //           )}
    //           <Stack spacing={1}>
    //             <Switch
    //               label={"Hidden"}
    //               checked={hidden}
    //               onChange={() => {
    //                 setHidden(!hidden);
    //               }}
    //             />
    //             <Typography variant="subtitle1">
    //               Toggling this option hides the command from the command
    //               palette.
    //             </Typography>
    //           </Stack>

    //           <div style={{ marginLeft: "auto" }}>
    //             <ActionButton icon="save" handler={() => onSave(handleClose)}>
    //               Save Configurations
    //             </ActionButton>
    //           </div>
    //         </Stack>
    //       )}
    //     </Modal>
    //     <Stack spacing={2} direction="row" alignItems="flex-start">
    //       {/* <Avatar sx={{ height: 30, width: 30, background: "#5842a5" }}>
    //         <Icon sx={{ fontSize: "20px !important" }}>info</Icon>
    //       </Avatar> */}
    //       <Stack mb={2}>
    //         <Typography variant="h5" fontWeight={"bold"}>
    //           General
    //         </Typography>
    //         <Blurb>command_general</Blurb>
    //       </Stack>
    //     </Stack>
    //     {/* <Typography variant="subtitle">Name</Typography> */}
    //     <Stack direction="row" alignItems="center" spacing={1}>
    //       {icon ? <Icon>{icon}</Icon> : null}
    //       <Typography sx={{ margin: 0 }} fontWeight={"bold"} variant="body1">
    //         {name || "-"}
    //       </Typography>
    //     </Stack>
    //     {/* <Typography variant="subtitle">Description</Typography> */}
    //     <Typography sx={{ margin: 0 }} variant="body1">
    //       {description || "-"}
    //     </Typography>
    //     {dataBacked ? (
    //       <Grid container spacing={1}>
    //         <Grid item xs={12} md={4}>
    //           <Switch
    //             label={"Update Data"}
    //             checked={updateData}
    //             disabled={true}
    //           />
    //         </Grid>
    //         <Grid item xs={12} md={4}>
    //           <Switch
    //             label={"Use Selection"}
    //             checked={selectionBased}
    //             disabled={true}
    //           />
    //         </Grid>
    //         <Grid item xs={12} md={4}>
    //           <Switch label={"Hidden"} checked={hidden} disabled={true} />
    //         </Grid>
    //         <Grid item xs={12} md={6}>
    //           <Switch
    //             label={priority ? "Realtime" : "Job"}
    //             checked={priority}
    //             disabled={true}
    //           />
    //         </Grid>
    //       </Grid>
    //     ) : (
    //       <Grid container spacing={1}>
    //         <Grid item xs={12} md={4}>
    //           <Switch label={"Hidden"} checked={hidden} disabled={true} />
    //         </Grid>
    //         <Grid item xs={12} md={6}>
    //           <Switch
    //             label={priority ? "Realtime" : "Job"}
    //             checked={priority}
    //             disabled={true}
    //           />
    //         </Grid>
    //       </Grid>
    //     )}
    //   </Stack>
    // </Card>
  );
}
