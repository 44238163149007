import { isExpired, decodeToken } from "react-jwt";
import React from "react";
import { useNavigate } from "react-router-dom";

const getLocalStorage = (item) => {
  return new Promise((resolve, reject) => {
    try {
      let session = localStorage.getItem(item);
      resolve(session);
    } catch {
      resolve(null);
    }
  });
};

const fetchSession = async () => {
  let session = await getLocalStorage("E1_JWT");
  if (!session) return [null, null, null];
  session = JSON.parse(session);
  let accessToken = session?.access_token;
  if (isExpired(accessToken)) {
    localStorage.removeItem("E1_JWT");
    return [null, null, null];
  }
  return [session, accessToken, decodeToken(accessToken)];
};

export const useAuthentication = () => {
  const [session, setSession] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const navigate = useNavigate();
  const fetchSessionHandler = async () => {
    if (session) {
      if (isExpired(session)) {
        if (window.location.pathname !== "/login") navigate("/login");
        // navigate("/login");
        return;
      }
      return;
    }
    const [s, accessToken, u] = await fetchSession();
    if (accessToken) {
      setSession(accessToken);
      setUser(u);
      return;
    } else {
      setSession(null);
      setUser(null);
      if (window.location.pathname !== "/login") navigate("/login");
    }
  };

  React.useEffect(() => {
    if (session && !isExpired(session)) return;
    fetchSessionHandler();
    window.addEventListener("storage", fetchSessionHandler);
    return () => window.removeEventListener("storage", fetchSessionHandler);
  }, []);
  return user;
};
export const login = (session) => {
  localStorage.setItem("E1_JWT", JSON.stringify(session));
  dispatchEvent(new Event("storage"));
};

export const logout = () => {
  localStorage.removeItem("E1_JWT");
  dispatchEvent(new Event("storage"));
};

export const authFetch = async (input, init) => {
  const [session, accessToken, user] = await fetchSession();
  if (isExpired(accessToken)) {
    logout();
  }
  init = init || {};
  if (accessToken) {
    init.headers = Object.assign({}, init?.headers, {
      Authorization: "Bearer " + accessToken,
    });
  }
  return fetch(input, init);
};
