import { useRef, useEffect } from "react";
import toast from "react-hot-toast";
import { useAsyncError } from "./useAsyncError";
import { authFetch } from "auth";
export default function useDownloadFile({ name, endpoint, notify }) {
  const throwError = useAsyncError();
  const download = async () => {
    try {
      toast.dismiss();
      const toaster = toast.loading(notify, {
        duration: Infinity,
      });
      const response = await authFetch(endpoint);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      a.remove();
      toast.dismiss(toaster);
    } catch (e) {
      toast.dismiss();
      throwError(e);
    }
  };
  return download;
}
