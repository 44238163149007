import { Search } from "@mui/icons-material";
import {
  Popover,
  TextField,
  InputAdornment,
  Button,
  Divider,
  Avatar,
  Skeleton,
  Icon,
  IconButton,
} from "@mui/material";
import MIcons from "assets/icons/m_icons.json";
import { useState } from "react";
import { themeOptions } from "theme";

const IconPicker = ({ value, onChange }) => {
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const iconPack = MIcons;

  if (!iconPack) {
    return <Skeleton variant="rectangular" width={210} height={40} />;
  }

  const iconsFiltered = iconPack.filter((icon) => {
    return icon.includes(searchText.toLowerCase());
  });

  return (
    <>
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        startIcon={
          value ? (
            <Icon sx={{ fontSize: "1.5rem !important" }}>{value}</Icon>
          ) : null
        }
        variant="contained"
        sx={{
          background: themeOptions.palette.primary.dark,
          fontSize: ".8rem !important",
          width: "150px",
        }}
      >
        {value ? "Change Icon" : "Select Icon"}
      </Button>
      <Popover
        className="iconPicker"
        id={anchorEl ? "iconPickerPopover" : undefined}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            marginTop: "10px",
            overflow: "visible",
          },
          className: "iconPicker__paper",
        }}
      >
        <div
          style={{
            boxSizing: "border-box",
            width: "320px",
            height: "100%",
            minHeight: "200px",
            maxHeight: "450px",
            display: "flex",
            flexDirection: "column",
            color: "#333",
            fontSize: "1rem",
          }}
        >
          <div
            style={{ padding: "1rem" }}
            className="iconPicker__popoverHeader"
          >
            <TextField
              sx={{
                background: themeOptions.palette.primary.dark,
                borderRadius: "8px",
              }}
              fullWidth
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: "#fff" }} />
                  </InputAdornment>
                ),
              }}
              size="small"
              variant="outlined"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <Divider />
          <div
            style={{
              width: "100%",
              flex: "1 1 auto",
              overflowY: "auto",
              overflowX: "hidden",
              padding: "0.75rem",
              display: "flex",
              flexWrap: "wrap",
              alignContent: "flex-start",
            }}
            className="iconPicker__iconsContainer"
          >
            {iconsFiltered.map((icon) => (
              <div
                style={{
                  minWidth: "1/6 * 100%",
                  padding: "0.25rem",
                }}
                className="iconPicker__iconWrapper"
                key={icon}
              >
                <button
                  style={{
                    background: themeOptions.palette.primary.dark,
                    display: "inline-flex",
                    width: "100%",
                    height: "100%",
                    minHeight: "30px",
                    textAlign: "center",
                    padding: "6px 4px",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    borderRadius: "4px",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  className={`iconPicker__iconItem ${
                    icon === value ? "selected" : ""
                  }`}
                  title={icon}
                  onClick={() => {
                    onChange?.(icon);
                    setSearchText("");
                    setAnchorEl(null);
                  }}
                >
                  <Icon>{icon}</Icon>
                </button>
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </>
  );
};
export default IconPicker;
