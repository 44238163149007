export default function getUserFriendlyDate(
  timestamp = "2023-11-15_02:29:42:846157"
) {
  let dateTimeParts = timestamp.split("_");
  let dateParts = dateTimeParts[0].split("-");
  let timeParts = dateTimeParts[1].split(":");

  let date = new Date(
    Date.UTC(
      dateParts[0],
      dateParts[1] - 1,
      dateParts[2],
      timeParts[0],
      timeParts[1],
      timeParts[2]
    )
  );

  let options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  let formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return formattedDate;
}
