import React from "react";
import Input from "common/components/Input";
import { IconButton, Icon, Stack, Typography } from "@mui/material";
import List from "common/components/List";
import { TYPE_INPUT_NORMALIZE } from "constants";
import DatePicker from "common/components/DatePicker";
import Switch from "common/components/Switch";
import MultiSelect from "../MultiSelect";
import FileUpload from "../FileUpload";
import Checkbox from "../Checkbox";
const JsonCreator = React.forwardRef(({ type, name }, ref) => {
  const [json, setJson] = React.useState(null);
  const [newArrayItem, setNewArrayItem] = React.useState("");
  const [key, setKey] = React.useState("");
  const [value, setValue] = React.useState("");
  const el = React.useMemo(() => ref?.current?.querySelector("textarea"), [
    ref,
  ]);
  React.useEffect(() => {
    const el = ref?.current?.querySelector("textarea");
    if (!ref) return;
    if (type === "array") {
      try {
        const current = JSON.parse(el.value, null, 4);
        if (!Array.isArray(current)) {
          el.value = "[]";
        }
      } catch (e) {
        el.value = "[]";
      }
    }
    if (type === "object") {
      try {
        const current = JSON.parse(el.value, null, 4);
        if (Array.isArray(current) || !typeof current === "object") {
          el.value = "{}";
        }
      } catch (e) {
        el.value = "{}";
      }
    }
    setJson(JSON.parse(el.value));
  }, [type, ref]);
  if (
    json &&
    type === "object" &&
    !Array.isArray(json) &&
    typeof json === "object"
  )
    return (
      <Stack spacing={2}>
        <Typography variant={"subtitle2"}>{name}</Typography>
        <Stack alignItems="center" spacing={2} direction="row">
          <Input
            sx={{ width: "80%" }}
            label="new object key"
            value={key}
            onChange={(e) => setKey(e.target.value)}
          />
          <Input
            sx={{ width: "80%" }}
            label="new object value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <IconButton
            onClick={() => {
              if (!key || !value) return;
              let v = value;
              try {
                v = JSON.parse(value);
              } catch (e) {}
              let update = { ...json, [key]: v };
              try {
                setJson(update);
                setKey("");
                setValue("");
                update = JSON.stringify(update, null, 4);
                el.value = update;
              } catch (e) {}
            }}
            color="success"
          >
            <Icon color="success">add</Icon>
          </IconButton>
        </Stack>
        <List
          items={
            typeof json === "object" && !Array.isArray(json)
              ? Object.entries(json)?.map(([k, v]) => ({
                  text: (
                    <Stack direction="row" spacing={1}>
                      <div>{`${k}: ${
                        typeof v === "object" ? JSON.stringify(v, null, 4) : v
                      } `}</div>
                      <IconButton
                        onClick={() => {
                          const j = { ...json };
                          delete j[k];
                          setJson(j);
                          el.value = JSON.stringify(j, null, 4);
                        }}
                        color="error"
                        sx={{ margin: 0, ml: "auto !important" }}
                      >
                        <Icon color="error">clear</Icon>
                      </IconButton>
                    </Stack>
                  ),
                }))
              : []
          }
        />
      </Stack>
    );
  if (json && type === "array" && Array.isArray(json))
    return (
      <Stack spacing={2}>
        <Typography variant={"subtitle2"}>{name}</Typography>
        <Stack alignItems="center" direction="row">
          <Input
            sx={{ width: "80%" }}
            label="new array item"
            value={newArrayItem}
            onChange={(e) => setNewArrayItem(e.target.value)}
          />
          <IconButton
            onClick={() => {
              if (!newArrayItem) return;
              let n = newArrayItem;
              try {
                n = JSON.parse(newArrayItem);
              } catch (e) {}
              let update = [...json, n];
              try {
                setJson(update);
                setNewArrayItem("");
                update = JSON.stringify(update, null, 4);
                el.value = update;
              } catch (e) {}
            }}
            color="success"
          >
            <Icon color="success">add</Icon>
          </IconButton>
        </Stack>
        <List
          items={
            json?.map((a, idx) => ({
              text: (
                <Stack direction="row" spacing={1}>
                  <div>{JSON.stringify(a, null, 4)}</div>
                  <IconButton
                    onClick={() => {
                      const j = [...json];
                      j.splice(idx, 1);
                      setJson(j);
                      el.value = JSON.stringify(j, null, 4);
                    }}
                    color="error"
                    sx={{ margin: 0, ml: "auto !important" }}
                  >
                    <Icon color="error">clear</Icon>
                  </IconButton>
                </Stack>
              ),
            })) || []
          }
        />
      </Stack>
    );
  return null;
});
const E1Input = ({ type, sx = {}, opts, defaultValue, ...rest }) => {
  const input = React.useRef();
  const [checkValue, setCheckValue] = React.useState(defaultValue);
  const [date, setDate] = React.useState(defaultValue || new Date());
  const [jsonViz, setJsonViz] = React.useState(false);
  const inputType = {
    str: "string",
    lstr: "string",
    object: "string",
    list: "string",
    int: "number",
    float: "number",
    bool: "bool",
    password: "password",
    date: "date",
    dropdown: "dropdown",
    file: "file",
  }[type];
  const options = ["list", "object", "lstr"].includes(type)
    ? {
        multiline: true,
        maxRows: 10,
        minRows: 10,
      }
    : {};
  // if (inputType === "date") {
  //   return (
  //     <div
  //       style={{
  //         ...sx,
  //         width: sx.width || "100%",
  //         display: "flex",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <DatePicker selected={date} onChange={setDate} {...rest} />
  //     </div>
  //   );
  // }
  if (inputType === "file") {
    return (
      <div style={{ ...sx, width: sx.width || "100%" }}>
        <FileUpload
          sizeInKb={100000}
          {...rest}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "10px",
            backgroundColor: "#171717",
            padding: "10px",
          }}
        >
          {rest.label}
        </FileUpload>
      </div>
    );
  }
  if (inputType === "bool") {
    return (
      <div style={{ ...sx, width: sx.width || "100%" }}>
        <Checkbox
          text={rest?.help}
          value={checkValue}
          onChange={(value) => {
            setCheckValue(value);
          }}
          id={rest?.label}
          inputProps={rest?.inputProps || {}}
        />
        {/* <Switch
          checked={checkValue}
          onChange={() => setCheckValue(!checkValue)}
          {...rest}
        /> */}
      </div>
    );
  }
  if (inputType === "dropdown") {
    return (
      <div style={{ ...sx, width: sx.width || "100%" }}>
        <MultiSelect
          options={JSON.parse(opts)}
          getOptionLabel={(e) => e}
          defaultValue={defaultValue}
          multiple={false}
          placeholder="select option"
          size="small"
          sx={sx}
          dark
          shrink
          {...options}
          {...rest}
        />
        {/* <Switch
          checked={checkValue}
          onChange={() => setCheckValue(!checkValue)}
          {...rest}
        /> */}
      </div>
    );
  }
  return (
    <Stack sx={{ flex: 1 }}>
      {/* {type === "json" ? (
        <Stack justifyContent="end" direction="row" spacing={1}>
          <IconButton
            onClick={() => {
              setJsonViz("array");
            }}
          >
            <Icon>data_array</Icon>
          </IconButton>
          <IconButton
            onClick={() => {
              setJsonViz("object");
            }}
          >
            <Icon>data_object</Icon>
          </IconButton>
          <IconButton
            onClick={() => {
              setJsonViz(false);
            }}
          >
            <Icon>power_input</Icon>
          </IconButton>
          <IconButton
            onClick={() => {
              const el = input.current.querySelector("textarea");
              el.value = JSON.stringify(JSON.parse(el.value), null, 4);
            }}
          >
            <Icon>segment</Icon>
          </IconButton>
        </Stack>
      ) : null} */}
      {/* {jsonViz ? (
        <JsonCreator name={rest.label} type={jsonViz} ref={input} />
      ) : null} */}

      <Input
        ref={input}
        type={inputType}
        defaultValue={defaultValue}
        {...options}
        {...rest}
        sx={sx}
        dark
        size="small"
      />
    </Stack>
  );
};
export default E1Input;
