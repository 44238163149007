import React from "react";
import {
  Stack,
  Icon,
  Grid,
  IconButton,
  Chip,
  Typography,
  InputAdornment,
} from "@mui/material";
import ActionButton from "common/components/ActionButton";
import Input from "common/components/Input";
import MultiSelect from "common/components/MultiSelect";
import { useLocation } from "react-router-dom";
import ViewEdit from "pages/Nexus/components/ViewEdit";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "common/components/Loader";
import Page from "common/components/Page";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import rootFetcher from "common/utils/fetcher";
import useFetcher from "common/hooks/useFetcher";
import Blurb from "constants/blurbs";
import AlertEmote from "common/components/AlertEmote";
import { Text } from "constants/blurbs";
import SidePanel from "common/components/SidePanel";
const Tile = ({ view, setEdit }) => {
  const navigate = useNavigate();
  return (
    <Grid
      md={3.9}
      sx={{
        flex: 1,
        position: "relative",
      }}
      className="user-card-title-ctn"
      item
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          alignItems: "flex-start",
          flex: 1,
        }}
        className="floating user-card-tile"
      >
        <Stack
          sx={{ height: "100%", justifyContent: "space-between" }}
          flex={1}
          gap={1}
          direction="column"
        >
          <Stack direction="row" gap={1} alignItems={"center"}>
            <Stack>
              <span>{view?.name}</span>
              <Typography variant="caption">{view?.owner_name}</Typography>
            </Stack>
            <div
              style={{
                marginLeft: "auto",
              }}
            >
              <Chip
                sx={{ fontWeight: "bold" }}
                color="secondary"
                label={view?.tiles?.length > 0 ? "+" + view.tiles.length : 0}
              />
            </div>
          </Stack>

          <Stack sx={{ marginLeft: "auto" }}>
            {view.tags.length ? (
              <Typography
                sx={{ height: "20px", fontWeight: "bold" }}
                variant="body2"
              >
                {view.tags.join(", ")}
              </Typography>
            ) : (
              <Typography
                sx={{ height: "20px", fontWeight: "bold" }}
                variant="body2"
              ></Typography>
            )}
          </Stack>
        </Stack>
      </div>
      <div className="user-card-tile__options">
        <IconButton
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            backgroundColor: "#28ebc0",
          }}
          onClick={() => navigate(`/app/nexus/${view.pk}`)}
        >
          <Icon
            sx={{
              color: "#fff !important",
            }}
          >
            dashboard
          </Icon>
        </IconButton>
        <IconButton
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            backgroundColor: "#ff711a",
          }}
          onClick={() => setEdit(view)}
        >
          <Icon
            sx={{
              color: "#fff !important",
            }}
          >
            tune
          </Icon>
        </IconButton>
      </div>
    </Grid>
  );
};

const SystemTile = ({ name, id, count, navigate }) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        action={
          <IconButton onClick={() => navigate(`/app/sys/${id}/dash`, "_blank")}>
            <Icon>arrow_forward</Icon>
          </IconButton>
        }
        title={name}
      />
      <CardContent>
        <Stack alignItems="center" justifyContent="center">
          <Typography variant="h1">{count}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
const SystemTiles = ({ systems }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, 350px)",
        gap: "10px",
        marginBottom: "50px",
      }}
    >
      {systems.map(({ name, count = 0, id }) => (
        <SystemTile
          key={id}
          id={id}
          name={name}
          count={count}
          navigate={navigate}
        />
      ))}
    </div>
  );
};

const NoViews = ({ createNew }) => {
  return (
    <Stack sx={{ height: "70vh" }} justifyContent="center" alignItems="center">
      <AlertEmote
        icon="dashboard"
        text={"No Nexus Views yet."}
        buttonText="Create View"
        onClick={createNew}
      />
    </Stack>
  );
};

const View = ({ content, edit, views, refresh }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const view = searchParams.get("view");
  const fetcher = useFetcher();
  const deleteView = () => {
    const payload = { views: views.filter((v) => v.id !== content.id) };
    fetcher.post(
      "deleting view",
      `/api/nexus/views`,
      payload,
      ({ error }) => {
        if (error) return toast.error(error);
        if (view) {
          setSearchParams({});
        } else {
          refresh();
        }
      },
      () => {}
    );
  };
  const systems = content?.content?.filter((c) => c.sk === "SYS") || [];
  const viewsNav =
    views?.map((v) => [
      "dashboard",
      `Go to ${v.name} view`,
      () => setSearchParams({ view: v.id }),
    ]) || [];
  const viewAdd =
    views.length > 10 ? [] : [["add", "Create View", () => edit(1)]];
  return (
    <Page
      menu={[
        ["edit", "Edit View", () => edit(content.id)],
        ["clear", "Delete View", () => deleteView(), null, !content?.id],
        ...viewAdd,
        ...viewsNav,
      ]}
      refresh={refresh}
      header={`${content.name} view`}
    >
      <Blurb>view_general</Blurb>
      <SystemTiles systems={systems} />
    </Page>
  );
};
export default function Nexus({ role }) {
  const [edit, setEdit] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [tag, setTag] = React.useState("");
  const [crewSearch, setCrewSearch] = React.useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const view = searchParams.get("view");
  const fetcher = useFetcher();
  const [loading, setLoading] = React.useState(false);
  const [views, setViews] = React.useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const fetchUserPreference = async () => {
    let { error, view } = await rootFetcher(`/api/nexus/views/default`);
    return view;
  };
  const fetchView = async () => {
    setLoading(true);
    try {
      let { error, views } = await rootFetcher(`/api/nexus/views`);
      const previousRoute = location.state?.from;
      if (!previousRoute) {
        let view = await fetchUserPreference();
        if (view) {
          return navigate(`/app/nexus/${view}`);
        }
      }
      if (!error) {
        setViews(views);
      }
    } catch {}
    setLoading(false);
  };
  const deleteView = async (id) => {
    await fetcher.delete(
      `deleting view`,
      `/api/nexus/views/${id}`,
      () => fetchView(),
      () => {}
    );
  };
  React.useEffect(() => {
    fetchView();
  }, [view]);
  const searchFilter = () => {
    if (!views) return [];
    if (!search && !crewSearch && !tag) return views;
    let filter = [...views];
    if (tag) {
      filter = filter.filter(({ tags }) => tags.includes(tag));
    }
    if (crewSearch) {
      filter = filter.filter(
        ({ owner_name, owner }) =>
          owner_name === crewSearch || owner === crewSearch
      );
    }
    if (search) {
      filter = filter.filter(({ name, description }) =>
        name.toLowerCase().includes(search.toLowerCase())
      );
    }
    return filter;
  };
  let filteredViews = searchFilter();

  if (loading) return <Loader />;
  if (!views.length)
    return (
      <>
        <NoViews createNew={() => setEdit(1)} />
        <SidePanel
          open={edit}
          width={600}
          closeDrawer={() => setEdit(false)}
          props={{
            view: edit,
            refresh: fetchView,
            deleteView: !["auditor", "operator"].includes(role)
              ? deleteView
              : null,
          }}
        >
          {ViewEdit}
        </SidePanel>
      </>
    );
  return (
    <Page
      actions={
        !["auditor", "operator"].includes(role)
          ? [
              <ActionButton
                icon={"add"}
                noload
                handler={async () => {
                  setEdit(1);
                }}
              >
                New View
              </ActionButton>,
            ]
          : null
      }
      refresh={fetchView}
      header={`Nexus`}
    >
      <SidePanel
        open={edit}
        width={600}
        closeDrawer={() => setEdit(false)}
        props={{
          view: edit,
          refresh: fetchView,
          deleteView: !["auditor", "operator"].includes(role)
            ? deleteView
            : null,
        }}
      >
        {ViewEdit}
      </SidePanel>
      <Stack
        sx={{ marginLeft: "auto", justifyContent: "flex-end" }}
        gap={1}
        direction={"row"}
      >
        <div style={{ marginBottom: "20px" }}>
          <Input
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearch("")} edge="end">
                    {search ? (
                      <Icon sx={{ fontSize: "18px !important" }} color="error">
                        close
                      </Icon>
                    ) : null}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="search..."
            style={{ marginLeft: "auto", width: "300px" }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <MultiSelect
          size="small"
          options={Array.from(
            new Set(
              views?.reduce(
                (a, t) => [...a, ...(t?.owner_name ? [t?.owner_name] : [])],
                []
              )
            )
          )}
          loading={loading}
          label="Crew Filter"
          multiple={false}
          placeholder="Select a crew"
          value={crewSearch}
          setValue={setCrewSearch}
          sx={{ width: "200px" }}
        />
        <MultiSelect
          size="small"
          options={Array.from(
            new Set(views?.reduce((a, t) => [...a, ...t.tags], []))
          )}
          loading={loading}
          label="Tag Filter"
          multiple={false}
          placeholder="Select a tag"
          value={tag}
          setValue={setTag}
          sx={{ width: "200px" }}
        />
      </Stack>
      <Stack spacing={2}>
        <Stack alignItems="start" spacing={2}>
          {filteredViews.length ? (
            <Grid gap={1} container>
              {filteredViews?.map((data, idx) => {
                return <Tile view={data} key={data.pk} setEdit={setEdit} />;
              })}
            </Grid>
          ) : null}
        </Stack>
      </Stack>
    </Page>
  );
}
