import React from "react";
import SidePanel from "common/components/SidePanel";
import {
  Grid,
  Stack,
  Icon,
  IconButton,
  Avatar,
  Typography,
} from "@mui/material";
import { nanoid } from "nanoid";
import { TypeMap } from "constants";
import DnDList from "common/components/DndList";
import SectionHeader from "common/components/SectionHeader";
import Input from "common/components/Input";
import MultiSelect from "common/components/MultiSelect";
import Switch from "common/components/Switch";
import Checkbox from "common/components/Checkbox";
import ColorPicker from "common/components/ColorPicker";
import ActionButton from "common/components/ActionButton";
import toast from "react-hot-toast";

const ColorBadge = ({ small, color = "purple", index, ...params }) => {
  let sizePx = small ? "20px" : "40px";
  let offset = small ? 15 : 20;
  return (
    <div
      style={{
        cursor: "pointer",
        position: "relative",
        height: sizePx,
        width: sizePx,
        left: `-${offset * index}px`,
        border: "1px solid #eee",
        borderRadius: "50%",
        backgroundColor: color,
      }}
      {...params}
    />
  );
};
export const ImportJSONPanel = ({ setProperties, onClose }) => {
  const [payload, setPayload] = React.useState("");
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Stack>
          <Typography variant="h4">Import Properties via JSON</Typography>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Stack gap={2}>
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            placeholder="JSON Object"
            value={payload}
            onChange={(e) => setPayload(e.target.value)}
            multiline
            minRows={20}
            maxRows={20}
          />
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto", display: "flex" }}>
        <ActionButton
          icon="upload"
          dark
          noload
          handler={async () => {
            try {
              let props = JSON.parse(payload);
              let existingProps = Object.values(props).map((p) => p.name);
              let propArray = Object.entries(props).reduce((a, [k, v]) => {
                if (existingProps.includes(k)) return a;
                let t = typeof v;
                let type =
                  t === "string"
                    ? "str"
                    : t === "number"
                    ? Number.isInteger(v)
                      ? "int"
                      : "float"
                    : t === "boolean"
                    ? "bool"
                    : Array.isArray(v)
                    ? "list"
                    : t === "object"
                    ? "object"
                    : "str";
                const property = {
                  id: nanoid(),
                  name: k,
                  width: "",
                  type,
                  colors: [],
                };

                return [...a, property];
              }, []);
              setProperties((ps) => [...ps, ...propArray]);
              onClose();
            } catch (e) {
              toast.error(
                "Error occurred parsing JSON. Note that nested JSON objects are not supported. Please verify the JSON and try again."
              );
            }
          }}
        >
          Import Properties
        </ActionButton>
      </div>
    </Stack>
  );
};
export const PropertyPanel = ({ prop, setProperties, onClose }) => {
  const [name, setName] = React.useState(prop?.name || "");
  const [width, setWidth] = React.useState(prop?.width || "");
  const [uid, setUid] = React.useState(prop?.uid || false);
  const [hidden, setHidden] = React.useState(prop?.hidden || false);
  const [type, setType] = React.useState(prop?.type || "");
  const [colorId, setColorId] = React.useState(null);
  const [color, setColor] = React.useState("#a827fa");
  const [colors, setColors] = React.useState(prop?.colors || []);
  const [expression, setExpression] = React.useState('resource["Type"]');
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
          width="100%"
        >
          <Typography variant="h4">
            {prop?.id ? "Update" : "Create"} Property
          </Typography>
          <div>
            <ActionButton
              icon="save"
              dark
              noload
              handler={async () => {
                if (!prop?.id) {
                  setProperties((props) => {
                    let otherProps = props;
                    if (uid) {
                      otherProps = otherProps.map((p) => {
                        delete p.uid;
                        return p;
                      });
                    }
                    return [
                      ...otherProps,

                      {
                        id: nanoid(),
                        name,
                        width,
                        uid,
                        hidden,
                        type,
                        colors,
                      },
                    ];
                  });
                } else {
                  setProperties((props) => {
                    return props.map((p) => {
                      if (p.id !== prop?.id) {
                        if (uid) {
                          delete p.uid;
                        }
                        return p;
                      }
                      return {
                        ...prop,
                        name,
                        width,
                        uid,
                        hidden,
                        type,
                        colors,
                      };
                    });
                  });
                }
                onClose();
              }}
            >
              Save
            </ActionButton>
          </div>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">General</Typography>
        <Stack gap={1}>
          <Typography>Identifying information for your property.</Typography>
        </Stack>
        <Stack gap={2}>
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            placeholder="Column Width"
            value={width}
            type="number"
            onChange={(e) => setWidth(e.target.value)}
          />
          {!uid ? (
            <MultiSelect
              size="small"
              dark
              options={Object.keys(TypeMap)}
              loading={false}
              label="Type"
              multiple={false}
              placeholder="Select a type"
              value={type}
              setValue={setType}
              sx={{ width: "100%" }}
            />
          ) : null}
          <Stack direction="row" alignItems="center">
            <Checkbox
              text="System Unique Identifier"
              value={uid}
              onChange={(value) => {
                if (value) {
                  setType("str");
                }
                setUid(value);
              }}
              id="update-sys-ui"
            />
            <Checkbox
              sx={{ marginLeft: "15px" }}
              text="Hidden"
              value={hidden}
              onChange={(value) => {
                setHidden(value);
              }}
              id="update-hidden-prop"
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Cell Coloring</Typography>
        <Stack gap={1}>
          <Typography>
            Define up to 5 cell color conditions for given expressions.
          </Typography>
        </Stack>
        <Stack gap={3}>
          <ColorPicker {...{ color, setColor }}>Cell Color</ColorPicker>
          <Input
            size="small"
            disabled={(colors?.length ?? 0) >= 5 && !colorId}
            dark
            label="Expression"
            sx={{ width: "100%" }}
            placeholder='resource["Type"]'
            value={expression}
            multiline
            minRows={4}
            maxRows={4}
            onChange={(e) => setExpression(e.target.value)}
          />
          <Stack
            direction="row"
            style={{ marginLeft: "auto", display: "flex" }}
            gap={1}
          >
            {colorId ? (
              <>
                <ActionButton
                  icon="delete"
                  danger
                  noload
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                  }}
                  dark
                  handler={async () => {
                    setColors((colors) =>
                      colors.filter((c) => c.id !== colorId)
                    );
                    setColorId(null);
                    setColor("#a827fa");
                    setExpression('resource["Type"]');
                  }}
                ></ActionButton>
                <ActionButton
                  icon="clear"
                  noload
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                  }}
                  dark
                  handler={async () => {
                    setColorId(null);
                    setColor("#a827fa");
                    setExpression('resource["Type"]');
                  }}
                ></ActionButton>
              </>
            ) : null}
            <ActionButton
              icon="add"
              disabled={(colors?.length ?? 0) >= 5 && !colorId}
              dark
              handler={async () => {
                if (colorId) {
                  setColors((colors) =>
                    colors.map((c) =>
                      c.id !== colorId ? c : { color, expression, id: colorId }
                    )
                  );
                } else {
                  setColors((colors) => [
                    ...colors,
                    { color, expression, id: nanoid() },
                  ]);
                }
                setColor("#a827fa");
                setColorId(null);
                setExpression('resource["Type"]');
              }}
            >
              {colorId ? "Update " : "Add "} Color
            </ActionButton>
          </Stack>
          <Stack
            sx={{ position: "relative", alignItems: "center" }}
            direction="row"
            gap={1}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Icon>palette</Icon>
            </span>
            {colors.map(({ id, color, expression }, index) => {
              return (
                <ColorBadge
                  onClick={() => {
                    setColorId(id);
                    setColor(color);
                    setExpression(expression);
                  }}
                  key={id}
                  color={color}
                  small
                  index={index + 1}
                />
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
const Property = ({ prop, setPanel, setProperties, systemUid }) => {
  return (
    <Grid
      sm={12}
      sx={{
        flex: 1,
        position: "relative",
      }}
      className="user-card-title-ctn"
      item
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          alignItems: "flex-start",
          flex: 1,
          padding: 0,
        }}
        className="floating "
      >
        <Stack
          sx={{
            height: "100%",
            position: "relative",
            justifyContent: "space-between",
          }}
          flex={1}
          gap={1}
          direction="column"
        >
          <Stack alignItems={"center"} direction="row" gap={10}>
            <Stack direction="row" gap={1} style={{ width: "350px" }}>
              <Icon>{TypeMap[prop.type]?.icon}</Icon>
              <span
                style={
                  systemUid === prop.name
                    ? {
                        background: "#ff914c",
                        padding: "5px 10px",
                        borderRadius: "15px",
                        color: "#171717",
                      }
                    : {}
                }
                className="user-card-tile__name"
              >
                {prop?.name}
              </span>
            </Stack>
            <Stack
              onClick={() => {
                setProperties((props) => {
                  let index = props.findIndex((x) => x.id === prop.id);
                  let properties = [...props];
                  properties[index] = {
                    ...properties[index],
                    hidden: !prop.hidden,
                  };
                  return properties;
                });
              }}
              sx={{ width: "60px" }}
              direction="column"
              alignItems={"center"}
              gap={1}
            >
              <Icon style={{ fontSize: "25px" }}>
                {prop?.hidden ? "visibility_off" : "visibility"}
              </Icon>
              <Typography variant="caption">
                {prop?.hidden ? "hidden" : "show"}
              </Typography>
            </Stack>
            <Stack
              sx={{ width: "60px" }}
              direction="column"
              alignItems={"center"}
              gap={1}
            >
              <Icon style={{ fontSize: "25px" }}>width_wide</Icon>
              <Typography variant="caption">
                {prop?.width ? prop?.width : "auto"}
              </Typography>
            </Stack>
            <Stack
              sx={{ position: "relative", alignItems: "center" }}
              direction="row"
              gap={1}
            >
              <Icon
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                palette
              </Icon>
              {!prop?.colors?.length ? (
                <Typography sx={{ position: "relative", left: "-5px" }}>
                  -
                </Typography>
              ) : null}
              {prop?.colors?.map(({ id, color, expression }, index) => {
                return (
                  <ColorBadge key={id} color={color} small index={index + 1} />
                );
              })}
            </Stack>
            <Stack
              direction="row"
              sx={{ marginLeft: "auto", marginRight: "20px" }}
              alignItems="center"
              gap={1}
            >
              <IconButton
                sx={{
                  height: "26px",
                  width: "26px",
                  borderRadius: "13px",
                  backgroundColor: "#ff711a",
                }}
                onClick={() => setPanel(prop)}
              >
                <Icon
                  sx={{
                    fontSize: "17px !important",
                    color: "#fff !important",
                  }}
                >
                  tune
                </Icon>
              </IconButton>
              <IconButton
                color="error"
                sx={{
                  height: "26px",
                  width: "26px",
                  borderRadius: "13px",
                  backgroundColor: "#FF6961",
                }}
                onClick={() => {
                  setProperties((props) =>
                    props.filter((p) => p.id !== prop.id)
                  );
                }}
              >
                <Icon
                  sx={{
                    fontSize: "17px !important",
                    color: "#fff !important",
                  }}
                >
                  close
                </Icon>
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </div>
    </Grid>
  );
};
export default function PropertyManager({ system, setSystem, dirty }) {
  const [properties, setProperties] = React.useState(system?.properties || []);
  const [importJSON, setImportJSON] = React.useState(false);
  const [notClean, setNotClean] = React.useState(dirty);
  React.useEffect(() => {
    if (!system) return;
    if (!notClean) return setNotClean(true);
    setSystem({
      ...system,
      properties,
      uid: properties.find((p) => p.uid)?.name,
    });
  }, [properties]);
  const [panel, setPanel] = React.useState(false);
  return (
    <Stack gap={1}>
      <SectionHeader icon="category">Property Management</SectionHeader>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          marginLeft: "auto",
        }}
      >
        <ActionButton
          icon="data_object"
          noload
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "40px",
            width: "40px",
            borderRadius: "50%",
          }}
          handler={async () => {
            setImportJSON(true);
          }}
        />
        <ActionButton
          icon="add"
          noload
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "40px",
            width: "40px",
            borderRadius: "50%",
          }}
          handler={async () => {
            setPanel(true);
          }}
        />
      </div>
      <Grid gap={1} container>
        <DnDList
          key={properties}
          state={[properties, setProperties]}
          itemComponent={({ item }) => (
            <Property
              setPanel={setPanel}
              key={item.id}
              setProperties={setProperties}
              prop={item}
              systemUid={system.uid}
            />
          )}
        />
      </Grid>
      <SidePanel
        width={500}
        open={importJSON}
        closeDrawer={() => setImportJSON(null)}
        props={{ setProperties }}
      >
        {ImportJSONPanel}
      </SidePanel>
      <SidePanel
        width={500}
        open={panel}
        closeDrawer={() => setPanel(null)}
        props={{ prop: panel, setProperties }}
      >
        {PropertyPanel}
      </SidePanel>
    </Stack>
  );
}
