import { Stack, Typography, IconButton, Icon } from "@mui/material";
import SectionHeader from "../SectionHeader";
import copyContent from "common/utils/copyContent";
import toast from "react-hot-toast";
const formulaContent = [
  [
    "Count",
    "count the total number of existing resources with specified property.",
    'Count("Service")',
  ],
  [
    "Sum",
    "add the number values of the specified property across resources.",
    'Sum("Cost")',
  ],
  [
    "Max",
    "get the highest value of the specified property across resources.",
    'Max("Cost")',
  ],
  [
    "Min",
    "get the lowest value of the specified property across resources.",
    'Min("Cost")',
  ],
  [
    "Avg",
    "get the average value of the specified property across resources.",
    'Avg("Cost")',
  ],
  ["Float", "ensure value is a decimal.", 'Float(Avg("Cost"))'],
  [
    "Integer",
    "ensure value does not contain decimal places.",
    'Integer(Avg("Cost"))',
  ],
  ["Percent", "X is what percent of Y.", "Percent(x,y)"],
  ["Round", "round the given number to 2 decimal places", 'Round("Cost")'],
];
const formula = () => (
  <Stack padding={2}>
    <SectionHeader icon="calculate" large>
      Formula Methods
    </SectionHeader>
    {formulaContent.map(([title, desc, ex], idx) => (
      <Stack key={idx} gap={2} className="floating">
        <SectionHeader>{title}</SectionHeader>
        <Typography variant="body2">{desc}</Typography>
        <Stack gap={1}>
          <Stack direction="row" alignItems={"center"}>
            <Typography variant="caption">Example</Typography>
            <IconButton
              onClick={() => {
                copyContent(ex);
                toast.success("copied value!");
              }}
              sx={{ marginLeft: "auto" }}
            >
              <Icon color="success">content_copy</Icon>
            </IconButton>
          </Stack>
          <pre style={{ margin: "0" }}>{ex}</pre>
        </Stack>
      </Stack>
    ))}
  </Stack>
);

const filterFormulaContent = [
  [
    "WithinHours",
    "filter by date string property within the last x number of hours",
    'WithinHours("TaskStart",24)',
  ],
  [
    "NotWithinHours",
    "filter by date string property not within the last x number of hours",
    'NotWithinHours("TaskStart",8)',
  ],
  [
    "Filtering by equality",
    "filter by property value equality to a given value",
    'resource["Service"] == "Lambda"',
  ],
  [
    "Filtering with a list",
    "filter by property value appearing in a list",
    'resource["Service"] in ["Lambda","S3"]',
  ],
];
const filterFormula = () => (
  <Stack padding={2}>
    <SectionHeader icon="calculate" large>
      Filter Formulas
    </SectionHeader>
    {filterFormulaContent.map(([title, desc, ex], idx) => (
      <Stack key={idx} gap={2} className="floating">
        <SectionHeader>{title}</SectionHeader>
        <Typography variant="body2">{desc}</Typography>
        <Stack gap={1}>
          <Stack direction="row" alignItems={"center"}>
            <Typography variant="caption">Example</Typography>
            <IconButton
              onClick={() => {
                copyContent(ex);
                toast.success("copied value!");
              }}
              sx={{ marginLeft: "auto" }}
            >
              <Icon color="success">content_copy</Icon>
            </IconButton>
          </Stack>
          <pre style={{ margin: "0" }}>{ex}</pre>
        </Stack>
      </Stack>
    ))}
  </Stack>
);

export default {
  formula,
  filterFormula,
};
