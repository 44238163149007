import React from "react";
import { Typography, IconButton, Icon } from "@mui/material";
import SidePanel from "../SidePanel";
import { useState } from "react";

export default function SectionHeader({
  large,
  icon,
  children,
  info,
  ...props
}) {
  const [help, setHelp] = useState(false);
  return (
    <>
      {info ? (
        <SidePanel
          width={500}
          open={help}
          closeDrawer={() => setHelp(null)}
          props={{}}
        >
          {info}
        </SidePanel>
      ) : null}
      <Typography
        variant={large ? "h4" : "h5"}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
        {...props}
      >
        {icon ? (
          <Icon
            {...(large
              ? {
                  sx: {
                    color: "#a827fa !important",
                    fontSize: "28px !important",
                  },
                }
              : {})}
          >
            {icon}
          </Icon>
        ) : null}
        <span>{children}</span>
        {info ? (
          <div style={{ marginLeft: "auto" }}>
            <IconButton onClick={() => setHelp(true)}>
              <Icon sx={{ color: "rgba(239,237,239,0.38) !important" }}>
                info
              </Icon>
            </IconButton>
          </div>
        ) : null}
      </Typography>
    </>
  );
}
