import React from "react";
import Page from "common/components/Page";
import {
  Stack,
  Grid,
  IconButton,
  Icon,
  Typography,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useState from "common/hooks/useState";
import useFetcher from "common/hooks/useFetcher";
import EditCrew from "pages/Crews/component/EditCrew";
import ActionButton from "common/components/ActionButton";
import Blurb from "constants/blurbs";
import Input from "common/components/Input";
import SidePanel from "common/components/SidePanel";
import { toast } from "react-hot-toast";
import useConfirm from "common/hooks/useConfirm";
const CrewTile = ({ crew, userCrew, deleteCrew, onClose }) => {
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete Crew`,
    message: `Are you sure you want to delete this ${crew?.name}? This action cannot be undone.`,
    callback: () => {
      deleteCrew(crew?.id);
    },
  });
  const navigate = useNavigate();
  return (
    <Grid
      md={3.9}
      sx={{
        flex: 1,
        position: "relative",
      }}
      className="user-card-title-ctn"
      item
    >
      <div
        className="floating user-card-tile"
        style={{
          width: "100%",
          height: "120px",
          alignItems: "flex-start",
          flex: 1,
          borderLeft: `6px solid ${crew?.color}`,
        }}
      >
        <DeleteModal />
        <Stack direction="column">
          <span className="user-card-tile__name">{crew.name}</span>
          <Typography mt={2} variant="body2">
            {crew.description}
          </Typography>
        </Stack>
      </div>
      <div className="user-card-tile__options">
        <IconButton
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            backgroundColor: "#ff711a",
          }}
          onClick={() => navigate(crew.id)}
        >
          <Icon
            sx={{
              color: "#fff !important",
            }}
          >
            tune
          </Icon>
        </IconButton>
        {userCrew === "administrators" ? (
          <IconButton
            color="error"
            sx={{
              height: "40px",
              width: "40px",
              borderRadius: "20px",
            }}
            onClick={() => openDeleteModal(true)}
          >
            <Icon color="error">close</Icon>
          </IconButton>
        ) : null}
      </div>
    </Grid>
  );
};

export default function Crews({ crew_name }) {
  const { crews, loading, error, refresh } = useState("/api/crews");
  const [search, setSearch] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [editReset, setEditReset] = React.useState(1);
  const [init, setInit] = React.useState(null);
  const fetcher = useFetcher();

  const searchFilter = () => {
    if (!crews) return [];
    let filtered = [...crews];
    if (!search) return crews;
    if (crew_name !== "administrators") {
      filtered = filtered?.filter((c) => c.name === crew_name);
    }
    return crews.filter(({ name }) =>
      name.toLowerCase().includes(search.toLowerCase())
    );
  };
  const deleteCrew = async (id) => {
    await fetcher.delete("deleting crew", `/api/crews/${id}`, ({ error }) => {
      if (error) return toast.error(error);
      refresh();
    });
  };
  const actions =
    crew_name === "administrators"
      ? [
          <ActionButton
            icon="add"
            noload
            handler={async () => {
              setEdit(true);
            }}
          >
            Add Crew
          </ActionButton>,
        ]
      : null;
  let loadedCrews = searchFilter();
  return (
    <Page
      refresh={refresh}
      loading={[loading]}
      header="Crews"
      actions={actions}
    >
      {/* <Blurb>crews_desc</Blurb> */}
      <Stack spacing={3}>
        <div style={{ display: "flex", marginLeft: "auto" }}>
          <Input
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearch("")} edge="end">
                    {search ? (
                      <Icon sx={{ fontSize: "18px !important" }} color="error">
                        close
                      </Icon>
                    ) : null}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="search..."
            sx={{ marginLeft: "auto", width: "300px" }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <SidePanel
          open={edit}
          closeDrawer={() => setEdit(false)}
          props={{ refresh }}
        >
          {EditCrew}
        </SidePanel>
        {loadedCrews.length ? (
          <Grid sx={{ gap: "10px" }} container>
            {loadedCrews?.map((data, idx) => {
              return (
                <CrewTile
                  onClick={(user) => {
                    // setDrawer(user);
                  }}
                  deleteCrew={deleteCrew}
                  userCrew={crew_name}
                  crew={data}
                  // saveUser={saveUser}
                  // deleteUser={deleteUser}
                  key={idx}
                  // user={data}
                  // color={crew?.color}
                />
              );
            })}
          </Grid>
        ) : null}
      </Stack>
    </Page>
  );
}
