import React from "react";
import {
  Stack,
  Typography,
  Grid,
  Avatar,
  Badge,
  Icon,
  IconButton,
} from "@mui/material";
import { MiniLoader } from "common/components/Loader";
import AssetImage from "common/components/AssetImage";
import { PlatformIconMap } from "pages/Targets";
import useFetcher from "common/hooks/useFetcher";
import ActionButton from "common/components/ActionButton";
import useConfirm from "common/hooks/useConfirm";
import SidePanel from "common/components/SidePanel";
import CreatePlatform from "./CreatePlatform";
import ViewPlatform from "./ViewPlatform";
import toast from "react-hot-toast";
const Tile = ({ platform, deletePlatform }) => {
  const [view, setView] = React.useState(false);
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete Custom Platform`,
    message: `Are you sure you want to delete ${platform?.name} platform? This action cannot be undone.`,
    callback: () => {
      deletePlatform(platform?.pk);
    },
  });
  return (
    <Grid
      sx={{
        position: "relative",
      }}
      xs={12}
      className="user-card-title-ctn"
      item
    >
      <SidePanel
        width="600px"
        open={view}
        closeDrawer={() => setView(false)}
        props={{ platform }}
      >
        {ViewPlatform}
      </SidePanel>
      <div
        style={{ width: "100%", flex: 1 }}
        className="floating user-card-tile"
      >
        <DeleteModal />
        <Stack flex={1} gap={1} direction="column">
          <Stack
            sx={{ width: "100%" }}
            alignItems={"center"}
            direction="row"
            gap={1}
          >
            <Avatar
              sx={{
                width: 60,
                height: 60,
                marginRight: "10px",
                background: "#262626",
              }}
            >
              {React.createElement(
                PlatformIconMap[platform?.type || platform?.value] ??
                  AssetImage,
                {
                  ...{
                    style: { width: "60px" },
                    path: `/api/assets/instance/platforms/${platform?.type ||
                      platform?.value}/logo.png`,
                  },
                }
              )}
            </Avatar>
            <Stack>
              <Typography variant="body1">{platform?.label}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </div>
      <div className="user-card-tile__options">
        <IconButton
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            backgroundColor: "#ff711a",
          }}
          onClick={() => setView(true)}
        >
          <Icon
            sx={{
              color: "#fff !important",
            }}
          >
            tune
          </Icon>
        </IconButton>

        <IconButton
          color="error"
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
          }}
          onClick={() => openDeleteModal(true)}
        >
          <Icon color="error">close</Icon>
        </IconButton>
      </div>
    </Grid>
  );
};

export default function CustomPlatforms({ onClose }) {
  const fetcher = useFetcher();
  const [panel, setPanel] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [platforms, setPlatforms] = React.useState([]);
  const getPlatforms = async () => {
    setLoading(true);
    setPlatforms([]);
    await fetcher.get(
      "fetching platforms",
      `/api/platforms`,
      ({ platforms }) => {
        setPlatforms([
          ...(platforms ?? []).map((p) => ({
            value: p.name,
            label: p.name,
            ...p,
          })),
        ]);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  const deletePlatform = async (id) => {
    await fetcher.delete(
      "deleting platform",
      `/api/platforms/${id}`,
      ({ error }) => {
        if (error) return toast.error(error);
        getPlatforms();
      },
      () => {}
    );
  };
  React.useEffect(() => {
    getPlatforms();
  }, []);
  return (
    <Stack p={2} gap={3}>
      <SidePanel
        width="600px"
        open={panel === "create"}
        closeDrawer={() => setPanel(null)}
        props={{ refresh: getPlatforms }}
      >
        {CreatePlatform}
      </SidePanel>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Stack>
          <Typography variant="h4">Custom Platforms</Typography>
        </Stack>
      </Stack>
      {loading ? (
        <div style={{ position: "relative" }}>
          <MiniLoader />
        </div>
      ) : (
        <>
          <div style={{ marginLeft: "auto" }}>
            <ActionButton
              dark
              icon="add"
              noload
              handler={async () => {
                setPanel("create");
              }}
            >
              Add Custom Platform
            </ActionButton>
          </div>
          <Grid gap={1} container>
            {(platforms || [])?.map((p) => {
              return (
                <Tile key={p.pk} platform={p} deletePlatform={deletePlatform} />
              );
            })}
          </Grid>
        </>
      )}
    </Stack>
  );
}
