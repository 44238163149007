import React from "react";

const useForm = (form) => {
  let controller = (name) => ({
    "data-form": form,
    name,
  });
  let getFormValues = () => {
    const values = {};
    document.querySelectorAll(`[data-form="${form}"]`).forEach((i) => {
      if (!i.name) return;
      if (i.type === "file") {
        values[i.name] = i.files[0];
        return;
      }
      if (i.type === "checkbox") {
        values[i.name] = i.checked;
        return;
      } else {
        values[i.name] = i.value;
      }
    });
    return values;
  };
  let setFormValues = (initial) => {
    Object.entries(initial).forEach(([key, value]) => {
      const input = document.querySelector(
        `input[name="${key}"][data-form="${form}"]`
      );
      if (input) {
        input.value = value;
      }
    });
  };
  return { controller, getFormValues, setFormValues };
};

export default useForm;
