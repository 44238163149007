import React from "react";
import { Stack, Avatar, Icon, Typography } from "@mui/material";
import { TargetsIcon } from "routes";
import Input from "common/components/Input";
import ActionButton from "common/components/ActionButton";
import SidePanel from "common/components/SidePanel";
import useScopeAttributes from "common/hooks/useScopeAttributes";
import SelectTenants from "./SelectTenants";
import SelectPlatform from "./SelectPlatform";
import SelectGeos from "./SelectGeos";
import validate from "constants/validate";
import useFetcher from "common/hooks/useFetcher";
import toast from "react-hot-toast";
export default function ModifyTarget({ refresh, target, onClose }) {
  const fetcher = useFetcher();
  const { ScopeInputs, getScope, setInit } = useScopeAttributes();
  const [panelView, setPanelView] = React.useState(false);
  const [name, setName] = React.useState(target?.name || "");
  const [platform, setPlatform] = React.useState(target?.type || "");
  const [tenants, setTenants] = React.useState(target?.tenants || []);
  const [geos, setGeos] = React.useState(target?.geos || []);
  React.useEffect(() => {
    if (!target) return;
    setInit(target?.tags, target?.acl);
  }, [target]);
  const createTarget = async () => {
    const payload = {
      geos,
      tenants,
      name,
      type: platform,
      ...getScope(),
    };
    if (target.id) payload.id = target.id;
    if (!validate("target_edit", payload)) return;
    await new Promise((r) =>
      fetcher.post(
        "creating target",
        "/api/targeting",
        payload,
        ({ error }) => {
          if (error) return toast.error(error);
          r();
          refresh();
          onClose();
        },
        () => {
          r();
        }
      )
    );
  };
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <TargetsIcon size={"40"} />
        </Avatar>
        <Stack>
          <Typography variant="h4">
            {target.id ? "Modify Target" : "Create Target"}
          </Typography>
          <Typography variant="body2">{target?.name}</Typography>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Target Information</Typography>
        <Stack gap={1}>
          <Typography>Give your target a name.</Typography>
        </Stack>
        <Stack gap={1}>
          <Input
            size="small"
            dark
            disabled={target?.pk}
            sx={{ width: "100%" }}
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Platform</Typography>
        <Stack gap={1}>
          <Typography>Select the platform of for your target.</Typography>
        </Stack>
        <Stack gap={1}>
          <SidePanel
            open={panelView === "platform"}
            closeDrawer={() => setPanelView(false)}
            props={{ platform, setPlatform }}
          >
            {SelectPlatform}
          </SidePanel>
          <ActionButton
            icon="cloud"
            dark
            noload
            handler={async () => {
              setPanelView("platform");
            }}
          >
            {!platform ? "No platform selected" : platform}
          </ActionButton>
          {/* <ColorPicker {...{ color, setColor }}>Brand</ColorPicker> */}
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Tenants</Typography>
        <Stack gap={1}>
          <Typography>Select tenants to assoicate with this target.</Typography>
        </Stack>
        <Stack gap={1}>
          <SidePanel
            width="700px"
            open={panelView === "tenants"}
            closeDrawer={() => setPanelView(false)}
            props={{ tenants, setTenants, platform }}
          >
            {SelectTenants}
          </SidePanel>
          <ActionButton
            disabled={!platform}
            icon="list"
            dark
            noload
            handler={async () => {
              setPanelView("tenants");
            }}
          >
            {!tenants.length
              ? "No tenants selected"
              : `${tenants.length} tenant${
                  tenants.length === 1 ? "" : "s"
                } selected`}
          </ActionButton>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Geographies</Typography>
        <Stack gap={1}>
          <Typography>Select geos to assoicate with this target.</Typography>
        </Stack>
        <Stack gap={1}>
          <SidePanel
            width="700px"
            open={panelView === "geos"}
            closeDrawer={() => setPanelView(false)}
            props={{ geos, setGeos, platform }}
          >
            {SelectGeos}
          </SidePanel>
          <ActionButton
            icon="location_on"
            disabled={!platform}
            dark
            noload
            handler={async () => {
              setPanelView("geos");
            }}
          >
            {!geos.length
              ? "No geographies selected"
              : `${geos.length} geograph${
                  geos.length === 1 ? "y" : "ies"
                } selected`}
          </ActionButton>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Access Control</Typography>
        <Stack gap={1}>
          <ScopeInputs {...{ size: "small", dark: true }} />
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto", display: "flex" }}>
        <ActionButton
          icon="save"
          dark
          handler={async () => {
            await createTarget();
          }}
        >
          Save Target
        </ActionButton>
      </div>
    </Stack>
  );
}
