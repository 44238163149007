import React from "react";
import { Stack, IconButton, Icon } from "@mui/material";
import DatePicker from "common/components/DatePicker";
import { useSearchParams } from "react-router-dom";
import Table from "common/components/Table";
import useFetcher from "common/hooks/useFetcher";
import toast from "react-hot-toast";
import convertTimeToApiString from "common/utils/convertTimeToApiString";
import AlertEmote from "common/components/AlertEmote";
import MultiSelect from "common/components/MultiSelect";
import Tooltip from "@mui/material/Tooltip";
import { MiniLoader } from "common/components/Loader";
import { Chart } from "react-google-charts";
import SidePanel from "common/components/SidePanel";
import getOffsetUTCHours from "common/utils/getOffsetUTCHours";
import Log from "./Log";
import gmtToLocal from "common/utils/gmtToLocal";
// Custom sorting function for date strings
function dateStringComparator(a, b) {
  const dateA = new Date(a);
  const dateB = new Date(b);
  return dateA - dateB;
}

// Function to parse date strings and extract hours
function createTimeSeriesByHour(data) {
  function parseTime(timeString) {
    const [hours, minutes] = timeString.split(":");
    return parseInt(hours) * 60 + parseInt(minutes);
  }

  function sortTimeArray(array) {
    return array.sort((a, b) => parseTime(a[0]) - parseTime(b[0]));
  }
  function formatUTCHour(hour) {
    // Ensure the hour is within the 0-23 range
    hour = hour % 24;
    // Format the hour and minute
    var formattedHour = (hour < 10 ? "0" : "") + hour;
    var formattedMinute = "00"; // Since we're formatting to the hour, minutes are always 00
    return formattedHour + ":" + formattedMinute;
  }
  // Initialize an object to hold the aggregated data
  const timeSeries = {};
  Array.from(Array(24)).forEach((_, idx) => {
    timeSeries[formatUTCHour(idx)] = 0;
  });
  // Iterate over each object in the data array
  data.forEach((item) => {
    // Parse the date string into a Date object
    const date = new Date(item.time);

    // Extract the hour from the date
    const hour = formatUTCHour(date.getHours());

    // Increment the count for this hour
    timeSeries[hour]++;
  });

  // Convert the timeSeries object into an array of [hour, count] pairs
  const timeSeriesArray = Object.entries(timeSeries).map(([hour, count]) => [
    hour,
    count,
  ]);

  return sortTimeArray(timeSeriesArray);
}

export default function Logging({ active }) {
  const [event, setEvent] = React.useState(null);
  const [logGroup, setLogGroup] = React.useState("/echelonone/systems");
  const [timeseries, setTimeseries] = React.useState([
    ["Hour", "Count"],
    ["0", 0],
  ]);
  const [logs, setLogs] = React.useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(false);
  const [openDate, setOpenDate] = React.useState(false);
  const [date, setDate] = React.useState(
    searchParams.get("date") ? new Date(searchParams.get("date")) : new Date()
  );
  const fetcher = useFetcher();
  const fetchLogs = async () => {
    setLoading(true);
    await fetcher.get(
      "fetching logs",
      `/api/logs?date=${convertTimeToApiString(
        date
      )}&offset=${getOffsetUTCHours()}`,
      ({ logs, error }) => {
        if (error) return toast.error(error);
        let data = logs.map((l) => {
          let key = l.Key.split("/").reverse()[0];
          let [time, system_name, cmd_name, name] = l.Key.split("/")
            .reverse()[0]
            .split("_");
          return {
            time: gmtToLocal(l.LastModified),
            name,
            system_name,
            cmd_name,
            key: l.Key,
          };
          // let [time, name, command] = key.split(".")[0].split("_");
          // return {
          //   time: gmtToLocal(l.LastModified),
          //   name,
          //   command,
          //   key: l.Key,
          // };
        });
        let timeseries = createTimeSeriesByHour(data);
        setTimeseries([
          ["Hour", "Count"],
          ...(timeseries.length ? timeseries : [["0", 0]]),
        ]);
        setLogs(data);
      },
      () => {}
    );
    setLoading(false);
  };
  React.useEffect(() => {
    fetchLogs();
  }, [searchParams, date]);
  console.log(timeseries);
  let d = new Date();
  let maxDate = new Date(d.setDate(d.getDate() + 1));
  return (
    <Stack spacing={1}>
      <SidePanel
        width={600}
        open={!!event}
        closeDrawer={() => setEvent(null)}
        props={{ event }}
      >
        {Log}
      </SidePanel>
      <Stack alignItems="center" direction="row" gap={"10px"}>
        <MultiSelect
          disabled
          sx={{ width: "30%", minWidth: "250px" }}
          clearHandler={() => {
            setLogGroup("");
          }}
          label="Log Group"
          options={["/echelonone/systems", "/echelonone/users"] || []}
          multiple={false}
          value={logGroup}
          setValue={(e) => {
            setLogGroup(e);
          }}
        />
        <div
          onClick={() => setOpenDate(true)}
          onMouseLeave={() => setOpenDate(false)}
          style={{ padding: "10px", cursor: "pointer" }}
        >
          <Tooltip
            open={openDate}
            disableHoverListener
            disableFocusListener
            disableTouchListener
            arrow
            title={
              <DatePicker
                selected={date}
                onChange={(d) => {
                  setDate(d);
                  setSearchParams({
                    ...searchParams,
                    date: convertTimeToApiString(d),
                  });
                }}
                maxDate={maxDate}
              />
            }
          >
            <Stack alignItems={"center"} direction={"row"} gap="10px">
              <Icon>event</Icon>
              <p>{convertTimeToApiString(date)}</p>
            </Stack>
          </Tooltip>
        </div>
        {loading ? (
          <div style={{ marginLeft: "20px", position: "relative" }}>
            <MiniLoader />
          </div>
        ) : null}
      </Stack>
      <div className="py-10">
        <Chart
          chartVersion="50" // Explicitly set version to 50
          chartType="ColumnChart"
          data={timeseries}
          options={{
            legend: { position: "none" },
            backgroundColor: {
              fill: "transparent",
            },
            chartArea: {
              left: 60, // Adjust left padding
              right: 60, // Adjust right padding
              top: 60, // Adjust top padding
              bottom: 60, // Adjust bottom padding
            },
            titleTextStyle: { color: "#fff" },
            annotations: {
              textStyle: {
                color: "#fff",
              },
            },
            hAxis: {
              textStyle: {
                color: "#fff",
              },
              showTextEvery: 2,
              title: "Hour",
              titleTextStyle: {
                color: "#fff",
                bold: true,
                italic: false,
              },
              gridlines: {
                color: "transparent",
              },
              format: "0",
            },
            vAxis: {
              textStyle: {
                color: "#fff",
              },
              titleTextStyle: {
                color: "#fff",
                bold: true,
                italic: false,
              },
              color: "#fff",
              gridlines: {
                color: "transparent",
              },
              title: "Count",
              format: "0",
            },
            colors: ["#ff914c"],
            lineWidth: 2,
            pointSize: 6,
            animation: {
              startup: true,
              duration: 1000,
              easing: "out",
            },
          }}
        />
      </div>
      <Stack direction="row" spacing={1}>
        <Stack spacing={1} alignItems="start">
          {/* <DatePicker selected={date} onChange={setDate} maxDate={maxDate} /> */}
          {/* <LoadingButton
            onClick={fetchLogs}
            loading={loading}
            color="secondary"
            varaint="contained"
          >
            Fetch Logs
          </LoadingButton> */}
        </Stack>
        <Stack style={{ flex: 1 }}>
          <Table
            expand
            data={logs}
            defaultSortInfo={{
              name: "time",
              dir: -1,
            }}
            emptyText={
              <AlertEmote noButton text="Sorry! No logs found for this date." />
            }
            idProperty="time"
            loading={loading}
            cols={[
              {
                name: "time",
                header: "Time Stamp",
                defaultWidth: 150,
                sort: dateStringComparator,
              },
              {
                name: "name",
                header: "Name",
                defaultWidth: 300,
              },
              {
                name: "cmd_name",
                header: "Command",
                defaultWidth: 200,
              },
              {
                name: "system_name",
                header: "System",
                defaultWidth: 200,
              },
              {
                name: "pk",
                header: "",
                defaultWidth: 40,
                textAlign: "center",
                render: ({ data }) => (
                  <Stack spacing={1} justifyContent="center" direction="row">
                    <IconButton
                      onClick={() => {
                        setEvent({
                          ...data,
                          name: `${data.system_name}_${data.cmd_name}_${data.name}`,
                          date: convertTimeToApiString(date),
                        });
                      }}
                      color="info"
                    >
                      <Icon color="info">travel_explore</Icon>
                    </IconButton>
                  </Stack>
                ),
              },
            ]}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
