import React from "react";
import Loader from "common/components/Loader";
import PageMenu from "common/components/Page/components/PageMenu";
import RefreshButton from "common/components/Page/components/RefreshButton";
import Header from "common/components/Page/components/Header";
import E1Chatbot from "common/components/Chatbot";
export default function Page({
  header,
  back,
  refresh,
  loading,
  chips = [],
  menu,
  actions = [],
  children,
  dynamicSize,
  nobot = false,
}) {
  if (typeof loading === "object" ? loading.find((v) => v) : loading)
    return <Loader />;

  const Refresh = React.useMemo(
    () => (refresh ? <RefreshButton {...{ refresh }} /> : null),
    [refresh]
  );

  const Menu = React.useMemo(
    () => (menu ? <PageMenu>{menu}</PageMenu> : null),
    [menu]
  );
  const PageHeader = React.useMemo(
    () => (
      <Header
        {...(back ? { goBack: back } : {})}
        chips={chips}
        actions={actions}
      >
        {header}
      </Header>
    ),
    [menu, header, chips]
  );
  return (
    <div style={dynamicSize ? {} : { height: "100%" }}>
      {Menu}
      {Refresh}
      {PageHeader}
      <div style={{ height: "100%", position: "relative" }}>
        {children}
        {!nobot ? <E1Chatbot /> : null}
      </div>
    </div>
  );
}
