import * as React from "react";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

export default function Drawer({
  buttonComponent,
  icon,
  button,
  children,
  width = 450,
  onClose,
}) {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (!open && onClose) {
      return onClose(() => setOpen(open));
    }
    setOpen(open);
  };
  const Trigger =
    buttonComponent ??
    (({ onClick }) => (
      <Button
        color="secondary"
        variant="contained"
        startIcon={<Icon color="primary">{icon}</Icon>}
        onClick={onClick}
      >
        {button}
      </Button>
    ));
  return (
    <>
      <Trigger onClick={() => setOpen(true)} />
      <MuiDrawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width,
            position: "relative",
            top: "80px",
          }}
          role="presentation"
        >
          {children}
        </Box>
      </MuiDrawer>
    </>
  );
}
