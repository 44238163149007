import React from "react";
import { Stack, Avatar, Icon, Typography } from "@mui/material";
import { TargetsIcon } from "routes";
import ReactMarkdown from "react-markdown";
import Input from "common/components/Input";
import ActionButton from "common/components/ActionButton";
import SidePanel from "common/components/SidePanel";
import useScopeAttributes from "common/hooks/useScopeAttributes";
import validate from "constants/validate";
import SelectPlatform from "pages/Targets/components/ModifyTarget/SelectPlatform";
import useFetcher from "common/hooks/useFetcher";
import toast from "react-hot-toast";
import useState from "common/hooks/useState";
import instructions from "./instructions";
import FileUpload from "common/components/FileUpload";
import { setupInstructions } from "./instructions";
import { CustomInstruction } from "./instructions";
export default function AddTenant({ refresh, onClose }) {
  const fetcher = useFetcher();
  const [confirmed, setConfirmed] = React.useState(null);
  const { tenant: e1tenant, loading, error } = useState("/api/meta");
  const { tags, TagInput, loading: attrLoading } = useScopeAttributes();
  const [panelView, setPanelView] = React.useState(false);
  const [platform, setPlatform] = React.useState("");
  const [tenant, setTenant] = React.useState("");
  const [configuration, setConfiguration] = React.useState(null);

  const addTenant = async () => {
    await fetcher.post(
      "adding tenant",
      `/api/tenants`,
      () => {
        let form = new FormData();
        form.append(
          "json",
          JSON.stringify({
            type: platform,
            tenant,
            tags,
          })
        );
        if (configuration) form.append("File", configuration);
        return form;
      },
      ({ id }, toast) => {
        if (id) {
          toast.success("Tenant added!");
          refresh();
          return onClose();
        }
        toast.error(
          "Looks like we had an issue. If the issue persists, please contact support."
        );
      },
      (e) => {}
    );
  };
  const confirmTenant = async () => {
    if ((platform !== "aws" && !configuration) || !tenant)
      return toast.error(
        "Enter a tenant identifer and upload a configuration file."
      );
    await fetcher.post(
      "confirming tenant access",
      `/api/tenants/confirm`,
      () => {
        let form = new FormData();
        form.append(
          "json",
          JSON.stringify({
            type: platform,
            tenant,
          })
        );
        if (configuration) form.append("File", configuration);
        return form;
      },
      ({ success }) => setConfirmed(success),
      (e) => setConfirmed(false)
    );
  };
  //   const [platform, setPlatform] = React.useState(target?.type || "");
  //   const [tenants, setTenants] = React.useState(target?.tenants || []);
  //   const [geos, setGeos] = React.useState(target?.geos || []);
  //   React.useEffect(() => {
  //     if (!target) return;
  //     setInit(target?.tags, target?.acl);
  //   }, [target]);
  //   console.log(target, name, platform, tenants, geos);
  //   const createTarget = async () => {
  //     const payload = {
  //       geos,
  //       tenants,
  //       name,
  //       type: platform,
  //       ...getScope(),
  //     };
  //     if (target.id) payload.id = target.id;
  //     if (!validate("target_edit", payload)) return;
  //     console.log(payload);
  //     await new Promise((r) =>
  //       fetcher.post(
  //         "creating target",
  //         "/api/targeting",
  //         payload,
  //         ({ error }) => {
  //           if (error) return toast.error(error);
  //           r();
  //           refresh();
  //           onClose();
  //         },
  //         () => {
  //           r();
  //         }
  //       )
  //     );
  //   };
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <TargetsIcon size={"40"} />
        </Avatar>
        <Stack>
          <Typography variant="h4">Add Tenant</Typography>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Platform</Typography>
        <Stack gap={1}>
          <SidePanel
            open={panelView === "platform"}
            closeDrawer={() => setPanelView(false)}
            props={{ platform, setPlatform }}
          >
            {SelectPlatform}
          </SidePanel>
          <ActionButton
            icon="cloud"
            dark
            noload
            handler={async () => {
              setPanelView("platform");
            }}
          >
            {!platform ? "No platform selected" : platform}
          </ActionButton>
        </Stack>
      </Stack>
      {platform ? (
        <Stack className="floating" gap={3}>
          <Typography variant="h5">Setup</Typography>
          <Stack gap={1}>
            <Typography>
              {setupInstructions[platform]
                ? setupInstructions[platform]()
                : "Provide a descriptive tenant name and upload the configuration JSON file below. Press Test to confirm configuration."}
            </Typography>
          </Stack>
          <Stack gap={1}>
            <Input
              size="small"
              dark
              sx={{ width: "100%" }}
              placeholder="Tenant Identifier"
              value={tenant}
              onChange={(e) => setTenant(e.target.value)}
            />
            {platform !== "aws" ? (
              <FileUpload
                sizeInKb={300}
                file={configuration}
                handleFileChange={setConfiguration}
              >
                Upload Configuration JSON
              </FileUpload>
            ) : null}
            {confirmed ? (
              <Stack gap={1}>
                <Typography sx={{ textAlign: "right" }} variant="body2">
                  Your tenant has been confirmed!
                </Typography>
                <div style={{ marginLeft: "auto", display: "flex" }}>
                  <ActionButton
                    icon="save"
                    dark
                    handler={async () => {
                      await addTenant();
                    }}
                  >
                    Add Tenant
                  </ActionButton>
                </div>
              </Stack>
            ) : (
              <div style={{ marginLeft: "auto" }}>
                <ActionButton
                  icon="science"
                  dark
                  handler={async () => {
                    await confirmTenant();
                  }}
                >
                  Test
                </ActionButton>
              </div>
            )}
          </Stack>
        </Stack>
      ) : null}
      {/* <Stack className="floating" gap={3}>
        <Typography variant="h5">Setup</Typography>
        <Stack gap={1}>
          <SidePanel
            open={panelView === "platform"}
            closeDrawer={() => setPanelView(false)}
            props={{ platform, setPlatform }}
          >
            {SelectPlatform}
          </SidePanel>
          <ActionButton
            icon="cloud"
            dark
            noload
            handler={async () => {
              setPanelView("platform");
            }}
          >
            {!platform ? "No platform selected" : platform}
          </ActionButton>
        </Stack>
      </Stack> */}
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Access Control</Typography>
        <Stack gap={1}>
          <TagInput {...{ size: "small", dark: true }} />
        </Stack>
      </Stack>
      {platform && e1tenant ? (
        <Stack className="floating" gap={3}>
          <Typography variant="h5">Instructions</Typography>
          <Stack gap={1}>
            <ReactMarkdown
              linkTarget="_blank"
              className="react-markdown-custom"
            >
              {instructions[platform]
                ? instructions[platform](e1tenant)
                : CustomInstruction}
            </ReactMarkdown>
          </Stack>
        </Stack>
      ) : null}
      <div style={{ height: "100px" }} />
      {/* <Stack className="floating" gap={3}>
        <Typography variant="h5">Tenants</Typography>
        <Stack gap={1}>
          <Typography>Select tenants to assoicate with this target.</Typography>
        </Stack>
        <Stack gap={1}>
          <SidePanel
            width="700px"
            open={panelView === "tenants"}
            closeDrawer={() => setPanelView(false)}
            props={{ tenants, setTenants, platform }}
          >
            {SelectTenants}
          </SidePanel>
          <ActionButton
            disabled={!platform}
            icon="list"
            dark
            noload
            handler={async () => {
              setPanelView("tenants");
            }}
          >
            {!tenants.length
              ? "No tenants selected"
              : `${tenants.length} tenant${
                  tenants.length === 1 ? "" : "s"
                } selected`}
          </ActionButton>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Geographies</Typography>
        <Stack gap={1}>
          <Typography>Select geos to assoicate with this target.</Typography>
        </Stack>
        <Stack gap={1}>
          <SidePanel
            width="700px"
            open={panelView === "geos"}
            closeDrawer={() => setPanelView(false)}
            props={{ geos, setGeos, platform }}
          >
            {SelectGeos}
          </SidePanel>
          <ActionButton
            icon="location_on"
            disabled={!platform}
            dark
            noload
            handler={async () => {
              setPanelView("geos");
            }}
          >
            {!geos.length
              ? "No geographies selected"
              : `${geos.length} geograph${
                  geos.length === 1 ? "y" : "ies"
                } selected`}
          </ActionButton>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Access Control</Typography>
        <Stack gap={1}>
          <ScopeInputs />
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto", display: "flex" }}>
        <ActionButton
          icon="save"
          dark
          handler={async () => {
            await createTarget();
          }}
        >
          Save Target
        </ActionButton>
      </div> */}
    </Stack>
  );
}
