import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@mui/material";
// const items = [
//   {
//     id: 1,
//     component: <span>Name</span>,
//     items: [{ id: 2, component: <span>That</span> }],
//   },
//   {
//     id: 2,
//     component: <span>Tune</span>,
//     items: [
//       { id: 3, component: <span>Trigger 1</span> },
//       { id: 4, component: <span>Trigger 2</span> },
//     ],
//   },
// ];
const Item = ({ item, expand, width }) => {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef();
  useEffect(() => {
    if (!ref.current) return;
    let height = 0;
    if (expanded) {
      height = `${(item.height ?? 50) * item?.items?.length}px`;
    }
    ref.current.style.height = height;
  }, [expanded, item]);
  return (
    <li className="tierlist__listitem">
      <div className="tierlist__item">
        {expand && (
          <span
            className={`tierlist__expand${
              expanded ? " tierlist__expand__expanded" : ""
            }`}
            onClick={() => setExpanded(!expanded)}
          >
            <Icon>expand_less</Icon>
          </span>
        )}
        {item.component}
      </div>
      {/* <span>{item.name}</span> */}
      <ul
        style={{ width }}
        ref={ref}
        className={`tierlist__children${
          expanded ? " tierlist__children__expanded" : ""
        }`}
      >
        {item?.items?.map((item, index) => (
          <Item key={index} item={item} />
        ))}
      </ul>
      {/* {expanded && item?.items && (
        <ul
          className={`tierlist__children${
            expanded ? " tierlist__children__expanded" : ""
          }`}
        >
          {item.items.map((item, index) => (
            <Item key={index} item={item} />
          ))}
        </ul>
      )} */}
    </li>
  );
};
export default function TieredList({ items, width = "300px" }) {
  return (
    <ul style={{ width }} className="tierlist">
      {items.map((item, index) => (
        <Item
          width={width}
          expand={item?.items?.length > 0}
          key={index}
          item={item}
        />
      ))}
    </ul>
  );
}
