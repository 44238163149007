import * as React from "react";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Background from "assets/poly_bg.svg";
export default function SidePanel({
  children,
  width = 450,
  open,
  closeDrawer,
  sx = {},
  props = {},
  noTopMargin,
  polybg = false,
}) {
  const polyStyles = polybg
    ? {
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }
    : {};
  return (
    <>
      <MuiDrawer
        anchor="right"
        open={!!open}
        onClose={closeDrawer}
        PaperProps={{
          sx: {
            ...polyStyles,
            ...sx,
          },
        }}
      >
        <Box
          sx={{
            width,
            position: "relative",
            top: noTopMargin ? 0 : "20px",
          }}
          role="presentation"
        >
          {children &&
            React.createElement(children, { ...props, onClose: closeDrawer })}
        </Box>
      </MuiDrawer>
    </>
  );
}
