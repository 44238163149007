import React from "react";

export default function Radio({
  disabled,
  defaultValue,
  onChange,
  options = [],
  sx = {},
}) {
  return (
    <div className="radio-inputs" style={sx}>
      {options.map((o, i) => {
        return (
          <label key={i}>
            <input
              onClick={() => onChange(o.name)}
              disabled={disabled}
              defaultChecked={defaultValue === o.name}
              className="radio-input"
              type="radio"
              name="engine"
            />
            <span className="radio-tile">
              <span className="radio-icon">{o.icon}</span>
              <span className="radio-label">{o.name}</span>
            </span>
          </label>
        );
      })}
    </div>
  );
}
