import React, { useState } from "react";
import { TextField, Box, Stack, Typography } from "@mui/material";
import ImageLoader from "common/components/ImageLoader";
import ReactMarkdown from "react-markdown";
import PillButtonGroup from "common/components/PillButtonGroup";
import useFetcher from "common/hooks/useFetcher";
import useFileUpload from "common/hooks/useFileUpload";
import toast from "react-hot-toast";
import { MiniLoader } from "common/components/Loader";
const defaultDoc = String.raw`
This system does not have documentation yet.

## Why Documentation Matters

Having proper documentation will help users and developers in the following ways:

- Makes it possible to use the system effectively without looking at its commands
- Allows separation between documented interface and internal implementation
- Provides a clear entry point to understand the system's purpose and functionality
- Helps standardize how information is presented and maintained
`;
const SystemInfo = ({
  role,
  system,
  onPublish,
  store,
  onInstall,
  onDelete,
}) => {
  const fetcher = useFetcher();
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState("");
  const [editContent, setEditContent] = useState("");
  const [refresh, setRefresh] = useState(1);
  const [owner, setOwner] = useState(false);
  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (store) {
        await fetcher.get(
          "fetching system info",
          `/api/store/system/${system?.id}`,
          ({ system }) => {
            setContent(system?.details || "");
            setOwner(!!system?.owner);
          },
          () => {}
        );
      } else {
        await fetcher.get(
          "fetching system info",
          `/api/sys/${system?.id}/info`,
          ({ info }) => {
            if (!info) return;
            setContent(info?.details || "");
            setEditContent(info?.details || "");
          },
          () => {}
        );
      }
    } catch {}
    setLoading(false);
  };
  React.useEffect(() => fetchInfo(), []);
  const saveInfo = async ({ content, image }) => {
    const payload = { details: content || "" };
    await fetcher.post(
      "saving system info",
      `/api/sys/${system?.id}/info`,
      () => {
        let form = new FormData();
        if (image) {
          form.append("Image", image);
        }
        if (content) {
          form.append("json", JSON.stringify(payload));
        }
        return form;
      },
      (data) => {
        if (data?.error) return toast.error(data?.error);
        setRefresh((x) => x + 1);
      },
      () => {}
    );
  };
  const { openFileSelect, FileInput } = useFileUpload({
    onChange: (image) => saveInfo({ image }),
  });
  const handleSave = () => {
    setContent(editContent);
    setIsEditing(false);
    saveInfo({ content: editContent });
  };
  return (
    <Stack
      sx={{ height: "100%", overflowY: "scroll", position: "relative" }}
      gap={1}
    >
      {FileInput}
      <div style={{ width: "100%", height: "300px", position: "relative" }}>
        <ImageLoader
          key={refresh}
          apiPath={
            store
              ? `/api/assets/store/store/${system?.id}/logo.png`
              : `/api/assets/system/systems/${system?.id}/info/logo.png`
          }
          width="100%"
          height="300px"
          alt="System Logo"
        />
        <div style={{ position: "absolute", right: "10px", top: "40px" }}>
          {store ? (
            <PillButtonGroup
              options={[
                {
                  icon: "download",
                  onClick: () => {
                    onInstall();
                    // setIsEditing(!isEditing);
                  },
                },
                ...(owner
                  ? [
                      {
                        icon: "close",
                        onClick: () => {
                          onDelete();
                        },
                      },
                    ]
                  : []),
              ]}
            />
          ) : !["auditor", "operator"].includes(role) ? (
            <PillButtonGroup
              options={[
                {
                  icon: isEditing ? "cancel" : "edit",
                  onClick: () => {
                    setIsEditing(!isEditing);
                  },
                },
                {
                  icon: "imagesmode",
                  onClick: () => {
                    openFileSelect();
                  },
                },
                {
                  icon: isEditing ? "save" : "publish",
                  onClick: isEditing ? handleSave : onPublish,
                },
              ]}
            />
          ) : null}
        </div>
      </div>
      <Typography
        sx={{
          textAlign: "center",
          letterSpacing: "1.2px",
          fontSize: "36px",
          paddingLeft: "5%",
          paddingRight: "5%",
          whiteSpace: "normal",
          wordWrap: "break-word",
        }}
        variant="h3"
      >
        {system?.name?.toUpperCase()}
      </Typography>
      {isEditing ? (
        <Stack p={2} sx={{ height: "100%", overflowY: "scroll" }} spacing={2}>
          <TextField
            fullWidth
            multiline
            minRows={4}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": { border: "none" },
              "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
            }}
            value={editContent}
            onChange={(e) => setEditContent(e.target.value)}
            placeholder="Enter markdown content..."
          />
        </Stack>
      ) : (
        <Box p={4} sx={{ typography: "body1", height: "100%" }}>
          {loading ? (
            <div
              style={{
                flex: 1,
                position: "relative",
                marginTop: "25px",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <MiniLoader />
            </div>
          ) : (
            <ReactMarkdown
              linkTarget="_blank"
              className="react-markdown-custom"
            >
              {content || defaultDoc}
            </ReactMarkdown>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default SystemInfo;
