import { useState, useEffect } from "react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemButton,
  Icon,
  Typography,
  Avatar,
} from "@mui/material";
import LogoPic from "../../../../assets/icons/logo.png";

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  marginTop: "10px",
  position: "relative",
  textTransform: "capitalize",
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(Icon)({
  width: 40,
  height: 40,
  display: "flex !important",
  alignItems: "center",
  justifyContent: "center",
});

function NavItem({ item, active, drawerOpen }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, children } = item;
  let IconImage = icon;
  const [open, setOpen] = useState(isActiveRoot);
  useEffect(() => {
    setOpen(isActiveRoot);
  }, [isActiveRoot, active]);
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "secondary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": { display: "block" },
  };

  const closedDrawer = {
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    justifyContent: "center",
  };
  const openDrawer = {
    pl: 2.5,
  };

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(!drawerOpen ? closedDrawer : openDrawer),
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      {/* <Avatar
        sx={
          isActiveRoot
            ? { border: "#ff914c 2px solid", width: 40, height: 40 }
            : { border: "#eee 1px solid", width: 40, height: 40 }
        }
      >
      </Avatar> */}
      <ListItemIconStyle>
        {icon && <IconImage fill={isActiveRoot ? "#ff914c" : "#bc35c8"} />}
      </ListItemIconStyle>
      {drawerOpen ? (
        <Typography
          sx={{
            // fontFamily: "ECHELONONE !important",
            // fontWeight: "bold !important",
            fontSize: ".7rem",
            ml: 1,
          }}
          variant="overline"
        >
          {title}
        </Typography>
      ) : null}
    </ListItemStyle>
  );
}

export default function NavSection({ navs, drawerOpen, ...other }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <List disablePadding>
        {navs.map((item) => (
          <NavItem
            drawerOpen={drawerOpen}
            key={item.title}
            item={item}
            active={match}
          />
        ))}
      </List>
    </Box>
  );
}

{
  /* <div
  style={{
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
  }}
  onClick={() => navigate("/app/store")}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill={window.location.pathname === "/app/store" ? "#ff914c" : "#bc35c8"}
    viewBox="0 0 256 256"
  >
    <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200ZM176,88a48,48,0,0,1-96,0,8,8,0,0,1,16,0,32,32,0,0,0,64,0,8,8,0,0,1,16,0Z"></path>
  </svg>

  <img
          height="50px"
          width="50px"
          style={{ marginBottom: "5px" }}
          src={LogoPic}
          alt="logo"
        />
</div>; */
}
