import { Stack, Icon, Typography, Grid } from "@mui/material";
import Page from "common/components/Page";
import React from "react";
import getUserFriendlyDate from "common/utils/getUserFriendlyDate";
import ActionButton from "common/components/ActionButton";
import useState from "common/hooks/useState";
import SectionHeader from "common/components/SectionHeader";
import Switch from "common/components/Switch";
import { themeOptions } from "theme";
import Widget from "common/components/Widget";
import IconButton from "common/components/IconButton";
import { ActivitySidePanel } from "pages/SystemDash/component/Jobs";
import useConfirm from "common/hooks/useConfirm";
import useFetcher from "common/hooks/useFetcher";
import { getNextCronExecutions } from "common/components/CronCreator";
const Status = {
  completed: "check_circle",
  failed: "error",
  queued: "pending",
  running: "pending",
};
export default function Activity({ role, crew_name }) {
  const { schedules, jobs, webhooks, loading, error, refresh } = useState(
    "/api/activity"
  );
  const fetcher = useFetcher();
  const [queueView, setQueueView] = React.useState(true);
  const [scheduleView, setScheduleView] = React.useState(true);
  const [webhookView, setWebhookView] = React.useState(true);
  const [panel, setPanel] = React.useState(false);
  const deleteHook = async (hook) => {
    await fetcher.delete(
      "deleting hook",
      `/api/sys/${hook?.data?.split("#")[1]}/hook/${hook?.pk}`,
      ({ success, error }) => {
        if (error) return;
        refresh();
      },
      () => {}
    );
  };
  const [openWebhookDeleteModal, WebhookDeleteModal] = useConfirm({
    title: `Delete Webhook`,
    message: `Are you sure you want to delete this webhook? This action can't be undone.`,
    callback: (props, context) => {
      deleteHook(context);
    },
  });
  const deleteSchedule = async (job) => {
    await fetcher.delete(
      "deleting schedule",
      `/api/sys/${job?.data?.split("#")[1]}/schedule/${job?.pk}`,
      ({ success, error }) => {
        if (error) return;
        refresh();
      },
      () => {}
    );
  };
  const [openScheduleDeleteModal, ScheduleDeleteModal] = useConfirm({
    title: `Delete Schedule`,
    message: `Are you sure you want to delete this schedule? This action can't be undone.`,
    callback: (props, context) => {
      deleteSchedule(context);
    },
  });
  // Calculate the timestamp for 24 hours ago
  const twentyFourHoursAgo = new Date(
    new Date().getTime() - 24 * 60 * 60 * 1000
  );
  // console.log(jobs?.filter((j) => j.sys_name));
  // console.log(
  //   Object.entries(
  //     (jobs || [])?.reduce(
  //       (a, c) => ({
  //         ...a,
  //         [c.data.split("#")[1]]: 1 + (a[c.data.split("#")[1]] || 0),
  //       }),
  //       {}
  //     )
  //   ).reduce(
  //     (a, c) => {
  //       console.log(
  //         jobs?.filter((j) => j?.sys_name)?.find((j) => j.data.includes(c[0]))
  //       );
  //       return [
  //         ...a,
  //         [
  //           // jobs
  //           //   ?.filter((j) => j?.sys_name)
  //           //   ?.find((j) => j.data.includes(c[0])?.sys_name),
  //           c[0],
  //           c[1],
  //         ],
  //       ];
  //     },
  //     [["System", "Count"]]
  //   )
  // );
  let last24hourJobs = (jobs || [])?.filter((item) => {
    // Extract the date part before the underscore and replace it with space
    const itemDate = new Date(item.start.replace(/_/, "T").slice(0, -7));
    const currentDate = new Date();
    const hoursDifference = (currentDate - itemDate) / (1000 * 60 * 60);
    // Keep items that are less than 24 hours old
    return hoursDifference < 24;
  });
  return (
    <Page refresh={refresh} header="Activity" loading={loading}>
      <Typography sx={{ fontWeight: "bold" }} pt={1} pb={3} variant="subtitle2">
        Widgets display key job metrics from activity over the past 24 hours.
        Access recent system logs and manage both schedules and webhooks across
        your entire platform.
      </Typography>
      <ScheduleDeleteModal />
      <WebhookDeleteModal />
      <ActivitySidePanel
        {...{
          open: panel,
          setOpen: setPanel,
          data: jobs,
          schedules,
          hooks: webhooks,
          role,
        }}
      />
      <Grid gap={1} container>
        <Grid item xs={12} md={3.9}>
          <Widget
            name="Total Jobs Executions"
            data={last24hourJobs?.length || 0}
            type="Metric"
            size="reg"
          />
        </Grid>
        <Grid item xs={12} md={3.9}>
          <Widget
            name="Jobs by System"
            data={Object.entries(
              (last24hourJobs || [])?.reduce(
                (a, c) => ({
                  ...a,
                  [c.data.split("#")[1]]: 1 + (a[c.data.split("#")[1]] || 0),
                }),
                {}
              )
            ).reduce(
              (a, c) => [
                ...a,
                [
                  jobs
                    ?.filter((j) => j?.sys_name)
                    ?.find((j) => j.data.includes(c[0]))?.sys_name,
                  c[1],
                ],
              ],
              [["System", "Count"]]
            )}
            type="Pie"
            size="reg"
          />
        </Grid>
        <Grid item xs={12} md={3.9}>
          <Widget
            name="Failed Job Executions"
            // link
            // group
            // label
            data={
              (last24hourJobs || [])?.filter((item) => {
                return item.status === "failed";
              })?.length
            }
            type="Metric"
            size="reg"
          />
        </Grid>
        <Grid mt={3} item xs={12}>
          <Stack
            justifyContent={"space-between"}
            alignItems={"center"}
            direction="row"
            mb={2}
          >
            <SectionHeader large icon="pending">
              Queue
            </SectionHeader>
            <IconButton onClick={() => setQueueView(!queueView)}>
              <Icon size="large">
                {queueView ? "expand_less" : "expand_more"}
              </Icon>
            </IconButton>
          </Stack>
          {queueView ? (
            <Stack
              sx={{
                overflowY: "scroll",
                maxHeight: "50vh",
                backgroundColor: themeOptions.palette.primary.main,
                padding: "10px",
                borderRadius: "7px",
              }}
              gap={2}
            >
              {jobs?.length ? (
                (jobs || [])
                  .sort((a, b) => {
                    // Convert the date strings into Date objects
                    const dateA = new Date(getUserFriendlyDate(a?.start));
                    const dateB = new Date(getUserFriendlyDate(b?.start));

                    // Compare the dates
                    return dateB - dateA; // For descending order (most current to historical)
                  })
                  .map((item, index) => {
                    return (
                      <Stack
                        key={index}
                        sx={{ width: "98%", margin: "0 auto" }}
                        direction="row"
                        alignItems={"center"}
                        p={2}
                        gap={2}
                        className="floating"
                      >
                        <Stack sx={{ flex: 1 }}>
                          <Stack direction="row" gap={1}>
                            <Icon>{Status[item.status]}</Icon>
                            <Typography>
                              {item?.content?.name?.split("_")?.at(-1)}
                            </Typography>
                          </Stack>
                          <Stack direction="row" gap={1}>
                            <Typography variant="caption">
                              {item?.start
                                ? getUserFriendlyDate(item?.start)
                                : ""}
                            </Typography>
                            <Typography variant="caption">-</Typography>
                            <Typography variant="caption">
                              {item?.end ? getUserFriendlyDate(item?.end) : ""}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Typography>
                          {item?.cmd_name
                            ? `${item?.cmd_name} (${item?.sys_name})`
                            : ""}
                        </Typography>
                        <ActionButton
                          noload
                          circle
                          icon="sms"
                          handler={() => setPanel({ type: "log", item })}
                        />
                      </Stack>
                    );
                  })
              ) : (
                <Typography>No jobs</Typography>
              )}
            </Stack>
          ) : null}
        </Grid>
        {!["auditor"].includes(role) ? (
          <Grid mt={3} item xs={12}>
            <Stack
              justifyContent={"space-between"}
              alignItems={"center"}
              direction="row"
              mb={2}
            >
              <SectionHeader large icon="schedule">
                Schedules
              </SectionHeader>
              <IconButton onClick={() => setScheduleView(!scheduleView)}>
                <Icon size="large">
                  {scheduleView ? "expand_less" : "expand_more"}
                </Icon>
              </IconButton>
            </Stack>

            {scheduleView ? (
              <Stack
                sx={{
                  overflowY: "scroll",
                  maxHeight: "50vh",
                  backgroundColor: themeOptions.palette.primary.main,
                  padding: "10px",
                  borderRadius: "7px",
                }}
                gap={2}
              >
                {schedules?.length ? (
                  (schedules || [])
                    ?.sort(
                      (a, b) =>
                        new Date(getNextCronExecutions(a?.content?.cron)[0]) -
                        new Date(getNextCronExecutions(b?.content?.cron)[0])
                    )
                    ?.reverse()
                    ?.map((item, index) => {
                      return (
                        <Stack
                          key={index}
                          sx={{ width: "98%", margin: "0 auto" }}
                          direction="row"
                          alignItems={"center"}
                          p={2}
                          className="floating"
                        >
                          <Stack flex={1}>
                            <Stack
                              alignItems={"center"}
                              direction="row"
                              gap={1}
                            >
                              <Switch disabled checked={item.enabled} />
                              <Typography>
                                {item?.content?.name?.split("_")?.at(-1)}
                              </Typography>
                            </Stack>
                            <Stack gap={1}>
                              <Typography variant="caption">
                                {/* {item.content.cron} */}
                              </Typography>

                              <Typography variant="caption">
                                <strong
                                  style={{ marginRight: "10px" }}
                                >{`Next Run:`}</strong>
                                {getNextCronExecutions(item?.content?.cron)[0]}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack alignItems={"center"} direction="row" gap={1}>
                            <Typography>
                              {item?.cmd_name
                                ? `${item?.cmd_name} (${item?.sys_name})`
                                : ""}
                            </Typography>
                            <ActionButton
                              noload
                              circle
                              icon="edit"
                              handler={() =>
                                setPanel({
                                  type: "schedule",
                                  refresh,
                                  item: { ...item, ...item.content },
                                })
                              }
                            />
                            <ActionButton
                              danger
                              noload
                              circle
                              icon="clear"
                              handler={() =>
                                openScheduleDeleteModal(true, { ...item })
                              }
                            />
                          </Stack>
                        </Stack>
                      );
                    })
                ) : (
                  <Typography>No schedules</Typography>
                )}
              </Stack>
            ) : null}
          </Grid>
        ) : null}
        {!["operator", "auditor"].includes(role) ? (
          <Grid mt={3} item xs={12}>
            <Stack
              justifyContent={"space-between"}
              alignItems={"center"}
              direction="row"
              mb={2}
            >
              <SectionHeader large icon="webhook">
                Webhooks
              </SectionHeader>
              <IconButton onClick={() => setWebhookView(!webhookView)}>
                <Icon size="large">
                  {webhookView ? "expand_less" : "expand_more"}
                </Icon>
              </IconButton>
            </Stack>
            {webhookView ? (
              <Stack
                sx={{
                  overflowY: "scroll",
                  maxHeight: "50vh",
                  backgroundColor: themeOptions.palette.primary.main,
                  padding: "10px",
                  borderRadius: "7px",
                }}
                gap={2}
              >
                {webhooks?.length ? (
                  (webhooks || []).map((item, index) => {
                    return (
                      <Stack
                        key={index}
                        sx={{ width: "98%", margin: "0 auto" }}
                        direction="row"
                        alignItems={"center"}
                        p={2}
                        className="floating"
                      >
                        <Stack flex={1}>
                          <Stack alignItems={"center"} direction="row" gap={1}>
                            <Icon>
                              {item?.type === "read" ? "bookmark" : "edit_note"}
                            </Icon>
                            <Typography>{item.name}</Typography>
                          </Stack>
                        </Stack>
                        <Stack alignItems={"center"} direction="row" gap={1}>
                          <Typography>
                            {`${item?.cmd_name || ""} (${item?.sys_name ||
                              ""})`}
                          </Typography>
                          <ActionButton
                            noload
                            circle
                            icon="navigate_next"
                            handler={() => setPanel({ type: "webhook", item })}
                          />
                          <ActionButton
                            danger
                            noload
                            circle
                            icon="clear"
                            handler={() =>
                              openWebhookDeleteModal(true, { ...item })
                            }
                          />
                        </Stack>
                      </Stack>
                    );
                  })
                ) : (
                  <Typography>No webhooks</Typography>
                )}
              </Stack>
            ) : null}
          </Grid>
        ) : null}
      </Grid>
      <Stack sx={{ height: "150px" }} />
    </Page>
  );
}
