import React from "react";
import SidePanel from "common/components/SidePanel";
import {
  Grid,
  Stack,
  Icon,
  IconButton,
  Avatar,
  Typography,
} from "@mui/material";
import useState from "common/hooks/useState";
import GraphBuilder from "common/components/GraphBuilder";
import useScopeAttributes from "common/hooks/useScopeAttributes";
import { ChartIcons } from "common/components/Widget";
import { nanoid } from "nanoid";
import { TypeMap } from "constants";
import DnDList from "common/components/DndList";
import SectionHeader from "common/components/SectionHeader";
import Input from "common/components/Input";
import MultiSelect from "common/components/MultiSelect";
import Switch from "common/components/Switch";
import ColorPicker from "common/components/ColorPicker";
import ActionButton from "common/components/ActionButton";
import toast from "react-hot-toast";
import validate from "constants/validate";
import { PLUGIN_OPTIONS } from "constants";
import { useNavigate } from "react-router-dom";
import useFetcher from "common/hooks/useFetcher";
import { MiniLoader } from "common/components/Loader";
import E1Input from "common/components/E1Input";
import useForm from "common/hooks/useForm";
import IconPicker from "common/components/IconPicker";
import Radio from "common/components/Radio";
const AddDetectorPanel = ({ setDetectors, onClose }) => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [icon, setIcon] = React.useState("");
  const [expression, setExpression] = React.useState("");
  const onSave = async () => {
    setDetectors((d) => [
      ...d,
      {
        id: nanoid(),
        name,
        description,
        icon,
        expression,
        triggers: [],
      },
    ]);
  };
  return (
    <Stack p={2} gap={3}>
      <SectionHeader large icon="manage_search">
        New Detector
      </SectionHeader>
      <Stack className="floating" gap={3}>
        <Stack>
          <Typography variant="body1">
            Add a name and description for your new detector.
          </Typography>
        </Stack>
        <Stack gap={2}>
          <IconPicker value={icon} onChange={setIcon} />
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            label="Name"
            placeholder="detector name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            label="Description"
            placeholder="detector description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            minRows={5}
            maxRows={5}
          />
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            label="Expression"
            placeholder='resource["Name"] == "Test"'
            value={expression}
            onChange={(e) => setExpression(e.target.value)}
            multiline
            minRows={5}
            maxRows={5}
          />
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto", display: "flex" }}>
        <ActionButton
          icon="add"
          dark
          handler={async () => {
            onSave();
            onClose();
          }}
        >
          Create Detector
        </ActionButton>
      </div>
    </Stack>
  );
};
export const DetectorPanel = ({
  detector,
  setDetectors,
  setTriggerPanel,
  onClose,
}) => {
  const [name, setName] = React.useState(detector?.name || "");
  const [icon, setIcon] = React.useState(detector?.icon || "");
  const [expression, setExpression] = React.useState(
    detector?.expression || ""
  );
  const [description, setDescription] = React.useState(
    detector?.description || ""
  );
  const [triggers, setTriggers] = React.useState(detector?.triggers || []);

  // React.useEffect(() => {
  // setDetectors((detectors) =>
  //   detectors.map((d) => {
  //     if (d.id !== detector.id) return d;
  //     return { ...d, description, expression, triggers };
  //   })
  // );
  // }, [triggers, expression, description]);

  if (!detector) return null;
  return (
    <Stack p={2} gap={3}>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <SectionHeader large icon={detector?.icon}>
          {detector?.name || "New Detector"}
        </SectionHeader>
        <ActionButton
          dark
          noload
          // disabled={!dirty}
          icon={"save"}
          handler={async () => {
            if (!description || !expression)
              return toast.error("Must provide a Description and Expression");
            if (!detector?.name) {
              setDetectors((d) => [
                ...d,
                {
                  id: nanoid(),
                  name,
                  description,
                  icon,
                  expression,
                  triggers: [],
                },
              ]);
            } else {
              setDetectors((detectors) =>
                detectors.map((d) => {
                  if (d.id !== detector.id) return d;
                  return { ...d, description, expression, triggers };
                })
              );
            }
            onClose();
          }}
        >
          Save
        </ActionButton>
      </Stack>
      {detector.id ? null : (
        <>
          <IconPicker value={icon} onChange={setIcon} />
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            label="Name"
            placeholder="detector name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </>
      )}
      <Input
        size="small"
        dark
        sx={{ width: "100%" }}
        label="Description"
        placeholder="detector description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        multiline
        minRows={5}
        maxRows={5}
      />
      <Input
        size="small"
        dark
        sx={{ width: "100%" }}
        label="Expression"
        placeholder='resource["Name"] == "Test"'
        value={expression}
        onChange={(e) => setExpression(e.target.value)}
        multiline
        minRows={5}
        maxRows={5}
      />
      <Stack direction="row" justifyContent={"space-between"}>
        <SectionHeader icon="bolt">Triggers</SectionHeader>
        <ActionButton
          dark
          noload
          icon="add"
          handler={() => {
            setTriggerPanel([detector, null, setTriggers]);
          }}
        >
          Add Trigger
        </ActionButton>
      </Stack>
      <Stack gap={2}>
        <DnDList
          state={[triggers, setTriggers]}
          itemComponent={({ item }) => (
            <Stack
              key={item.id}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              style={{ width: "100%", height: "50px" }}
              className="floating"
            >
              <Stack direction="row" gap={1} alignItems={"center"}>
                <Switch
                  onChange={() => {
                    setTriggers((triggers) => {
                      return triggers.map((t) => {
                        if (t.id !== item.id) return t;
                        return { ...t, enabled: !item.enabled };
                      });
                    });
                  }}
                  checked={item?.enabled}
                />
                <Typography>{item.name}</Typography>
              </Stack>
              <div style={{ marginLeft: "auto" }}>
                <IconButton
                  onClick={() => setTriggerPanel([detector, item, setTriggers])}
                >
                  <Icon>navigate_next</Icon>
                </IconButton>

                <IconButton
                  onClick={() => {
                    setTriggers((triggers) => {
                      return triggers.filter((t) => t.id !== item.id);
                    });
                  }}
                >
                  <Icon color="error">delete</Icon>
                </IconButton>
              </div>
            </Stack>
          )}
        />
      </Stack>
    </Stack>
  );
};
export const TriggerPanel = ({ data, system, onClose }) => {
  const [detector, trigger, setTriggers] = data || [{}, {}];
  const [name, setName] = React.useState(trigger?.name || "");
  const [description, setDescription] = React.useState(
    trigger?.description || ""
  );
  const [action, setAction] = React.useState(trigger?.action || "");
  const [actionData, setActionData] = React.useState(null);
  const [target, setTarget] = React.useState(trigger?.target || "");
  const { targets, loading: targetsLoading } = useState(`/api/targeting`);
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const [loading, setLoading] = React.useState(false);
  const { controller: cmdinputs, getFormValues: getCmdInputs } = useForm(
    "cmdinputs"
  );
  const fetchCommand = async () => {
    setLoading(true);
    await fetcher.get(
      "fetching action",
      `/api/sys/${system?.id}/action/${action}`,
      ({ action }) => {
        if (!action) return;
        let update = { ...action };
        delete update.code;
        if (action.inputs.map((i) => i.type).includes("file")) {
          toast.error("Cannot use commands with file input");
          setAction("");
          return;
        }
        setActionData(action);
      },
      () => {}
    );
    setLoading(false);
  };
  React.useEffect(() => {
    if (!action && actionData) return setActionData(false);
    if (!action) return;
    fetchCommand();
  }, [action]);
  return (
    <Stack p={2} gap={3}>
      <SectionHeader large icon="rocket">
        {trigger?.id ? "Update " : "Add "} Trigger
      </SectionHeader>
      <Stack className="floating" gap={3}>
        <Stack>
          <Typography variant="body1">
            Add a name and description for your new trigger
          </Typography>
        </Stack>
        <Stack gap={2}>
          <Input
            size="small"
            dark
            label="Name"
            sx={{ width: "100%" }}
            placeholder="trigger name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            size="small"
            dark
            trigger="Description"
            sx={{ width: "100%" }}
            placeholder="trigger description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            minRows={5}
            maxRows={5}
          />
          <MultiSelect
            disabled={loading}
            dark
            options={system?.actions
              ?.filter((a) => a.type !== "new")
              ?.map(({ id }) => id)}
            getOptionLabel={(e) =>
              system?.actions?.find((d) => d.id === e)?.name || ""
            }
            placeholder="select action"
            multiple={false}
            label="Action"
            value={action}
            setValue={setAction}
            size="small"
          />
          {loading ? (
            <div style={{ margin: "20px", position: "relative" }}>
              <MiniLoader />
            </div>
          ) : null}
          {actionData?.inputs?.length ? (
            <Stack
              sx={{
                width: "100%",
              }}
              spacing={2}
            >
              <Typography variant="body1">Inputs</Typography>
              {actionData?.inputs?.map((p) => {
                return (
                  <E1Input
                    key={p.id}
                    sx={{ width: "100%" }}
                    type={p.type}
                    label={p.name}
                    help={p.help}
                    defaultValue={
                      trigger?.inputs ? trigger?.inputs[p.name] || "" : p.value
                    }
                    opts={p?.defaultValue || "[]"}
                    inputProps={{ ...cmdinputs(p.name) }}
                  />
                );
              })}
              {actionData?.targeting?.select &&
              !actionData?.targeting?.target ? (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Typography variant="body1">Target</Typography>
                  <MultiSelect
                    dark
                    size="small"
                    options={targets.map((t) => t.id)}
                    loading={targetsLoading}
                    getOptionLabel={(e) =>
                      targets.find((t) => t.id === e)?.name || ""
                    }
                    label="Target"
                    multiple={false}
                    placeholder="Select a target"
                    value={target}
                    setValue={setTarget}
                    sx={{ width: "100%" }}
                  />
                </Stack>
              ) : null}
            </Stack>
          ) : null}
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto", display: "flex" }}>
        <ActionButton
          icon="add"
          dark
          noload
          handler={async () => {
            const newTrigger = {
              id: trigger?.id || nanoid(),
              name,
              description,
              detector: detector.id,
              action,
              enabled: trigger?.enabled || false,
              inputs: getCmdInputs(),
              target: target || null,
            };
            if (!validate("system_trigger_edit", newTrigger)) return;

            setTriggers((triggers) => {
              if (!trigger?.id) {
                return [...triggers, newTrigger];
              }
              const index = triggers.findIndex((t) => t.id === trigger.id);
              triggers[index] = newTrigger;
              return triggers;
            });

            onClose();
          }}
        >
          Save Trigger
        </ActionButton>
      </div>
    </Stack>
  );
};
const GeneralPanel = ({
  system,
  setSystem,
  description,
  setDescription,
  onClose,
  dataBacked,
}) => {
  const { ScopeInputs, getScope, setInit, loading } = useScopeAttributes();
  // if(system){
  //       setInit(system.tags, system.acl);
  // }
  React.useEffect(() => {
    if (!loading) setInit(system.tags, system.acl);
  }, [loading]);
  return (
    <Stack p={2} gap={3}>
      <SectionHeader large icon="info">
        General
      </SectionHeader>
      <Stack className="floating" gap={3}>
        <Stack>
          <Typography variant="h5">Description</Typography>
        </Stack>
        <Stack gap={2}>
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            placeholder="system description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            minRows={5}
            maxRows={5}
          />
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Stack direction="row" justifyContent={"space-between"}>
          <Typography variant="h5">Plugins</Typography>
        </Stack>
        <Stack gap={1}>
          {PLUGIN_OPTIONS.map(([label, desc, key, disabled, update], idx) => {
            return (
              <Stack
                gap={1}
                alignItems="center"
                key={idx}
                direction={"row"}
                spacing={1}
              >
                <span style={{ minWidth: "150px" }}>
                  <Switch
                    disabled={key === "data" && dataBacked}
                    onChange={() => {
                      setSystem((s) => ({
                        ...s,
                        plugins: {
                          ...system.plugins,
                          [key]: !system.plugins[key],
                        },
                      }));
                    }}
                    disable
                    label={label}
                    checked={system.plugins[key]}
                  />
                </span>
                <Typography variant="subtitle1">{desc}</Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Access Control</Typography>
        <Stack gap={1}>
          {loading ? (
            <div
              style={{
                marginLeft: "20px",
                padding: "50px",
                position: "relative",
              }}
            >
              <MiniLoader />
            </div>
          ) : (
            <ScopeInputs />
          )}
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto" }}>
        <ActionButton
          icon="save"
          dark
          noload
          handler={async () => {
            setSystem({ ...system, ...getScope() });
            onClose();
          }}
        >
          Save Configuration
        </ActionButton>
      </div>
      <div style={{ height: "100px" }} />
    </Stack>
  );
};
export const NewCommandPanel = ({ system, onClose }) => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [icon, setIcon] = React.useState("");
  const [type, setType] = React.useState("default");
  const [commandFunction, setCommandFunction] = React.useState(
    "import e1\n\ndef handler(context):\n    return None\n"
  );
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const defaultCommand = {
    name: "",
    description: "",
    inputs: [],
    envs: [],
    packages: [],
    tags: [],
    acl: [],
    scan: [],
    code: commandFunction,
    targeting: {
      enabled: false,
      select: false,
      target: null,
    },
  };
  const onSave = async (onClose) => {
    let newCommand = { ...defaultCommand, name, description, icon };
    if (name.includes("_"))
      return toast.error("Command name cannot contain underscores");
    if (!validate("command_edit", newCommand)) return;
    if (["collector", "selection"].includes(type)) {
      newCommand.updateData = true;
      if (type === "selection") {
        newCommand.selectionBased = true;
      }
    }
    await fetcher.post(
      "saving command",
      `/api/sys/${system?.id}/action/new`,
      newCommand,
      (data) => {
        if (data?.error) return toast.error(data?.error);
        if (data?.id)
          return navigate(
            `/app/sys/${system?.id}/action/${data?.id}?system=${system?.name}`
          );
      },
      () => {}
    );
    onClose();
  };
  return (
    <Stack p={2} gap={3}>
      <SectionHeader large icon="rocket">
        New Command
      </SectionHeader>
      <Stack className="floating" gap={3}>
        <Stack>
          <Typography variant="body1">
            Add a name and description for your new command
          </Typography>
        </Stack>
        <Stack gap={2}>
          <IconPicker value={icon} onChange={setIcon} />
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            placeholder="command name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            placeholder="command description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            minRows={5}
            maxRows={5}
          />
          <Typography sx={{ fontSize: "14px" }} variant="overline">
            Starter Templates
          </Typography>
          <Radio
            sx={{ justifyContent: "flex-start" }}
            defaultValue={type}
            onChange={(v) => {
              setType(v);
              let command =
                "import e1\n\ndef handler(context):\n    return None\n";
              if (v === "collector") {
                command =
                  "import e1\n\ndef handler(context):\n\tdataset = []\n\t# add logic here to add to the dataset\n\treturn dataset";
              } else if (v === "selection") {
                command =
                  "import e1\n\ndef handler(context):\n\tfor resource in context.dataset:\n\t\tif not context.is_selected(resource):\n\t\t\tcontinue\n\t\t# add logic here to handle each selected resource\n\treturn context.dataset";
              }
              setCommandFunction(command);
            }}
            options={[
              {
                name: "default",
                icon: (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                    <rect width={256} height={256} fill="none" />
                    <polyline
                      points="64 88 16 128 64 168"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={16}
                    />
                    <polyline
                      points="192 88 240 128 192 168"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={16}
                    />
                    <line
                      x1={160}
                      y1={40}
                      x2={96}
                      y2={216}
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={16}
                    />
                  </svg>
                ),
              },
              ...(system?.plugins?.data
                ? [
                    {
                      name: "collector",
                      icon: (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 256 256"
                        >
                          <rect width={256} height={256} fill="none" />
                          <line
                            x1={40}
                            y1={64}
                            x2={216}
                            y2={64}
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={16}
                          />
                          <line
                            x1={40}
                            y1={128}
                            x2={216}
                            y2={128}
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={16}
                          />
                          <line
                            x1={40}
                            y1={192}
                            x2={144}
                            y2={192}
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={16}
                          />
                          <line
                            x1={184}
                            y1={192}
                            x2={232}
                            y2={192}
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={16}
                          />
                          <line
                            x1={208}
                            y1={168}
                            x2={208}
                            y2={216}
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={16}
                          />
                        </svg>
                      ),
                    },
                    {
                      name: "selection",
                      icon: (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 256 256"
                        >
                          <rect width={256} height={256} fill="none" />
                          <ellipse
                            cx={128}
                            cy={112}
                            rx={104}
                            ry={64}
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={16}
                          />
                          <path
                            d="M48,225.6c32,16.7,80,0,80-41.77,0-53.66-60.64-62.5-70.62-24.85"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={16}
                          />
                        </svg>
                      ),
                    },
                  ]
                : []),
            ]}
          />
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto", display: "flex" }}>
        <ActionButton
          icon="add"
          dark
          handler={async () => {
            await onSave(onClose);
          }}
        >
          Create Command
        </ActionButton>
      </div>
    </Stack>
  );
};

export default function GeneralManagement({
  system,
  setSystem,
  dataBacked,
  dirty,
}) {
  const [description, setDescription] = React.useState(
    system?.description || ""
  );
  const [notClean, setNotClean] = React.useState(dirty);
  const [widgets, setWidgets] = React.useState(system?.graphs || []);
  const [actions, setActions] = React.useState(system?.actions || []);
  const [detectors, setDetectors] = React.useState(system?.detectors || []);
  const [editGeneral, setEditGeneral] = React.useState(false);
  const [editGraph, setEditGraph] = React.useState(false);
  const [newCommand, setNewCommand] = React.useState(false);
  const [addDetectorPanel, setAddDetectorPanel] = React.useState(false);
  const [triggerPanel, setTriggerPanel] = React.useState(false);
  const [detectorPanel, setDetectorPanel] = React.useState(false);
  const [sampleCache, setSampleCache] = React.useState("");
  const navigate = useNavigate();
  const onSaveGraph = async (payload) => {
    let graphs;
    if (widgets.find(({ id }) => id === payload.id)) {
      graphs = widgets?.map((g) => {
        if (g.id !== payload.id) return g;
        return payload;
      });
    } else {
      graphs = [...(widgets || []), payload];
    }
    setWidgets(graphs);
  };

  React.useEffect(() => {
    if (!notClean) return setNotClean(true);
    setSystem({ ...system, graphs: widgets, actions, detectors, description });
  }, [widgets, actions, detectors, description]);
  return (
    <Stack gap={2}>
      <Stack gap={2}>
        <SectionHeader large icon="info">
          General
        </SectionHeader>
        <Stack className="floating" gap={3}>
          <Stack direction="row" justifyContent={"space-between"}>
            <Typography variant="h5">Description</Typography>
            <ActionButton
              circle
              noload
              icon="edit"
              handler={() => setEditGeneral(true)}
            />
          </Stack>
          <Stack sx={{ width: "80%" }} gap={1}>
            <Typography>{description}</Typography>
          </Stack>

          <Stack direction="row" justifyContent={"space-between"}>
            <Typography variant="h5">Plugins</Typography>
          </Stack>
          <Stack gap={2}>
            {PLUGIN_OPTIONS.map(([label, desc, key, disabled, update], idx) => {
              return (
                <Stack
                  // alignItems="center"
                  key={idx}
                  direction={"column"}
                  spacing={1}
                >
                  <span sx={{ minWidth: "150px" }}>
                    <Switch
                      label={label}
                      checked={system.plugins[key]}
                      disabled
                    />
                  </span>
                  <Typography style={{ margin: 0 }} variant="subtitle1">
                    {desc}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
          {system?.tags?.length ? (
            <Stack
              sx={{ marginLeft: "auto", alignItems: "center" }}
              direction="row"
              gap={1}
            >
              <Icon style={{ fontSize: "15px" }}>tag</Icon>
              <Typography sx={{ fontWeight: "bold" }}>
                {system?.tags?.join(", ")}
              </Typography>
            </Stack>
          ) : null}

          <Stack
            sx={{ marginLeft: "auto", alignItems: "center" }}
            direction="row"
            gap={1}
          >
            <Icon style={{ fontSize: "15px" }}>group</Icon>
            <Typography sx={{ fontWeight: "bold" }}>
              {system?.acl?.length}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {system?.plugins?.graphs ? (
        <Stack gap={2}>
          <SectionHeader large icon="donut_large">
            Widgets
          </SectionHeader>
          <Stack className="floating" gap={3}>
            <Stack direction="row" justifyContent={"space-between"}>
              <Typography variant="body1">Visualize your dataset</Typography>
              <span
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <ActionButton
                  circle
                  noload
                  icon="add"
                  handler={() => setEditGraph(true)}
                />
              </span>
            </Stack>
            <DnDList
              state={[widgets, setWidgets]}
              itemComponent={({ item }) => (
                <Stack
                  key={item.id}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  style={{ width: "100%", height: "50px" }}
                  className="floating"
                >
                  <Stack sx={{ width: "200px" }} direction={"row"} gap={1}>
                    <Icon>{ChartIcons[item.type || "Metric"]}</Icon>
                    <Typography>{item.name}</Typography>
                  </Stack>
                  <div>
                    <IconButton onClick={() => setEditGraph(item)}>
                      <Icon>edit</Icon>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        let graphs = widgets.filter((d) => d.id !== item.id);
                        setWidgets(graphs);
                      }}
                    >
                      <Icon color="error">delete</Icon>
                    </IconButton>
                  </div>
                </Stack>
              )}
            />
          </Stack>
        </Stack>
      ) : null}
      <Stack gap={2}>
        <SectionHeader large icon="rocket">
          Commands
        </SectionHeader>

        <Stack className="floating" gap={3}>
          <Stack direction="row" justifyContent={"space-between"}>
            <Typography variant="body1">
              Execute logic on your system
            </Typography>
            <span
              style={{ display: "flex", flexDirection: "row", gap: "10px" }}
            >
              <ActionButton
                circle
                noload
                icon="add"
                handler={() => setNewCommand(true)}
              />
            </span>
          </Stack>
          <Grid gap={1} container>
            <DnDList
              state={[actions, setActions]}
              itemComponent={({ item }) => (
                <Stack
                  key={item.id}
                  direction={"row"}
                  alignItems={"center"}
                  style={{ width: "100%" }}
                  className="floating"
                >
                  <Stack gap={1}>
                    <Stack direction={"row"} gap={1}>
                      <Icon>{item.icon}</Icon>
                      <Typography>{item.name}</Typography>
                    </Stack>
                    <Typography
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "normal",
                      }}
                      variant="subtitle1"
                    >
                      {item.description}
                    </Typography>
                  </Stack>
                  <div style={{ marginLeft: "auto" }}>
                    <IconButton
                      onClick={() =>
                        navigate(
                          `/app/sys/${system?.id}/action/${item?.id}?system=${system?.name}`
                        )
                      }
                    >
                      <Icon>navigate_next</Icon>
                    </IconButton>
                  </div>
                </Stack>
              )}
            />
          </Grid>
        </Stack>

        <SidePanel
          width={500}
          open={newCommand}
          closeDrawer={() => setNewCommand(null)}
          props={{ system }}
        >
          {NewCommandPanel}
        </SidePanel>
        <SidePanel
          width={500}
          open={editGeneral}
          closeDrawer={() => setEditGeneral(null)}
          props={{ description, setDescription, system, setSystem, dataBacked }}
        >
          {GeneralPanel}
        </SidePanel>
        <SidePanel
          width={500}
          open={addDetectorPanel}
          closeDrawer={() => setAddDetectorPanel(null)}
          props={{ setDetectors }}
        >
          {AddDetectorPanel}
        </SidePanel>
        <SidePanel
          width={600}
          open={detectorPanel}
          closeDrawer={() => setDetectorPanel(null)}
          props={{ setDetectors, detector: detectorPanel, setTriggerPanel }}
        >
          {DetectorPanel}
        </SidePanel>
        <SidePanel
          width={600}
          open={triggerPanel}
          closeDrawer={() => setTriggerPanel(null)}
          props={{ data: triggerPanel, setDetectors, system }}
        >
          {TriggerPanel}
        </SidePanel>
        <SidePanel
          width={900}
          open={editGraph}
          closeDrawer={() => setEditGraph(null)}
          props={{
            data: system,
            onSave: onSaveGraph,
            existing: editGraph,
            sampleCache,
            setSampleCache,
          }}
        >
          {GraphBuilder}
        </SidePanel>
      </Stack>
      {system?.plugins?.detectors ? (
        <Stack gap={2}>
          <SectionHeader large icon="manage_search">
            Detectors
          </SectionHeader>
          <Stack className="floating" gap={3}>
            <Stack direction="row" justifyContent={"space-between"}>
              <Typography variant="body1">
                Identify resoures within the system using expressions.
              </Typography>
              <span
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <ActionButton
                  circle
                  noload
                  icon="add"
                  handler={() => setAddDetectorPanel(true)}
                />
              </span>
            </Stack>
            {system?.detectors?.map((item) => {
              return (
                <Stack
                  key={item.id}
                  direction={"row"}
                  alignItems={"center"}
                  style={{ width: "100%" }}
                  className="floating"
                >
                  <Stack gap={1}>
                    <Stack direction={"row"} gap={1}>
                      <Icon>{item.icon}</Icon>
                      <Typography>{item.name}</Typography>
                    </Stack>
                    <Typography
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "normal",
                      }}
                      variant="subtitle1"
                    >
                      {item.description}
                    </Typography>
                  </Stack>
                  <div style={{ marginLeft: "auto" }}>
                    <IconButton onClick={() => setDetectorPanel(item)}>
                      <Icon>navigate_next</Icon>
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        setDetectors(detectors.filter((d) => d.id !== item.id));
                      }}
                    >
                      <Icon color="error">delete</Icon>
                    </IconButton>
                  </div>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
}
