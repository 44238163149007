import React from "react";
import { Icon } from "@mui/material";
export default function Fab({
  active,
  iconColor = "#fff",
  icon = "add",
  onClick,
  ...rest
}) {
  return (
    <div
      onClick={onClick}
      className={"e1-fab" + (active ? " e1-fab__active" : "")}
      {...rest}
    >
      <Icon sx={{ fontSize: "35px !important", color: iconColor }}>{icon}</Icon>
    </div>
  );
}
