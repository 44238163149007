import * as React from "react";
import { Typography, Stack, Chip, IconButton, Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Header({ children, actions, chips = [], goBack }) {
  const navigate = useNavigate();
  const chipData = React.useMemo(() => {
    return chips
      .filter((c) => !c.hidden)
      .map(({ label, ...rest }, idx) => (
        <Chip key={idx} label={label} {...rest} />
      ));
  }, [chips]);
  return (
    <Stack
      spacing={2}
      pb={2}
      mb={2}
      sx={{ borderBottom: "1px solid #45475a" }}
      direction="row"
      alignItems="center"
    >
      {goBack ? (
        <IconButton
          onClick={() =>
            typeof goBack === "string" ? navigate(goBack) : goBack()
          }
        >
          <Icon>navigate_before</Icon>
        </IconButton>
      ) : null}
      <Typography variant="h4" gutterBottom>
        {children}
      </Typography>
      {chipData}
      <Stack sx={{ marginLeft: "auto !important" }} direction="row" spacing={1}>
        {actions}
        <div id="page-menu" />
      </Stack>
    </Stack>
  );
}
