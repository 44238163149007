import React, { useEffect } from "react";
import Page from "common/components/Page";
import { Icon, Stack, Card, Typography, IconButton } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import AwsTarget from "pages/Targets/components/AwsTarget";
import ConfigDrawer from "./components/ConfigDrawer";
import SectionHeader from "common/components/SectionHeader";
import CodeAnalyzer from "./components/CodeAnalyzer";
import useFetcher from "common/hooks/useFetcher";
import { themeOptions } from "theme";
import { TypeMap } from "constants";
import CodeEditor from "common/components/CodeEditor";
import CommandCopy from "pages/Action/components/Copy";
import CommandRestore from "pages/Action/components/Restore";
import toast from "react-hot-toast";
import MultiLevelMenu from "common/components/MultilevelMenu";
import ActionButton from "common/components/ActionButton";
import validate from "constants/validate";
import JobsModal from "pages/SystemDash/component/Jobs";
import FilesModal from "pages/SystemDash/component/Files";
import useConfirm from "common/hooks/useConfirm";
import Fab from "common/components/Fab";
import SidePanel from "common/components/SidePanel";
import copyContent from "common/utils/copyContent";
import { MiniLoader } from "common/components/Loader";
import CommandDebugPanel from "./components/Execute";
import CliCommand from "common/components/CliCommand";
import AiIcon from "assets/icons/magic.svg";
import CodeGenerator from "./components/CodeGenerator";
import ClickableTypography from "common/components/ClickableTypography";

const customSnippets = [
  {
    caption: "e1Dataset",
    snippet: "context.dataset",
    type: "snippet",
    meta: "current dataset",
  },
  {
    caption: "e1HandlerSelection",
    snippet:
      "for resource in context.dataset: \n\tif not context.is_selected(resource):\n\t\tcontinue\n\t${1:# code to run on selected resources goes here}",
    type: "snippet",
    meta:
      "logic to write logic against only selected resources from the dataset",
  },
  {
    caption: "e1FileUpload",
    snippet: "e1.file.upload(${1:LOCAL_FILE_PATH})",
    type: "snippet",
    meta: "upload file to system",
  },
  {
    caption: "e1FileDownload",
    snippet: "e1.file.download(${1:FILE_NAME},${2:LOCAL_FILE_PATH})",
    type: "snippet",
    meta: "download file from system",
  },
  {
    caption: "e1FileDelete",
    snippet: "e1.file.delete(${1:FILE_NAME})",
    type: "snippet",
    meta: "delete file from system",
  },
  {
    caption: "e1TenantId",
    snippet: "e1.tenant().id",
    type: "snippet",
    meta: "access current tenant id",
  },
  {
    caption: "e1TenantAlias",
    snippet: "e1.tenant().alias",
    type: "snippet",
    meta: "access current tenant alias",
  },
  {
    caption: "e1TenantGeo",
    snippet: "e1.tenant().geo",
    type: "snippet",
    meta: "access current tenant geo",
  },
  {
    caption: "e1AwsSession",
    snippet: "session = e1.cloud.aws.session()",
    type: "snippet",
    meta: "create session for current AWS tenant",
  },
  {
    caption: "e1AwsAssume",
    snippet: "session = e1.cloud.aws.assume(${1:ACCOUNT},${2:REGION})",
    type: "snippet",
    meta: "create session for a given AWS account id and region",
  },
  {
    caption: "e1AzureSession",
    snippet: "session = e1.cloud.azure.session()",
    type: "snippet",
    meta: "create session for current Azure tenant",
  },
  {
    caption: "e1AzureAssume",
    snippet:
      "session = e1.cloud.azure.assume(${1:SUBSCRIPTION_ID},${2:REGION})",
    type: "snippet",
    meta: "create session for a given Azure subscription id and region",
  },
  {
    caption: "e1AzureSubscription",
    snippet: 'os.getenv("AZURE_SUBSCRIPTION_ID")',
    type: "snippet",
    meta: "get current Azure subscription id",
  },
];

const Panel = ({
  menu,
  system,
  onClose,
  data,
  setData,
  dataBacked,
  setE1Pkg,
}) => {
  return (
    <Stack>
      <ConfigDrawer
        {...{ menu, data, system, setData, dataBacked, setE1Pkg }}
        close={onClose}
      />
    </Stack>
  );
};
const PackageInfo = ({ e1Pkg }) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const fetcher = useFetcher();

  const fetchPkg = async () => {
    setLoading(true);
    let [pkg, version] = e1Pkg.split("==");
    await fetcher.get(
      "fetching package info",
      `/api/pkg/${pkg}/version/${version}`,
      ({ pkg }) => {
        setData(pkg);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
    setLoading(false);
  };
  useEffect(() => {
    if (!e1Pkg) return;
    fetchPkg();
  }, [e1Pkg]);
  if (loading)
    return (
      <div
        style={{
          marginTop: "100px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MiniLoader />
        <Typography sx={{ marginTop: "60px" }}>loading package info</Typography>
      </div>
    );
  let docs = data?.doc;
  let packages = data?.packages;
  return (
    <Stack p={4}>
      <SectionHeader>{e1Pkg}</SectionHeader>
      {docs ? (
        <Card
          className="floating"
          sx={{
            minHeight: 250,
            padding: 2,
            marginTop: "25px",
            background: "#171717",
          }}
        >
          <Stack>
            <SectionHeader>Documentation</SectionHeader>
            {Object.keys(docs).map((key, idx) => {
              const { signature, docstring } = docs[key];
              return (
                <Stack className="floating" key={idx} gap={2}>
                  <Typography mt={2} variant="body1">
                    {docstring}
                  </Typography>
                  <Stack sx={{ position: "relative" }} gap={1}>
                    <Stack
                      sx={{
                        position: "absolute",
                        padding: 0,
                        margin: 0,
                        top: "5px",
                        left: "5px",
                      }}
                      direction="row"
                      alignItems={"center"}
                    >
                      <IconButton
                        onClick={() => {
                          copyContent(signature);
                          toast.success("copied value!");
                        }}
                        sx={{ margin: 0, padding: 0 }}
                      >
                        <Icon
                          sx={{ fontSize: "16px !important" }}
                          color="success"
                        >
                          content_copy
                        </Icon>
                      </IconButton>
                    </Stack>
                    <span
                      style={{
                        borderRadius: "10px",
                        backgroundColor: themeOptions.palette.primary.main,
                        padding: "20px",
                        paddingLeft: "40px",
                      }}
                    >
                      <pre
                        style={{
                          margin: 0,
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          wordBreak: "break-all",
                        }}
                      >
                        {signature}
                      </pre>
                    </span>
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Card>
      ) : null}
      {packages ? (
        <Card
          className="floating"
          sx={{
            padding: 2,
            marginTop: "25px",
            background: "#171717",
          }}
        >
          <Stack>
            <SectionHeader>Packages</SectionHeader>
            {packages?.length ? (
              packages.map((pkg, idx) => {
                return (
                  <Stack className="floating" key={idx} gap={2}>
                    <Typography mt={2} variant="body1">
                      {pkg.pkg}
                    </Typography>
                  </Stack>
                );
              })
            ) : (
              <Stack className="floating" gap={2}>
                <Typography mt={2} variant="body1">
                  no packages
                </Typography>
              </Stack>
            )}
          </Stack>
        </Card>
      ) : null}
    </Stack>
  );
};
export default function Action({ role }) {
  const [loading, setLoading] = React.useState(false);
  const [generator, setGenerator] = React.useState(false);
  const [execute, setExecute] = React.useState("");
  const [copy, setCopy] = React.useState(false);
  const [restore, setRestore] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);
  const [err, setError] = React.useState(false);
  const [properties, setProperties] = React.useState([]);
  const [systemData, setSystemData] = React.useState({});
  const [newCommand, setNewCommand] = React.useState(false);
  const { id, action } = useParams();
  const [jobsOpen, setJobsOpen] = React.useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const systemname = searchParams.get("system") || "";
  const isNew = action === "new";
  const [refreshKey, setRefreshKey] = React.useState("1");
  const [configs, setConfigs] = React.useState(false);
  const [cliModal, setCliModal] = React.useState(false);
  const [filesOpen, setFilesOpen] = React.useState(false);
  const [dataBacked, setDataBacked] = React.useState(false);
  const [e1Pkg, setE1Pkg] = React.useState(false);
  const [code, setCodeState] = React.useState(
    "import e1\n\ndef handler(context):\n    return None\n"
  );
  const [originalCode, setOriginalCode] = React.useState(
    "import e1\n\ndef handler(context):\n    return None\n"
  );
  const [data, setDataState] = React.useState({
    name: "",
    description: "",
    inputs: [],
    envs: [],
    packages: [],
    tags: [],
    acl: [],
    scan: [],
    targeting: {
      enabled: false,
      select: false,
      target: null,
    },
  });
  const setCode = (d) => {
    setCodeState(d);
    setRefreshKey(Math.random());
    if (!dirty) setDirty(true);
  };
  const setData = (d) => {
    setDataState(d);
    if (!dirty) setDirty(true);
  };

  const fetcher = useFetcher();

  const navigate = useNavigate();

  const saveCommand = async () => {
    const payload = { ...data, code };
    if (!validate("command_edit", payload)) return;
    await fetcher.post(
      "saving action",
      `/api/sys/${id}/action/${action}`,
      payload,
      (data) => {
        if (data?.error) return toast.error(data?.error);
        setDirty(false);
        if (action === "new" && data?.id)
          return navigate(
            `/app/sys/${id}/action/${data?.id}?system=${systemname}`
          );
        setRefreshKey(Math.random());
        fetchCommand();
      },
      () => {}
    );
  };
  const deleteCommand = async () => {
    setLoading(true);
    await fetcher.delete(
      "deleting command",
      `/api/sys/${id}/action/${action}`,
      ({ error }) => {
        if (error) return toast.error("error deleting command");
        navigate(`/app/sys/${id}/dash`);
      },
      () => {
        setLoading(false);
        setError(true);
      }
    );
    setLoading(false);
  };
  const fetchCommand = async (override, systemOverride = null) => {
    setLoading(true);
    setDirty(false);
    const system_id = systemOverride || id;
    await fetcher.get(
      "fetching command",
      `/api/sys/${system_id}/action/${override || action}`,
      ({ action, system }) => {
        setSystemData(system);
        setDataBacked(action?.data_backed);
        setProperties(action?.properties || []);
        if (isNew) return setError(false);
        delete action?.data_backed;
        delete action?.properties;
        setDataState(action);
        setCodeState(action.code);
        setOriginalCode(action.code);
        setError(false);
      },
      () => {
        setLoading(false);
        setError(true);
      }
    );
    setLoading(false);
  };
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete Command`,
    message: `Are you sure you want to delete this command? This action can't be undone.`,
    callback: deleteCommand,
  });

  React.useEffect(() => {
    fetchCommand();
  }, [searchParams, action]);
  if (err) {
    return (
      <Page
        back={`/app/sys/${id}/dash`}
        loading={loading}
        header={`Cannot fetch command (${id})`}
      />
    );
  }
  const PanelMenu = React.useCallback(() => {
    return (
      <div style={{ position: "relative", alignSelf: "flex-end" }}>
        <MultiLevelMenu
          button={(props) => (
            <ActionButton
              handler={props.onClick}
              circle
              dark
              noload
              icon="more_vert"
            />
          )}
          menu={{
            main: {
              "Local Development": {
                leftIcon: <Icon>terminal</Icon>,
                onClick: () => {
                  setCliModal(true);
                },
              },
              "Copy Command Id": {
                leftIcon: <Icon>content_copy</Icon>,
                onClick: () => {
                  copyContent(action);
                  toast.success("copied command id");
                },
              },
              "Copy Command": {
                leftIcon: <Icon>content_copy</Icon>,
                onClick: () => setCopy(true),
              },
              "Restore Command": {
                leftIcon: <Icon>settings_backup_restore</Icon>,
                onClick: () => setRestore(true),
              },
              "Delete Command": {
                leftIcon: <Icon color="error">delete</Icon>,
                onClick: () => openDeleteModal(true),
              },
            },
          }}
        />
      </div>
    );
  }, [action]);
  return (
    <>
      <SidePanel
        width={500}
        open={generator}
        closeDrawer={() => setGenerator(null)}
        polybg
        props={{
          system: id,
          action: action,
          setCode,
        }}
      >
        {CodeGenerator}
      </SidePanel>
      <SidePanel
        width={600}
        open={!!execute}
        closeDrawer={() => setExecute(null)}
        props={{
          system: id,
          id: execute,
        }}
      >
        {CommandDebugPanel}
      </SidePanel>
      <FilesModal
        role={role}
        // system={system?.id}
        open={filesOpen}
        setOpen={setFilesOpen}
      />
      <JobsModal system={id} open={jobsOpen} setOpen={setJobsOpen} />
      <CliCommand
        context={
          "Initialize this command into your local development environment"
        }
        code={`e1 cmd init -s ${id} -c ${action}`}
        open={cliModal}
        onClose={() => setCliModal(false)}
      />
      <Page
        refresh={!isNew ? () => fetchCommand(action) : null}
        back={`/app/sys/${id}/dash`}
        header={
          <Stack direction="row" gap={1}>
            <Typography
              sx={{ userSelect: "none" }}
              variant="h4"
            >{`${systemname} /`}</Typography>
            <ClickableTypography
              onClick={(v) =>
                navigate(
                  `/app/sys/${id}/action/${
                    (systemData?.actions || []).find((o) => o.name === v).id
                  }?system=${encodeURI(systemData.name)}`
                )
              }
              options={(systemData?.actions || [])
                .filter((a) => a.id !== action)
                .map((a) => a.name)}
              variant="h4"
            >
              {!isNew ? `${data?.name}` : "New Command"}
            </ClickableTypography>
          </Stack>
          // !isNew
          //   ? `${systemname} / ${data?.name} command`
          //   : `${systemname} / New Command`
        }
        dynamicSize
        loading={loading}
        actions={[
          ...[
            <ActionButton
              noload
              icon="rocket_launch"
              circle
              handler={() => setExecute(data.id)}
            />,
            <ActionButton
              noload
              icon="dashboard"
              circle
              handler={() => navigate(`/app/sys/${id}/dash`)}
            />,
            <ActionButton
              noload
              icon="sms"
              circle
              handler={() => setJobsOpen(true)}
            />,
            <ActionButton
              noload
              icon="attachment"
              circle
              handler={() => setFilesOpen(true)}
            />,
            <ActionButton
              noload
              icon="more_vert"
              circle
              handler={() => setConfigs("base")}
            />,
          ],
        ]}
      >
        <div>
          <CodeEditor
            parent="app-body"
            name="code-editor"
            value={code}
            setValue={setCode}
            liveSnippets={[
              ...customSnippets,
              ...(data?.inputs || [])?.map((i) => {
                return {
                  caption: `e1Input${i.name
                    .split("_")
                    .map((i) => {
                      return i[0].toUpperCase() + i.substr(1);
                    })
                    .join("")}`,
                  snippet: `context.inputs.${i.name}`,
                  type: "snippet",
                  meta: "value of given input",
                };
              }),
              ...(data?.envs || [])?.reduce((a, i) => {
                return [
                  ...a,
                  {
                    caption: `e1EnvRead${i.name
                      .split("_")
                      .map((i) => {
                        return i[0].toUpperCase() + i.substr(1);
                      })
                      .join("")}`,
                    snippet: `context.env.${i.name}`,
                    type: "snippet",
                    meta: "value of given variable",
                  },
                  {
                    caption: `e1EnvUpdate${i.name
                      .split("_")
                      .map((i) => {
                        return i[0].toUpperCase() + i.substr(1);
                      })
                      .join("")}`,
                    snippet: `e1.env.update(context.refs.env["${i.name}"],"\${1:VALUE}")`,
                    type: "snippet",
                    meta: "update a given variable",
                  },
                ];
              }, []),
            ]}
            snippets={(editor, insert) => {
              const inputs = data?.inputs?.reduce(
                (a, { name }) => ({
                  ...a,
                  [name]: { onClick: () => insert(`context.inputs.${name}`) },
                }),
                {}
              );
              const packages = data?.packages
                ?.filter(({ source }) => source)
                ?.reduce(
                  (a, { pkg }) => ({
                    ...a,
                    [pkg]: { onClick: () => setE1Pkg(pkg) },
                  }),
                  {}
                );
              const env = data.envs.reduce(
                (a, { name, id }) => ({
                  ...a,
                  [`Use ${name}`]: {
                    onClick: () => insert(`context.env.${name}`),
                  },
                  [`Update ${name}`]: {
                    onClick: () =>
                      insert(
                        `e1.env.update(context.refs.env["${name}"],value)`
                      ),
                  },
                }),
                {}
              );
              return {
                main: {
                  System: {
                    goToMenu: "system",
                  },
                  Files: {
                    goToMenu: "file",
                  },
                  Tenant: {
                    goToMenu: "tenant",
                  },
                  Inputs: {
                    goToMenu: "inputs",
                  },
                  Packages: {
                    goToMenu: "packages",
                  },
                  "Environment Variables": {
                    goToMenu: "env",
                  },
                  Convert: {
                    goToMenu: "convert",
                  },
                },
                system: {
                  "Is Selected": {
                    onClick: () => insert("context.is_selected(RESOURCE)"),
                  },
                  "System Dataset": {
                    onClick: () => insert("context.dataset"),
                  },
                  "System Object": {
                    onClick: () =>
                      insert(
                        JSON.stringify(
                          properties.reduce(
                            (o, p) =>
                              (o[p.name] = {
                                ...o,
                                [p.name]: TypeMap[p.type]?.default,
                              }),
                            {}
                          ),
                          null,
                          4
                        )
                      ),
                  },
                },
                file: {
                  "Upload File": {
                    onClick: () => insert("e1.file.upload(LOCAL_FILE_PATH)"),
                  },
                  "Download File": {
                    onClick: () =>
                      insert("e1.file.download(FILE_NAME,LOCAL_FILE_PATH)"),
                  },
                  "Delete File": {
                    onClick: () => insert("e1.file.delete(FILE_NAME)"),
                  },
                },
                tenant: {
                  "Tenant id": {
                    onClick: () => insert("e1.tenant().id"),
                  },
                  "Tenant alias": {
                    onClick: () => insert("e1.tenant().alias"),
                  },
                  "Tenant geo": {
                    onClick: () => insert("e1.tenant().geo"),
                  },
                  "Tenant info": {
                    onClick: () => insert("e1.tenant()"),
                  },
                  "(AWS) Assume into tenant": {
                    onClick: () =>
                      insert("e1.cloud.aws.assume(ACCOUNT,REGION)"),
                  },
                  "(AWS) Current Session": {
                    onClick: () => insert("e1.cloud.aws.session()"),
                  },
                  "(Azure) Assume into tenant": {
                    onClick: () =>
                      insert("e1.cloud.azure.assume(SUBSCRIPTION_ID,REGION)"),
                  },
                  "(Azure) Current Session": {
                    onClick: () => insert("e1.cloud.azure.session()"),
                  },
                  "(Azure) Subscription ID": {
                    onClick: () => insert('os.getenv("AZURE_SUBSCRIPTION_ID")'),
                  },
                },
                env,
                inputs,
                packages,
                convert: {
                  "String to Datetime": {
                    onClick: () =>
                      insert("datetime.strptime(INPUT,'%Y-%m-%d')"),
                  },
                },
              };
            }}
            onMenu={() => setConfigs(true)}
            activeTools={[
              <IconButton
                onClick={() => setGenerator(true)}
                className="cssbuttons-io-button"
                sx={{
                  alignSelf: "right",
                  padding: 0,
                  margin: 0,
                  height: "40px",
                  width: "40px",
                  backgroundColor: "#a827fa",
                  "&:hover": {
                    backgroundColor: "#bb1fe2",
                  },
                }}
              >
                <img height="24px" width="24px" src={AiIcon} />
              </IconButton>,
            ]}
            passiveTools={(editor, insert) => [
              // {
              //   icon: (
              //     <IconButton>
              //       <Icon color="success">rocket_launch</Icon>
              //     </IconButton>
              //   ),
              //   onClick: () => setExecute(data.id),
              // },
              {
                icon: (
                  <IconButton
                    onClick={() => setGenerator(true)}
                    className="cssbuttons-io-button"
                    sx={{
                      alignSelf: "right",
                      padding: 0,
                      margin: 0,
                      height: "40px",
                      width: "40px",
                      backgroundColor: "#a827fa",
                      "&:hover": {
                        backgroundColor: "#bb1fe2",
                      },
                    }}
                  >
                    <img height="24px" width="24px" src={AiIcon} />
                  </IconButton>
                ),
              },
              ...(data?.scan?.length
                ? [
                    {
                      component: <CodeAnalyzer data={data?.scan} />,
                    },
                  ]
                : []),
            ]}
          />
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(500px, 1fr))",
          }}
        >
          <CommandCopy
            {...{
              open: copy,
              refresh: (id, system) => fetchCommand(id, system),
              close: () => {
                setCopy(false);
              },
              data,
              code,
            }}
          />
          <CommandRestore
            {...{
              open: restore,
              close: () => setRestore(false),
              action,
              refresh: () => fetchCommand(),
            }}
          />
        </div>
        <Fab
          active={dirty}
          icon="save"
          onClick={() => {
            setConfigs(null);
            saveCommand();
          }}
        />
        <DeleteModal />
        <SidePanel
          width={700}
          open={!!configs}
          closeDrawer={() => {
            if (configs !== "base") {
              window.codeEditorFocusHandler();
            }
            setConfigs(null);
          }}
          props={{
            menu: PanelMenu,
            system: id,
            setE1Pkg,
            data,
            setData,
            dataBacked,
          }}
        >
          {Panel}
        </SidePanel>
        <SidePanel
          width={600}
          open={!!e1Pkg}
          closeDrawer={() => {
            setE1Pkg(null);
          }}
          props={{
            e1Pkg,
          }}
        >
          {PackageInfo}
        </SidePanel>
      </Page>
    </>
  );
}
