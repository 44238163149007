import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import { Icon } from "@mui/material";
import withScrolling, {
  createHorizontalStrength,
  createVerticalStrength,
} from "react-dnd-scrolling";

const ScrollingComponent = withScrolling("div");
const vStrength = createVerticalStrength(2000);
const hStrength = createHorizontalStrength(2000);

const DnDItem = ({ id, index, moveItem, item }) => {
  const ref = React.useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "item",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: "item",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drop(preview(ref));
  return (
    <MenuItem
      disableRipple
      ref={ref}
      data-handler-id={handlerId}
      sx={{
        p: 1,
        opacity,
        display: "flex",
        alignItems: "center",
        flex: 1,
        width: "100%",
      }}
    >
      <Icon ref={drag} sx={{ cursor: "move" }}>
        drag_indicator
      </Icon>
      {item}
      {/* <Text sx={{ pl: 1, pr: 1, flex: 1 }} type="body2">
        {text}
      </Text>
      <IconButton onClick={() => deleteItem(id)} color="error">
        <Icon color="error">close</Icon>
      </IconButton> */}
    </MenuItem>
  );
};

//state = _list[_list_[_obj_{id:_str_|_int_|...rest}],_func_(_list_)=>{}]
//itemComponent = _reactcomponent_
export default function DnDList({
  state,
  itemComponent: ItemComponent,
  maxHeight = "500px",
}) {
  // React.useEffect(() => {
  //   // Reset the backend setup flag
  //   if (window.__isReactDndBackendSetUp) {
  //     window.__isReactDndBackendSetUp = false;
  //   }
  // }, []);
  const [items, setItems] = state;
  const moveItem = React.useCallback((dragIndex, hoverIndex) => {
    setItems((prevItems) => {
      const updateItems = [...prevItems];
      updateItems.splice(dragIndex, 1);
      updateItems.splice(hoverIndex, 0, prevItems[dragIndex]);
      return updateItems;
    });
  }, []);
  const renderItem = (item, index) => {
    if (!item) return null;
    return (
      <DnDItem
        key={item.id}
        id={item.id}
        index={index}
        moveItem={moveItem}
        item={<ItemComponent item={item} />}
      />
    );
  };
  const renderedItems = items.map((item, i) => renderItem(item, i));

  return (
    <Stack style={{ width: "100%" }}>
      <DndProvider context={window} backend={HTML5Backend}>
        <ScrollingComponent
          verticalStrength={vStrength}
          horizontalStrength={hStrength}
          style={{ maxHeight, overflow: "auto", width: "100%" }}
        >
          {renderedItems}
        </ScrollingComponent>
      </DndProvider>
    </Stack>
  );
}
