import React from "react";
import { Stack, IconButton, Icon, Typography, Card } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Avatar } from "@mui/material";
import { PackageIcon } from "routes";
// import AwsTarget from "pages/Targets/components/AwsTarget";
import Input from "common/components/Input";
import Modal from "common/components/Modal";
import List from "common/components/List";
import Blurb from "constants/blurbs";
import { nanoid } from "nanoid";
import validate from "constants/validate";
import ActionButton from "common/components/ActionButton";
import SectionHeader from "common/components/SectionHeader";
import MultiSelect from "common/components/MultiSelect";
import useFetcher from "common/hooks/useFetcher";
import { useNavigate } from "react-router-dom";
import useScopeAttributes from "common/hooks/useScopeAttributes";
import toast from "react-hot-toast";
import SidePanel from "common/components/SidePanel";
const SOURCE_LIST = [
  { name: "PyPI", id: 0 },
  { name: "Echelon One", id: 1 },
];
function CopyPackage({ system, action, pkg, onClose }) {
  const fetcher = useFetcher();
  const [name, setName] = React.useState("");
  const { ScopeInputs, getScope, setInit } = useScopeAttributes();
  const navigate = useNavigate();
  const [description, setDescription] = React.useState("");
  const savePackage = async () => {
    const payload = {
      pk: null,
      name,
      description,
      copy: pkg,
      system,
      action,
      ...getScope(),
    };
    if (!/^[A-Za-z_0-9]{1,50}$/.test(name))
      return toast.error(
        "Name must be a string containing only letters and underscores, with a limit of 50 characters"
      );
    if (!/^.{1,50}$/.test(description))
      return toast.error(
        "Description must be a string containing only letters and underscores, with a limit of 50 characters"
      );
    await fetcher.post(
      "creating package",
      `/api/pkg`,
      payload,
      (data) => {
        if (data?.error) {
          toast.error(data?.error);
          return;
        }
        if (data?.name) {
          navigate(`/app/packages/${data?.name}`);
        }
        onClose();
      },
      () => {}
    );
  };
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <PackageIcon fill="#171717" size="50" />
        </Avatar>
        <Stack>
          <Typography variant="h4">Copy Package</Typography>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Information</Typography>
        <Stack gap={1}>
          <Typography>Identifying information for your package.</Typography>
        </Stack>
        <Stack gap={1}>
          <Input
            dark
            size="small"
            sx={{ width: "100%" }}
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            dark
            size="small"
            sx={{ width: "100%" }}
            placeholder="Description"
            value={description}
            multiline
            minRows={3}
            maxRows={3}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto", display: "flex" }}>
        <ActionButton
          icon="save"
          dark
          handler={async () => {
            await savePackage();
          }}
        >
          Copy Package
        </ActionButton>
      </div>
    </Stack>
  );
}
export default function CommandPackages({ system, data, setData, setE1Pkg }) {
  const [pkg, setPackage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [e1pkg, setE1PackageSelect] = React.useState(null);
  const [e1pkgVer, setE1PkgVer] = React.useState(null);
  const [availableE1Pkgs, setAvailableE1Pkgs] = React.useState([]);
  const [source, setSource] = React.useState("0");
  const [packages, setPackages] = React.useState([]);
  const [copyPackage, setCopyPackage] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const existing = searchParams.get("id");
  const fetcher = useFetcher();
  React.useEffect(() => {
    if (!data) return;
    setPackages(data.packages);
  }, [data]);
  const fetchPkgs = async () => {
    setLoading(true);
    await fetcher.get(
      "fetching packages",
      `/api/pkg`,
      ({ packages }) => {
        setAvailableE1Pkgs(packages);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
    setLoading(false);
  };
  React.useEffect(() => {
    if (source === "1") {
      fetchPkgs();
    }
  }, [source]);
  const onSave = (close) => {
    let payload = {};
    if (source === "1") {
      let packageName = e1pkg?.name + "==" + e1pkgVer;
      if (!validate("command_local_package_edit", { pkg: packageName, source }))
        return;
      payload = { pkg: packageName, source };
    } else {
      if (!validate("command_package_edit", { pkg })) return;
      payload = { pkg };
    }
    if (edit?.id) {
      setData({
        ...data,
        packages: packages.map((i) => {
          if (i.id !== edit?.id) return i;
          return { ...payload, id: edit?.id };
        }),
      });
    } else {
      setData({
        ...data,
        packages: [...data.packages, { id: nanoid(), ...payload }],
      });
    }
    setE1PackageSelect(null);
    setE1PkgVer(null);
    setPackage("");
    setSource("0");
    close();
  };
  const onEdit = (item) => {
    setPackage(item.pkg);
    setSource(item.source || "0");
    setEdit(item);
  };
  const deletePackage = (id) => {
    setData({
      ...data,
      packages: packages.filter((i) => i.id !== id),
    });
  };
  return (
    <Stack m={0} gap={1} p={0}>
      <SidePanel
        width="500px"
        open={!!copyPackage}
        closeDrawer={() => setCopyPackage(false)}
        props={{
          pkg: copyPackage,
          system,
          action: data.id,
        }}
      >
        {CopyPackage}
      </SidePanel>
      <Stack sx={{ position: "relative" }} spacing={1}>
        <Modal
          forceOpen={edit}
          onClose={() => {
            setPackage("");
            setEdit(false);
            // setSearchParams({});
          }}
          button={(props) => (
            <Stack
              sx={{ position: "absolute", right: 0 }}
              direction="row"
              spacing={1}
            >
              <IconButton {...props}>
                <Icon>add</Icon>
              </IconButton>
            </Stack>
          )}
        >
          {({ handleClose }) => (
            <Stack gap={2}>
              <SectionHeader icon="archive">
                {edit?.id ? "Update" : "New"} Package
              </SectionHeader>
              <MultiSelect
                options={["0", "1"]}
                isOptionEqualToValue={(option, value) => {
                  return Number(option) === Number(value);
                }}
                getOptionLabel={(e) =>
                  SOURCE_LIST.find((x) => x.id === Number(e)).name
                }
                getOptionKey={(e) =>
                  SOURCE_LIST.find((x) => x.id === Number(e)).id
                }
                label="Source"
                multiple={false}
                placeholder="Select a source"
                value={source}
                setValue={(e) => {
                  setSource(e);
                  setPackage("");
                  setE1PackageSelect(null);
                  setE1PkgVer(null);
                }}
                sx={{ width: "100%" }}
                size="small"
              />
              {source === "0" ? (
                <Input
                  sx={{ width: "100%" }}
                  label="Package"
                  value={pkg}
                  size="small"
                  placeholder="requests==2.0.0"
                  onChange={(e) => setPackage(e.target.value)}
                />
              ) : (
                <MultiSelect
                  getOptionLabel={(e) => e.name}
                  getOptionKey={(e) => e.name}
                  options={availableE1Pkgs || []}
                  loading={loading}
                  label="Package"
                  multiple={false}
                  placeholder="select a package"
                  value={e1pkg}
                  setValue={(e) => setE1PackageSelect(e)}
                  dark
                  size="small"
                />
              )}
              {e1pkg ? (
                <MultiSelect
                  getOptionLabel={(e) => e}
                  getOptionKey={(e) => e}
                  options={e1pkg?.versions?.filter((x) => x !== "0") || []}
                  loading={loading}
                  label="Version"
                  placeholder="select a version"
                  value={e1pkgVer}
                  setValue={(e) => setE1PkgVer(e)}
                  multiple={false}
                  dark
                  size="small"
                />
              ) : null}
              <div style={{ marginLeft: "auto" }}>
                <ActionButton
                  handler={() => onSave(handleClose)}
                  noload
                  icon="archive"
                >
                  Add Package
                </ActionButton>
              </div>
            </Stack>
          )}
        </Modal>

        <Blurb>command_pkg</Blurb>
        {!packages.length ? (
          <Typography sx={{ margin: 0, paddingLeft: 1 }} variant="body1">
            -
          </Typography>
        ) : (
          <List
            items={data?.packages?.map((item) => ({
              text: (
                <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <span
                    style={{
                      marginRight: "10px",
                      width: "100%",
                    }}
                  >
                    {item.source
                      ? `${item.pkg} (Echelon One)`
                      : `${item.pkg} (PyPI)`}
                  </span>
                  <Stack spacing={1} direction="row">
                    {item.source ? (
                      <>
                        <IconButton onClick={() => setE1Pkg(item.pkg)}>
                          <Icon>info</Icon>
                        </IconButton>
                        <IconButton onClick={() => setCopyPackage(item.pkg)}>
                          <Icon>content_copy</Icon>
                        </IconButton>
                      </>
                    ) : null}
                    {!item.source ? (
                      <IconButton
                        onClick={() => onEdit(item)}
                        color="primary"
                        sx={{ margin: 0, ml: "auto !important" }}
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                    ) : null}
                    <IconButton
                      onClick={() => deletePackage(item.id)}
                      color="error"
                      sx={{ margin: 0, ml: "auto !important" }}
                    >
                      <Icon color="error">close</Icon>
                    </IconButton>
                  </Stack>
                </Stack>
              ),
            }))}
          />
        )}
      </Stack>
    </Stack>
  );
}
