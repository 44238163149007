import React from "react";
import Theme from "theme";
import Router from "routes";
import useAuthentication from "auth";
import LoginPage from "pages/Login";
import { definedRoutes } from "routes";
import { useRoleForRoutes } from "auth";
import { Toaster } from "react-hot-toast";
import { theme } from "theme";
import useTabTitle from "app/hooks/useTabTitle";
import { useIdleTimer } from "react-idle-timer";

export default function App() {
  let user = useAuthentication();
  let routes = useRoleForRoutes(user, definedRoutes);
  useTabTitle();
  const { getRemainingTime } = useIdleTimer({
    onIdle: () => {
      localStorage.removeItem("E1_JWT");
      dispatchEvent(new Event("storage"));
    },
    timeout: 1200000,
    throttle: 500,
  });
  if (!user) {
    return (
      <Theme>
        <Toaster
          position="top-center"
          reverseOrder={true}
          toastOptions={{
            style: {
              borderRadius: "10px",
              background: theme.palette.primary.light,
              color: theme.palette.text.primary,
            },
            success: {
              duration: 3000,
            },
            error: {
              duration: 5000,
            },
          }}
        />
        <LoginPage />
      </Theme>
    );
  }
  return (
    <Theme>
      <Toaster
        position="top-center"
        reverseOrder={true}
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: theme.palette.primary.light,
            color: theme.palette.text.primary,
          },
          success: {
            duration: 3000,
          },
          error: {
            duration: 5000,
          },
        }}
      />
      <Router routes={routes} />
    </Theme>
  );
}
