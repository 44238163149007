import React from "react";
import { themeOptions } from "theme";
import { Icon, IconButton } from "@mui/material";
export default function Tile({ type, icon, text1, text2, buttons }) {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: buttons?.length && isHovered ? "100px" : "70px",
        background: themeOptions.palette.primary.dark,
        borderRadius: "20px",
        border: `1px solid ${themeOptions.palette.text.hint}`,
        display: "flex",
        flexDirection: "column",
        transition: "0.1s ease-in-out",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{
          width: "100%",
          height: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          transition: "0.5s ease-in-out",
        }}
      >
        <div
          style={{
            width: "50px",
            height: "50px",
            marginLeft: "10px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon
            color={
              type === "info"
                ? "info"
                : type === "security"
                ? "error"
                : "warning"
            }
          >
            {icon}
          </Icon>
        </div>
        <div
          style={{
            width: "calc(100% - 90px)",
            marginLeft: "10px",
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                maxWidth: "70%",
                wordWrap: "break-word",
              }}
            >
              {text1}
            </p>
            {text2 ? (
              <span style={{ fontSize: "10px", minWidth: "45px" }}>
                {text2}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      {buttons ? (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            display: "flex",
            gap: 1,
            height: isHovered ? "30px" : "0px",
            opacity: isHovered ? 1 : 0,
            transition: "0.2s ease-in-out",
            overflow: "hidden",
          }}
        >
          {buttons.map(([type, icon, onClick], index) => {
            return (
              <IconButton key={index} color={type} onClick={onClick}>
                <Icon sx={{ fontSize: "20px !important" }} color={type}>
                  {icon}
                </Icon>
              </IconButton>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
