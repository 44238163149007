export default () => {
  const offsetInMinutes = new Date().getTimezoneOffset();
  const offsetInHours = offsetInMinutes / 60;
  return offsetInHours;
};

export const updateUtcTimestampWithOffset = (date) => {
  const offset = new Date().getTimezoneOffset() * 60 * 1000;
  const offsetTime = Number(offset < 0 ? "" : "-" + offset);
  return new Date(new Date(date).getTime() + offsetTime).toLocaleString();
};
