import React, { useRef, useState } from "react";
// import { FaUpload } from "react-icons/fa"; // You'll need to install react-icons
import toast from "react-hot-toast";
const useFileUpload = ({ onChange }) => {
  //   const [file, setFile] = useState(null);
  const [status, setStatus] = useState("initial");
  const fileInputRef = useRef(null);

  // Trigger file input when icon is clicked
  const openFileSelect = () => {
    fileInputRef.current.click();
  };

  const validateFile = (file) => {
    // Check if file is selected
    if (!file) return { isValid: false, error: "Please select a file." };

    // Check file type
    const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!validTypes.includes(file.type)) {
      return {
        isValid: false,
        error: "Only JPG, JPEG, and PNG files are allowed.",
      };
    }

    // Check file size (1MB = 1024 * 1024 bytes)
    const maxSize = 1 * 1024 * 1024;
    if (file.size > maxSize) {
      return {
        isValid: false,
        error: "File size must be less than 1MB.",
      };
    }

    return { isValid: true, error: null };
  };
  // Handle file selection
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const validation = validateFile(e.target.files[0]);
      if (!validation.isValid) {
        toast.error(validation.error);
      }
      //   setFile(e.target.files[0]);
      onChange(e.target.files[0]);
    }
  };

  // Handle file upload
  //   const handleUpload = async () => {
  //     if (file) {
  //       setStatus("uploading");
  //       const formData = new FormData();
  //       formData.append("file", file);

  //       try {
  //         const response = await fetch("YOUR_UPLOAD_ENDPOINT", {
  //           method: "POST",
  //           body: formData,
  //         });

  //         const data = await response.json();
  //         console.log("Upload successful:", data);
  //         setStatus("success");
  //       } catch (error) {
  //         console.error("Upload failed:", error);
  //         setStatus("fail");
  //       }
  //     }
  //   };
  const FileInput = (
    <input
      type="file"
      ref={fileInputRef}
      onChange={handleFileChange}
      style={{ display: "none" }}
    />
  );

  return { openFileSelect, FileInput };
};

export default useFileUpload;
