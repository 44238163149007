import React from "react";
import { IconButton as MuiIconButton, Icon } from "@mui/material";

const IconButton = ({ children, ...props }) => (
  <MuiIconButton
    sx={{ height: "50px", width: "50px" }}
    color="secondary"
    {...props}
  >
    <Icon>{children}</Icon>
  </MuiIconButton>
);

export default IconButton;
