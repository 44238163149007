import React from "react";
import { Typography, Stack, Grid, Icon } from "@mui/material";
import Input from "../Input";
import MultiSelect from "../MultiSelect";
import awsCronParser from "aws-cron-parser";
import Switch from "../Switch";
import ActionButton from "../ActionButton";
import SectionHeader from "../SectionHeader";
import MultiLevelMenu from "../MultilevelMenu";

function getTimeZoneOffset() {
  // Get the timezone offset in minutes
  const offsetMinutes = new Date().getTimezoneOffset();
  // Calculate the offset in hours and minutes
  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;
  // Determine the sign of the offset
  const offsetSign = offsetMinutes < 0 ? "+" : "-";
  // Format the offset as a string
  const formattedOffset = `UTC${offsetSign}${offsetHours
    .toString()
    .padStart(2, "0")}:${offsetMinutesRemainder.toString().padStart(2, "0")}`;
  return formattedOffset;
}

export const getNextCronExecutions = (
  { minute, hour, date, months, day },
  count = 1
) => {
  try {
    let min, hr, dt, mt, dy;
    if (minute.includes("every 5 min")) {
      min = "*/5";
    } else {
      min = minute.join(",");
    }
    if (hour.includes("every hour")) {
      hr = "*";
    } else {
      hr = hour.join(",");
    }
    if (date.includes("every date") && day.includes("every day")) {
      dt = "*";
      dy = "?";
    } else if (date.includes("every date")) {
      dt = "?";
      dy = day.join(",");
    } else if (day.includes("every day")) {
      dt = date.join(",");
      dy = "?";
    } else {
      dt = date.join(",");
      dy = day.join(",");
    }
    if (months.includes("every month")) {
      mt = "*";
    } else {
      let mapping = monthOptions
        .slice(1)
        .reduce((a, c, i) => ({ ...a, [c]: ++i }), {});
      mt = months.map((m) => mapping[m]).join(",");
    }
    let timestamp = new Date();
    let dates = Array.from(Array(count)).map((x) => {
      let cron = awsCronParser.parse(`${min} ${hr} ${dt} ${mt} ${dy} *`);
      timestamp = awsCronParser.next(cron, timestamp);
      // Parse the timestamp into a Date object
      const date = new Date(timestamp);

      // Extract the day, month, and year
      const day = date.getUTCDate();
      const weekday = date.getUTCDay();
      const month = date.getUTCMonth(); // Note: Months are 0-indexed in JavaScript
      const year = date.getUTCFullYear();
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const weekdayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const weekdayString = weekdayNames[weekday];
      // Convert the month number to a string (e.g., 0 -> "Jan")
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const monthString = monthNames[month];

      // Format the day to always have two digits
      const dayString = day.toString().padStart(2, "0");

      const hoursString = hours.toString().padStart(2, "0");
      const minutesString = minutes.toString().padStart(2, "0");

      // Combine the parts into the desired format
      const formattedDate = `${weekdayString}, ${dayString} ${monthString} ${year} ${hoursString}:${minutesString}`;

      return formattedDate + ` (${getTimeZoneOffset()})`;
    });
    return [dates, `${min} ${hr} ${dt} ${mt} ${dy} *`];
  } catch (e) {
    return [];
  }
};
let monthOptions = [
  "every month",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
let minuteOptions = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
export default function CronCreator({ cron, setCron }) {
  const [once, setOnce] = React.useState(cron?.once ?? false);
  const [isDate, setIsDate] = React.useState(cron?.isDate ?? true);
  const [months, setMonths] = React.useState(cron?.months || []);
  const [date, setDate] = React.useState(cron?.date || []);
  const [day, setDay] = React.useState(cron?.day || ["every day"]);
  const [hour, setHour] = React.useState(cron?.hour || []);
  const [minute, setMinute] = React.useState(cron?.minute || []);
  const [execs, setExecs] = React.useState([]);
  const [expression, setExpression] = React.useState("");

  React.useEffect(() => {
    let [result, expression] = getNextCronExecutions(
      { minute, hour, date, months, day },
      once ? 1 : 10
    );
    setExpression(expression || "");
    setExecs(result);
  }, [months, date, day, hour, minute, once, isDate]);
  const templateMenuOptions = {
    main: {
      "Once a day at Midnight (UTC)": {
        leftIcon: <Icon>schedule</Icon>,
        onClick: () => {
          setMonths(["every month"]);
          setHour(["0"]);
          setMinute(["0"]);
          setIsDate(true);
          setDay(["every day"]);
          setDate(["every date"]);
        },
      },
      "Once a week on Sunday at Midnight (UTC)": {
        leftIcon: <Icon>schedule</Icon>,
        onClick: () => {
          setMonths(["every month"]);
          setHour(["0"]);
          setMinute(["0"]);
          setIsDate(false);
          setDate(["every date"]);
          setDay(["Sun"]);
        },
      },
      "On the 1st of every month at Midnight (UTC)": {
        leftIcon: <Icon color="error">schedule</Icon>,
        onClick: () => {
          setMonths(["every month"]);
          setHour(["0"]);
          setMinute(["0"]);
          setIsDate(true);
          setDay(["every day"]);
          setDate(["1"]);
        },
      },
    },
  };

  return (
    <Grid
      className="floating"
      alignItems={"center"}
      gap={1}
      rowGap={2}
      container
    >
      <Grid xs={12} item>
        <SectionHeader icon="schedule">Schedule Builder</SectionHeader>
      </Grid>
      <Grid xs={5} item>
        <Typography>Schedule Templates</Typography>
      </Grid>
      <Grid xs={4} item>
        <div style={{ marginLeft: "auto", position: "relative" }}>
          <MultiLevelMenu
            button={(props) => (
              <ActionButton
                handler={props.onClick}
                circle
                noload
                icon="date_range"
              />
            )}
            menu={templateMenuOptions}
          />
        </div>
      </Grid>
      <Grid xs={4} item>
        <Switch
          label={"Run Once"}
          checked={once}
          onChange={() => {
            setOnce(!once);
          }}
        />
      </Grid>
      <Grid xs={5} item>
        <Switch
          label={isDate ? "By Date" : "By Day"}
          checked={isDate}
          onChange={() => {
            if (!isDate) {
              setDay(["every day"]);
              setDate([]);
            } else {
              setDay([]);
              setDate(["every date"]);
            }
            setIsDate(!isDate);
          }}
        />
      </Grid>
      <Grid xs={12} item>
        Run my schedule
      </Grid>
      <Grid xs={1} item>
        <Typography>in</Typography>
      </Grid>
      <Grid xs={10} item>
        <MultiSelect
          dark
          options={monthOptions}
          ChipProps={{ size: "small" }}
          label="Month"
          value={months}
          setValue={setMonths}
          size="small"
        />
      </Grid>
      <Grid xs={1} item>
        <Typography>on</Typography>
      </Grid>
      <Grid xs={10} item>
        {isDate ? (
          <MultiSelect
            dark
            size="small"
            options={[
              "every date",
              ...Array.from(new Array(31)).map((x, idx) => `${idx + 1}`),
            ]}
            ChipProps={{ size: "small" }}
            label="Date"
            value={date}
            setValue={setDate}
          />
        ) : (
          <MultiSelect
            dark
            options={[
              "every day",
              "Mon",
              "Tue",
              "Wed",
              "Thu",
              "Fri",
              "Sat",
              "Sun",
            ]}
            size="small"
            ChipProps={{ size: "small" }}
            label="Day"
            value={day}
            setValue={setDay}
          />
        )}
      </Grid>
      <Grid xs={1} item>
        <Typography>at</Typography>
      </Grid>
      <Grid xs={5} item>
        <MultiSelect
          dark
          options={[
            "every hour",
            ...Array.from(new Array(24)).map((x, idx) => `${idx}`),
          ]}
          size="small"
          ChipProps={{ size: "small" }}
          label="Hour"
          value={hour}
          setValue={setHour}
        />
      </Grid>
      <Grid xs={5} item>
        <MultiSelect
          dark
          size="small"
          options={["every 5 min", ...minuteOptions]}
          ChipProps={{ size: "small" }}
          label="Minute"
          value={minute}
          setValue={setMinute}
        />
      </Grid>
      <Grid xs={12} item>
        <Typography sx={{ fontWeight: "bold" }} variant="body1">
          Next 10 trigger dates
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography variant="body2">
          Date and time are displayed in your current time zone in UTC format,
          e.g. “Wed, Nov 9, 2022 09:00 (UTC-04:00)” for Eastern time
        </Typography>
      </Grid>
      <Grid xs={12} item>
        {execs?.length ? (
          execs.map((e) => {
            return (
              <Typography sx={{ display: "block" }} variant="caption">
                {e}
              </Typography>
            );
          })
        ) : (
          <Typography sx={{ display: "block" }} variant="caption">
            Not a valid cron
          </Typography>
        )}
      </Grid>
      <Grid xs={12} item>
        <ActionButton
          icon="save"
          noload
          dark
          handler={() =>
            setCron({
              once,
              isDate,
              months,
              date,
              day,
              hour,
              minute,
              expression,
            })
          }
        >
          Save Schedule
        </ActionButton>
      </Grid>
    </Grid>
  );
}
