import ChatBot, { Loading } from "react-simple-chatbot";
import React from "react";
import { Button, Stack } from "@mui/material";
import { ThemeProvider } from "styled-components";
import BotImage from "../../../assets/crew/bot.png";
import useFetcher from "common/hooks/useFetcher";
import { useNavigate } from "react-router-dom";
import DatePicker from "common/components/DatePicker";
import E1Input from "common/components/E1Input";
import useForm from "common/hooks/useForm";
import useScopeAttributes from "common/hooks/useScopeAttributes";
import validate from "constants/validate";
import { nanoid } from "nanoid";

const theme = {
  background: "#262626",
  fontFamily: "Titillium Web",
  headerBgColor: "#a926fa",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "#a926fa",
  botFontColor: "#fff",
  userBubbleColor: "#ff914c",
  userFontColor: "#000",
};

const CreateAVariable = ({ triggerNextStep, steps }) => {
  const [loading, setLoading] = React.useState(false);
  const { controller, getFormValues } = useForm("variablevalue");
  const fetcher = useFetcher();
  let name = steps["Variable Input"]?.value;
  let description = steps["Variable Description Prompt"]?.value;
  let type = steps["Variable Type Input"]?.value;
  let secret = steps["Variable Secure Input"]?.value === "Yes";
  const { ScopeInputs, getScope, setInit } = useScopeAttributes();

  const createVariable = async (close) => {
    setLoading(true);
    const value = getFormValues();
    let name = steps["Variable Input"]?.value;
    let description = steps["Variable Description Prompt"]?.value;
    let type = steps["Variable Type Input"]?.value;
    let secret = steps["Variable Secure Input"]?.value === "Yes";

    let payload = {
      name,
      description,
      type,
      secret,
      ...getFormValues(),
      ...getScope(),
    };
    if (type === "list") {
      try {
        let test = JSON.parse(value);
        if (typeof test === "object" && Array.isArray(test) && test !== null) {
        } else {
          setLoading(false);
          return toast.error("value is not valid JSON");
        }
      } catch {
        setLoading(false);
        return toast.error("value is not valid JSON");
      }
    }
    if (type === "object") {
      try {
        let test = JSON.parse(value);
        if (typeof test === "object" && !Array.isArray(test) && test !== null) {
        } else {
          setLoading(false);
          return toast.error("value is not valid JSON");
        }
      } catch {
        setLoading(false);
        return toast.error("value is not valid JSON");
      }
    }
    if (!validate("variable_edit", payload)) return setLoading(false);
    await fetcher.post(
      "saving variable",
      `/api/vars`,
      payload,
      ({ error }) => {
        if (error) {
          toast.error(error);
          setLoading(false);
          return;
        }
        triggerNextStep();
      },
      ({ error }) => {}
    );
  };
  if (loading) return <Loading />;
  return (
    <Stack spacing={1}>
      <E1Input
        sx={{ width: "100%" }}
        type={type === "str" && secret ? "password" : type}
        label="Variable Value"
        inputProps={{ ...controller("value") }}
      />
      <Button
        onClick={createVariable}
        sx={{ marginTop: "10px" }}
        variant="contained"
        color="success"
      >
        Submit
      </Button>
    </Stack>
  );
};
const CreateAView = ({ triggerNextStep, steps }) => {
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const createView = async (name, tag, defaultView) => {
    await new Promise((x) => setTimeout(() => x(), 1000));
    if (!name) return triggerNextStep();
    const payload = {
      name,
      tag,
      default: defaultView,
    };
    await fetcher.post(
      "creating view",
      `/api/nexus/views`,
      payload,
      ({ id, error }) => {
        if (error) {
          return toast.error(error);
        }
        navigate(`/app/nexus?view=${id}`);
      },
      () => {}
    );
  };
  React.useEffect(() => {
    let name = steps["View Input"]?.value;
    let defaultView = steps["Default View Input"]?.value === "Yes";
    let tag = steps["View Tag Input"]?.value;
    createView(name, tag, defaultView);
  }, []);
  return <Loading />;
};
const CreateASystem = ({ triggerNextStep, steps }) => {
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const createSystem = async (name, dataBacked) => {
    await new Promise((x) => setTimeout(() => x(), 1000));
    if (!name) return triggerNextStep();
    let options = {};
    if (dataBacked) {
      options.properties = [
        {
          colors: [],
          id: nanoid(),
          name: "uid",
          type: "str",
          width: "",
        },
      ];
      options.uid = "uid";
    }
    const payload = {
      uid: null,
      name,
      plugins: {
        data: dataBacked === "Yes" ? true : false,
        graphs: false,
        detectors: false,
      },
      description: "",
      properties: [],
      detectors: [],
      actions: [],
      graphs: [],
      tags: [],
      acl: [],
      ...options,
    };
    await fetcher.post(
      "creating system",
      `/api/sys`,
      payload,
      (data) => {
        if (data?.error) {
          toast.error(data?.error);
        }
        navigate(`/app/sys/${data?.id}/dash`);
      },
      () => {}
    );
  };
  React.useEffect(() => {
    let name = steps["System Name User Input"]?.value;
    let dataBacked = steps["System Data Input Options"]?.value;
    createSystem(name, dataBacked);
  }, []);
  return <Loading />;
};
const GoToLog = ({ triggerNextStep, steps }) => {
  const navigate = useNavigate();
  const [date, setDate] = React.useState(new Date());
  let d = new Date();
  let maxDate = new Date(d.setDate(d.getDate() + 1));
  const fetchLogs = () => {
    navigate("/app/archive?date=" + date.toString());
  };
  return (
    <div style={{ width: "400px" }}>
      <DatePicker selected={date} onChange={setDate} maxDate={maxDate} />
      <Button
        onClick={fetchLogs}
        sx={{ marginTop: "10px" }}
        variant="contained"
        color="success"
      >
        Fetch Logs
      </Button>
    </div>
  );
};

function E1Chatbot(props) {
  const [opened, setOpened] = React.useState(false);
  const config = {
    width: "400px",
    height: "400px",
    floating: true,
    botAvatar: BotImage,
    toggleFloating: () => {
      setOpened(!opened);
    },
    opened,
  };
  const steps = [
    {
      id: "Greet",
      delay: 0,
      message: "What can I help you with?",
      trigger: "Options",
    },
    {
      id: "Options",
      options: [
        { value: 1, label: "Build a System", trigger: "Build a System" },
        { value: 2, label: "View Logs", trigger: "Go To Log" },
        // { value: 3, label: "Build a View", trigger: "Build a View" },
        { value: 4, label: "Create a Variable", trigger: "Create a Variable" },
      ],
    },
    {
      id: "Build a System",
      message: "What will the system name be?",
      trigger: "System Name User Input",
    },
    {
      id: "System Name User Input",
      user: true,
      validator: (value) => {
        if (!value) {
          return "Enter a name for the system";
        }
        return true;
      },
      trigger: "System Data Input",
    },
    {
      id: "System Data Input",
      message: "Will the system be data backed?",
      trigger: "System Data Input Options",
    },
    {
      id: "System Data Input Options",
      options: [
        { value: "Yes", label: "Yes", trigger: "System Create Confirm" },
        { value: "No", label: "No", trigger: "System Create Confirm" },
      ],
    },
    {
      id: "System Create Confirm",
      message: "I'll get this system whipped up for you!",
      trigger: "Create System",
    },
    {
      id: "Go To Log",
      component: <GoToLog />,
      waitAction: true,
      replace: true,
      asMessage: true,
      trigger: "Greet",
    },
    {
      id: "Create System",
      component: <CreateASystem />,
      waitAction: true,
      replace: true,
      asMessage: true,
      trigger: "Greet",
    },
    // {
    //   // id: "Build a View",
    //   message: "What will the view name be?",
    //   trigger: "View Input",
    // },
    // {
    //   id: "View Input",
    //   user: true,
    //   validator: (value) => {
    //     if (!value) {
    //       return "Enter a name for the view";
    //     }
    //     return true;
    //   },
    //   trigger: "Default View Text",
    // },
    // {
    //   id: "Default View Text",
    //   message: "Will this view be your default one?",
    //   trigger: "Default View Input",
    // },
    // {
    //   id: "Default View Input",
    //   options: [
    //     { value: "Yes", label: "Yes", trigger: "View Tag Text" },
    //     { value: "No", label: "No", trigger: "View Tag Text" },
    //   ],
    // },
    // {
    //   id: "View Tag Text",
    //   message: "What tag should I create a view from?",
    //   trigger: "View Tag Input",
    // },
    // {
    //   id: "View Tag Input",
    //   user: true,
    //   validator: (value) => {
    //     if (!value) {
    //       return "Enter a tag to make the view with";
    //     }
    //     return true;
    //   },
    //   trigger: "Create View",
    // },
    // {
    //   id: "Create View",
    //   component: <CreateAView />,
    //   waitAction: true,
    //   replace: true,
    //   asMessage: true,
    //   trigger: "Greet",
    // },
    {
      id: "Create a Variable",
      message: "What will the variable name be?",
      trigger: "Variable Input",
    },
    {
      id: "Variable Input",
      user: true,
      validator: (value) => {
        if (!value) {
          return "Enter a name for the variable";
        }
        return true;
      },
      trigger: "Variable Description",
    },
    {
      id: "Variable Description",
      message: "What is the variable's description?",
      trigger: "Variable Description Prompt",
    },
    {
      id: "Variable Description Prompt",
      user: true,
      validator: (value) => {
        if (!value) {
          return "Enter a description for the variable";
        }
        return true;
      },
      trigger: "Variable Type Prompt",
    },
    {
      id: "Variable Type Prompt",
      message: "What will the variable type be?",
      trigger: "Variable Type Input",
    },
    {
      id: "Variable Type Input",
      options: [
        {
          value: "str",
          label: "Short String",
          trigger: "Variable Secure Prompt",
        },
        { value: "lstr", label: "Long String", trigger: "Variable Custom" },
        { value: "list", label: "List", trigger: "Variable Custom" },
        { value: "object", label: "Object", trigger: "Variable Custom" },
      ],
    },
    {
      id: "Variable Secure Prompt",
      message: "Will the variable be a secret?",
      trigger: "Variable Secure Input",
    },
    {
      id: "Variable Secure Input",
      options: [
        { value: "Yes", label: "Yes", trigger: "Variable Custom" },
        { value: "No", label: "No", trigger: "Variable Custom" },
      ],
    },
    {
      id: "Variable Custom",
      component: <CreateAVariable />,
      waitAction: true,
      replace: true,
      asMessage: true,
      trigger: "Variable Finish",
    },
    {
      id: "Variable Finish",
      message: "I've created that variable for you!",
      trigger: "Greet",
      delay: 3000,
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      {/* <ChatBot
        key={opened}
        hideUserAvatar
        cache={false}
        userDelay={0}
        botDelay={500}
        steps={steps}
        {...config}
      /> */}
    </ThemeProvider>
  );
}
export default E1Chatbot;
