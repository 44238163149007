import React from "react";
import Input from "common/components/Input";
import { Stack, Typography, Avatar, Icon } from "@mui/material";
import ColorPicker from "common/components/ColorPicker";
import useFetcher from "common/hooks/useFetcher";
import validate from "constants/validate";
import ActionButton from "common/components/ActionButton";
import toast from "react-hot-toast";
import { theme } from "theme";
import FileUpload from "common/components/FileUpload";
export default function CreatePlatform({ refresh, onClose }) {
  const [name, setName] = React.useState("");
  const [imageFile, setImageFile] = React.useState(null);
  const [geoFile, setGeoFile] = React.useState(null);
  const [platformConfig, setPlatformConfig] = React.useState("");
  const [description, setDescription] = React.useState("");
  const fetcher = useFetcher();

  const createPlatform = async () => {
    let payload = {
      type: name,
      description,
      platformConfig,
      imageFile,
      name,
    };
    if (!validate("add_platform", payload)) throw new Error("incorrect format");
    await fetcher.post(
      "adding platform",
      `/api/platforms`,
      () => {
        let form = new FormData();
        form.append("json", JSON.stringify(payload));
        form.append("Icon", imageFile);
        form.append("Geo", geoFile);
        return form;
      },
      ({ id, error }, toast) => {
        if (id) {
          toast.success("Platform added!");
          refresh();
          onClose();
          return;
        }
        toast.error(error);
      },
      (e) => {}
    );
  };
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <Icon
            style={{
              fontSize: "40px",
              color: theme.palette.primary.main,
            }}
          >
            add
          </Icon>
        </Avatar>
        <Stack>
          <Typography variant="h4">Create Custom Platform</Typography>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Information</Typography>
        <Stack gap={1}>
          <Typography>Define your platforms's name and description.</Typography>
        </Stack>
        <Stack gap={1}>
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            placeholder="MyNewPlatform"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            placeholder="Platform description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            minRows={3}
            maxRows={3}
          />
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Icon</Typography>
        <Stack gap={1}>
          <Typography>
            Upload a small PNG file to be used as this platform's icon.
          </Typography>
        </Stack>
        <Stack gap={1} alignItems={"start"}>
          <FileUpload
            sizeInKb={300}
            file={imageFile}
            handleFileChange={setImageFile}
          >
            Upload Icon
          </FileUpload>
          {imageFile && (
            <div
              style={{
                overflow: "hidden",
                height: "50px",
                width: "50px",
                backgroundImage: `url(${URL.createObjectURL(imageFile)})`,
                backgroundSize: "cover",
              }}
            />
          )}
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Geography</Typography>
        <Stack gap={1}>
          <Typography>
            {`(Optional) Upload available geographies for this platform. Must be a JSON array file with each object having a format of {"name":"NAME","code":"CODE"}.`}
          </Typography>
        </Stack>
        <Stack gap={1} alignItems={"start"}>
          <FileUpload
            sizeInKb={600}
            file={geoFile}
            handleFileChange={setGeoFile}
          >
            Upload JSON
          </FileUpload>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Configuration Definition</Typography>
        <Stack gap={1}>
          <Typography>
            Provide a JSON example of what this platform's JSON configuration
            should look like when adding a platform tenant.
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            placeholder="Platform configuration"
            value={platformConfig}
            onChange={(e) => setPlatformConfig(e.target.value)}
            multiline
            minRows={5}
            maxRows={5}
          />
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto" }}>
        <ActionButton
          icon="save"
          dark
          handler={async () => {
            await createPlatform();
          }}
        >
          Save Custom Platform
        </ActionButton>
      </div>
    </Stack>
  );
}
