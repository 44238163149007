import React, { useState } from "react";
import useAuthentication, { logout } from "auth";
import {
  Button,
  Box,
  Divider,
  Typography,
  Stack,
  Avatar,
  Icon,
  Popover,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuditorIcon from "assets/crew/auditor_avatar.png";
import OperatorIcon from "assets/crew/operator_avatar.png";
import EngineerIcon from "assets/crew/engineer_avatar.png";
import AdminIcon from "assets/crew/admin_avatar.png";
import Menu from "common/components/Menu";
import CliInstall from "common/components/CliInstall";

const roleIcon = {
  auditor: AuditorIcon,
  engineer: EngineerIcon,
  operator: OperatorIcon,
  admin: AdminIcon,
};

export default function AccountPopover() {
  const user = useAuthentication();
  const [cliOpen, setCliOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "name-popover" : undefined;
  const navigate = useNavigate();
  const logoutHandler = () => {
    logout();
  };
  const avatar = (
    <Avatar
      sx={{
        width: 50,
        height: 50,
        border: `${user?.color} 2px solid`,
        backgroundColor: `${user?.color}`,
      }}
    >
      <img src={roleIcon[user?.rls]} alt="member" height="50px" width="50px" />
    </Avatar>
  );
  return (
    <>
      <Menu icon={avatar}>
        {() => (
          <>
            <Box sx={{ minWidth: "200px", my: 1.5, px: 2.5 }}>
              <Stack alignItems="center" spacing={3} direction="row">
                <Stack>
                  <Typography
                    variant="subtitle1"
                    noWrap
                    sx={{
                      maxWidth: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                    }}
                    aria-owns={open ? id : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handleMouseEnter}

                    // onMouseLeave={handleMouseLeave}
                  >
                    {user?.id}
                  </Typography>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleMouseLeave}
                    PaperProps={{
                      onMouseLeave: handleMouseLeave,
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <Typography sx={{ p: 1 }}>{user?.id}</Typography>
                  </Popover>
                  <Stack>
                    <Typography variant="caption" noWrap>
                      <strong>crew:</strong> {user?.crew_name}
                    </Typography>
                    <Typography variant="caption" sx={{}} noWrap>
                      <strong>role:</strong> {user?.role}
                    </Typography>
                  </Stack>
                  {/* <Typography variant="body2" sx={{}} noWrap>
                {user?.rls}
              </Typography> */}
                </Stack>
              </Stack>
            </Box>

            <Divider sx={{ my: 1 }} />
            <CliInstall open={cliOpen} onClose={setCliOpen} />
            <Stack spacing={2} sx={{ p: 2, pt: 1.5 }}>
              <Button
                onClick={() => setCliOpen(true)}
                fullWidth
                sx={{ borderColor: "#474747" }}
                color="inherit"
                variant="outlined"
              >
                <Icon sx={{ marginRight: "10px" }}>terminal</Icon>
                Manage CLI
              </Button>
              <Button
                onClick={() => {
                  let { access_token } = JSON.parse(
                    localStorage?.getItem("E1_JWT") || "{}"
                  );
                  window.open(
                    `${window.location.protocol}//${window.location.host}/docs?token=${access_token}`
                  );
                }}
                fullWidth
                sx={{ borderColor: "rgba(132, 42, 197, 0.9)" }}
                color="inherit"
                variant="outlined"
              >
                <Icon sx={{ marginRight: "10px" }}>school</Icon>
                View Docs
              </Button>
              <Button
                onClick={logoutHandler}
                fullWidth
                sx={{ borderColor: "#a84d4d" }}
                color="inherit"
                variant="outlined"
              >
                Logout
              </Button>
            </Stack>
          </>
        )}
      </Menu>
    </>
  );
}
