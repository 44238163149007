import React from "react";
import { Stack, IconButton, Icon, Typography } from "@mui/material";
import useFetcher from "common/hooks/useFetcher";
import SidePanel from "common/components/SidePanel";
import { useParams } from "react-router-dom";
import { MiniLoader } from "common/components/Loader";
import useConfirm from "common/hooks/useConfirm";
import ActionButton from "common/components/ActionButton";
import FileUpload from "common/components/FileUpload";
import { Card, CardHeader, CardActions, Avatar } from "@mui/material";
import Tile from "common/components/Tile";
import toast from "react-hot-toast";
import { theme } from "theme";
export default function FilesModal({
  system,
  open,
  setOpen,
  dataBacked,
  role,
}) {
  const fetcher = useFetcher();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [schedules, setSchedules] = React.useState([]);
  const [hooks, setHooks] = React.useState([]);
  const [job, setJob] = React.useState(null);
  const [view, setView] = React.useState(0);
  const { id } = useParams();
  const [file, setFile] = React.useState(null);
  const deleteFile = async (key) => {
    await fetcher.delete(
      "deleting file",
      `/api/sys/${id}/file?key=${key}`,
      ({ success, error }) => {
        if (error) return toast.error(error);
        getFiles();
      },
      () => {}
    );
  };
  const getFiles = async () => {
    setLoading(true);
    await fetcher.get(
      "fetching system files",
      `/api/sys/${id}/file`,
      (files) => {
        try {
          let sorted = [...files];
          sorted.sort((a, b) => {
            // Convert string dates into `Date` objects
            const dateA = new Date(a.last_modified);
            const dateB = new Date(b.last_modified);

            // Subtract dateB from dateA to sort in descending order
            return dateB - dateA;
          });
          setData(sorted);
          setLoading(false);
        } catch {
          setData([]);
          setLoading(false);
        }
      },
      () => {}
    );
  };
  const uploadFile = async () => {
    if (!file) {
      toast.error("Please select a file");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    await fetcher.post(
      "uploading file",
      `/api/sys/${id}/file`,
      () => {
        let form = new FormData();
        form.append("file", file);
        return form;
      },
      ({ error }) => {
        if (error) {
          toast.error(error);
          setLoading(false);
          return;
        }
        toast.success("file uploaded!");
        setFile(null);
        getFiles();
        setLoading(false);
      },
      ({ error }) => {}
    );
  };
  const downloadFile = async (key) => {
    await fetcher.get(
      "downloading file",
      `/api/sys/${id}/file/download?key=${encodeURIComponent(key)}`,
      ({ url }) => {
        if (url) {
          const a = document.createElement("a");
          a.href = url;
          a.download = "filename";
          a.click();
        }
      },
      () => {}
    );
  };

  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete File`,
    message: `Are you sure you want to delete this file? This action can't be undone.`,
    callback: (props, context) => {
      deleteFile(context.key);
    },
  });
  const [openConfirmModal, UploadModal] = useConfirm({
    title: `Upload File`,
    message: `Upload ${file?.name}? `,
    callback: (props, context) => {
      uploadFile();
    },
    onCancel: () => setFile(null),
  });

  React.useEffect(() => {
    if (!open) return;
    getFiles();
  }, [open, view]);
  React.useEffect(() => {
    if (file) {
      openConfirmModal(true);
    }
  }, [file]);
  return (
    <SidePanel
      width={600}
      open={open}
      closeDrawer={() => setOpen(null)}
      props={{}}
    >
      {({ handleClose }) => {
        return (
          <Stack p={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <UploadModal type="info" noConfirm />
              <DeleteModal />
              <div style={{ display: "flex", gap: "10px", marginLeft: "auto" }}>
                <IconButton key="refresh" onClick={getFiles} color="secondary">
                  <Icon color="secondary">refresh</Icon>
                </IconButton>
                <FileUpload file={file} hideFileName handleFileChange={setFile}>
                  Upload File
                </FileUpload>
              </div>
            </Stack>
            {loading ? (
              <div style={{ padding: "50px", position: "relative" }}>
                <MiniLoader />
              </div>
            ) : (
              <Stack p={2} gap={2}>
                {data?.length ? (
                  (data || []).map((item, index) => {
                    return (
                      <Tile
                        key={index}
                        type={"info"}
                        icon={"description"}
                        text1={item.name}
                        text2={item.last_modified}
                        buttons={[
                          [
                            "info",
                            "download",
                            () => {
                              downloadFile(item.key);
                            },
                          ],
                          [
                            "error",
                            "delete",
                            () => {
                              openDeleteModal(true, { key: item.key });
                            },
                          ],
                        ]}
                      />
                      // <Card
                      //   sx={{
                      //     display: "flex",
                      //     backgroundColor: theme.palette.primary.dark,
                      //     alignItems: "center",
                      //   }}
                      // >
                      //   <div
                      //     style={{
                      //       display: "flex",
                      //       flexDirection: "column",
                      //       width: "100%",
                      //     }}
                      //   >
                      //     <div
                      //       style={{
                      //         display: "flex",
                      //         flexDirection: "row",
                      //         alignItems: "center",
                      //       }}
                      //     >
                      //       <CardHeader
                      //         title={item.name}
                      //         subheader={item.last_modified}
                      //         sx={{ flex: 1 }}
                      //         titleTypographyProps={{ variant: "subtitle1" }} // Smaller title
                      //         subheaderTypographyProps={{ variant: "body2" }} // Smaller subheader
                      //       />
                      //     </div>
                      //     <CardActions sx={{ marginLeft: "auto" }}>
                      //       <IconButton
                      //         onClick={() => {
                      //           downloadFile(item.key);
                      //         }}
                      //         aria-label="download"
                      //       >
                      //         <Icon>download</Icon>
                      //       </IconButton>
                      //       <IconButton
                      //         onClick={() => {
                      //           openDeleteModal(true, { key: item.key });
                      //         }}
                      //         aria-label="delete"
                      //       >
                      //         <Icon color="error">delete</Icon>
                      //       </IconButton>
                      //     </CardActions>
                      //   </div>
                      // </Card>
                    );
                  })
                ) : (
                  <Typography>No Files</Typography>
                )}
              </Stack>
            )}
          </Stack>
        );
      }}
    </SidePanel>
  );
}
