import React from "react";
import { Stack, IconButton, Icon, Typography, Button } from "@mui/material";
import Checkbox from "common/components/Checkbox";
import useState from "common/hooks/useState";
// import AwsTarget from "pages/Targets/components/AwsTarget";
import Modal from "common/components/Modal";
import Loader from "common/components/Loader";
import MultiSelect from "common/components/MultiSelect";
import Blurb from "constants/blurbs";
import validate from "constants/validate";
import ActionButton from "common/components/ActionButton";
export default function CommandTargeting({ data, setData }) {
  // const [loading, setLoading] = React.useState(false);
  const [enabled, setEnabled] = React.useState(false);
  const [select, setSelect] = React.useState(true);
  const [target, setTarget] = React.useState(null);
  const { targets, loading, error, refresh } = useState("/api/targeting");

  React.useEffect(() => {
    if (!data) return;
    setEnabled(data.targeting.enabled);
    setSelect(data.targeting.select);
    setTarget(data.targeting.target);
  }, [data]);
  const onSave = (close) => {
    if (!validate("command_target_edit", { enabled, select, target })) return;
    setData({
      ...data,
      targeting: {
        enabled,
        select,
        target: target ? { id: target?.id, name: target?.name } : null,
      },
    });
    close();
  };
  return (
    <Stack m={0} gap={1} p={0}>
      <Stack sx={{ position: "relative" }} spacing={1}>
        <Modal
          onClose={() => {
            // setVariable(null);
          }}
          button={(props) => (
            <IconButton sx={{ position: "absolute", right: 0 }} {...props}>
              <Icon>edit</Icon>
            </IconButton>
          )}
        >
          {({ handleClose }) =>
            loading ? (
              <Loader small />
            ) : (
              <Stack alignItems="start" spacing={3}>
                <Typography variant="h5">Update Targeting</Typography>
                <Blurb>command_targeting_enabled</Blurb>
                <Stack direction="row" alignItems={"center"} gap={2}>
                  <Checkbox
                    text={enabled ? "Enabled" : "Single Run Only"}
                    value={enabled}
                    onChange={(value) => {
                      if (!value) {
                        setSelect(false);
                        setTarget(null);
                      }
                      setEnabled(value);
                    }}
                    id="enabled-toggle"
                  />
                  {enabled ? (
                    <Checkbox
                      text={"Select at run"}
                      value={select}
                      onChange={(value) => {
                        if (value) {
                          setTarget(null);
                        }
                        setSelect(value);
                      }}
                      id="select-run-toggle"
                    />
                  ) : null}
                  {/* <Switch
                    label={enabled ? "Enabled" : "Single Run Only"}
                    checked={enabled}
                    onChange={() => {
                      if (enabled) setSelect(false);
                      if (enabled) setTarget(null);
                      setEnabled(!enabled);
                    }}
                  /> */}
                  {/* {enabled ? (
                    <Switch
                      label="Select at run"
                      checked={select}
                      onChange={() => {
                        if (!select) setTarget(null);
                        setSelect(!select);
                      }}
                    />
                  ) : null} */}
                </Stack>
                <Stack direction="row" spacing={2}>
                  {enabled && !select ? (
                    <MultiSelect
                      size="small"
                      options={targets}
                      loading={loading}
                      getOptionLabel={(e) => e.name}
                      label="Target"
                      multiple={false}
                      placeholder="Select a target"
                      value={target}
                      setValue={setTarget}
                      sx={{ minWidth: "416px" }}
                    />
                  ) : null}
                </Stack>
                <div style={{ marginLeft: "auto" }}>
                  <ActionButton
                    handler={() => onSave(handleClose)}
                    noload
                    icon="save"
                  >
                    Save
                  </ActionButton>
                </div>
              </Stack>
            )
          }
        </Modal>
        <Stack mb={2}>
          <Blurb>command_targeting</Blurb>
        </Stack>
        {!data?.targeting?.enabled ? (
          <Typography variant="body1">No targeting enabled</Typography>
        ) : !data.targeting.select ? (
          <Typography variant="body1">
            Enabled for {data?.targeting?.target?.name || "-"} target group.
          </Typography>
        ) : (
          <Typography variant="body1">Select target at run time.</Typography>
        )}

        {/* <Switch
          label={data.targeting.enabled ? "Enabled" : "Single Run Only"}
          disabled
          checked={data.targeting.enabled}
        /> */}
        {/* {data.targeting.enabled ? (
          <>
            {!data.targeting.select ? (
              <Typography sx={{ margin: 0, paddingTop: 1 }} variant="body1">
                Target: {data?.targeting?.target?.name || "-"}
              </Typography>
            ) : (
              <Switch
                label="Select at run"
                disabled
                checked={data.targeting.select}
              />
            )}
          </>
        ) : null} */}
      </Stack>
    </Stack>
  );
}
