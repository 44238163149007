import React from "react";
import MultiSelect from "common/components/MultiSelect";
import useState from "common/hooks/useState";
import useAuthentication from "auth";
import { Stack } from "@mui/material";
import Blurb from "constants/blurbs";
const useScopeAttributes = () => {
  const { crews, error: crewError } = useState("/api/crews");
  const [tags, setTags] = React.useState([]);
  const [acl, setAcl] = React.useState([]);

  const setInit = (tags = [], acl = []) => {
    setTags(tags);
    setAcl(
      acl
        .filter((id) => id)
        .map((id) => crews?.find((crew) => crew?.id === id))
        .filter((c) => c)
    );
  };
  const TagInput = ({ ...params }) => (
    <>
      <MultiSelect
        getOptionLabel={(e) => e}
        label="Tags (press enter after each tag)"
        freeSolo
        placeholder="New Tag"
        value={tags}
        shrink
        setValue={(t) => {
          if (t.filter((x) => x === "")?.length) return;
          if (t.length >= 4) return;
          let tags = t.map((tag) => tag.toLowerCase());
          setTags(tags);
        }}
        dark
        size="small"
        {...params}
      />
    </>
  );
  const ScopeInputs = ({ ...props }) => (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <div>
        <Blurb margin={0.5}>acl_crew</Blurb>
        <MultiSelect
          {...props}
          options={crews?.filter((c) => c?.name !== "administrators") || []}
          loading={!crews || !crewError}
          getOptionLabel={(e) => e?.name}
          label="Access Control"
          placeholder="Crew"
          value={acl}
          setValue={setAcl}
          dark
          size="small"
        />
      </div>
      <div>
        <Blurb margin={0.5}>acl_tags</Blurb>
        <MultiSelect
          {...props}
          getOptionLabel={(e) => e}
          label="Tags"
          freeSolo
          placeholder="New Tag"
          value={tags}
          setValue={(t) => {
            if (t.filter((x) => x === "")?.length) return;
            if (t.length >= 4) return;
            let tags = t.map((tag) => tag.toLowerCase());
            setTags(tags);
          }}
          dark
          size="small"
        />
      </div>
    </Stack>
  );
  return {
    tags,
    acl,
    ScopeInputs,
    TagInput,
    setInit,
    loading: !crews && !crewError,
    getScope: () => ({ tags, acl: acl?.map(({ id }) => id) }),
  };
};
export default useScopeAttributes;
