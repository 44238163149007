import React from "react";
import {
  Stack,
  Chip,
  Paper,
  Typography,
  Icon,
  Autocomplete,
  IconButton,
} from "@mui/material";
import SectionHeader from "common/components/SectionHeader";
import MultiSelect from "common/components/MultiSelect";
import Input from "common/components/Input";
import ActionButton from "common/components/ActionButton";
import toast from "react-hot-toast";
import { nanoid } from "nanoid";
import SidePanel from "common/components/SidePanel";
import MultiLevelMenu from "common/components/MultilevelMenu";
import List from "common/components/List";
import useConfirm from "common/hooks/useConfirm";
const OPERATORS = [
  "contains",
  "equals",
  "starts with",
  "ends with",
  "is empty",
  "is not empty",
  "is any of",
  "is none of",
  "greater than",
  "less than",
];
const AddFilter = ({
  saved,
  add,
  columns,
  edit,
  setEdit,
  update,
  setFilters,
  setManage,
  query,
  filters,
  clear,
  queryBasedOnPassedFilters,
  additionalOptions = [],
}) => {
  const [column, setColumn] = React.useState("");
  const [operator, setOperator] = React.useState("");
  const [value, setValue] = React.useState("");
  React.useEffect(() => {
    if (!edit) return;
    setColumn(edit.column);
    setOperator(edit.operator);
    setValue(edit.value);
  }, [edit]);
  let view_menu = { main: {} };
  saved.map((value) => {
    view_menu.main[value.name] = {
      onClick: () => {
        setFilters(value.filters);
        queryBasedOnPassedFilters(value.filters);
      },
    };
  });
  if (additionalOptions?.length) {
    additionalOptions.forEach(([icon, name, action]) => {
      view_menu.main[`${name} detector`] = {
        leftIcon: <Icon color="success">{icon}</Icon>,
        onClick: () => {
          action();
        },
      };
    });
  }
  view_menu.main["Clear Filters"] = {
    leftIcon: <Icon color="error">clear</Icon>,
    onClick: () => {
      clear();
    },
  };
  if (saved?.length) {
    view_menu.main["Manage Views"] = {
      leftIcon: <Icon>settings</Icon>,
      onClick: () => {
        setManage("manage");
      },
    };
  }
  return (
    <Stack direction="row" gap={1} alignItems={"center"}>
      <div style={{ position: "relative" }}>
        {Object.keys(view_menu.main).length ? (
          <MultiLevelMenu
            noOffset
            button={(props) => (
              <ActionButton
                handler={props.onClick}
                circle
                noload
                icon="more_vert"
              />
            )}
            menu={view_menu}
          />
        ) : null}
      </div>
      <MultiSelect
        size="small"
        label="Column"
        sx={{ width: "200px" }}
        options={columns}
        multiple={false}
        value={column}
        shrink
        setValue={(e) => {
          setColumn(e);
        }}
      />
      <MultiSelect
        size="small"
        shrink
        label="Operator"
        sx={{ width: "200px" }}
        options={OPERATORS}
        multiple={false}
        value={operator}
        setValue={(e) => {
          if (["is any of", "is none of"].includes(e)) {
            setValue("");
          }
          setOperator(e);
        }}
      />

      {["is not empty", "is empty"].includes(operator) ? null : (
        <Input
          size="small"
          label="Value"
          value={value}
          sx={{ width: "300px" }}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
      <ActionButton
        icon="add"
        noload
        handler={async () => {
          if (!column || !operator)
            return toast.error("current filter not valid");
          setColumn("");
          setOperator("");
          setValue("");
          if (edit) {
            setEdit(null);
            return update({ ...edit, column, operator, value });
          }
          add({ id: nanoid(), column, operator, value });
        }}
      >
        {edit ? "Edit" : "Add"} Filter
      </ActionButton>
      {filters?.length ? (
        <>
          <ActionButton
            handler={() => setManage("save")}
            circle
            noload
            icon="save"
          />
          <ActionButton
            handler={() => query()}
            circle
            noload
            icon="play_arrow"
          />
        </>
      ) : null}
    </Stack>
  );
};

export default function QueryBuilder({
  query,
  columns,
  filters,
  currentView,
  setFilters,
  clear,
  searchParam,
  saveSystem,
  system,
  queryBasedOnPassedFilters,
  additionalOptions = [],
}) {
  const [edit, setEdit] = React.useState(null);
  const [manage, setManage] = React.useState(false);
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete saved query?`,
    message: `Are you sure you want to delete this saved query? This action cannot be undone.`,
    callback: (props, context) => {
      let queries = system?.queries || [];
      queries = queries.filter((q) => q.name !== context?.filter?.name);
      saveSystem({ ...system, queries }, false);
      if (queries.length === 0) {
        context.close();
      }
    },
  });
  const addFilter = (filter) => {
    setFilters((f) => [...f, filter]);
    setEdit(false);
  };
  const updateFilter = (filter) => {
    setFilters(
      filters?.map((f) => {
        return f.id !== filter.id ? f : filter;
      })
    );
  };
  const removeFilter = (id) => {
    setFilters((f) => f.filter((f) => f.id !== id));
    setEdit(false);
  };

  return (
    <div>
      <DeleteModal />
      <SidePanel
        open={!!manage}
        width={480}
        closeDrawer={() => {
          setManage(false);
        }}
      >
        {({ onClose }) => {
          const [newView, setNewView] = React.useState("");
          const updateSaveSelection = (event, newValue) => {
            event.preventDefault();
            if (typeof newValue === "string") {
              setNewView(newValue);
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setNewView(newValue.inputValue);
            } else {
              setNewView(newValue?.name || "");
            }
          };
          return (
            <Stack
              className="floating"
              sx={{ margin: "20px", padding: "20px" }}
            >
              {manage === "save" ? (
                <Stack p={2} gap={2}>
                  <SectionHeader icon={"save"} large>
                    Save Query
                  </SectionHeader>
                  <Typography>
                    Save your query to navigate your dataset easier
                  </Typography>
                  <Autocomplete
                    sx={{ p: 0 }}
                    value={newView}
                    disablePortal
                    PaperComponent={({ children }) => (
                      <Paper
                        style={{
                          background: "#262626",
                          color: "rgba(239,237,239,0.87)",
                        }}
                      >
                        {children}
                      </Paper>
                    )}
                    onChange={updateSaveSelection}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={system?.queries || []}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    // sx={{ width: 300 }}
                    inputValue={newView}
                    onInputChange={updateSaveSelection}
                    fullWidth
                    freeSolo
                    renderInput={(params) => (
                      <Input
                        {...params}
                        color="secondary"
                        size="small"
                        dark
                        label="Saved Query Name"
                      />
                    )}
                  />
                  <Typography>Filters</Typography>
                  <Stack direction="row" gap={1}>
                    {filters?.map((f) => {
                      return (
                        <Chip
                          color="secondary"
                          sx={{
                            fontSize: "0.8.7",
                            height: "40px",
                          }}
                          clickable
                          onClick={() => setEdit(f)}
                          key={f.id}
                          label={`${f.column} ${f.operator} "${f.value}"`}
                          onDelete={() => {
                            removeFilter(f.id);
                            if (filters.length === 1) onClose();
                          }}
                        />
                      );
                    })}
                  </Stack>
                  <Stack direction={"row"} justifyContent={"flex-end"}>
                    <ActionButton
                      icon="save"
                      dark
                      handler={async () => {
                        if (newView.length > 25)
                          return toast.error("query name is too long");
                        let queries = system?.queries ?? [];
                        let index = queries.findIndex(
                          (x) => x.name === newView
                        );
                        if (index >= 0) {
                          queries[index] = {
                            name: newView,
                            filters,
                          };
                        } else {
                          queries.push({
                            name: newView,
                            filters,
                          });
                        }
                        await saveSystem({ ...system, queries });
                        onClose();
                      }}
                    >
                      Save Query
                    </ActionButton>
                  </Stack>
                </Stack>
              ) : (
                <Stack p={2} gap={2}>
                  <SectionHeader icon={"settings"} large>
                    Manage Queries
                  </SectionHeader>
                  <Typography>Manage your saved queries</Typography>
                  <List
                    items={(system?.queries || []).map((q) => {
                      return {
                        text: (
                          <Stack
                            sx={{
                              backgroundColor: "#171717",
                              padding: "10px",
                              alignItems: "center",
                              borderRadius: "7px",
                              width: "100%",
                            }}
                            direction="row"
                            spacing={1}
                          >
                            <Icon>filter_alt</Icon>
                            <div>{q.name}</div>
                            <IconButton
                              onClick={() => {
                                openDeleteModal(true, {
                                  filter: q,
                                  close: onClose,
                                });
                              }}
                              color="error"
                              sx={{
                                margin: 0,
                                ml: "auto !important",
                              }}
                            >
                              <Icon color="error">clear</Icon>
                            </IconButton>
                          </Stack>
                        ),
                      };
                    })}
                  />
                </Stack>
              )}
            </Stack>
          );
        }}
      </SidePanel>
      <Stack marginBottom={2} gap={2}>
        <Stack direction="row" gap={1}>
          {filters?.map((f) => {
            return (
              <Chip
                color="secondary"
                sx={{ fontSize: "0.8.7", height: "40px" }}
                clickable
                onClick={() => setEdit(f)}
                key={f.id}
                label={`${f.column} ${f.operator} "${f.value}"`}
                onDelete={() => removeFilter(f.id)}
              />
            );
          })}
        </Stack>
        <AddFilter
          additionalOptions={additionalOptions}
          queryBasedOnPassedFilters={queryBasedOnPassedFilters}
          saved={system?.queries || []}
          add={addFilter}
          filters={filters}
          columns={columns}
          edit={edit}
          setEdit={setEdit}
          update={updateFilter}
          setFilters={setFilters}
          setManage={setManage}
          query={query}
          clear={clear}
        />

        <Stack direction="row" gap={2}>
          {/* {searchParam ? (
            <ActionButton danger icon="clear" noload handler={clear}>
              Clear Filter
            </ActionButton>
          ) : null} */}
          {/* <ActionButton icon="download" noload handler={async () => {}}>
            Load Filter
          </ActionButton> */}
          {filters?.length ? (
            <Stack direction="row" gap="10px">
              {/* <ActionButton icon="save" noload handler={async () => {}}>
                Save Filter
              </ActionButton> */}

              {/* <ActionButton
                icon="save"
                noload
                handler={async () => {
                  query();
                }}
              >
                Save Query
              </ActionButton> */}
            </Stack>
          ) : null}
        </Stack>
      </Stack>
    </div>
  );
}
