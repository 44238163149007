import React, { useRef, useCallback, useEffect, useState } from "react";
import AceEditor from "react-ace";
import "common/components/CodeEditor/mode-python";
import "common/components/CodeEditor/theme-dracula";
import { IconButton, Stack } from "@mui/material";
import "common/components/CodeEditor/styles.css";
import styled from "styled-components";
import "ace-builds/src-noconflict/ext-language_tools";
import "common/components/CodeEditor/ace-search-box.css";
import ace from "ace-builds/src-noconflict/ace";
import snippets from "./snippets";
import MultiLevelMenu from "../MultilevelMenu";
import ActionButton from "../ActionButton";

// snippets(ace);

const CodeContainer = styled.div`
  padding: 10px;
  width: 100%;
  border-radius: 7px;
  transition: 0.3s;
  position: relative;
  /* ${({ focus }) => focus && "z-index: 1301;"} */
  /* background: #fff; */
  .ace_content {
    font-family: monospace !important;
    /* windows doesn't like fira code */
    /* font-family: "Fira Code", monospace !important; */
  }
`;
const FocusContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1300;
  background: rgba(0, 0, 0, 0.5);
`;
const CodeToolbar = styled.div`
  margin-top: 1rem;
  z-index: 1401;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
  flex-direction: row-reverse;
`;

export default function CodeEditor({
  name,
  value,
  setValue,
  passiveTools,
  liveSnippets,
  snippets,
  parent = null,
  handleClose,
  onSave,
  onCancel,
  onMenu,
  disabled,
  activeTools,
  ...rest
}) {
  let [readOnly, setReadOnly] = useState(true);
  let [editor, setEditor] = useState(null);
  let [fontSize, setFontSize] = useState(20);
  let [focus, setFocus] = useState(false);
  // let [wrap, setWrap] = useState(false);
  let [classes, setClasses] = useState([]);
  let aceEditorRef = useRef();
  let editorRef = useRef();
  let origin_zindex = useRef();

  const focusHandler = (e) => {
    e?.preventDefault();
    setFocus(true);
    setClasses((c) => [...c, "full-screen"]);
    if (!parent) return setReadOnly(false);
    editorRef.current.style.height = "90vh";
    origin_zindex.current = editorRef.current.style.zIndex;
    editorRef.current.style.zIndex = "1301";
    // document.getElementById(parent).style.pointerEvents = "none";
    document.getElementById(parent).style.overflow = "hidden";
    document.body.style.overscrollBehaviorX = "none";
    if (!editorRef.current) return;
    let { top } = editorRef.current.getBoundingClientRect();
    window.scrollTo(0, top - 40 + scrollY);
    // editorRef.current.scrollIntoView();
    const updateChildren = (e, func) => {
      func(e);
      if (!e?.children?.length) return;
      for (let index = 0; index < e.children.length; index++) {
        const element = e.children[index];
        func(element);
      }
    };
    updateChildren(editorRef.current, (e) => (e.style.pointerEvents = "auto"));
    editorRef.current.style.boxShadow =
      "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset";
    setReadOnly(false);
  };
  window.codeEditorFocusHandler = focusHandler;
  const closeHandler = (noshrink) => {
    setFocus(false);
    if (noshrink) {
      setClasses((c) => c.filter((c) => c !== "full-screen"));
    }
    if (handleClose) {
      handleClose(value);
    }
    if (parent) {
      document.getElementById(parent).style.pointerEvents = "auto";
      document.getElementById(parent).style.overflow = "auto";
      editorRef.current.style.height = "70vh";
      editorRef.current.style.zIndex = origin_zindex.current;
      document.body.style.overscrollBehaviorX = "inherit";
      // document.getElementsByClassName("ace_editor")[0].style.height = "500px";
      if (editorRef) {
        editorRef.current.style.boxShadow = "none";
      }
      setReadOnly(true);
    }
  };
  const blurHandler = () => {
    closeHandler();
    setClasses([]);
  };
  useEffect(() => {
    //handle snippets
    // Get the language tools and snippet manager
    const langTools = ace.require("ace/ext/language_tools");
    const snippetManager = ace.require("ace/snippets").snippetManager;

    // Clear existing snippets
    snippetManager.files = {};
    snippetManager.snippetMap = {};
    snippetManager.snippets = [];

    // Set up custom completers
    langTools.setCompleters([
      {
        getCompletions: (editor, session, pos, prefix, callback) => {
          callback(null, liveSnippets);
        },
        getDocTooltip: (item) => {
          if (item.type === "snippet" && !item.docHTML) {
            item.docHTML = [
              "<b>",
              item.caption,
              "</b>",
              "<hr></hr>",
              "<p>",
              item.meta,
              "</p>",
              "<p>",
              item.snippet.replace(/\n/g, "<br>"),
              "</p>",
            ].join("");
          }
        },
      },
    ]);
  }, [liveSnippets]);
  useEffect(() => {
    if (editor?.renderer) {
      editor.renderer.setScrollMargin(10, 400, 10, 10);
      window.editor = editor;

      // editor.session.on("change", () => {
      //   editor.renderer.scrollToLine(Number.POSITIVE_INFINITY);
      // });
    }
    return () => {
      document.getElementById(parent).style.overflow = "auto";
      document.body.style.overscrollBehaviorX = "inherit";
    };
  }, [editor]);

  const insertInEditor = React.useCallback(
    (value) => {
      editor.session.insert(editor.getCursorPosition(), value);
    },
    [editor]
  );
  const Toolbar = useCallback(() => {
    // if (!tools || !editor) return null;
    return (
      <CodeToolbar>
        {focus && (
          <>
            <Stack direction={"row"} gap={5}>
              {activeTools}
              {snippets ? (
                <Stack direction="row" gap={2} sx={{ position: "relative" }}>
                  <MultiLevelMenu
                    button={(props) => (
                      <ActionButton
                        handler={props.onClick}
                        circle
                        noload
                        icon="code_blocks"
                      />
                    )}
                    menu={snippets(editor, insertInEditor)}
                  />
                </Stack>
              ) : null}
              <Stack direction="row" gap={2}>
                <ActionButton
                  handler={() => {
                    setFontSize((v) => (v > 5 ? v - 5 : v));
                  }}
                  circle
                  noload
                  icon="remove_circle_outline"
                />
                <ActionButton
                  handler={() => {
                    setFontSize((v) => (v < 40 ? v + 5 : v));
                  }}
                  circle
                  noload
                  icon="add_circle_outline"
                />
                {/* <ActionButton
                  handler={() => {
                    if (classes.includes("full-screen")) {
                      setClasses((c) => c.filter((c) => c !== "full-screen"));
                    } else {
                      setClasses((c) => [...c, "full-screen"]);
                    }
                  }}
                  circle
                  noload
                  icon="fit_screen"
                /> */}
              </Stack>
              <Stack direction="row" gap={2}>
                <ActionButton
                  handler={() => {
                    blurHandler();
                  }}
                  circle
                  noload
                  icon="zoom_in_map"
                />
                {onMenu ? (
                  <ActionButton
                    handler={() => {
                      closeHandler();
                      onMenu();
                    }}
                    circle
                    noload
                    icon="more_vert"
                  />
                ) : null}
              </Stack>
            </Stack>
          </>
        )}
        {!focus && (
          <Stack
            spacing={1.5}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {passiveTools &&
              passiveTools(editor, insertInEditor).map(
                ({ icon, component, ...rest }) => {
                  if (!icon) return component;
                  return <IconButton {...rest}>{icon}</IconButton>;
                }
              )}
            {!disabled ? (
              <ActionButton
                noload
                icon="zoom_out_map"
                circle
                handler={() => focusHandler()}
              />
            ) : null}
          </Stack>
        )}
      </CodeToolbar>
    );
  }, [editor, focus, classes, disabled]);

  return (
    <>
      {focus && <FocusContainer />}
      <CodeContainer
        focus={focus}
        id={name}
        className={"ace-editor-container" + " " + classes.join(" ")}
        ref={editorRef}
      >
        <Toolbar className="ace-toolbar" />
        <AceEditor
          ref={aceEditorRef}
          commands={[
            {
              name: "find",
              bindKey: { win: "Ctrl-F", mac: "Command-F" },
              exec: () => {
                const e = require("ace-builds/src-noconflict/ext-searchbox");
                e.Search(aceEditorRef.current.editor, false);
              },
            },
          ]}
          mode="python"
          readOnly={false}
          {...(classes.includes("full-screen")
            ? { height: `${window.innerHeight - 100}px` }
            : focus
            ? { height: "80vh" }
            : { height: "70vh" })}
          width="100%"
          theme="dracula"
          onLoad={(e) => {
            setEditor(e);
          }}
          onChange={(v) => setValue(v)}
          value={value}
          // onFocus={focusHandler}
          fontSize={`${fontSize}px`}
          name={name}
          enableSnippets={true}
          enableLiveAutocompletion={true}
          enableBasicAutocompletion={true}
          wrapEnabled={true}
          setOptions={{
            enableBasicAutocompletion: true,
            enableSnippets: true,
            enableLiveAutocompletion: true,
          }}
          {...rest}
        />
      </CodeContainer>
    </>
  );
}
