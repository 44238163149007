import React, { useRef } from "react";
import { Button, Icon, Stack, IconButton } from "@mui/material";
import toast from "react-hot-toast";
export default function FileUpload({
  sizeInKb,
  children,
  file = null,
  handleFileChange = () => {},
  hideFileName,
  style = {},
  inputProps = {},
}) {
  const [refresh, setRefresh] = React.useState(1);
  const fileEl = useRef();
  window.fileEl = fileEl;
  const file_object = file || fileEl?.current?.files[0];

  const clickHandler = () => {
    if (fileEl?.current) fileEl.current.value = null;
    fileEl?.current?.click();
  };
  const fileSelectHandler = (e) => {
    const file = e.target.files[0];
    if (sizeInKb && file?.size > sizeInKb * 1024) {
      toast.error(`File size must be less than ${sizeInKb}KB`);
      return;
    }
    handleFileChange(file);
    setRefresh((x) => x + 1);
  };
  const removeFile = () => {
    handleFileChange("");
    setRefresh((x) => x + 1);
    if (fileEl?.current) fileEl.current.value = null;
  };
  return (
    <div style={style}>
      <input
        ref={fileEl}
        type="file"
        {...inputProps}
        onChange={fileSelectHandler}
        style={{ display: "none" }}
      />
      <Stack
        key={`btn${refresh}`}
        alignItems={"center"}
        spacing={1}
        direction="row"
      >
        <Button
          startIcon={<Icon style={{ color: "#fff" }}>cloud_upload</Icon>}
          onClick={clickHandler}
          sx={{ color: "#fff" }}
          variant="contained"
          // color="success"
        >
          {children}
        </Button>

        {file_object && <Icon color="success">check</Icon>}
      </Stack>
      {!hideFileName ? (
        <Stack
          key={`name${refresh}`}
          spacing={2}
          direction="row"
          alignItems="center"
        >
          {file_object ? <p>{file_object.name}</p> : <p>no file selected</p>}
          {file_object && (
            <IconButton color="error" onClick={removeFile}>
              <Icon color="error">clear</Icon>
            </IconButton>
          )}
        </Stack>
      ) : null}
    </div>
  );
}
