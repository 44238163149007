import React from "react";
import { Chip } from "@mui/material";

const Tag = ({ label }) => {
  return (
    <Chip
      label={label.toUpperCase()}
      sx={{
        backgroundColor: "#bb1fe2",
        color: "#fff",
        fontSize: "10px",
        letterSpacing: "2px",
        fontWeight: "bold",
        "&:hover": {
          backgroundColor: "#bb1fe2", // Slightly darker on hover
        },
      }}
    />
  );
};

export default Tag;
