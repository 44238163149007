import React from "react";
import { Stack, Typography, Grid, Avatar, Badge, Icon } from "@mui/material";
import { MiniLoader } from "common/components/Loader";
import AssetImage from "common/components/AssetImage";
import { PlatformIconMap } from "pages/Targets";
import useFetcher from "common/hooks/useFetcher";
export default function SelectPlatform({ platform, setPlatform, onClose }) {
  const fetcher = useFetcher();
  const [loading, setLoading] = React.useState(true);
  const [platforms, setPlatforms] = React.useState([]);
  const getPlatforms = async () => {
    setLoading(true);
    setPlatforms([]);
    await fetcher.get(
      "fetching platforms",
      `/api/platforms`,
      ({ platforms }) => {
        setPlatforms([
          { value: "aws", label: "Amazon Web Services" },
          {
            value: "gcp",
            label: "Google Cloud Platform",
          },
          {
            value: "azure",
            label: "Azure",
          },
          ...(platforms ?? []).map((p) => ({
            value: p.name,
            label: p.name,
          })),
        ]);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  React.useEffect(() => {
    getPlatforms();
  }, []);
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Stack>
          <Typography variant="h4">Select Platform</Typography>
        </Stack>
      </Stack>

      {loading ? (
        <div style={{ position: "relative" }}>
          <MiniLoader />
        </div>
      ) : platforms?.length ? (
        <Grid gap={1} container>
          {platforms.map((p) => {
            return (
              <Grid
                onClick={() => {
                  setPlatform(p.value);
                  onClose();
                }}
                sx={{ cursor: "pointer" }}
                className="floating"
                xs={12}
                item
              >
                <Badge
                  sx={{ width: "100%" }}
                  badgeContent={
                    platform === p?.value ? (
                      <Icon
                        sx={{
                          borderRadius: "15px",
                          backgroundColor: "#28ebc0",
                          color: "#262626",
                        }}
                      >
                        check
                      </Icon>
                    ) : null
                  }
                  color="primary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Stack
                    sx={{ width: "100%" }}
                    alignItems={"center"}
                    direction="row"
                    gap={1}
                  >
                    <Avatar
                      sx={{
                        width: 60,
                        height: 60,
                        marginRight: "10px",
                        background: "#262626",
                      }}
                    >
                      {React.createElement(
                        PlatformIconMap[p?.type || p?.value] ?? AssetImage,
                        {
                          ...{
                            style: { width: "60px" },
                            path: `/api/assets/instance/platforms/${p?.type ||
                              p?.value}/logo.png`,
                          },
                        }
                      )}
                    </Avatar>
                    <Stack>
                      <Typography variant="body1">{p?.label}</Typography>
                    </Stack>
                  </Stack>
                </Badge>
              </Grid>
            );
          })}
        </Grid>
      ) : null}
    </Stack>
  );
}
