import React from "react";
import fetcher from "common/utils/fetcher";
import toast from "react-hot-toast";
import { useAsyncError } from "./useAsyncError";
const useFetcher = () => {
  let { current: toaster } = React.useRef();
  const throwError = useAsyncError();
  return {
    get: async (msg, path, success, error) => {
      try {
        toast.dismiss();
        toaster = msg ? toast.loading(msg, { duration: Infinity }) : null;
        const res = await fetcher(path);
        toast.dismiss(toaster);
        if (!res) {
          return throwError("");
        }
        if (res.error) {
          toast.error(res.error);
          return error(res.error);
        }
        success(res, toast, error);
      } catch (e) {
        toast.error(e);
        throwError(e);
        return error(e);
      }
    },
    delete: async (msg, path, success, error) => {
      try {
        toast.dismiss();
        toaster = toast.loading(msg, { duration: Infinity });
        const res = await fetcher(path, { method: "delete" });
        toast.dismiss(toaster);
        if (!res) {
          return throwError("");
        }
        success(res, toast, error);
      } catch (e) {
        throwError(e);
        toast.error(e);
      }
    },
    post: async (msg, path, body, success, error) => {
      try {
        toast.dismiss();
        toaster = toast.loading(msg, { duration: Infinity });
        const res = await fetcher(path, {
          method: "post",
          body: typeof body === "function" ? body() : JSON.stringify(body),
        });
        toast.dismiss(toaster);
        if (!res) {
          return throwError("");
        }
        success(res, toast, error);
      } catch (e) {
        throwError(e);
        success(null, toast, e);
      }
    },
    put: async (msg, path, body, success, error) => {
      try {
        toast.dismiss();
        toaster = toast.loading(msg, { duration: Infinity });
        const res = await fetcher(path, {
          method: "put",
          body: typeof body === "function" ? body() : JSON.stringify(body),
        });
        toast.dismiss(toaster);
        if (!res) {
          return throwError("");
        }
        success(res, toast, error);
      } catch (e) {
        throwError(e);
        success(null, toast, e);
      }
    },
  };
};
export default useFetcher;
