import React from "react";
import {
  Stack,
  IconButton,
  Icon,
  Typography,
  Card,
  Button,
} from "@mui/material";
import ActionButton from "common/components/ActionButton";
import useState from "common/hooks/useState";
// import AwsTarget from "pages/Targets/components/AwsTarget";
import Modal from "common/components/Modal";
import Blurb from "constants/blurbs";
import Loader from "common/components/Loader";
import MultiSelect from "common/components/MultiSelect";
import DnDList from "common/components/DndList";
export default function CommandEnvironment({ data, setData }) {
  const [envs, setEnvs] = React.useState([]);
  const { variables, loading, refresh } = useState("/api/vars");
  const [variable, setVariable] = React.useState(null);
  React.useEffect(() => {
    if (!data) return;
    setEnvs(data.envs);
  }, [data]);
  const onSave = (close) => {
    if (!variable) return;
    setData({
      ...data,
      envs: [...envs, { id: variable.id, name: variable.name }],
    });
    setVariable(null);
    close();
  };
  const deleteEnv = (id) => {
    setData({ ...data, envs: envs.filter((i) => i.id !== id) });
  };
  return (
    <Stack m={0} gap={1} p={0}>
      <Stack sx={{ position: "relative" }} spacing={1}>
        <Modal
          onClose={() => {
            setVariable(null);
          }}
          button={({ onClick, ...props }) => (
            <IconButton
              sx={{ position: "absolute", right: 0 }}
              onClick={() => {
                refresh();
                onClick();
              }}
              {...props}
            >
              <Icon>add</Icon>
            </IconButton>
          )}
        >
          {({ handleClose }) =>
            loading ? (
              <Loader small />
            ) : (
              <Stack alignItems="start" spacing={3}>
                <Typography variant="h5">New Environment Variable</Typography>
                <MultiSelect
                  options={variables.filter(
                    (v) => !envs.find((e) => e.id === v.id)
                  )}
                  loading={loading}
                  getOptionLabel={(e) => `${e.name} (${e.pk})`}
                  label="Variables"
                  multiple={false}
                  placeholder="Select a variable"
                  value={variable}
                  setValue={setVariable}
                  sx={{ width: "100%" }}
                  size="small"
                />
                <div style={{ marginLeft: "auto" }}>
                  <ActionButton
                    handler={() => onSave(handleClose)}
                    noload
                    icon="save"
                  >
                    Save Variable
                  </ActionButton>
                </div>
              </Stack>
            )
          }
        </Modal>
        <Blurb>command_env</Blurb>
        {!envs.length ? (
          <Typography sx={{ margin: 0, paddingLeft: 1 }} variant="body1">
            -
          </Typography>
        ) : (
          <DnDList
            state={[envs, setEnvs]}
            itemComponent={({ item }) => (
              <Stack
                sx={{ width: "100%" }}
                ml={2}
                direction="row"
                alignItems="center"
                spacing={1}
              >
                <Stack>
                  <span style={{ marginRight: "10px", width: "100%" }}>
                    {item.name}
                  </span>
                  <Typography variant="caption" sx={{ color: "grey" }}>
                    ({item.id})
                  </Typography>
                </Stack>
                <IconButton
                  onClick={() => deleteEnv(item.id)}
                  color="error"
                  sx={{ margin: 0, ml: "auto !important" }}
                >
                  <Icon color="error">close</Icon>
                </IconButton>
              </Stack>
            )}
          />
        )}
      </Stack>
    </Stack>
  );
}
