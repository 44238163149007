import React from "react";
import { Stack, IconButton, Icon, Typography, Card } from "@mui/material";
import { useSearchParams } from "react-router-dom";
// import AwsTarget from "pages/Targets/components/AwsTarget";
import Input from "common/components/Input";
import Modal from "common/components/Modal";
import List from "common/components/List";
import Blurb from "constants/blurbs";
import { nanoid } from "nanoid";
import validate from "constants/validate";
import ActionButton from "common/components/ActionButton";
import SectionHeader from "common/components/SectionHeader";
// import PackageOrder from "pages/Action/components/Packages/PackageOrder";
export default function CommandPackages({
  isStaging,
  packages,
  setPackagesState,
}) {
  const [pkg, setPackage] = React.useState("");
  // const [packages, setPackages] = React.useState([]);
  const [edit, setEdit] = React.useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const existing = searchParams.get("id");

  // React.useEffect(() => {
  //   if (!data) return;
  //   setPackages(data.packages);
  // }, [data]);
  const onSave = (close) => {
    if (!validate("command_package_edit", { pkg })) return;
    if (edit?.id) {
      setPackagesState(
        packages.map((i) => {
          if (i.id !== edit?.id) return i;
          return { pkg, id: edit?.id };
        })
      );
    } else {
      setPackagesState([...packages, { id: nanoid(), pkg }]);
    }
    close();
  };
  const onEdit = (item) => {
    setPackage(item.pkg);
    setEdit(item);
  };
  const deletePackage = (id) => {
    setPackagesState(packages.filter((i) => i.id !== id));
  };
  return (
    <Card
      className="floating"
      sx={{ minHeight: 250, margin: 2, padding: 2, background: "#171717" }}
    >
      <Stack sx={{ position: "relative" }} spacing={1}>
        <Modal
          forceOpen={edit}
          onClose={() => {
            setPackage("");
            setEdit(false);
            // setSearchParams({});
          }}
          button={
            !isStaging
              ? null
              : (props) => (
                  <Stack
                    sx={{ position: "absolute", right: 0 }}
                    direction="row"
                    spacing={1}
                  >
                    <IconButton {...props}>
                      <Icon>add</Icon>
                    </IconButton>
                  </Stack>
                )
          }
        >
          {({ handleClose }) => (
            <Stack gap={2}>
              <SectionHeader icon="archive">
                {edit?.id ? "Update" : "New"} Package
              </SectionHeader>
              <Input
                sx={{ width: "100%" }}
                label="Package"
                value={pkg}
                size="small"
                placeholder="requests==2.0.0"
                onChange={(e) => setPackage(e.target.value)}
              />
              <div style={{ marginLeft: "auto" }}>
                <ActionButton
                  handler={() => onSave(handleClose)}
                  noload
                  icon="archive"
                >
                  Add Package
                </ActionButton>
              </div>
            </Stack>
          )}
        </Modal>
        <Typography variant="h5">Packages</Typography>
        <Blurb>command_pkg</Blurb>
        {!packages.length ? (
          <Typography sx={{ margin: 0, paddingLeft: 1 }} variant="body1">
            -
          </Typography>
        ) : (
          <List
            items={packages?.map((item) => ({
              text: (
                <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <span
                    style={{
                      marginRight: "10px",
                      width: "100%",
                    }}
                  >
                    {item.pkg}
                  </span>
                  {isStaging ? (
                    <Stack spacing={1} direction="row">
                      <IconButton
                        onClick={() => onEdit(item)}
                        color="primary"
                        sx={{ margin: 0, ml: "auto !important" }}
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                      <IconButton
                        onClick={() => deletePackage(item.id)}
                        color="error"
                        sx={{ margin: 0, ml: "auto !important" }}
                      >
                        <Icon color="error">close</Icon>
                      </IconButton>
                    </Stack>
                  ) : null}
                </Stack>
              ),
            }))}
          />
        )}
      </Stack>
    </Card>
  );
}
