import React from "react";
import { Stack, Typography, Icon, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CountUp from "react-countup";
import useFitText from "use-fit-text";
import { useNavigate } from "react-router-dom";
const Metric = ({
  name,
  description,
  count,
  text,
  link,
  textFont = 100,
  titleIcon = "pin",
}) => {
  const navigate = useNavigate();
  const { fontSize, ref } = useFitText({
    maxFontSize: 700.7142857142857,
    minFontSize: 100.7142857142857,
  });
  return (
    <Card sx={{ height: 280 }}>
      <CardHeader
        action={
          link ? (
            <IconButton
              onClick={() =>
                typeof link === "string" ? navigate(link) : link()
              }
            >
              <Icon>arrow_forward</Icon>
            </IconButton>
          ) : null
        }
        title={
          <Stack spacing={1}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Icon>{titleIcon}</Icon>{" "}
              <span style={{ marginLeft: "5px" }}>{name}</span>
            </Typography>
            <Typography variant="subtitle2">{description}</Typography>
          </Stack>
        }
      />

      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {text ? (
          <Typography
            style={{
              fontSize: textFont,
              width: "100%",
              textAlign: "center",
            }}
            variant="h3"
          >
            {text}
          </Typography>
        ) : (
          <CountUp start={0} end={count} duration={1.5} delay={0}>
            {({ countUpRef }) => (
              <Typography
                ref={countUpRef}
                style={{
                  fontSize,
                  width: "100%",
                  //   height: "100%",
                  textAlign: "center",
                }}
                variant="h1"
              />
            )}
          </CountUp>
        )}
      </CardContent>
    </Card>
  );
};
export default Metric;
