import React from "react";
import { TwitterPicker } from "react-color";
import "common/components/ColorPicker/styles.css";
import { Stack } from "@mui/material";
const ColorPicker = ({ color, setColor, children }) => {
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);

  const handleClick = () => setDisplayColorPicker(!displayColorPicker);
  const handleClose = () => setDisplayColorPicker(false);
  const handleChange = ({ hex }) => {
    setColor(hex);
    // handleClose();
  };
  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <div
        style={{
          padding: "3px",
          background: "#262626",
          borderRadius: "7px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <div
          style={{
            width: "36px",
            height: "36px",
            borderRadius: "7px",
            background: `${color}`,
          }}
        />
      </div>
      {displayColorPicker ? (
        <div
          style={{
            position: "absolute",
            zIndex: "2",
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onClick={handleClose}
          />
          <TwitterPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
      <div>{children}</div>
    </Stack>
  );
};

export const ColorBox = ({ color }) => (
  <div>
    <div
      style={{
        padding: "3px",
        background: "#262626",
        borderRadius: "7px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          width: "36px",
          height: "36px",
          borderRadius: "7px",
          background: `${color}`,
        }}
      />
    </div>
  </div>
);

export default ColorPicker;
