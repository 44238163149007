import React from "react";
import {
  Icon,
  Badge,
  Grid,
  Typography,
  Stack,
  IconButton,
  Box,
} from "@mui/material";
import Input from "common/components/Input";
import ActionButton from "common/components/ActionButton";
import { styled } from "@mui/material/styles";
import { theme } from "theme";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AnimatedGridItem = styled(Grid)(({ theme }) => ({
  cursor: "pointer",
  "&:hover .animated-icon": {
    transform: "rotateX(25deg)",
  },
  "&:hover .folder-text": {
    fontWeight: "bold !important",
  },
  "&:hover .MuiBadge-badge": {
    top: "10px",
  },
}));

const AnimatedIcon = styled(Icon)(({ theme }) => ({
  fontSize: "5rem !important",
  color: "grey !important",
  transition: "transform 0.3s ease-in-out",
  transformOrigin: "left bottom",
}));

const TagUpdateDialog = ({ title, open, onClose, tags, onUpdateTag }) => {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  React.useEffect(() => {
    if (!open) {
      setValue(null);
      setInputValue("");
    }
  }, [open]);
  const handleSubmit = () => {
    onUpdateTag(value || inputValue);
    onClose();
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          bgcolor: "#171717",
          border: "2px solid #000",
          boxShadow: 24,
          borderRadius: 3,
          p: 3,
        },
      }}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        Move {title}
        <IconButton
          aria-label="close"
          color="error"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon color="error" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Autocomplete
          freeSolo
          options={tags.map((t) => t[0])}
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
          renderInput={(params) => (
            <Input
              {...params}
              color="secondary"
              size="small"
              dark
              InputLabelProps={{ shrink: true }}
              label="Select a tag or enter a new one and press enter"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <ActionButton
          PaperComponent={({ children }) => (
            <Paper
              style={{ background: "#262626", color: "rgba(239,237,239,0.87)" }}
            >
              {children}
            </Paper>
          )}
          icon="move_up"
          noload
          handler={async () => {
            handleSubmit();
          }}
        >
          Change Tag
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

const useFolderView = () => {
  const [folder, setFolder] = React.useState(null);
  const [moveFolder, setMoveFolder] = React.useState(null);
  const filterFolders = (filtered, data) => {
    let tags = { default: 0 };

    filtered?.forEach((obj) => {
      tags["default"]++;
      obj.tags.forEach((tag) => {
        let t = tag.toLowerCase().trim();
        if (tags[t]) {
          tags[t]++;
        } else {
          tags[t] = 1;
        }
      });
    });
    tags = Object.entries(tags).sort((a, b) => {
      if (a[0] === "default") return -1;
      if (b[0] === "default") return 1;
      return a[0].localeCompare(b[0]);
    });
    let tag_options = { default: 0 };
    (data || [])?.forEach((obj) => {
      obj.tags.forEach((tag) => {
        let t = tag.toLowerCase().trim();
        if (t !== "default") {
          tag_options["default"]++;
        }
        if (tag_options[t]) {
          tag_options[t]++;
        } else {
          tag_options[t] = 1;
        }
      });
    });
    tag_options = Object.entries(tag_options).sort((a, b) => {
      if (a[0] === "default") return -1;
      if (b[0] === "default") return 1;
      return a[0].localeCompare(b[0]);
    });
    return [tags, tag_options];
  };
  const MoveFolderButton = ({ id }) => {
    return (
      <IconButton
        sx={{
          height: "40px",
          width: "40px",
          borderRadius: "20px",
          backgroundColor: "#a370f0",
        }}
        onClick={() => setMoveFolder(id)}
      >
        <Icon
          sx={{
            color: "#fff !important",
          }}
        >
          move_up
        </Icon>
      </IconButton>
    );
  };
  const FolderHeader = () => {
    return folder ? (
      <Typography
        style={{ display: "flex", alignItems: "center" }}
        variant="h4"
      >
        <IconButton onClick={() => setFolder(null)}>
          <Icon sx={{ color: "#fff" }}>arrow_back</Icon>
        </IconButton>
        <Icon sx={{ fontSize: "2rem !important", marginRight: "10px" }}>
          folder
        </Icon>
        {folder}
      </Typography>
    ) : null;
  };
  const FolderView = ({ tags }) => {
    return (
      <Grid container spacing={2}>
        {tags.map(([tag, value], index) => (
          <AnimatedGridItem
            onClick={() => setFolder(tag.toLowerCase())}
            p={2}
            item
            xs={6}
            sm={3}
            md={2}
            key={index}
          >
            <Box
              sx={{
                width: "100%",
                height: "200px", // Adjust the height as needed
                borderRadius: "8px",
                border: `1px solid ${theme.palette.primary.light}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
              }}
            >
              <Stack gap={1}>
                <Stack alignItems={"center"}>
                  <Badge
                    max={999}
                    badgeContent={value}
                    color="secondary"
                    class
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    sx={{
                      "& .MuiBadge-badge": {
                        transition: ".3s",
                        fontWeight: "bold",
                        top: "20px",
                        zIndex: "-1",
                      },
                    }}
                  >
                    <AnimatedIcon className="animated-icon">
                      folder
                    </AnimatedIcon>
                  </Badge>
                  <span className="folder-text">{tag?.toLowerCase()}</span>
                </Stack>
              </Stack>
            </Box>
          </AnimatedGridItem>
        ))}
      </Grid>
    );
  };
  return {
    FolderView,
    FolderHeader,
    setFolder,
    folder,
    TagUpdateDialog,
    moveFolder,
    setMoveFolder,
    MoveFolderButton,
    filterFolders,
  };
};

export default useFolderView;
