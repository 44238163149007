import Page from "common/components/Page";
import Logging from "pages/Archive/components/Logging";
import ActionButton from "common/components/ActionButton";
import useDownloadFile from "common/hooks/useDownloadFile";
export default function Archive({ role, crew_name }) {
  const generateUserLog = useDownloadFile({
    name: `e1_user_logs.csv`,
    notify: "getting user logs",
    endpoint: `/api/user_log`,
  });

  return (
    <Page
      header="Archives"
      actions={
        ["admin", "auditor"].includes(role) && crew_name === "administrators"
          ? [
              <ActionButton
                icon="download"
                handler={async () => {
                  await generateUserLog();
                }}
              >
                Download User Log
              </ActionButton>,
            ]
          : null
      }
    >
      <Logging />
    </Page>
  );
}
