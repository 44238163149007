import React from "react";
import { Stack, Chip, Box, useTheme, Typography } from "@mui/material";

const QuickTasks = ({ tasks, onTaskSelect }) => {
  const theme = useTheme();

  return (
    <Stack p={1}>
      <Typography sx={{ fontWeight: "bold" }} p={0} m={0} variant="overline">
        Quick Tasks
      </Typography>
      <Stack
        sx={{
          position: "sticky",
          bottom: 0,
          padding: 2,
          borderRadius: "16px 16px 0 0",
          backgroundColor: "transparent", //theme.palette.background.default,
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {tasks.map((task) => (
            <Chip
              key={task.id}
              label={task.label}
              onClick={() => onTaskSelect(task)}
              variant="outlined"
              sx={{
                "&:hover": {
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.primary.contrastText,
                },
                transition: "all 0.2s ease-in-out",
              }}
            />
          ))}
        </Box>
      </Stack>
    </Stack>
  );
};

export default QuickTasks;
