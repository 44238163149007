import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ActionButton from "common/components/ActionButton";
import useFetcher from "common/hooks/useFetcher";
import Input from "common/components/Input";
import Modal from "common/components/Modal";
import validate from "constants/validate";
export default function SystemCopy({ open, data, onClose }) {
  const [name, setName] = React.useState("");
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const saveSystem = async (close) => {
    const payload = { ...data, name, copy: data.id };
    if (!validate("system_copy_edit", { name })) return;
    delete payload.id;
    await fetcher.post(
      "saving system",
      `/api/sys`,
      payload,
      ({ id, error }) => {
        if (error) return toast.error(error);
        onClose();
        navigate(`/app/sys/${id}/dash`);
      },
      () => {}
    );
  };
  return (
    <Modal
      forceOpen={open}
      onClose={() => {
        setName("");
        onClose();
        // close();
      }}
    >
      {({ handleClose }) => (
        <Stack spacing={3}>
          <Typography variant="h5">Copy System</Typography>
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div style={{ marginLeft: "auto" }}>
            <ActionButton
              noload
              handler={() => {
                saveSystem(handleClose);
              }}
              icon="content_copy"
            >
              Copy System
            </ActionButton>
          </div>
        </Stack>
      )}
    </Modal>
  );
}
