import * as React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "serviceWorker";
import "theme/global.css";
import { BrowserRouter, Navigate } from "react-router-dom";
import App from "app";

const NavigateWrapper = ({ children }) => {
  if (window.location.pathname !== "/login")
    return <Navigate to="/login" replace={true} />;
  else return null;
};
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  resetErrorState() {
    this.setState({ hasError: false });
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.hasError !== this.state.hasError && this.state.hasError) {
      this.resetErrorState();
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <NavigateWrapper>{this.props.children}</NavigateWrapper>;
    }

    return this.props.children;
  }
}
ReactDOM.render(
  <React.Fragment>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.Fragment>,
  document.getElementById("root")
);

serviceWorker.unregister();
