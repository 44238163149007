import React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  // Checkbox,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import Checkbox from "../Checkbox";
export function CheckboxList({
  items,
  title,
  checkedItems,
  getItemLabel,
  getItemValue,
  onChange,
  maxHeight = 600,
}) {
  const theme = useTheme();
  const handleToggle = (item) => () => {
    const currentIndex = checkedItems.findIndex(
      (i) => getItemValue(i) === getItemValue(item)
    );
    const newChecked = [...checkedItems];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    onChange(newChecked);
  };

  return (
    <Paper
      sx={{
        overflow: "auto",
        borderRadius: "8px",
        bgcolor: theme.palette.primary.dark,
        position: "relative",
        overflow: "visible",
      }}
    >
      <Typography
        sx={{
          fontSize: ".8rem !important",
          paddingLeft: "15px",
          position: "relative",
          top: "-9.6px",
        }}
      >
        {title}
      </Typography>
      <List sx={{ overflow: "scroll", maxHeight, padding: 0 }}>
        {items.map((item, index) => {
          const labelId = `checkbox-list-label-${index}`;
          const isChecked = checkedItems.some(
            (i) => getItemValue(i) === getItemValue(item)
          );

          return (
            <ListItem
              sx={{ backgroundColor: theme.palette.primary.dark }}
              key={getItemValue(item)}
              disablePadding
            >
              <ListItemButton onClick={handleToggle(item)} dense>
                <ListItemIcon>
                  <Checkbox
                    value={isChecked}
                    disabled
                    onChange={() => {}}
                    id={labelId}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={getItemLabel(item)} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
}
