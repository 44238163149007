import React, { useState, useCallback, useMemo, useEffect } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/base.css";
import "common/components/Table/styles.css";
import Pagination from "@mui/material/Pagination";
import StringFilter from "@inovua/reactdatagrid-community/StringFilter";
import styled from "styled-components";
import findArrayMode from "common/components/Table/findArrayMode";
import useInputForm from "common/hooks/useInputForm";
import { Stack, Box } from "@mui/material";
import Input from "common/components/Input";
import IconButton from "common/components/IconButton";
import { NavOpenContext } from "layouts/default";

const Container = styled.div`
  .InovuaReactDataGrid__virtual-list.inovua-react-scroll-container {
  }
  .InovuaReactDataGrid {
    // width: ${({ width }) => `${width}px`};
    width: 100%;
    ${({ noHeightGrow }) => !noHeightGrow && `margin-bottom: 100px;`};
  }
  .InovuaReactDataGrid__header {
  }
  .InovuaReactDataGrid__cell__content {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    -ms-user-select: all !important;
    user-select: all !important;
  }
`;

export default function Table({
  pages = 1,
  page = 1,
  data,
  onPaginate,
  idProperty,
  cols,
  width = "85vw",
  height = "100%",
  columnDefaultWidth = 200,
  searchProps,
  expand,
  noHeightGrow,
  ...rest
}) {
  const { getValues, generate, getValue, setValue } = useInputForm();
  const [activateRowOnFocus, setActivateRowOnFocus] = useState(false);
  const [parentContainerWidth, setParentContainerWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(height || "100%");
  const navOpen = React.useContext(NavOpenContext);
  const ctn = React.useRef();
  const paginate = (v, page) => {
    onPaginate(page);
    let { top } = ctn?.current?.getBoundingClientRect();
    window.scrollTo(0, window.scrollY + top);
  };

  useEffect(() => {
    if (expand) {
      setParentContainerWidth(ctn.current.offsetParent.offsetWidth - 100);
      if (!noHeightGrow) {
        setTableHeight(
          data.length * 66 > 500 ? data.length * 66 + 20 : "500px"
        );
      }
    }
  }, [ctn, data]);
  let columns = React.useMemo(() => {
    let columnWidths = cols.map((c) => c.defaultWidth || 200);
    if (parentContainerWidth > columnWidths.reduce((a, c) => a + c, 0)) {
      cols = cols.map((column) => {
        let percentageOfTotalColumnWidth =
          (column.defaultWidth || 200) /
          columnWidths.reduce((a, c) => a + c, 0);
        column.defaultWidth =
          percentageOfTotalColumnWidth * parentContainerWidth;
        return column;
      });
    }
    return cols.map((c) => {
      const opts = {};
      if (data?.length) {
        opts.type = findArrayMode(
          data
            .slice(0, 10)
            .map((i) => (Number(i[c.name]) ? "number" : typeof i[c.name]))
        );
      }
      return { ...c, ...opts };
    });
  }, [cols, idProperty, data, parentContainerWidth]);
  let computedWidth = columns.reduce((a, c) => a + (c?.defaultWidth || 200), 0);
  let adjustForNav = navOpen ? 80 : 85;
  computedWidth =
    (100 * computedWidth) / window.innerWidth >= adjustForNav
      ? (window.innerWidth * adjustForNav) / 100
      : computedWidth + 100;

  const renderPaginationToolbar = (paginationProps) => {
    return (
      <div
        style={{
          height: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: `${computedWidth}px`,
        }}
      >
        <Pagination
          onChange={paginate}
          showFirstButton
          showLastButton
          color="secondary"
          page={page}
          count={pages}
          variant="outlined"
        />
      </div>
    );
  };
  const paginateProps =
    pages > 1
      ? {
          pagination: "remote",
          renderPaginationToolbar,
        }
      : {};
  const computedHeight = ((data?.length || 0) + 3) * 60;
  // const width = !computedWidth ? "100%" : `${computedWidth + 50}px`;
  // const width = width;
  const gridStyle = {
    minHeight:
      computedHeight > 500 ? 500 : computedHeight < 300 ? 300 : computedHeight,
    height: tableHeight,
    marginTop: 10,
  };

  return (
    <Container ref={ctn} noHeightGrow={noHeightGrow} width={computedWidth}>
      {searchProps ? (
        <Stack
          sx={{ width: computedWidth }}
          direction="row"
          spacing={2}
          alignItems="center"
        >
          {!searchProps?.query ? (
            <Box
              as="form"
              sx={{ width: computedWidth }}
              direction="row"
              display="flex"
              alignItems="center"
              onSubmit={(e) => {
                e.preventDefault();
                searchProps?.onSearch(getValue("search_form"));
              }}
            >
              {generate({
                el: Input,
                key: "search_form",
                props: {
                  label: "Search...",
                  sx: { width: "99%", minWidth: "300px" },
                  defaultValue: "",
                },
              })}
              <IconButton type="submit">search</IconButton>
            </Box>
          ) : (
            <>
              <span>Search Query: {searchProps?.query}</span>
              <IconButton color="error" onClick={searchProps?.onClearSearch}>
                close
              </IconButton>
            </>
          )}
        </Stack>
      ) : null}
      <ReactDataGrid
        checkboxOnlyRowSelect={true}
        idProperty={idProperty}
        style={gridStyle}
        activateRowOnFocus={false}
        columns={columns}
        dataSource={data}
        theme="default-dark"
        showHoverRows={false}
        rowHeight={60}
        columnDefaultWidth={columnDefaultWidth}
        {...paginateProps}
        {...rest}
        showColumnMenuTool={false}
      />
    </Container>
  );
}
