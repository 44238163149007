import React from "react";
import { Stack, Typography, Icon } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import useFitText from "use-fit-text";
const TextNode = ({ name, description, image }) => {
  // const { fontSize, ref } = useFitText({
  //   maxFontSize: 800.7142857142857,
  //   minFontSize: 125.7142857142857,
  // });
  return (
    <Card
      className={image ? "overlay" : ""}
      sx={{
        height: 280,
        background: image ? `url(${image}) no-repeat center center` : "",
        backgroundSize: "cover",
        overflow: "none",
      }}
    >
      <CardHeader
        sx={{ position: "relative", zIndex: "40" }}
        title={
          <Stack spacing={1}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Icon>format_size</Icon>{" "}
              <span
                style={{
                  marginLeft: "5px",
                  textShadow: "0 1px 0 black",
                  color: "#fff",
                }}
              >
                {name}
              </span>
            </Typography>
          </Stack>
        }
      />

      <CardContent
        sx={{
          position: "relative",
          zIndex: "40",
          height: "185px",
          overflowY: "auto",
        }}
      >
        <Typography
          sx={{
            whiteSpace: "pre-line",
            textShadow: "0 1px 0 black",
            color: "#fff",
          }}
          component="p"
          variant="subtitle1"
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};
export default TextNode;
