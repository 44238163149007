import { useRef, useEffect } from "react";

export default function useClickOutside(callback) {
  const ref = useRef();
  const clickOutside = (event) => {
    if (!ref?.current) return;
    const isClickInside = ref.current.contains(event.target);
    if (!isClickInside) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, [ref]);
  return ref;
}
