import { authFetch, logout } from "auth";
import toast from "react-hot-toast";
const fetcher = (...rest) => {
  return authFetch(...rest)
    .then(async (res) => {
      if (res.status === 401) {
        logout();
        return null;
      } else if (res.status === 413) {
        return [413, { error: "Request too large" }];
      } else if (res.status === 403) {
        toast.error("Unauthorized Access!");
        logout();
      } else if ([500, 502].includes(res.status)) {
        toast.error("Server Error. Please submit a support ticket.");
        logout();
      } else {
        const data = await res.json();
        return [res.status, data];
      }
    })
    .then(([status, data]) => {
      //  if (status === 400) {
      //    toast.error(data?.error || "Don't Panic! Something went wrong.", {
      //      duration: 5000,
      //    });
      //    return;
      //  }
      return data;
    })
    .catch((e) => {
      // logout();
    });
};
export default fetcher;
