import React from "react";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Chart } from "react-google-charts";
import { useNavigate } from "react-router-dom";

const ChartType = {
  Bar: "ColumnChart",
  Pie: "PieChart",
};

export const Size = {
  reg: {
    scatter: {
      containerHeight: "280px",
      canvasHeight: "240px",
      canvasStyle: {
        position: "absolute",
        top: "50%",
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    pie: {
      containerHeight: "280px",
      canvasHeight: "280px",
      canvasStyle: {
        position: "absolute",
        top: "30%",
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    bar: {
      containerHeight: "280px",
      canvasHeight: "180px",
      canvasStyle: {
        position: "absolute",
        top: "60%",
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    timeseries: {
      containerHeight: "280px",
      canvasHeight: "180px",
      canvasStyle: {
        position: "absolute",
        top: "60%",
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
  },
  lg: {
    scatter: {
      containerHeight: "450px",
      canvasHeight: "180px",
      canvasStyle: {},
    },
    pie: {
      containerHeight: "450px",
      canvasHeight: "380px",
      canvasStyle: {},
    },
    bar: {
      containerHeight: "450px",
      canvasHeight: "380px",
      canvasStyle: {
        overflow: "",
      },
    },
    timeseries: {
      containerHeight: "450px",
      canvasHeight: "380px",
      canvasStyle: {
        overflow: "",
      },
    },
  },
};

export function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export let colors = [
  "#cc6666",
  "#cc8f66",
  "#ccb866",
  "#b8cc66",
  "#8fcc66",
  "#66cc66",
  "#66cc8f",
  "#66ccb8",
  "#66b8cc",
  "#668fcc",
  "#6666cc",
  "#8f66cc",
  "#b866cc",
  "#cc66b8",
  "#cc668f",
];
shuffleArray(colors);

export class ColorGenerator {
  constructor(colors) {
    this.original = colors;
    this.colors = [...this.original];
  }
  reset() {
    this.colors = [...this.original];
  }
  get availableColors() {
    return this.colors.length;
  }
  get colorArray() {
    return this.original;
  }
  getRandomColor() {
    return this.colors.splice(0, 1)[0];
  }
  generate() {
    if (this.availableColors === 0) {
      this.reset();
    }
    return this.getRandomColor();
  }
}

const Graph = ({
  name,
  link,
  description,
  property,
  yProperty,
  data,
  type,
  size = "reg",
}) => {
  const navigate = useNavigate();
  const palette = React.useMemo(() => new ColorGenerator(colors), []);
  const coloredData = React.useMemo(() => {
    if (type === "Scatter") return [];
    palette.reset();
    return [
      [property, "count", { role: "style" }],
      ...data.map((d, i) => [...d, palette.generate()]),
    ];
  }, [data]);
  palette.reset();
  if (!type === "Scatter" && !Size[size][type?.toLowerCase()]) return null;
  return (
    <Card
      sx={{
        height: Size[size][type.toLowerCase()]?.containerHeight || "280px",
        position: "relative",
        overflow: "visible",
      }}
    >
      {property ? (
        <CardContent sx={{ position: "relative" }}>
          <Stack
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Icon>{type === "Pie" ? "pie_chart" : "insert_chart"}</Icon>{" "}
              <span style={{ marginLeft: "5px" }}>{name}</span>
            </Typography>
            {link ? (
              <IconButton
                style={{ position: "relative", zIndex: 100, cursor: "pointer" }}
                onClick={() =>
                  typeof link === "string" ? navigate(link) : link()
                }
              >
                <Icon>arrow_forward</Icon>
              </IconButton>
            ) : null}
          </Stack>
          <Typography
            gutterBottom
            variant="subtitle2"
            component="div"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <span style={{ marginLeft: "5px" }}>{description}</span>
          </Typography>
          <div style={Size[size][type.toLowerCase()].canvasStyle}>
            {type === "Scatter" ? (
              <Chart
                chartVersion="50" // Explicitly set version to 50
                chartType="ScatterChart"
                options={{
                  legend: { position: "none" },
                  vAxis: {
                    baselineColor: "transparent",
                    gridlines: {
                      color: "transparent",
                    },
                    textStyle: {
                      color: "transparent",
                    },
                    textPosition: "none",
                  },
                  hAxis: {
                    baselineColor: "transparent",
                    gridlines: {
                      color: "transparent",
                    },
                    textStyle: {
                      color: "transparent",
                    },
                    minValue: 0,
                  },
                  colors: colors,
                  animation: {
                    startup: true,
                    easing: "out",
                    duration: 1000,
                  },
                  backgroundColor: "transparent",
                }}
                height={Size[size][type.toLowerCase()].canvasHeight}
                data={[
                  [property, yProperty, { role: "tooltip" }, { role: "style" }],
                  ...data.map((d, i) => [
                    ...d,
                    `point {size: 5 ;fill-color: ${palette.generate()}`,
                  ]),
                ]}
              />
            ) : (
              <Chart
                chartVersion="50" // Explicitly set version to 50
                chartType={ChartType[type]}
                className="graph"
                chartEvents={
                  [
                    // {
                    //   eventName: "ready",
                    //   callback: ({ chartWrapper, google }) => {
                    //     const chart = chartWrapper.getChart();
                    //     google.visualization.events.addListener(
                    //       chart,
                    //       "select",
                    //       (e) => {
                    //         try {
                    //           let item = chart.getSelection()[0];
                    //           navigate(
                    //             `/app/systems/${resourceCount[item?.row]?.name}`
                    //           );
                    //         } catch (e) {}
                    //       }
                    //     );
                    //   },
                    // },
                  ]
                }
                options={{
                  legend: { position: "none" },
                  colors: palette.colorArray,
                  animation: {
                    startup: true,
                    easing: "out",
                    duration: 1000,
                  },
                  backgroundColor: {
                    fill: "transparent",
                  },
                  annotations: {
                    textStyle: {
                      color: "#fff",
                    },
                  },
                  hAxis: {
                    textStyle: {
                      color: "#ffffff",
                    },
                  },
                  vAxis: {
                    textStyle: {
                      color: "#ffffff",
                    },
                  },
                  titleTextStyle: {
                    color: "#ffffff",
                  },
                }}
                data={coloredData}
                width="100%"
                height={Size[size][type.toLowerCase()].canvasHeight}
              />
            )}
          </div>
        </CardContent>
      ) : null}
    </Card>
  );
};

export default Graph;
