import React from "react";
import Page from "common/components/Page";
import {
  Stack,
  Avatar,
  IconButton,
  Icon,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import MultiSelect from "common/components/MultiSelect";
import ActionButton from "common/components/ActionButton";
import SidePanel from "common/components/SidePanel";
import Input from "common/components/Input";
import useState from "common/hooks/useState";
import useFetcher from "common/hooks/useFetcher";
import { TypeMap } from "constants";
import useConfirm from "common/hooks/useConfirm";
import useFolderView from "common/hooks/useFolderView";
import CreatePackage from "./components/CreatePackage";
import { useNavigate } from "react-router-dom";
const Tile = ({ pkg, moveFolderButton: MoveFolderButton, refresh }) => {
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const deletePackage = async (id) => {
    await fetcher.delete("deleting package", `/api/pkg/${id}`, () => {
      refresh();
    });
  };

  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete Package`,
    message: `Are you sure you want to delete ${pkg?.name} package? This action cannot be undone.`,
    callback: () => {
      deletePackage(pkg?.name);
    },
  });
  return (
    <Grid
      md={2.9}
      sx={{
        flex: 1,
        position: "relative",
      }}
      className="user-card-title-ctn"
      item
    >
      <DeleteModal />
      <div
        style={{ width: "100%", flex: 1 }}
        className="floating user-card-tile"
      >
        <Stack flex={1} gap={1} direction="column">
          <Stack direction="row" gap={1} alignItems={"center"}>
            <Stack>
              <span className="user-card-tile__name">{pkg.name}</span>
              <Typography variant="caption">latest {pkg.latest}</Typography>
            </Stack>
          </Stack>
          <Typography variant="body2">{pkg.description}</Typography>
          <Stack sx={{ marginLeft: "auto" }}>
            {pkg.tags.length ? (
              <Typography
                sx={{ height: "20px", fontWeight: "bold" }}
                variant="body2"
              >
                {pkg.tags.join(", ")}
              </Typography>
            ) : (
              <Typography
                sx={{ height: "20px", fontWeight: "bold" }}
                variant="body2"
              ></Typography>
            )}
          </Stack>
        </Stack>
      </div>
      <div className="user-card-tile__options">
        <IconButton
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            backgroundColor: "#ff711a",
          }}
          onClick={() => navigate(`/app/packages/${pkg.name}`)}
        >
          <Icon
            sx={{
              color: "#fff !important",
            }}
          >
            tune
          </Icon>
        </IconButton>
        <MoveFolderButton id={pkg.pk} />
        <IconButton
          color="error"
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            backgroundColor: "#FF6961",
          }}
          onClick={() => {
            openDeleteModal(true);
          }}
        >
          <Icon
            sx={{
              color: "#fff !important",
            }}
          >
            close
          </Icon>
        </IconButton>
      </div>
    </Grid>
  );
};

export default function Packages({ role }) {
  const { packages, loading, error, refresh } = useState("/api/pkg");
  // const [variableModal, setVariableModal] = React.useState(false);
  const [addPackage, setAddPackage] = React.useState(false);
  // const [editReset, setEditReset] = React.useState(1);
  // const [init, setInit] = React.useState(null);
  const [filter, setFilter] = React.useState("");
  const fetcher = useFetcher();
  const [search, setSearch] = React.useState("");
  const [tag, setTag] = React.useState("");
  const [crewSearch, setCrewSearch] = React.useState("");
  const {
    FolderView,
    FolderHeader,
    folder,
    setFolder,
    TagUpdateDialog,
    moveFolder,
    setMoveFolder,
    MoveFolderButton,
    filterFolders,
  } = useFolderView();
  // const deleteVariable = async (id) => {
  //   await fetcher.delete("deleting variable", `/api/vars/${id}`, () => {
  //     refresh();
  //   });
  // };
  // const onUpdateTag = async (tag) => {
  //   await fetcher.post(
  //     "updating variable tags",
  //     `/api/vars/${moveFolder}/tag`,
  //     { oldTag: folder, newTag: tag },
  //     (data) => {
  //       if (data?.error) return toast.error(data?.error);
  //       refresh();
  //     },
  //     () => {}
  //   );
  // };
  const onUpdateTag = async (tag) => {
    await fetcher.post(
      "updating package tags",
      `/api/pkg/${moveFolder}/tag`,
      { oldTag: folder, newTag: tag },
      (data) => {
        if (data?.error) return toast.error(data?.error);
        refresh();
      },
      () => {}
    );
  };
  const searchFilter = () => {
    if (!packages) return [];
    if (!search && !tag && !crewSearch && !folder) return packages;
    let filter = [...packages];
    if (crewSearch) {
      filter = filter.filter((v) => v.owner_name === crewSearch);
    }
    if (tag) {
      filter = filter.filter(({ tags }) => tags.includes(tag));
    }
    if (search) {
      filter = filter.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (folder) {
      if (folder === "default") return filter;
      filter = filter.filter(({ name, tags }) =>
        (tags || [])?.map((t) => t.toLowerCase())?.includes(folder)
      );
    }
    return filter;
  };
  let filteredPackages = searchFilter();
  let [tags, tag_options] = filterFolders(filteredPackages, packages);
  return (
    <Page
      header="Packages"
      actions={[
        <ActionButton
          icon="add"
          noload
          handler={async () => {
            setAddPackage(true);
          }}
        >
          Add Package
        </ActionButton>,
      ]}
      loading={[loading]}
      refresh={refresh}
    >
      <TagUpdateDialog
        title="Package"
        onUpdateTag={onUpdateTag}
        tags={tag_options}
        open={!!moveFolder}
        onClose={() => setMoveFolder(null)}
      />
      <SidePanel
        width="500px"
        open={addPackage}
        closeDrawer={() => setAddPackage(false)}
        props={{}}
      >
        {CreatePackage}
      </SidePanel>
      <Stack p={2} direction={"row"}>
        <FolderHeader />
        <Stack
          sx={{ marginLeft: "auto", justifyContent: "flex-end" }}
          gap={1}
          direction={"row"}
        >
          <div style={{ marginBottom: "20px" }}>
            <Input
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")} edge="end">
                      {search ? (
                        <Icon
                          sx={{ fontSize: "18px !important" }}
                          color="error"
                        >
                          close
                        </Icon>
                      ) : null}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="search..."
              style={{ marginLeft: "auto", width: "300px" }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <MultiSelect
            size="small"
            options={Array.from(
              new Set(
                packages?.reduce(
                  (a, t) => [...a, ...(t?.owner_name ? [t?.owner_name] : [])],
                  []
                )
              )
            )}
            loading={loading}
            label="Crew Filter"
            multiple={false}
            placeholder="Select a crew"
            value={crewSearch}
            setValue={setCrewSearch}
            sx={{ width: "200px" }}
          />
          <MultiSelect
            size="small"
            options={Array.from(
              new Set(packages?.reduce((a, t) => [...a, ...t.tags], []))
            )}
            loading={loading}
            label="Tag Filter"
            multiple={false}
            placeholder="Select a tag"
            value={tag}
            setValue={setTag}
            sx={{ width: "200px" }}
          />
        </Stack>
      </Stack>
      {!folder ? (
        <FolderView tags={tags} />
      ) : (
        <Stack spacing={2}>
          <Stack alignItems="start" spacing={2}>
            {filteredPackages.length ? (
              <Grid gap={1} container>
                {filteredPackages?.map((data, idx) => {
                  return (
                    <Tile
                      refresh={refresh}
                      moveFolderButton={MoveFolderButton}
                      role={role}
                      pkg={data}
                      key={data.pk}
                    />
                  );
                })}
              </Grid>
            ) : null}
          </Stack>
        </Stack>
      )}
    </Page>
  );
}
