import React, { useState } from "react";
import { Typography, Menu, MenuItem, Icon } from "@mui/material";

const ClickableTypography = ({ onClick, children, options, ...rest }) => {
  // State for managing menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Handle opening the menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle menu item selection
  const handleMenuItemClick = (option) => {
    onClick(option);
    handleClose();
  };

  return (
    <div>
      <Typography
        onClick={handleClick}
        sx={{
          userSelect: "none",
          cursor: "pointer",
          "&:hover": {
            color: options.length ? "text.secondary" : "text",
          },
        }}
        {...rest}
      >
        {children} {options.length ? <Icon>arrow_drop_down</Icon> : null}
      </Typography>

      {options.length ? (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {options.map((o, index) => {
            return (
              <MenuItem key={index} onClick={() => handleMenuItemClick(o)}>
                {o}
              </MenuItem>
            );
          })}
        </Menu>
      ) : null}
    </div>
  );
};

export default ClickableTypography;
