// Mode is the value which occurs most frequently in a set of observations.
const findArrayMode = (arr) => {
    return arr
      .sort(
        (a, b) =>
          arr.filter((v) => v === a).length - arr.filter((v) => v === b).length
      )
      .pop();
  };
  
  export default findArrayMode;
  