import toast from "react-hot-toast";

//regexs
const regex = {
  color: /^#[\w\d]{6}$/i,
  resourcename: /^(?!.*\.\.)(?!.*\.$)[^\W][\w\s.]{2,50}$/i,
  variablename: /^[^\W0-9]\w*$/i,
  property: /^(?!.*\.\.)(?!.*\.$)[^\W][\w\s.]{1,29}$/i,
  username: /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{5,29}$/i,
  variable: /^(?!.*\.\.)(?!.*\.$)[^\W]{5,29}$/i,
  message: /[\w\d\s\.\'\,\-\!\@\#\$\&\*\`\~\[\]\?\<\>\"\:\;\\\/\{\}\|\%\^\(\)\+\=]{4,136}/,
  password: new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  ),
  code: /(def\shandler\(context\)\:)/,
  package: /.{1,}/,
  email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
};

const isValidated = (values) => {
  const r = values.find((x) => x[1] === false);
  if (r === undefined) return true;
  toast.error(r[0]);
  return false;
};

const VALIDATIONS = {
  crew_edit: ({ color = "", name = "", description = "" }) => [
    ["select a brand color", regex.color.test(color)],
    ["enter a valid name", regex.resourcename.test(name)],
    ["enter a valid description", regex.message.test(description)],
  ],
  user_edit: ({ id, name = "", password = "", sso }) => [
    ["enter a valid email", id || regex.email.test(name)],
    [
      "Password must be a minimum of eight characters, at least one letter, one number and one special character",
      (id || sso) && !password ? true : regex.password.test(password),
    ],
  ],
  target_edit: ({ geos = [], tenants = [], name = "", type }) => [
    ["enter a valid name", regex.username.test(name)],
    // ["must select at least 1 tenant", tenants.length > 0],
    // ["must select at least 1 geo", geos.length > 0],
  ],
  add_platform: ({ name, imageFile, description, platformConfig }) => {
    let passConfig = false;
    try {
      let config = JSON.parse(platformConfig);
      if (
        typeof config === "object" &&
        !Array.isArray(config) &&
        Object.keys(config)?.length > 0
      ) {
        passConfig = true;
      }
    } catch {}
    return [
      [
        "enter a valid name",
        name.length >= 3 && name.length < 15 && /^[a-z_]*$/.test(name),
      ],
      ["enter a valid image file", !!imageFile],
      ["enter a valid description", description.length >= 10],
      ["enter a valid JSON example", passConfig],
    ];
  },
  variable_edit: ({ description = "", name = "", value, type }) => [
    ["enter a valid name", regex.variable.test(name)],
    ["enter a valid description", regex.message.test(description)],
    ["enter a valid value", !!value],
    ["enter a valid type", !!type],
  ],
  command_edit: ({ description = "", name = "", code = "" }) => [
    ["enter a valid name", regex.resourcename.test(name)],
    ["enter a valid description", regex.message.test(description)],
    ["check your handler function", regex.code.test(code)],
  ],
  command_input_edit: ({ help = "", name = "" }) => [
    ["enter a valid name", regex.variablename.test(name)],
    ["enter a help string", regex.message.test(help)],
  ],
  command_package_edit: ({ pkg }) => [
    ["enter a valid package", regex.package.test(pkg)],
  ],
  command_local_package_edit: ({ pkg, source }) => [
    [
      "enter a valid package",
      /^[a-z_0-9]+==([1-9]\d*|0)$/.test(pkg) && Number(source) === 1,
    ],
  ],
  command_output_edit: ({ name = "" }) => [
    ["enter a valid output name", regex.resourcename.test(name)],
  ],
  command_target_edit: ({ enabled, select, target }) => [
    [
      "pick a target or the select toggle",
      (enabled && (select || target)) || !enabled,
    ],
  ],
  command_restore: ({ selection }) => [["pick a restore point", selection]],
  command_copy_edit: ({ name }) => [
    ["enter a valid name", regex.resourcename.test(name)],
  ],
  system_install: ({ name, envs }) => [
    ["enter a valid name", regex.resourcename.test(name)],
    //ensure the each env in envs is not falsey
    [
      "enter valid environment variable values",
      Object.values(envs).every((e) => !!e),
    ],
  ],
  system_copy_edit: ({ name }) => [
    ["enter a valid name", regex.resourcename.test(name)],
  ],
  system_general_edit: ({ name, description, properties, uid, plugins }) => [
    ["enter a valid name", regex.resourcename.test(name)],
    ["enter a valid description", regex.message.test(description)],
    [
      "must have at least one property defined",
      !plugins.data || properties.length > 0,
    ],
    [
      "a primary key must be defined",
      !plugins.data || (!!uid && !!properties.find((p) => p.name === uid)),
    ],
  ],
  system_property_edit: ({ name }) => [
    ["enter a valid name", regex.property.test(name)],
  ],
  system_color_edit: ({ color, expression }) => [
    ["select a brand color", regex.color.test(color)],
    ["enter a valid expression", regex.message.test(expression)],
  ],
  system_detector_edit: ({ icon, expression, name, description }) => [
    ["select an icon", regex.package.test(icon)],
    ["enter a valid name", regex.resourcename.test(name)],
    ["enter a valid description", regex.message.test(description)],
    ["enter a valid expression", regex.message.test(expression)],
  ],
  system_action_edit: ({ icon, name, description, type, output, command }) => [
    ["select an icon", regex.package.test(icon)],
    ["enter a valid name", regex.resourcename.test(name)],
    ["enter a valid description", regex.message.test(description)],
    ["select a command", !(["external", "batch"].includes(type) && !command)],
    ["select a command output", !(type === "batch" && !output)],
  ],
  system_graph_edit: ({
    id,
    name,
    description,
    type,
    property,
    yProperty,
    label,
    metric,
  }) => [
    ["enter a valid name", regex.resourcename.test(name)],
    ["enter a valid description", type === "Text" || description.length <= 136],
    ["select a type", type ? true : false],
    ["select a metric type", type === "Metric" && !metric ? false : true],
    ["select a property", type === "Text" || property ? true : false],
    ["select a Y property", type === "Scatter" && !yProperty ? false : true],
    ["select a label", type === "Scatter" && !label ? false : true],
  ],
  system_trigger_edit: ({ name, description, detector, action }) => [
    ["enter a valid name", regex.resourcename.test(name)],
    ["enter a valid description", regex.message.test(description)],
    ["select a detector", !!detector],
    ["select an action", !!action],
  ],
  system_discover_edit: ({ discover, cmd, output, schedule, cron }) => [
    ["select a command", discover ? !!cmd : true],
    ["select an output", !!output],
    ["enter a valid cron", schedule ? !!cron : true],
  ],
  nexus_general_edit: ({ name, tiles }) => [
    ["enter a valid name", regex.resourcename.test(name)],
    ["must create at least one tile", !!tiles.length],
  ],
  shareable_url_edit: ({ name }) => [
    ["enter a valid name", regex.resourcename.test(name)],
  ],
};

const validate = (type, data) => {
  //return true for validated
  return isValidated(VALIDATIONS[type](data));
};
export default validate;
