import React from "react";
import Modal from "common/components/Modal";
import Input from "common/components/Input";
import { Stack } from "@mui/material";
import IconPicker from "common/components/IconPicker";
import SectionHeader from "common/components/SectionHeader";
import ActionButton from "common/components/ActionButton";
import toast from "react-hot-toast";
import { nanoid } from "nanoid";
const NewDetector = ({ handleClose, setDetectors }) => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [icon, setIcon] = React.useState("");
  const [expression, setExpression] = React.useState("");

  return (
    <Stack gap={3}>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <SectionHeader>New Detector</SectionHeader>
      </Stack>

      <IconPicker value={icon} onChange={setIcon} />
      <Input
        size="small"
        sx={{ width: "100%" }}
        label="Name"
        placeholder="detector name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <Input
        size="small"
        sx={{ width: "100%" }}
        label="Description"
        placeholder="detector description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        multiline
        minRows={5}
        maxRows={5}
      />
      <Input
        size="small"
        sx={{ width: "100%" }}
        label="Expression"
        placeholder='resource["Name"] == "Test"'
        value={expression}
        onChange={(e) => setExpression(e.target.value)}
        multiline
        minRows={5}
        maxRows={5}
      />
      <div style={{ marginLeft: "auto" }}>
        <ActionButton
          icon={"save"}
          handler={async () => {
            if (!icon || !name || !description || !expression)
              return toast.error(
                "Must provide an Icon, Name, Description, and Expression"
              );
            setDetectors((d) => [
              ...d,
              {
                id: nanoid(),
                name,
                description,
                icon,
                expression,
                triggers: [],
              },
            ]);
            handleClose();
          }}
        >
          Save
        </ActionButton>
      </div>
    </Stack>
  );
};

export default function NewDetectorModal({ open, setOpen, setDetectors }) {
  return (
    <Modal
      width={500}
      forceOpen={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      {({ handleClose }) => (
        <NewDetector handleClose={handleClose} setDetectors={setDetectors} />
      )}
    </Modal>
  );
}
