import React from "react";
import Page from "common/components/Page";
import { Icon, Button, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SidePanel from "common/components/SidePanel";
import useFetcher from "common/hooks/useFetcher";
// import SystemDiscover from "pages/System/components/Discover";
import SystemCopy from "pages/System/components/Copy";
import validate from "constants/validate";
import toast from "react-hot-toast";
import PropertyManager from "./components/PropertyManager";
import copyContent from "common/utils/copyContent";
import Fab from "common/components/Fab";
import useConfirm from "common/hooks/useConfirm";
import GeneralManagement from "./components/GeneralManagement";
import ActionButton from "common/components/ActionButton";
import MultiLevelMenu from "common/components/MultilevelMenu";
import AstroHubPublish from "./components/AstroHubPublish";
export default function System() {
  const [loading, setLoading] = React.useState(false);
  const [copy, setCopy] = React.useState(false);
  const [publish, setPublish] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);
  const [importProperties, setImportProperties] = React.useState(false);
  const [reorder, setReorder] = React.useState(false);
  const [pluginEdit, setPluginEdit] = React.useState(false);
  const [propertyOpen, setPropertyOpen] = React.useState(false);
  const [colorOpen, setColorOpen] = React.useState(false);
  const [dataBacked, setDataBacked] = React.useState(false);
  const { id } = useParams();
  const [data, setDataState] = React.useState({
    uid: null,
    name: "",
    plugins: {
      data: false,
      graphs: false,
      detectors: false,
    },
    description: "",
    properties: [],
    detectors: [],
    actions: [],
    graphs: [],
    tags: [],
    acl: [],
  });
  const setData = (d) => {
    setDataState(d);
    if (!dirty) setDirty(true);
  };
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const saveSystem = async (close) => {
    const payload = { ...data };
    if (!validate("system_general_edit", payload)) return;
    await fetcher.post(
      "saving system",
      `/api/sys`,
      payload,
      (data) => {
        if (data?.error) return toast.error(data?.error);
        if (!id && data?.id) return navigate(`/app/sys/${data?.id}/dash`);
        fetchSystem(id);
      },
      () => {}
    );
  };
  const deleteSystem = async (id) => {
    await fetcher.delete("deleting system", `/api/sys/${id}`, () => {
      navigate("/app/sys");
    });
  };
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete System`,
    message: `Are you sure you want to delete ${data?.name} system? This action cannot be undone.`,
    callback: () => {
      deleteSystem(id);
    },
  });

  const fetchSystem = async (id) => {
    setLoading(true);
    setDirty(false);
    await fetcher.get(
      "fetching system",
      `/api/sys/${id}`,
      ({ system }) => {
        if (system.plugins.data) {
          setDataBacked(true);
        }
        setDataState(system);
      },
      () => {}
    );
    setCopy(false);
    setLoading(false);
  };

  React.useEffect(() => {
    if (!id) return;
    fetchSystem(id);
  }, [id]);

  // const detectorTriggerTeir = (data?.detectors ?? []).map((d) => {
  //   let subItems =
  //     data.triggers
  //       ?.filter((t) => t.detector === d.id)
  //       ?.map((t, index) => {
  //         return {
  //           id: t.id,
  //           component: (
  //             <div style={{ display: "flex", alignItems: "center" }}>
  //               <Switch
  //               // checked={checkValue}
  //               // onChange={() => setCheckValue(!checkValue)}
  //               // {...rest}
  //               />
  //               <div
  //                 style={{
  //                   height: "100%",
  //                   display: "flex",
  //                   alignItems: "center",
  //                 }}
  //                 onClick={() => alert("test")}
  //               >
  //                 <span>
  //                   {`${index + 1}. `}
  //                   {t.name}
  //                 </span>
  //               </div>
  //             </div>
  //             // <div>

  //             //   <span>{t.name}</span>
  //             // </div>
  //           ),
  //         };
  //       }) ?? [];

  //   return {
  //     id: d.id,
  //     component: (
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //         <Icon style={{ margin: "0 5px" }}>{d.icon}</Icon>
  //         <span>{d.name}</span>
  //         <IconButton variant="filled">
  //           <Icon>add</Icon>
  //         </IconButton>
  //         <IconButton>
  //           <Icon>low_priority</Icon>
  //         </IconButton>
  //       </div>
  //     ),
  //     items: subItems,
  //   };
  // });

  return (
    <Page
      chips={[
        {
          label: `Owner: ${data?.owner}`,
        },
      ]}
      // chips={
      //   data?.version
      //     ? [
      //         {
      //           label: data?.version,
      //         },
      //       ]
      //     : []
      // }
      refresh={id ? () => fetchSystem(id) : null}
      back="/app/sys"
      header={id ? data?.name : "System"}
      loading={loading}
      actions={
        id
          ? [
              <ActionButton
                icon="dashboard"
                circle
                noload
                handler={() => navigate(`/app/sys/${id}/dash`)}
              />,
              <div style={{ position: "relative" }}>
                <MultiLevelMenu
                  button={(props) => (
                    <Button {...props}>
                      <Icon>more_vert</Icon>
                    </Button>
                  )}
                  menu={{
                    main: {
                      "Save System": {
                        leftIcon: <Icon>save</Icon>,
                        onClick: saveSystem,
                      },
                      "Copy System": {
                        leftIcon: <Icon>content_copy</Icon>,
                        onClick: () => setCopy(true),
                      },
                      "Copy System Id": {
                        leftIcon: <Icon>content_copy</Icon>,
                        onClick: () => {
                          copyContent(id);
                          toast.success("copied system id");
                        },
                      },
                      "Publish to AstroHub": {
                        leftIcon: <Icon>publish</Icon>,
                        onClick: () => {
                          setPublish(true);
                        },
                      },
                      "Delete System": {
                        leftIcon: <Icon color="error">delete</Icon>,
                        onClick: () => {
                          openDeleteModal(true);
                        },
                      },
                    },
                  }}
                />
              </div>,
            ]
          : null
      }
    >
      <DeleteModal />
      {/* <Blurb>system_edit_prop</Blurb> */}
      {/* <TieredList width="500px" items={detectorTriggerTeir} /> */}
      <SystemCopy {...{ open: copy, onClose: () => setCopy(false), data }} />
      {/* <SystemPublish
        key={publish}
        {...{
          system: data,
          open: publish,
          onClose: () => setPublish(false),
          data,
        }}
      /> */}
      {/* <PluginsModal
        {...{
          open: pluginEdit,
          onClose: () => setPluginEdit(false),
          data,
          setData,
          dataBacked,
        }}
      /> */}
      {/* <SystemProperty
        {...{ open: propertyOpen, setOpen: setPropertyOpen, data, setData }}
      />
      <SystemColor
        {...{ open: colorOpen, setOpen: setColorOpen, data, setData }}
      />
      <SystemPropertyOrder
        {...{ open: reorder, setOpen: setReorder, data, setData }}
      />
      <SystemPropertyImport
        {...{
          open: importProperties,
          setOpen: setImportProperties,
          data,
          setData,
        }}
      /> */}
      <Stack gap={3}>
        {data?.plugins?.data ? (
          <PropertyManager {...{ system: data, setSystem: setData, dirty }} />
        ) : null}
        <GeneralManagement
          {...{ system: data, setSystem: setData, dataBacked, dirty }}
        />
      </Stack>
      <SidePanel
        width={700}
        open={publish}
        closeDrawer={() => setPublish(null)}
        props={{ system: data }}
      >
        {AstroHubPublish}
      </SidePanel>
      <div style={{ height: "200px" }} />
      <Fab active={dirty} icon="save" onClick={saveSystem} />
    </Page>
  );
}
