import React from "react";
import { Stack, Grid, Typography, Icon } from "@mui/material";
import useAuthentication from "auth";
import { useNavigate } from "react-router-dom";
import useState from "common/hooks/useState";
import Switch from "common/components/Switch";
import useFetcher from "common/hooks/useFetcher";
import Loader from "common/components/Loader";
import MultiSelect from "common/components/MultiSelect";
import toast from "react-hot-toast";
import E1Input from "common/components/E1Input";
import useForm from "common/hooks/useForm";
import copyContent from "common/utils/copyContent";
import RealTimeUpdate from "./components/RealTimeUpdate";
import ActionButton from "common/components/ActionButton";
import SectionHeader from "common/components/SectionHeader";
import Radio from "common/components/Radio";
import CronCreator from "common/components/CronCreator";
import { getNextCronExecutions } from "common/components/CronCreator";
import { WebhookInfo } from "../Jobs/WebhookView";
export default function CommandExecuteModal({
  resetSelection,
  resources,
  system,
  action,
  query,
  allSelected,
  role,
  refreshSystemData,
  onClose,
}) {
  const [job, setJob] = React.useState(null);
  const [schedule, setSchedule] = React.useState(false);
  // const [oneTime, setOneTime] = React.useState(false);
  const [webhook, setWebhook] = React.useState(false);
  const [hook, setHook] = React.useState(null);
  const [cron, setCron] = React.useState({});
  const [editCron, setEditCron] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dispatching, setDispatching] = React.useState(false);
  const [target, setTarget] = React.useState(null);
  const [executed, setExecuted] = React.useState(false);
  const { targets, loading: targetsLoading } = useState("/api/targeting");
  // const { getValues, generate, getValue, setValue } = useInputForm();
  const { controller, getFormValues } = useForm("commandcontext");
  const {
    controller: schedulecontroller,
    getFormValues: getScheduleFormValues,
  } = useForm("schedulecontext");
  const fetcher = useFetcher();
  const user = useAuthentication();
  const navigate = useNavigate();
  const [data, setData] = React.useState({
    name: "",
    description: "",
    inputs: [],
    envs: [],
    packages: [],
    tags: [],
    acl: [],
    scan: [],
    priority: false,
  });
  const placeholder = `${data?.name} ran by ${user?.id}`;
  const fetchCommand = async () => {
    setLoading(true);
    await fetcher.get(
      "fetching command",
      `/api/sys/${system?.id}/action/${action}`,
      ({ action }) => {
        let update = { ...action };
        delete update.code;
        setData(action);
      },
      () => setLoading(false)
    );
    setLoading(false);
  };

  React.useEffect(() => {
    fetchCommand();
  }, []);

  const dispatch = async (close, run = false) => {
    setDispatching(true);
    let inputs = getFormValues();
    let { name } = getScheduleFormValues();
    name = name || placeholder;
    if (/_|\//.test(name)) {
      toast.error("Name cannot container underscores or slashes.");
      setDispatching(false);
      return;
    }
    let error = "";
    (data?.inputs || []).forEach((input) => {
      if (!!input?.required && !inputs[input.name]) {
        error += `${input.name} requires a value\n`;
      }
    });
    if (error) {
      toast.error(error);
      throw Error(error);
    }
    let form = new FormData();
    Object.entries(inputs).forEach(([k, v]) => {
      let input = data?.inputs?.find((i) => i.name === k);
      if (input.type === "file") {
        if (v) {
          form.append(k, v);
        }
        delete inputs[k];
      } else {
        let dv = input?.defaultValue;
        if (!v && dv && input?.type !== "bool") {
          inputs[k] = dv;
        }
      }
    });
    if (data?.targeting?.select && !target?.id) {
      setDispatching(false);
      return toast.error("select a target");
    }
    const payload = {
      name,
      inputs,
      target: target ? target?.id : null,
      selection: { ...resources, uid: system?.uid },
    };
    setExecuted(true);
    if (schedule) {
      if (!cron || !cron?.expression) {
        return toast.error("configure a schedule");
      }
      payload.schedule = true;
      payload.cron = cron;
      payload.run = run;
      // payload.once = oneTime;
    }
    if (webhook) {
      payload.webhook = true;
    }
    form.append("json", JSON.stringify(payload));
    await fetcher.post(
      webhook
        ? "creating webhook"
        : schedule
        ? "scheduling command"
        : "executing command",
      `/api/sys/${system?.id}/action/${action}/run`,
      () => {
        return form;
      },
      ({ error, log, job, hook }) => {
        if (error) {
          toast.error(error);
          setDispatching(false);
          return setLoading(false);
        }
        resetSelection();
        setDispatching(false);
        if (log) {
          window.open(
            window.location.origin + `/log/view?job=${job}&log=${log}`,
            "_blank",
            "toolbar=0,location=0,menubar=0,titlebar=no,directories=no,status=no,width=1000,height=600"
          );
        }
        if (hook) {
          setHook(hook);
          return;
        }
        if (schedule) {
          if (run) {
            if (data.priority) {
              setDispatching(false);
              setLoading(false);
              setJob(job);
              return;
            }
          } else {
            return close();
          }
        }
        if (data.priority) {
          setDispatching(false);
          setLoading(false);
          setJob(job);
          return;
        }

        close();
      },
      () => {
        setDispatching(false);
        setLoading(false);
      }
    );
  };
  const reRun = async () => {
    await fetcher.post(
      "executing command again",
      `/api/sys/${system?.id}/jobs/${job}/rerun`,
      {},
      ({ error, job }) => {
        if (error) {
          toast.error(error);
        }
        if (data.priority) {
          setJob(job);
          return;
        }
      },
      () => {}
    );
  };
  return (
    <Stack>
      {loading ? (
        <div style={{ paddingTop: "50px" }}>
          <Loader small />
        </div>
      ) : (
        <Stack gap={2} p={3}>
          <SectionHeader icon={data?.icon} large>
            Execute {data?.name}
          </SectionHeader>
          {/* <Typography varinat="caption">{data?.description}</Typography> */}

          {job ? (
            <RealTimeUpdate
              reRun={reRun}
              job={job}
              refresh={data?.updateData ? refreshSystemData : () => {}}
            />
          ) : (
            <>
              <Stack direction={"row"}>
                <span style={{ marginLeft: "auto" }}>
                  <Radio
                    disabled={executed}
                    defaultValue={"execute"}
                    onChange={(v) => {
                      if (v === "execute") {
                        setCron({});
                        setEditCron(false);
                        setWebhook(false);
                        setSchedule(false);
                        return;
                      } else if (v === "schedule") {
                        setCron({});
                        setEditCron(true);
                        setWebhook(false);
                        setSchedule(true);
                        return;
                      } else if (v === "webhook") {
                        setCron({});
                        setSchedule(false);
                        setWebhook(true);
                        return;
                      }
                    }}
                    options={[
                      {
                        name: "execute",
                        icon: (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 256"
                          >
                            <rect width={256} height={256} fill="none" />
                            <path
                              d="M72,39.88V216.12a8,8,0,0,0,12.15,6.69l144.08-88.12a7.82,7.82,0,0,0,0-13.38L84.15,33.19A8,8,0,0,0,72,39.88Z"
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={16}
                            />
                          </svg>
                        ),
                      },
                      {
                        name: "schedule",
                        icon: (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 256"
                          >
                            <rect width={256} height={256} fill="none" />
                            <circle
                              cx={128}
                              cy={128}
                              r={96}
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={16}
                            />
                            <polyline
                              points="128 72 128 128 184 128"
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={16}
                            />
                          </svg>
                        ),
                      },
                      ...(!["auditor", "operator"].includes(role)
                        ? [
                            {
                              name: "webhook",
                              icon: (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 256 256"
                                >
                                  <rect width={256} height={256} fill="none" />
                                  <circle cx={192} cy={168} r={16} />
                                  <circle cx={128} cy={64} r={16} />
                                  <circle cx={64} cy={168} r={16} />
                                  <path
                                    d="M32,144a40,40,0,1,0,72,24h88"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={16}
                                  />
                                  <path
                                    d="M164.66,48A40,40,0,1,0,107,98.07L64,168"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={16}
                                  />
                                  <path
                                    d="M192,208a40,40,0,1,0-21-74.07L128,64"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={16}
                                  />
                                </svg>
                              ),
                            },
                          ]
                        : []),
                    ]}
                  />
                </span>
              </Stack>
              <E1Input
                sx={{ width: "100%" }}
                type="str"
                label={"Job Name"}
                defaultValue=""
                inputProps={{ ...schedulecontroller("name") }}
                placeholder={placeholder}
              />

              {/* //render inputs from input */}
              {data?.inputs?.length || data?.targeting?.select ? (
                <>
                  <Stack
                    sx={{ position: "relative" }}
                    // gap={2}
                    // p={2}
                    className="floating"
                  >
                    <SectionHeader icon="input">Inputs</SectionHeader>
                    {data.inputs.map((i) => {
                      return (
                        <Stack key={i.name} p={1} gap={1}>
                          <Stack direction="row" gap={1} alignItems={"center"}>
                            {i.type !== "bool" && i.required ? (
                              <Icon
                                sx={{ fontSize: ".8rem !important" }}
                                color="error"
                              >
                                emergency
                              </Icon>
                            ) : null}
                            <Stack sx={{ width: "100%" }}>
                              {i.type !== "bool" ? (
                                <Typography
                                  sx={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                  }}
                                  variant="subtitle2"
                                >
                                  <em>{i.help}</em>
                                </Typography>
                              ) : null}
                              <E1Input
                                sx={{ width: "100%" }}
                                type={i.type}
                                label={i.name}
                                help={i.help}
                                defaultValue={
                                  i.type !== "dropdown" ? i?.defaultValue : ""
                                }
                                opts={i?.defaultValue || "[]"}
                                inputProps={{ ...controller(i.name) }}
                              />
                            </Stack>
                          </Stack>
                          {/* <Typography
                            sx={{
                              textAlign: "right",
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                            variant="subtitle2"
                          >
                            <em>{i.help}</em>
                          </Typography> */}
                        </Stack>
                      );
                    })}
                  </Stack>
                  {data?.targeting?.select ? (
                    <Stack
                      sx={{ position: "relative" }}
                      gap={2}
                      p={2}
                      className="floating"
                    >
                      <SectionHeader>Targeting</SectionHeader>
                      <MultiSelect
                        options={targets}
                        loading={targetsLoading}
                        getOptionLabel={(e) => e.name}
                        label="Target"
                        dark
                        size="small"
                        multiple={false}
                        placeholder="Select a target"
                        value={target}
                        setValue={setTarget}
                        sx={{ width: "100%" }}
                      />
                    </Stack>
                  ) : null}
                </>
              ) : null}
              {schedule ? (
                editCron ? (
                  <CronCreator
                    cron={cron}
                    setCron={(cron) => {
                      setCron(cron);
                      setEditCron(false);
                    }}
                  />
                ) : (
                  <Grid className="floating" gap={1} container>
                    <Grid xs={12} item>
                      <SectionHeader icon="schedule">Schedule</SectionHeader>
                    </Grid>
                    <Grid xs={12} item>
                      <Typography sx={{ fontWeight: "bold" }} variant="body1">
                        Next trigger date
                      </Typography>
                    </Grid>
                    <Grid xs={12} item>
                      <Typography variant="body2">
                        Date and time are displayed in your current time zone in
                        UTC format, e.g. “Wed, Nov 9, 2022 09:00 (UTC-04:00)”
                        for Eastern time
                      </Typography>
                    </Grid>
                    <Grid xs={12} item>
                      {getNextCronExecutions(cron)[0]?.map((e) => {
                        return (
                          <Typography
                            sx={{ display: "block", fontWeight: "bold" }}
                            variant="subtitle"
                          >
                            {e}
                          </Typography>
                        );
                      })}
                    </Grid>
                    <Grid xs={12} item>
                      <ActionButton
                        icon="schedule"
                        noload
                        handler={() => {
                          setEditCron(true);
                        }}
                      >
                        Change Schedule
                      </ActionButton>
                    </Grid>
                  </Grid>
                )
              ) : null}

              {hook ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div style={{ marginLeft: "auto" }}>
                    <ActionButton
                      icon="webhook"
                      dark
                      noload
                      handler={async () => {
                        toast.success("copied webhook");
                        copyContent(
                          `https://${window.location.host}/api/sys/${system?.id}/hook/${hook}`
                        );
                      }}
                    >
                      Copy Webhook
                    </ActionButton>
                  </div>
                  <WebhookInfo
                    type="write"
                    system={system?.id}
                    hook={hook}
                    action={data?.name}
                  />
                </div>
              ) : job ? null : (
                <div
                  style={{
                    marginTop: "20px",
                    marginLeft: "auto",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {schedule ? (
                    <ActionButton
                      icon="rocket_launch"
                      dark
                      handler={async () => await dispatch(onClose, true)}
                    >
                      Run Now and Create Schedule
                    </ActionButton>
                  ) : null}
                  <ActionButton
                    icon={!schedule ? "rocket_launch" : "schedule"}
                    dark
                    handler={async () => await dispatch(onClose)}
                  >
                    {!schedule
                      ? !webhook
                        ? "Execute"
                        : "Create Webhook"
                      : "Create Schedule"}
                  </ActionButton>
                </div>
              )}
            </>
          )}
        </Stack>
      )}
    </Stack>
  );
}
