import React from "react";
import { Stack, Typography, Icon } from "@mui/material";
import useAuthentication from "auth";
import { useNavigate } from "react-router-dom";
import useState from "common/hooks/useState";
import useFetcher from "common/hooks/useFetcher";
import Loader from "common/components/Loader";
import MultiSelect from "common/components/MultiSelect";
import toast from "react-hot-toast";
import E1Input from "common/components/E1Input";
import useForm from "common/hooks/useForm";
import RealTimeUpdate from "pages/SystemDash/component/Action/components/RealTimeUpdate";
import ActionButton from "common/components/ActionButton";
import SectionHeader from "common/components/SectionHeader";
export default function CommandDebugPanel({ id, system, onClose }) {
  const [job, setJob] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [dispatching, setDispatching] = React.useState(false);
  const [target, setTarget] = React.useState(null);
  const [executed, setExecuted] = React.useState(false);
  const { targets, loading: targetsLoading } = useState("/api/targeting");
  const { controller, getFormValues } = useForm("commandcontext");
  const {
    controller: schedulecontroller,
    getFormValues: getScheduleFormValues,
  } = useForm("schedulecontext");
  const fetcher = useFetcher();
  const user = useAuthentication();
  const navigate = useNavigate();
  const [data, setData] = React.useState({
    name: "",
    description: "",
    inputs: [],
    envs: [],
    packages: [],
    tags: [],
    acl: [],
    scan: [],
    priority: false,
  });
  const placeholder = `${data?.name} ran by ${user?.id}`;
  const fetchCommand = async () => {
    setLoading(true);
    await fetcher.get(
      "fetching command",
      `/api/sys/${system}/action/${id}`,
      ({ action }) => {
        let update = { ...action };
        delete update.code;
        setData(action);
      },
      () => setLoading(false)
    );
    setLoading(false);
  };

  React.useEffect(() => {
    fetchCommand();
  }, []);

  const dispatch = async (close) => {
    setDispatching(true);
    let inputs = getFormValues();
    let { name } = getScheduleFormValues();
    name = name || placeholder;
    if (/_|\//.test(name)) {
      toast.error("Name cannot container underscores or slashes.");
      setDispatching(false);
      return;
    }
    let error = "";
    (data?.inputs || []).forEach((input) => {
      if (!!input?.required && !inputs[input.name]) {
        error += `${input.name} requires a value\n`;
      }
    });
    if (error) {
      toast.error(error);
      throw Error(error);
    }
    let form = new FormData();
    Object.entries(inputs).forEach(([k, v]) => {
      let input = data?.inputs?.find((i) => i.name === k);
      if (input.type === "file") {
        if (v) {
          form.append(k, v);
        }
        delete inputs[k];
      } else {
        let dv = input?.defaultValue;
        if (!v && dv && input?.type !== "bool") {
          inputs[k] = dv;
        }
      }
    });
    if (data?.targeting?.select && !target?.id) {
      setDispatching(false);
      return toast.error("select a target");
    }
    const payload = {
      name,
      inputs,
      target: target ? target?.id : null,
    };
    form.append("json", JSON.stringify(payload));
    setExecuted(true);
    await fetcher.post(
      "executing command",
      `/api/sys/${system}/action/${id}/run`,
      () => {
        return form;
      },
      ({ error, log, job, hook }) => {
        if (error) {
          toast.error(error);
          setDispatching(false);
          return setLoading(false);
        }
        if (data.priority) {
          setDispatching(false);
          setLoading(false);
          setJob(job);
          return;
        }
        setDispatching(false);
        close();
      },
      () => {
        setDispatching(false);
        setLoading(false);
      }
    );
  };
  const reRun = async () => {
    await fetcher.post(
      "executing command again",
      `/api/sys/${system}/jobs/${job}/rerun`,
      {},
      ({ error, job }) => {
        if (error) {
          toast.error(error);
        }
        if (data.priority) {
          setJob(job);
          return;
        }
      },
      () => {}
    );
  };
  return (
    <Stack>
      {loading ? (
        <div style={{ paddingTop: "50px" }}>
          <Loader small />
        </div>
      ) : (
        <Stack gap={2} p={3}>
          <SectionHeader icon={data?.icon} large>
            Execute {data?.name}
          </SectionHeader>
          {/* <Typography varinat="caption">{data?.description}</Typography> */}

          {job ? (
            <RealTimeUpdate reRun={reRun} job={job} refresh={() => {}} />
          ) : (
            <>
              <E1Input
                sx={{ width: "100%" }}
                type="str"
                label={"Job Name"}
                defaultValue=""
                inputProps={{ ...schedulecontroller("name") }}
                placeholder={placeholder}
              />
              {/* //render inputs from input */}
              {data?.inputs?.length || data?.targeting?.select ? (
                <>
                  <Stack
                    sx={{ position: "relative" }}
                    // gap={2}
                    // p={2}
                    className="floating"
                  >
                    <SectionHeader icon="input">Inputs</SectionHeader>
                    {data.inputs.map((i) => {
                      return (
                        <Stack key={i.name} p={1} gap={1}>
                          <Stack direction="row" gap={1} alignItems={"center"}>
                            {i.type !== "bool" && i.required ? (
                              <Icon
                                sx={{ fontSize: ".8rem !important" }}
                                color="error"
                              >
                                emergency
                              </Icon>
                            ) : null}
                            <Stack sx={{ width: "100%" }}>
                              {i.type !== "bool" ? (
                                <Typography
                                  sx={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                  }}
                                  variant="subtitle2"
                                >
                                  <em>{i.help}</em>
                                </Typography>
                              ) : null}
                              <E1Input
                                sx={{ width: "100%" }}
                                type={i.type}
                                label={i.name}
                                help={i.help}
                                defaultValue={
                                  i.type !== "dropdown" ? i?.defaultValue : ""
                                }
                                opts={i?.defaultValue || "[]"}
                                inputProps={{ ...controller(i.name) }}
                              />
                            </Stack>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                  {data?.targeting?.select ? (
                    <Stack
                      sx={{ position: "relative" }}
                      gap={2}
                      p={2}
                      className="floating"
                    >
                      <SectionHeader>Targeting</SectionHeader>
                      <MultiSelect
                        options={targets}
                        loading={targetsLoading}
                        getOptionLabel={(e) => e.name}
                        label="Target"
                        dark
                        size="small"
                        multiple={false}
                        placeholder="Select a target"
                        value={target}
                        setValue={setTarget}
                        sx={{ width: "100%" }}
                      />
                    </Stack>
                  ) : null}
                </>
              ) : null}
              {job ? null : (
                <div style={{ marginTop: "20px", marginLeft: "auto" }}>
                  <ActionButton
                    icon="rocket_launch"
                    dark
                    handler={async () => await dispatch(onClose)}
                  >
                    Execute
                  </ActionButton>
                </div>
              )}
            </>
          )}
        </Stack>
      )}
    </Stack>
  );
}

// import React from "react";
// import { Stack, Icon, Typography } from "@mui/material";
// import useAuthentication from "auth";
// import { LoadingButton } from "@mui/lab";
// import { useNavigate } from "react-router-dom";
// import useState from "common/hooks/useState";
// import useFetcher from "common/hooks/useFetcher";
// import Modal from "common/components/Modal";
// import Loader from "common/components/Loader";
// import MultiSelect from "common/components/MultiSelect";
// import toast from "react-hot-toast";
// import E1Input from "common/components/E1Input";
// import useForm from "common/hooks/useForm";
// import RealTimeUpdate from "pages/SystemDash/component/Action/components/RealTimeUpdate";
// import ActionButton from "common/components/ActionButton";
// import SectionHeader from "common/components/SectionHeader";
// export default function CommandExecuteModal({ onClose, id, system }) {
//   const [job, setJob] = React.useState(null);
//   const [loading, setLoading] = React.useState(false);
//   const [dispatching, setDispatching] = React.useState(false);
//   const [target, setTarget] = React.useState(null);
//   const { targets, loading: targetsLoading } = useState("/api/targeting");
//   const { controller, getFormValues } = useForm("commandcontext");
//   const fetcher = useFetcher();
//   const user = useAuthentication();
//   const navigate = useNavigate();
//   const [data, setData] = React.useState({
//     name: "",
//     description: "",
//     inputs: [],
//     envs: [],
//     packages: [],
//     tags: [],
//     acl: [],
//     scan: [],
//     priority: false,
//   });
//   const placeholder = `${data?.name} ran by ${user?.id}`;
//   const fetchCommand = async () => {
//     setLoading(true);
//     await fetcher.get(
//       "fetching action",
//       `/api/sys/${system}/action/${id}`,
//       ({ action }) => {
//         let update = { ...action };
//         delete update.code;
//         setData(action);
//       },
//       () => setLoading(false)
//     );
//     setLoading(false);
//   };

//   React.useEffect(() => {
//     if (!id) return;
//     fetchCommand(id);
//   }, [id]);

//   const dispatch = async (close) => {
//     setDispatching(true);
//     let inputs = getFormValues();
//     let name = placeholder;
//     if (data?.targeting?.select && !target?.id) {
//       setDispatching(false);
//       return toast.error("select a target");
//     }
//     const payload = {
//       name,
//       inputs,
//       target: target ? target?.id : null,
//     };
//     await fetcher.post(
//       "executing action",
//       `/api/sys/${system}/action/${id}/run`,
//       // `/api/sys/${system}/action/${id}/debug`,
//       payload,
//       ({ error, log, job }) => {
//         if (error) {
//           toast.error(error);
//           return setLoading(false);
//         }
//         setDispatching(false);
//         toast.success("command submitted!");
//         if (data.priority) {
//           setDispatching(false);
//           setLoading(false);
//           setJob(job);
//           return;
//         }
//         close();
//       },
//       () => setLoading(false)
//     );
//   };

//   return (
//     <Modal
//       glow
//       width={600}
//       forceOpen={!!id}
//       padding={0}
//       onClose={() => {
//         setDispatching(false);
//         setLoading(false);
//         setJob(null);
//         setTarget(null);
//         onClose();
//       }}
//     >
//       {({ handleClose }) =>
//         loading ? (
//           <Stack style={{ paddingTop: "50px" }}>
//             <Loader small />
//           </Stack>
//         ) : (
//           <Stack sx={{ background: "#333333" }} gap={2} p={3}>
//             <SectionHeader icon={data?.icon} large>
//               Execute {data?.name}
//             </SectionHeader>
//             <Typography varinat="caption">{data?.description}</Typography>

//             {job ? (
//               <RealTimeUpdate key={job} job={job} />
//             ) : (
//               <>
//                 {data?.inputs?.length || data?.targeting?.select ? (
//                   <>
//                     <Stack
//                       sx={{ position: "relative" }}
//                       gap={2}
//                       p={2}
//                       className="floating"
//                     >
//                       <SectionHeader>Inputs</SectionHeader>
//                       {data.inputs.map((i) => {
//                         return (
//                           <Stack key={i.name} spacing={1}>
//                             <Typography
//                               sx={{ fontWeight: "bold", marginBottom: "10px" }}
//                               variant="subtitle2"
//                             >
//                               <em>{i.help}</em>
//                             </Typography>
//                             <E1Input
//                               sx={{ width: "100%" }}
//                               type={i.type}
//                               label={i.name}
//                               defaultValue=""
//                               inputProps={{ ...controller(i.name) }}
//                             />
//                           </Stack>
//                         );
//                       })}
//                     </Stack>
//                     {data?.targeting?.select ? (
//                       <Stack
//                         sx={{ position: "relative" }}
//                         gap={2}
//                         p={2}
//                         className="floating"
//                       >
//                         <SectionHeader>Targeting</SectionHeader>
//                         <Stack spacing={1}>
//                           <MultiSelect
//                             size="small"
//                             dark
//                             options={targets}
//                             loading={targetsLoading}
//                             getOptionLabel={(e) => e.name}
//                             label="Target"
//                             multiple={false}
//                             placeholder="Select a target"
//                             value={target}
//                             setValue={setTarget}
//                             sx={{ width: "100%" }}
//                           />
//                         </Stack>
//                       </Stack>
//                     ) : null}
//                   </>
//                 ) : null}
//                 <div style={{ marginTop: "20px", marginLeft: "auto" }}>
//                   <ActionButton
//                     icon="rocket_launch"
//                     dark
//                     handler={async () => await dispatch(handleClose)}
//                   >
//                     Execute
//                   </ActionButton>
//                 </div>
//               </>
//             )}
//           </Stack>
//         )
//       }
//     </Modal>
//   );
// }
