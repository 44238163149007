export default {
  aws: (id) => {
    return `
## Setting Up Echelon One with AWS

This guide will walk you through creating the necessary IAM role for Echelon One to access your AWS resources.

### Option 1: Launch with default Administrator Access IAM Role (Least recommended)

This option is the quickest because it uses the Administrator Access IAM role. It's recommended only for testing purposes.

This [link](https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review?templateURL=https://e1public.s3.amazonaws.com/assume_role.template&param_AWSAccount=${id}&stackName=EchelonOneAssumeRole) will launch the CloudFormation template and create the resources with Administrator Access permissions.

### Option 2: Launch with custom IAM role (Recommended)

This option is more secure because you can create a custom IAM role with specific permissions for the resources being created.

Download the [CloudFormation template](https://e1public.s3.amazonaws.com/assume_role.template). This template is a text file that defines the resources you want to create in AWS.
Edit the template to update the IAM policy. The IAM policy specifies the permissions that the IAM role will have. 

You can find many [examples](https://docs.aws.amazon.com/IAM/latest/UserGuide/access_policies.html) of IAM policies online.

Launch the template in the CloudFormation console. When you launch the template, you will need to specify the value for the AWSAccount parameter. This parameter should be set to your Tenant account ID, which is ${id}.
    `;
  },
  azure: (id) => {
    return `

## Setting Up Echelon One with Azure

This guide will walk you through creating the necessary service principal for Echelon One to access your Azure resources.

### Create a Service Principal:

Open a command prompt or terminal window.

Run the following command, replacing [AZURE_SUBSCRIPTION_ID] with your actual Azure subscription ID:

\`
az ad sp create-for-rbac --name e1-admin --role="Owner" --scopes="/subscriptions/[AZURE_SUBSCRIPTION_ID]" --json-auth > auth.json
\`

This command creates a service principal named "e1-admin" with "Owner" permissions on your subscription. The --json-auth option saves the authentication details in a file named "auth.json".

### Upload the Authentication File:

Upload the "auth.json" file which contains the credentials that grant access to the service principal.
`;
  },
  gcp: (id) => {
    return `
## Setting Up Echelon One with Google Cloud Platform (GCP)

This guide will walk you through enabling the necessary APIs and configuring Workload Identity Federation (WIF) for Echelon One to access your GCP resources.

### Enable IAM Service Account Credentials API (One-Time):

This API allows Echelon One to generate short-lived credentials for accessing GCP resources securely. Use this [link](https://console.developers.google.com/apis/api/iamcredentials.googleapis.com/overview) if you haven't used this API before to enable it or if it's currently disabled in your project. Wait a few minutes for the changes to take effect across GCP systems.

### Create a Service Account:

A service account is a special identity used by applications to access GCP resources. Click this [link](https://console.cloud.google.com/iam-admin/serviceaccounts/create) to create a new service account specifically for Echelon One within your GCP project.

### Configure Workload Identity Federation for AWS:

WIF allows your AWS resources to trust identities managed by GCP. Click this [link](https://console.cloud.google.com/iam-admin/workload-identity-pools/create) to create a new WIF pool.

Select "AWS" as the provider you want to connect with.

Enter ${id} in the "AWS account ID" field. Leave the provider attributes with their default settings.

### Download Configuration File:

Once the WIF pool is created, use this [link](https://console.cloud.google.com/iam-admin/workload-identity-pools) to select the newly created pool. Click the "Grant Access" option to allow the pool to access the service account you created earlier. Keep "All identities in the pool" selected. Choose the provider you just configured and download the configuration file.

### Upload the Authentication File (Next Step):

Upload the configuration file which contains the credentials that grant access to the service account.
`;
  },
};

export const setupInstructions = {
  aws: () => "Enter your Account Id below and press Test to confirm access.",
  gcp: () =>
    "Input your Project Name and upload the configuration JSON file below. Press Test to confirm access.",
  azure: () =>
    "Enter your Subscription Id or descriptive name and upload the configuration JSON file below. Press Test to confirm access.",
};

export const CustomInstruction = `
## Setting Up Echelon One with a Custom Platform.

### Choose a Tenant Identifier:

Think of a descriptive name that clearly identifies your tenant. This name will help you easily distinguish your tenant from others.

### Prepare Your Platform Configuration File:

This file contains information specific to how you want your platform tenant to be set up. The format and contents of this file will depend on the platform you're using.

Tip: If you're unsure what this file should contain, you can download the platform's configuration for reference on the targets page.

### Upload the Configuration File:

Upload the configuration file which contains the configuration of the custom platform tenant.
`;
