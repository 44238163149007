import * as React from "react";
import ReactDOM from "react-dom";
import OptionMenu from "common/components/OptionMenu";
const PageMenu = ({ children }) => {
  const parent = React.useRef();
  const el = React.useRef(document.createElement("div"));

  React.useEffect(() => {
    parent.current = document.getElementById("page-menu");
    parent.current.appendChild(el.current);
    return () => {
      parent.current.removeChild(el.current);
    };
  }, []);
  return ReactDOM.createPortal(
    <OptionMenu
      // sx={{ position: "absolute", top: "100px" }}
      width={"400px"}
      options={children}
    />,
    el.current
  );
};

export default PageMenu;
