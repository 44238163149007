import React from "react";
import { Stack } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useFetcher from "common/hooks/useFetcher";
import Input from "common/components/Input";
import Modal from "common/components/Modal";
import validate from "constants/validate";
import toast from "react-hot-toast";
import ActionButton from "common/components/ActionButton";
import SectionHeader from "common/components/SectionHeader";
export default function CommandCopy({ open, refresh, close, data, code }) {
  const [name, setName] = React.useState("");
  const [systemId, setSystemId] = React.useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const systemname = searchParams.get("system");

  const navigate = useNavigate();
  const fetcher = useFetcher();
  const { id, action } = useParams();
  const saveCommand = async (close) => {
    let system = systemId ? systemId : id;
    if (!validate("command_copy_edit", { name })) return;
    const payload = { ...data, code, name };
    delete payload.id;
    await fetcher.post(
      "copying action",
      `/api/sys/${system}/action/new`,
      payload,
      ({ id: action, error, system_name }) => {
        if (error) return toast.error(error);
        navigate(`/app/sys/${system}/action/${action}?system=${system_name}`, {
          replace: true,
        });
        refresh(action, system);
        close();
      },
      () => {}
    );
  };
  return (
    <Modal
      forceOpen={open}
      onClose={() => {
        setName("");
        close();
      }}
    >
      {({ handleClose }) => (
        <Stack spacing={3}>
          <SectionHeader icon="content_copy">Copy Command</SectionHeader>
          <Input
            sx={{ width: "100%" }}
            label="Name"
            value={name}
            size="small"
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            sx={{ width: "100%" }}
            label="System Id (optional)"
            placehodler="default is current system"
            value={systemId}
            size="small"
            onChange={(e) => setSystemId(e.target.value)}
          />
          <div style={{ marginLeft: "auto" }}>
            <ActionButton
              handler={() => saveCommand(handleClose)}
              noload
              icon="content_copy"
            >
              Copy Command
            </ActionButton>
          </div>
        </Stack>
      )}
    </Modal>
  );
}
