import React, { useRef } from "react";
import { Stack, Typography, Icon } from "@mui/material";
import rootFetcher from "common/utils/fetcher";
import { themeOptions } from "theme";
import ReactMarkdown from "react-markdown";
import ActionButton from "common/components/ActionButton";
import { MiniLoader } from "common/components/Loader";
import gmtToLocal from "common/utils/gmtToLocal";
import isValidJSON from "common/utils/isValidJSON";

export default function RealTimeUpdate({ job, reRun, refresh }) {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);
  let callback = useRef().current;
  const fetchHandler = async () => {
    let res = await rootFetcher(`/api/realtime/log/${job}`);
    if (res?.log) {
      setData(JSON.parse(res.log));
      return res;
    }
    return null;
  };
  const fetchLog = async () => {
    setLoading(true);
    let res = await fetchHandler();
    if (res?.log) {
      setLoading(false);
      if (refresh) refresh();
      return;
    }
    callback = setTimeout(fetchLog, 5000);
  };

  React.useEffect(() => {
    fetchLog();
    return () => {
      try {
        clearTimeout(callback);
      } catch {}
    };
  }, [job]);
  return (
    <div>
      <Stack sx={{ margin: "auto", padding: "0" }} gap={1}>
        {loading ? (
          <Stack
            sx={{
              alignItems: "center",
              position: "relative",
              marginTop: "50px",
            }}
          >
            <MiniLoader />
          </Stack>
        ) : (
          <>
            <Stack direction="row">
              <Typography sx={{ fontWeight: "bold" }} variant="h5">
                Result
              </Typography>
              {job ? (
                <ActionButton
                  sx={{ marginLeft: "auto" }}
                  icon="refresh"
                  noload
                  dark
                  handler={() => {
                    setLoading(true);
                    reRun();
                  }}
                >
                  Rerun
                </ActionButton>
              ) : null}
            </Stack>
            {/* <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.2,
                },
              }}
            > */}
            {data?.map(({ time, type, message }, i) => {
              return (
                <Stack
                  sx={{
                    position: "relative",
                    width: "100%",
                    background: themeOptions.palette.primary.dark,
                    borderRadius: "20px",
                    border: `1px solid ${themeOptions.palette.text.hint}`,
                    transition: "0.1s ease-in-out",
                  }}
                  p={2}
                  gap={1}
                  key={i}
                  direction="row"
                  alignItems={"flex-start"}
                >
                  <Icon color={type === "error" ? "error" : "success"}>
                    {type === "error" ? "crisis_alert" : "info"}
                  </Icon>
                  <div style={{ overflow: "hidden" }}>
                    <div>
                      {gmtToLocal(
                        new Date(
                          time.replace(
                            /(\d{4})\/(\d{2})\/(\d{2}) (\d{2}):(\d{2}):(\d{2})/,
                            "$1-$2-$3T$4:$5:$6Z"
                          )
                        )
                      )}
                    </div>
                    <div>
                      {isValidJSON(message) ? (
                        <pre
                          style={{
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                          }}
                        >
                          {JSON.stringify(JSON.parse(message), null, 4)}
                        </pre>
                      ) : (
                        <ReactMarkdown
                          linkTarget="_blank"
                          className="react-markdown-custom"
                        >
                          {message}
                        </ReactMarkdown>
                      )}
                    </div>
                  </div>
                </Stack>
                // <TimelineItem key={i}>
                //   <TimelineOppositeContent
                //     variant="caption"
                //     color="text.secondary"
                //   >
                //     {time}
                //   </TimelineOppositeContent>
                //   <TimelineSeparator>
                //     <TimelineDot
                //       color={type === "error" ? "error" : "success"}
                //     >
                //       <Icon color={"primary"}>
                //         {type === "error" ? "crisis_alert" : "info"}
                //       </Icon>
                //     </TimelineDot>
                //     <TimelineConnector />
                //   </TimelineSeparator>
                //   <TimelineContent variant="caption">
                //     <div style={{ maxWidth: "70%" }} className="floating">
                //       {message}
                //     </div>
                //   </TimelineContent>
                // </TimelineItem>
              );
            })}
            {/* </Timeline> */}
          </>
        )}
      </Stack>
    </div>
  );
}
