import React from "react";
import { Button, Icon, Stack, Typography } from "@mui/material";
import Shrug from "../../../assets/echo/echo_shrug_112p.png";
import Background from "../../../assets/polygon_bg_2.svg";
import ActionButton from "../ActionButton";
export default function AlertEmote({
  icon,
  text,
  onClick,
  buttonText,
  noButton = false,
}) {
  return (
    <div
      className="holo-card"
      style={{
        position: "relative",
        width: "400px",
        height: noButton ? "130px" : "175px",
        borderRadius: "10%",
        padding: "20px 30px",
        border: "1px solid #262626",
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
      }}
    >
      <Stack
        style={{ position: "absolute", bottom: "0px" }}
        spacing={2}
        direction="row"
        alignItems="center"
      >
        <img src={Shrug} />
      </Stack>
      <Stack
        p={1}
        gap={1}
        style={{
          justifyContent: "center",
          marginLeft: "auto",
          width: "65%",
          marginTop: noButton ? "20px" : "30px",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        {noButton ? null : (
          <div style={{ width: "200px" }}>
            <ActionButton
              icon="add"
              noload
              handler={async () => {
                onClick();
              }}
            >
              {buttonText}
            </ActionButton>
          </div>
          // <Button
          //   onClick={onClick}
          //   variant="contained"
          //   color="secondary"
          //   startIcon={<Icon sx={{ color: "#000" }}>{icon}</Icon>}
          //   sx={{
          //     fontWeight: "bold",
          //     letterSpacing: "2px",
          //     height: "60px",
          //     width: "100%",
          //     marginTop: "20px",
          //   }}
          // >
          //   {buttonText}
          // </Button>
        )}
      </Stack>
    </div>
  );
}
