import { Modal, Stack, IconButton, Icon, Box, Typography } from "@mui/material";
import ActionButton from "common/components/ActionButton";
import ReactMarkdown from "react-markdown";
import copyContent from "common/utils/copyContent";
import toast from "react-hot-toast";
const style = () => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#171717",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 3,
  p: 3,
  maxHeight: "90vh",
  overflow: "hidden",
});

export default function CliInstall({ open, onClose }) {
  const close = () => onClose();
  let { access_token } = JSON.parse(localStorage?.getItem("E1_JWT") || "{}");
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal"
      aria-describedby="modal"
    >
      <Box
        className={"glowborder"}
        sx={{
          ...style(),
          width: 700,
        }}
      >
        <IconButton
          onClick={close}
          sx={{
            position: "absolute",
            right: "5px",
            top: "5px",
            zIndex: 2000,
          }}
          color={"error"}
        >
          <Icon color="error">close</Icon>
        </IconButton>

        <Stack gap={1}>
          <Stack direction="row" gap={1} alignItems={"flex-end"}>
            <Icon sx={{ marginBottom: "2px", fontSize: "2.5rem !important" }}>
              terminal
            </Icon>
            <Typography
              p={0}
              m={0}
              variant="h2"
              sx={{ fontSize: "2rem !important" }}
            >
              <strong>ECHELON</strong>ONE
            </Typography>
            <Typography
              p={0}
              m={0}
              variant="h2"
              sx={{ fontSize: "2rem !important" }}
            >
              CLI
            </Typography>
          </Stack>
          <Typography>
            Echelon One CLI is crafted to improve your development experience.
            Our CLI makes integrating our platform into your development
            environment easy.
          </Typography>
          <Typography
            sx={{ padding: 0, margin: 0, fontSize: "1rem" }}
            variant="overline"
          >
            Install CLI
          </Typography>
          <Typography variant="body2">
            You may need to change the pip call per your local environment
          </Typography>
          <Stack
            sx={{ overflowY: "scroll" }}
            direction="row"
            gap={1}
            alignItems={"center"}
          >
            <IconButton
              color="success"
              onClick={() => {
                copyContent(
                  `pip install ${window.location.protocol}//${
                    window.location.host
                  }/api/assets/cli?token=${
                    JSON.parse(localStorage.getItem("E1_JWT"))?.access_token
                  }`
                );
                toast.success("copied install command");
              }}
            >
              <Icon color="success">content_copy</Icon>
            </IconButton>
            <ReactMarkdown
              linkTarget="_blank"
              className="react-markdown-custom"
            >
              {`\`\`\`pip install ${window.location.protocol}//${
                window.location.host
              }/api/assets/cli?token=${
                JSON.parse(localStorage.getItem("E1_JWT"))?.access_token
              }\`\`\``}
            </ReactMarkdown>
          </Stack>
          <Typography
            sx={{ padding: 0, margin: 0, fontSize: "1rem" }}
            variant="overline"
          >
            Authenticate CLI
          </Typography>
          <Typography variant="body2">
            This command authenticates your local environment.
          </Typography>
          <Stack
            sx={{ overflowY: "scroll" }}
            direction="row"
            gap={1}
            alignItems={"center"}
          >
            <IconButton
              color="success"
              onClick={() => {
                copyContent(
                  `e1 auth --url ${window.location.protocol}//${window.location.host} --token ${access_token}`
                );
                toast.success("copied authenticate command");
              }}
            >
              <Icon color="success">content_copy</Icon>
            </IconButton>
            <ReactMarkdown
              linkTarget="_blank"
              className="react-markdown-custom"
            >
              {`e1 auth --url ${window.location.protocol}//${window.location.host} --token ${access_token}`}
            </ReactMarkdown>
          </Stack>
          <Stack
            direction="row"
            justifyContent={"center"}
            sx={{ marginTop: "10px !important" }}
            spacing={2}
          >
            <ActionButton
              icon="done"
              dark
              handler={async () => {
                close();
              }}
            >
              Close
            </ActionButton>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
