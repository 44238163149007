import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Icon, List as MuiList, IconButton, Stack } from "@mui/material";

function generate(items) {
  return items.map(
    ({ icon, color = "primary", text, onClick, onDelete }, key) => (
      <ListItem
        sx={{ paddingRight: "8px", paddingLeft: "8px" }}
        key={key}
        secondaryAction={
          <Stack direction="row" spacing={2}>
            {!onClick ? null : (
              <IconButton {...{ onClick }} edge="end">
                <Icon>travel_explore</Icon>
              </IconButton>
            )}
            {!onDelete ? null : (
              <IconButton {...{ onClick: onDelete }} edge="end">
                <Icon>close</Icon>
              </IconButton>
            )}
          </Stack>
        }
      >
        {icon ? (
          <ListItemIcon color={color}>
            <Icon color={color}>{icon}</Icon>
          </ListItemIcon>
        ) : null}
        <ListItemText primary={text} />
      </ListItem>
    )
  );
}

export default function List({ items }) {
  return <MuiList dense={true}>{generate(items)}</MuiList>;
}
