import React from "react";
import { Stack, Avatar, Typography } from "@mui/material";
import Input from "common/components/Input";
import ActionButton from "common/components/ActionButton";
import useScopeAttributes from "common/hooks/useScopeAttributes";
import useFetcher from "common/hooks/useFetcher";
import { SystemsIcon } from "routes";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
export default function CreateSystem({ onClose }) {
  const fetcher = useFetcher();
  const { ScopeInputs, getScope, setInit } = useScopeAttributes();
  const [name, setName] = React.useState("");
  const navigate = useNavigate();
  const [description, setDescription] = React.useState("");
  const saveSystem = async () => {
    const payload = {
      uid: null,
      name,
      plugins: {
        data: false,
        graphs: false,
        detectors: false,
      },
      description,
      properties: [],
      detectors: [],
      actions: [],
      graphs: [],
      ...getScope(),
    };
    if (name.length < 5 || description.length < 15 || name.includes("_"))
      return toast.error(
        "Name and Description must be valid. Name cannot have underscores."
      );
    await fetcher.post(
      "creating system",
      `/api/sys`,
      payload,
      (data) => {
        if (data?.error) {
          toast.error(data?.error);
          return;
        }
        navigate(`/app/sys/${data?.id}/dash`);
        onClose();
      },
      () => {}
    );
  };
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <SystemsIcon fill="#171717" size="50" />
        </Avatar>
        <Stack>
          <Typography variant="h4">Create System</Typography>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Information</Typography>
        <Stack gap={1}>
          <Typography>Identifying information for your system.</Typography>
        </Stack>
        <Stack gap={1}>
          <Input
            dark
            size="small"
            sx={{ width: "100%" }}
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            dark
            size="small"
            sx={{ width: "100%" }}
            placeholder="Description"
            value={description}
            multiline
            minRows={3}
            maxRows={3}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Access Control</Typography>
        <Stack gap={1}>
          <ScopeInputs {...{ dark: true, size: "small" }} />
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto", display: "flex" }}>
        <ActionButton
          icon="save"
          dark
          handler={async () => {
            await saveSystem();
          }}
        >
          Create System
        </ActionButton>
      </div>
    </Stack>
  );
}
