import { Opacity } from "@mui/icons-material";
import { CssBaseline } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";

export const themeOptions = {
  palette: {
    action: {
      // disabledBackground: 'set color of background here',
      disabled: "grey",
    },
    type: "light",
    primary: {
      main: "#262626",
      dark: "#171717",
      light: "#333333",
    },
    secondary: {
      main: "#ff914c",
      dark: "#ff711a",
    },
    background: {
      default: "#171717",
      paper: "#262626",
    },
    text: {
      primary: "rgba(239,237,239,0.87)",
      secondary: "rgba(239,237,239,0.54)",
      disabled: "rgba(239,237,239,0.38)",
      hint: "rgba(239,237,239,0.38)",
    },
    error: {
      main: "#FF6961",
    },
    warning: {
      main: "#f5df32",
    },
    info: {
      main: "#ff914c",
    },
    success: {
      main: "#28ebc0",
    },
    divider: "rgba(0,0,0,0)",
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          // backgroundColor: "#171717",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#fff", // text color when not selected
          "&.Mui-selected": {
            color: "#ff914c", // text color when selected
          },
        },
      },
    },
    MuiIcon: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "rgba(239,237,239,0.87)",
          "&:active": {
            color: "rgba(239,237,239,0.87)",
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          style: { zIndex: 35001 },
        },
      },
      styleOverrides: {
        root: {
          background: "#262626 !important",
          color: "rgba(239,237,239,0.87) !important",
        },
      },
    },
  },
  typography: {
    fontFamily: '"Mulish", sans-serif',
    h1: {
      fontFamily: '"ECHELONONE", "Helvetica", "Arial", sans-serif',
    },
    h2: {
      fontFamily: '"ECHELONONE", "Helvetica", "Arial", sans-serif',
    },
    h3: {
      fontFamily: '"ECHELONONE", "Helvetica", "Arial", sans-serif',
    },
    h4: {
      fontFamily: '"Exo", "Helvetica", "Arial", sans-serif',
      fontSize: "28px",
    },
    h5: {
      fontFamily: '"Titillium Web", "Helvetica", "Arial", sans-serif',
      fontSize: "24px",
    },
    h6: {
      fontFamily: '"Titillium Web", "Helvetica", "Arial", sans-serif',
    },
    button: {
      fontFamily: '"Titillium Web", "Helvetica", "Arial", sans-serif',
      fontWeight: "bold",
    },
    caption: {
      fontFamily: '"Titillium Web", "Helvetica", "Arial", sans-serif',
    },
    overline: {
      fontFamily: '"Mulish", "Helvetica", "Arial", sans-serif',
    },
  },
};
export const theme = themeOptions;
const Theme = ({ children }) => {
  const theme = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Theme;
