import React from "react";
import Input from "common/components/Input";
import { Stack, Typography, Avatar, Icon } from "@mui/material";
import ColorPicker from "common/components/ColorPicker";
import useFetcher from "common/hooks/useFetcher";
import validate from "constants/validate";
import ActionButton from "common/components/ActionButton";
import toast from "react-hot-toast";
import { theme } from "theme";
import AssetImage from "common/components/AssetImage";
import FileUpload from "common/components/FileUpload";
import { useAsyncError } from "common/hooks/useAsyncError";
import { authFetch } from "auth";
import useDownloadFile from "common/hooks/useDownloadFile";
export default function ViewPlatform({ platform, onClose }) {
  const [name, setName] = React.useState("");
  const [imageFile, setImageFile] = React.useState(null);
  const [geoFile, setGeoFile] = React.useState(null);
  const [platformConfig, setPlatformConfig] = React.useState("");
  const [description, setDescription] = React.useState("");
  const fetcher = useFetcher();

  const generateTenantConfig = useDownloadFile({
    name: `${platform.name}_tenant_config.json`,
    notify: "getting tenant configuration",
    endpoint: `/api/platforms/${platform?.pk}/tenant/config`,
  });
  const generateTenantGeos = useDownloadFile({
    name: `${platform.name}_tenant_geos.json`,
    notify: "getting tenant geographies",
    endpoint: `/api/platforms/${platform?.pk}/tenant/geos`,
  });

  const createPlatform = async () => {
    let payload = {
      type: name,
      description,
      platformConfig,
      imageFile,
      name,
    };
    if (!validate("add_platform", payload)) throw new Error("incorrect format");
    await fetcher.post(
      "adding platform",
      `/api/platforms`,
      () => {
        let form = new FormData();
        form.append("json", JSON.stringify(payload));
        form.append("Icon", imageFile);
        form.append("Geo", geoFile);
        return form;
      },
      ({ id, error }, toast) => {
        if (id) {
          toast.success("Platform added!");
          refresh();
          onClose();
          return;
        }
        toast.error(error);
      },
      (e) => {}
    );
  };
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          {platform?.pk && (
            <AssetImage
              path={`/api/assets/instance/platforms/${platform.pk}/logo.png`}
              style={{ height: "60px", width: "60px" }}
            />
          )}
        </Avatar>
        <Stack>
          <Typography variant="h4">{platform?.name}</Typography>
          <Typography variant="body2">custom platform</Typography>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Description</Typography>
        <Stack gap={1}>
          <Typography variant="body2">{platform?.description}</Typography>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Configuration</Typography>
        <Stack gap={1}>
          <Typography>
            Download the tenant configuration or geographies.
          </Typography>
        </Stack>
        <Stack gap={1}>
          <ActionButton
            dark
            icon="download"
            handler={async () => {
              await generateTenantConfig();
            }}
          >
            Download Tenant Configuration
          </ActionButton>
          <ActionButton
            dark
            icon="download"
            handler={async () => {
              await generateTenantGeos();
            }}
          >
            Download Geographies
          </ActionButton>
        </Stack>
      </Stack>
    </Stack>
  );
}
