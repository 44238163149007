import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Icon,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const AccordionSection = ({ sections }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      {sections.map((section, index) => {
        const panelId = `panel${index + 1}`;

        return (
          <Accordion
            key={panelId}
            expanded={expanded === panelId}
            onChange={handleChange(panelId)}
            className="floating"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls={`${panelId}-content`}
              id={`${panelId}-header`}
              sx={{
                minHeight: "48px", // Reduce from default 64px
                "& .MuiAccordionSummary-content": {
                  margin: "8px 0", // Reduce from default 12px 0
                },
              }}
            >
              <Stack gap={1} flexDirection="row" alignItems="center">
                {section?.icon ? (
                  <Icon color="secondary">{section.icon}</Icon>
                ) : (
                  section.component
                )}
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold" }}
                  component="div"
                >
                  {section.title}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>{section.content}</AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default AccordionSection;

// // Example usage:
// const App = () => {
//   const sectionData = [
//     {
//       title: "Section 1",
//       content: <Typography>Content for section 1</Typography>,
//     },
//     {
//       title: "Section 2",
//       content: <Typography>Content for section 2</Typography>,
//     },
//     {
//       title: "Section 3",
//       content: <Typography>Content for section 3</Typography>,
//     },
//   ];

//   return (
//     <div style={{ maxWidth: 800, margin: "0 auto", padding: 20 }}>
//       <AccordionSection sections={sectionData} />
//     </div>
//   );
// };

// export default App;
