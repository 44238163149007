import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Switch as MuiSwitch } from "@mui/material";

const IOSSwitch = styled(({ colorOverride, ...props }) => (
  <MuiSwitch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    colorOverride={colorOverride}
    {...props}
  />
))(({ theme, colorOverride, ...props }) => {
  return {
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? colorOverride || "#ff914c"
              : colorOverride || "#ff914c",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: colorOverride || "#ff914c",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  };
});

export default function Switch({
  label = "",
  checked = false,
  onChange = (event) => {},
  defaultChecked = null,
  colorOverride,
  ...props
}) {
  const checkOptions =
    defaultChecked !== null
      ? { defaultChecked }
      : { checked, onChange: (event) => onChange(event.target.checked, event) };
  return (
    <FormControlLabel
      sx={{ margin: 0 }}
      control={
        <IOSSwitch
          sx={{ m: 1 }}
          colorOverride={colorOverride}
          // checked={checked}
          {...checkOptions}
          // onChange={(event) => onChange(event.target.checked, event)}
          {...props}
        />
      }
      label={label}
    />
  );
}
