export function createGridDashboardArray(number) {
  if (number < 3) {
    return [2];
  }
  let array = [];
  while (number >= 3) {
    array.push(3);
    number -= 3;
  }
  if (number === 1) {
    array[array.length - 1] = 2;
    array.push(2);
  } else if (number === 2) {
    array.push(2);
  }
  array = array.reduce((a, c) => {
    return [...a, ...[...Array(c)].map((x) => (c === 3 ? 4 : 6))];
  }, []);
  return array;
}
