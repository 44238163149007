import useSWR from "swr";
import fetcher from "common/utils/fetcher";
import { useSWRConfig } from "swr";
import toast from "react-hot-toast";
export default function useState(key) {
  const { data, error } = useSWR(key, fetcher, { revalidateOnFocus: false });
  const { mutate } = useSWRConfig();
  return {
    loading: !data && !error,
    error,
    refresh: () => {
      toast.success("refreshing data");
      mutate(key);
    },
    ...data,
  };
}
