import React from "react";
import { Stack, Typography, Icon, IconButton } from "@mui/material";
import { MiniLoader } from "common/components/Loader";
import Table from "common/components/Table";
import useFetcher from "common/hooks/useFetcher";
import ActionButton from "common/components/ActionButton";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "common/components/Input";

export default function SelectTenants({
  tenants,
  platform,
  setTenants,
  onClose,
}) {
  const fetcher = useFetcher();
  const [loading, setLoading] = React.useState(true);
  const [availableTenants, setAvailableTenants] = React.useState([]);
  const [unselectedTenants, setUnselectedTenants] = React.useState(null);
  const [search, setSearch] = React.useState("");

  const getTenants = async () => {
    setLoading(true);
    setAvailableTenants([]);
    await fetcher.get(
      "fetching tenants",
      `/api/tenants`,
      (data) => {
        let refArr = tenants || [];
        setAvailableTenants(data?.tenants);
        setAvailableTenants(
          (data?.tenants || []).sort((a, b) => {
            const indexA = refArr.indexOf(a.pk);
            const indexB = refArr.indexOf(b.pk);

            if (indexA !== -1 && indexB !== -1) {
              return indexA - indexB;
            } else if (indexA !== -1) {
              return -1;
            } else if (indexB !== -1) {
              return 1;
            } else {
              return 0;
            }
          })
        );
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  React.useEffect(() => {
    getTenants();
  }, []);
  let tableTenants = availableTenants.filter((x) =>
    RegExp(search, "i").test(JSON.stringify(x))
  );
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Stack gap={1}>
          <Typography variant="h4">Select Tenants</Typography>
          <Typography variant="body2">
            Pick tenants that should be assoicated with the target.
          </Typography>
        </Stack>
      </Stack>
      <div>
        <ActionButton
          icon="check"
          sx={{ marginLeft: "auto" }}
          dark
          noload
          handler={async () => {
            onClose();
          }}
        >
          Done
        </ActionButton>
      </div>
      {loading ? (
        <div style={{ position: "relative" }}>
          <MiniLoader />
        </div>
      ) : availableTenants?.length ? (
        <Stack gap={1}>
          <div>
            <Input
              dark
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")} edge="end">
                      {search ? (
                        <Icon
                          sx={{ fontSize: "18px !important" }}
                          color="error"
                        >
                          close
                        </Icon>
                      ) : null}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="search..."
              style={{ marginLeft: "auto", width: "100%" }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <Table
            width="600px"
            data={tableTenants.filter((i) => i.type === platform) || []}
            loading={loading}
            idProperty={"pk"}
            checkboxColumn
            onSelectionChange={({ selected, unselected }) => {
              setUnselectedTenants(unselected);
              if (typeof selected == "boolean") return setTenants(selected);
              setTenants(Object.keys(selected));
            }}
            selected={
              typeof tenants == "boolean"
                ? tenants
                : tenants?.reduce((a, c) => ({ ...a, [c]: true }), {}) || {}
            }
            unselected={unselectedTenants}
            cols={[
              {
                name: "name",
                header: "Name",
                defaultFlex: 1,
              },
              {
                name: "id",
                header: "Id",
                defaultFlex: 1,
              },
            ]}
          />
        </Stack>
      ) : null}
    </Stack>
  );
}
