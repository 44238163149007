export default function getEasierToReadColor(hexColor) {
  // Convert hex color to RGB
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);

  // Calculate brightness
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Determine threshold
  const threshold = 127;

  // Compare brightness with threshold
  if (brightness <= threshold) {
    return "#fff"; // White for dark colors
  } else {
    return "#000"; // Black for light colors
  }
}
