import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import MultiSelect from "common/components/MultiSelect";
// import AwsTarget from "pages/Targets/components/AwsTarget";
import useFetcher from "common/hooks/useFetcher";
import Modal from "common/components/Modal";
import Blurb from "constants/blurbs";
import validate from "constants/validate";
import rootFetcher from "common/utils/fetcher";
import SectionHeader from "common/components/SectionHeader";
import ActionButton from "common/components/ActionButton";
export default function CommandRestore({ open, close, id, refresh }) {
  const fetcher = useFetcher();
  const { id: system, action } = useParams();
  const [selection, setSelection] = React.useState(null);
  const [restores, setRestores] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const restoreCommand = async (close) => {
    if (!validate("command_restore", { selection })) return;
    await fetcher.post(
      "restoring action",
      `/api/sys/${system}/action/${action}/restore/${selection}`,
      {},
      ({ success, error }) => {
        if (error) return toast.error(error);
        refresh();
        close();
      },
      () => {}
    );
  };
  const fetchRestores = async () => {
    setLoading(true);
    let { restores } = await rootFetcher(
      `/api/sys/${system}/action/${action}/restore`
    );
    if (restores) setRestores(restores);
    setLoading(false);
  };
  React.useEffect(() => {
    if (!open) {
      if (selection) {
        setSelection(null);
      }
      return;
    }
    fetchRestores();
  }, [open]);
  return (
    <Modal
      forceOpen={open}
      onClose={() => {
        setSelection(false);
        setRestores([]);
        close();
      }}
    >
      {({ handleClose }) => (
        <Stack gap={2}>
          <SectionHeader icon="settings_backup_restore">
            Command Restore
          </SectionHeader>
          <MultiSelect
            getOptionLabel={(e) => (!e ? "" : e?.replace("_", ", "))}
            label="Restore Point"
            placeholder="select restore point"
            options={restores || []}
            multiple={false}
            loading={loading}
            value={selection}
            size="small"
            setValue={setSelection}
          />
          {selection ? (
            <div style={{ marginLeft: "auto" }}>
              <ActionButton
                handler={async () => await restoreCommand(handleClose)}
                danger
                icon="settings_backup_restore"
              >
                Restore
              </ActionButton>
            </div>
          ) : null}
        </Stack>
      )}
    </Modal>
  );
}
