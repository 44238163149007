import React from "react";
import { Stack, IconButton, Icon, Typography, Tabs, Tab } from "@mui/material";
import NewReadWebhookModal from "pages/SystemDash/component/ReadWebhook";
import getUserFriendlyDate from "common/utils/getUserFriendlyDate";
import WebhookView from "./WebhookView.js";
import useFetcher from "common/hooks/useFetcher";
import SidePanel from "common/components/SidePanel";
import { useParams } from "react-router-dom";
import { MiniLoader } from "common/components/Loader";
import Switch from "common/components/Switch";
import useConfirm from "common/hooks/useConfirm";
import ActionButton from "common/components/ActionButton";
import Log from "pages/Archive/components/Logging/Log";
import ScheduleEdit from "./ScheduleEdit";
const Status = {
  completed: "check_circle",
  failed: "error",
  queued: "pending",
  running: "pending",
};

export default function JobsModal({ system, open, setOpen, dataBacked, role }) {
  const fetcher = useFetcher();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [schedules, setSchedules] = React.useState([]);
  const [hooks, setHooks] = React.useState([]);
  const [job, setJob] = React.useState(null);
  const [view, setView] = React.useState(0);
  const { id } = useParams();
  const deleteSchedule = async (job) => {
    await fetcher.delete(
      "deleting schedule",
      `/api/sys/${system}/schedule/${job}`,
      ({ success, error }) => {
        if (error) return;
        setJob(null);
        getSchedules();
      },
      () => {}
    );
  };
  // const [openDeleteModal, DeleteModal] = useConfirm({
  //   title: `Delete Schedule`,
  //   message: `Are you sure you want to delete this schedule? This action can't be undone.`,
  //   callback: (props, context) => deleteSchedule(context?.id),
  // });
  const getJobs = async () => {
    setLoading(true);
    // const res = await fetcher.get(`/api/sys/${system.id}/jobs`);
    await fetcher.get(
      "fetching system jobs",
      `/api/sys/${id}/jobs`,
      ({ jobs }) => {
        try {
          let data = jobs.map((j) => ({ ...j, ...j.content }));
          setData(
            data.sort((a, b) => {
              // Convert string dates into `Date` objects
              const dateA = new Date(a.start.split("_")[0]);
              const dateB = new Date(b.start.split("_")[0]);

              // Subtract dateB from dateA to sort in descending order
              return dateB - dateA;
            })
          );
          setLoading(false);
        } catch {
          setData([]);
          setLoading(false);
        }
      },
      () => {}
    );
  };
  const getSchedules = async () => {
    // const res = await fetcher.get(`/api/sys/${system.id}/jobs`);
    setLoading(true);
    await fetcher.get(
      "fetching system schedules",
      `/api/sys/${id}/schedules`,
      ({ schedules }) => {
        let data = schedules.map((j) => ({
          ...j,
          ...j.content,
        }));
        try {
          setSchedules(data);
          setLoading(false);
        } catch {
          setSchedules([]);
          setLoading(false);
        }
      },
      () => {
        setLoading(false);
      }
    );
  };
  const getHooks = async () => {
    await fetcher.get(
      "fetching system hook",
      `/api/sys/${id}/hooks`,
      ({ hooks }) => {
        try {
          setHooks(hooks);
        } catch (e) {
          console.log(e);
          setHooks([]);
        }
      },
      () => {}
    );
  };
  const deleteHook = async (hook) => {
    await fetcher.delete(
      "deleting hook",
      `/api/sys/${id}/hook/${hook}`,
      ({ success, error }) => {
        if (error) return;
        getHooks();
      },
      () => {}
    );
  };

  React.useEffect(() => {
    if (!open) return;
    view === 0 ? getJobs() : view === 1 ? getSchedules() : getHooks();
  }, [open, view]);
  // const JobView = () => {
  //   return (
  //     <Stack spacing={2}>
  //       <Stack direction="row" alignItems="center" spacing={1}>
  //         <IconButton onClick={() => setJob(null)}>
  //           <Icon>arrow_back_ios</Icon>
  //         </IconButton>
  //         <Typography variant="h5">Schedule</Typography>
  //       </Stack>
  //       <List>
  //         <ListItem>
  //           <Typography>Name: {job.name}</Typography>
  //         </ListItem>
  //         <ListItem>
  //           <Typography>Run Once: {String(job.once)}</Typography>
  //         </ListItem>
  //         <ListItem>
  //           <Typography>Cron: {job.cron}</Typography>
  //         </ListItem>
  //         <ListItem>
  //           <Typography>Target: {job.target ?? "None"}</Typography>
  //         </ListItem>
  //       </List>
  //       <CodeViewer code={{ selection: job.selection, inputs: job.inputs }} />
  //     </Stack>
  //   );
  // };
  // const scheduleCols = [
  //   {
  //     name: "name",
  //     header: "Name",
  //     defaultWidth: 200,
  //   },
  //   {
  //     name: "cron",
  //     header: "Cron",
  //     defaultWidth: 150,
  //     render: ({ data }) => {
  //       return data.cron;
  //     },
  //   },
  //   {
  //     name: "schedule",
  //     header: "Schedule",
  //     defaultWidth: 250,
  //     render: ({ data }) => {
  //       return `${getNextCronTime(data?.content?.cron)}`;
  //     },
  //   },
  //   {
  //     name: "pk",
  //     header: "Actions",
  //     defaultWidth: 100,
  //     textAlign: "center",
  //     render: ({ data }) => {
  //       return (
  //         <Stack spacing={1} direction="row">
  //           <IconButton onClick={() => setJob(data)} color="error">
  //             <Icon color="secondary">travel_explore</Icon>
  //           </IconButton>

  //           <IconButton
  //             onClick={() => openDeleteModal(true, { id: data.pk })}
  //             color="error"
  //           >
  //             <Icon color="error">close</Icon>
  //           </IconButton>
  //         </Stack>
  //       );
  //     },
  //   },
  // ];
  // const jobCols = [
  //   {
  //     name: "name",
  //     header: "Name",
  //     defaultWidth: 300,
  //   },
  //   {
  //     name: "status",
  //     header: "Status",
  //     defaultWidth: 100,
  //   },
  //   {
  //     name: "start",
  //     header: "Start Time",
  //     render: ({ data }) =>
  //       data?.start ? getUserFriendlyDate(data?.start) : "",
  //     defaultWidth: 200,
  //   },
  //   {
  //     name: "end",
  //     header: "Stop Time",
  //     render: ({ data }) => (data?.end ? getUserFriendlyDate(data?.end) : ""),
  //     defaultWidth: 200,
  //   },
  //   {
  //     name: "pk",
  //     header: "Actions",
  //     defaultWidth: 100,
  //     textAlign: "center",
  //     render: ({ data }) => {
  //       if (!data.path) return null;
  //       return (
  //         <IconButton
  //           onClick={() =>
  //             window.open(
  //               window.location.origin +
  //                 `/log/view?job=${data.pk}&log=${data.path}&name=${data?.name}`,
  //               "_blank",
  //               "toolbar=0,location=0,menubar=0,titlebar=no,directories=no,status=no,width=1000,height=600"
  //             )
  //           }
  //           color="secondary"
  //         >
  //           <Icon color="secondary">travel_explore</Icon>
  //         </IconButton>
  //       );
  //     },
  //   },
  // ];
  const Webhooks = () => {
    const [webhook, setWebhook] = React.useState(false);
    const [systemReadUrl, setSystemReadUrl] = React.useState(false);
    const [openDeleteModal, DeleteModal] = useConfirm({
      title: `Delete Webhook`,
      message: `Are you sure you want to delete this webhook? This action can't be undone.`,
      callback: (props, context) => {
        deleteHook(context?.pk);
      },
    });
    if (!dataBacked || ["operator", "auditor"].includes(role)) return null;
    return (
      <Stack p={2}>
        <NewReadWebhookModal
          open={systemReadUrl}
          setOpen={setSystemReadUrl}
          refresh={getHooks}
          system={id}
        />
        <DeleteModal />
        <div style={{ marginLeft: "auto" }}>
          <ActionButton
            dark
            icon="webhook"
            noload
            handler={async () => setSystemReadUrl(true)}
          >
            Create Read Webhook
          </ActionButton>
        </div>
        <Stack p={2}>
          <SidePanel
            width={600}
            open={!!webhook}
            closeDrawer={() => setWebhook(false)}
            props={{ hook: webhook, system: id }}
          >
            {WebhookView}
          </SidePanel>
          {hooks?.length ? (
            (hooks || []).map((item, index) => {
              return (
                <Stack
                  key={index}
                  sx={{ width: "98%", margin: "0 auto" }}
                  direction="row"
                  alignItems={"center"}
                  p={2}
                  className="floating"
                >
                  <Stack sx={{ width: "100%" }}>
                    <Stack alignItems={"center"} direction="row" gap={1}>
                      <Icon>
                        {item?.type === "read" ? "bookmark" : "edit_note"}
                      </Icon>
                      <Typography>{item.name}</Typography>
                    </Stack>
                    <Stack gap={1}>
                      <Typography variant="caption">
                        {/* {item.content.cron} */}
                      </Typography>

                      <Typography variant="caption">
                        {/* {getNextCronTime(item?.content?.cron)} */}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <ActionButton
                      noload
                      circle
                      icon="navigate_next"
                      handler={() => {
                        setWebhook(item);
                      }}
                    />
                    <ActionButton
                      danger
                      noload
                      circle
                      icon="clear"
                      handler={() => openDeleteModal(true, { ...item })}
                    />
                  </Stack>
                </Stack>
              );
            })
          ) : (
            <Typography>No webhooks</Typography>
          )}
        </Stack>
      </Stack>
    );
  };
  const Schedules = () => {
    const [schedule, setSchedule] = React.useState(false);
    const [openDeleteModal, DeleteModal] = useConfirm({
      title: `Delete Schedule`,
      message: `Are you sure you want to delete this schedule? This action can't be undone.`,
      callback: (props, context) => {
        deleteSchedule(context?.pk);
      },
    });
    if (!dataBacked || ["auditor"].includes(role)) return null;
    return (
      <Stack p={2}>
        <DeleteModal />
        <Stack p={2}>
          <SidePanel
            width={600}
            open={!!schedule}
            closeDrawer={() => setSchedule(false)}
            props={{ schedule, refresh: getSchedules }}
          >
            {ScheduleEdit}
          </SidePanel>
          {schedules?.length ? (
            (schedules || []).map((item, index) => {
              return (
                <Stack
                  key={index}
                  sx={{ width: "98%", margin: "0 auto" }}
                  direction="row"
                  alignItems={"center"}
                  p={2}
                  className="floating"
                >
                  <Stack sx={{ width: "100%" }}>
                    <Stack alignItems={"center"} direction="row" gap={1}>
                      <Switch
                        disabled
                        checked={item.enabled}
                        // onChange={() => {
                        //   setLimit(!limit);
                        //   setOrder("");
                        //   setLimitCount("");
                        // }}
                      />
                      <Typography>
                        {item.content.name.split("_").at(-1)}
                      </Typography>
                    </Stack>
                    <Stack gap={1}>
                      <Typography variant="caption">
                        {/* {item.content.cron} */}
                      </Typography>

                      <Typography variant="caption">
                        {/* {getNextCronTime(item?.content?.cron)} */}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <ActionButton
                      noload
                      circle
                      icon="edit"
                      handler={() => {
                        setSchedule(item);
                      }}
                    />
                    <ActionButton
                      danger
                      noload
                      circle
                      icon="clear"
                      handler={() => openDeleteModal(true, { ...item })}
                    />
                  </Stack>
                </Stack>
              );
            })
          ) : (
            <Typography>No schedules</Typography>
          )}
        </Stack>
      </Stack>
    );
  };
  const Queue = () => {
    const [log, setLog] = React.useState(null);
    return (
      <Stack p={2}>
        <SidePanel
          width={600}
          open={!!log}
          closeDrawer={() => setLog(null)}
          props={{
            event: { name: log?.name, key: log?.path },
          }}
        >
          {Log}
        </SidePanel>
        {data?.length ? (
          (data || [])
            .sort((a, b) => {
              // Convert the date strings into Date objects
              const dateA = new Date(getUserFriendlyDate(a?.start));
              const dateB = new Date(getUserFriendlyDate(b?.start));

              // Compare the dates
              return dateB - dateA; // For descending order (most current to historical)
            })
            .map((item, index) => {
              return (
                <Stack
                  key={index}
                  sx={{ width: "98%", margin: "0 auto" }}
                  direction="row"
                  alignItems={"center"}
                  p={2}
                  className="floating"
                >
                  <Stack sx={{ width: "100%" }}>
                    <Stack direction="row" gap={1}>
                      <Icon>{Status[item.status]}</Icon>
                      <Typography>{item.name.split("_").at(-1)}</Typography>
                    </Stack>
                    <Stack direction="row" gap={1}>
                      <Typography variant="caption">
                        {item?.start ? getUserFriendlyDate(item?.start) : ""}
                      </Typography>
                      <Typography variant="caption">-</Typography>
                      <Typography variant="caption">
                        {item?.end ? getUserFriendlyDate(item?.end) : ""}
                      </Typography>
                    </Stack>
                  </Stack>
                  <ActionButton
                    noload
                    circle
                    icon="sms"
                    handler={() => setLog(item)}
                  />
                </Stack>
              );
            })
        ) : (
          <Typography>No jobs</Typography>
        )}
      </Stack>
    );
  };
  return (
    <SidePanel
      width={600}
      open={open}
      closeDrawer={() => setOpen(null)}
      props={{}}
    >
      {({ handleClose }) => {
        return (
          <Stack p={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <Tabs value={view} onChange={(e, value) => setView(value)}>
                <Tab
                  label={
                    <Stack direction="row" gap={1}>
                      <Icon>pending</Icon>
                      <Typography>Queue</Typography>
                    </Stack>
                  }
                />
                {dataBacked && !["auditor"].includes(role) ? (
                  <Tab
                    label={
                      <Stack direction="row" gap={1}>
                        <Icon>schedule</Icon>
                        <Typography>Schedules</Typography>
                      </Stack>
                    }
                  />
                ) : null}
                {dataBacked && !["operator", "auditor"].includes(role) ? (
                  <Tab
                    label={
                      <Stack direction="row" gap={1}>
                        <Icon>webhooks</Icon>
                        <Typography>Webhooks</Typography>
                      </Stack>
                    }
                  />
                ) : null}
              </Tabs>

              <IconButton
                key="refresh"
                onClick={
                  view === 0 ? getJobs : view === 1 ? getSchedules : getHooks
                }
                color="secondary"
              >
                <Icon color="secondary">refresh</Icon>
              </IconButton>
            </Stack>
            {loading ? (
              <div style={{ padding: "50px", position: "relative" }}>
                <MiniLoader />
              </div>
            ) : null}
            {view === 2 && !loading ? <Webhooks /> : null}
            {view === 1 && !loading ? <Schedules /> : null}
            {view === 0 && !loading ? <Queue /> : null}
          </Stack>
        );
      }
      // !job ? (
      //   <>
      //     <DeleteModal />
      //     <TableView />
      //   </>
      // ) : (
      //   <JobView />
      // )
      }
    </SidePanel>
    // <Modal
    //   width={1000}
    //   forceOpen={!!open}
    //   onClose={() => {
    //     setOpen(false);
    //     setData([]);
    //     setJob(null);
    //   }}
    // >

    // </Modal>
  );
}
export function ActivitySidePanel({ open, setOpen, role }) {
  if (open?.type === "log") {
    return (
      <SidePanel
        width={600}
        open={open?.item}
        closeDrawer={() => setOpen(null)}
        props={{
          event: { name: open?.item?.content?.name, key: open?.item?.path },
        }}
      >
        {Log}
      </SidePanel>
    );
  }
  if (open?.type === "schedule") {
    return (
      <>
        <Stack p={2}>
          <SidePanel
            width={600}
            open={true}
            closeDrawer={() => setOpen(false)}
            props={{ schedule: open?.item, refresh: open?.refresh }}
          >
            {ScheduleEdit}
          </SidePanel>
        </Stack>
      </>
    );
  }
  if (open?.type === "webhook") {
    return (
      <SidePanel
        width={600}
        open={true}
        closeDrawer={() => setOpen(false)}
        props={{ hook: open?.item, system: open?.item?.data?.split("#")[1] }}
      >
        {WebhookView}
      </SidePanel>
    );
  }
  return null;
}
