import React from "react";
import Input from "common/components/Input";
import { Stack, Typography, Avatar, Icon } from "@mui/material";
import ColorPicker from "common/components/ColorPicker";
import useFetcher from "common/hooks/useFetcher";
import validate from "constants/validate";
import ActionButton from "common/components/ActionButton";
import toast from "react-hot-toast";
import { theme } from "theme";

export default function EditCrew({ refresh, onClose }) {
  const [color, setColor] = React.useState("#8458c3");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const fetcher = useFetcher();

  const saveCrew = async () => {
    let payload = { color, name, description };
    if (!validate("crew_edit", payload)) return;
    await fetcher.post(
      "saving crew",
      `/api/crews`,
      payload,
      ({ error }) => {
        if (error) return toast.error(error);
        refresh();
        onClose();
      },
      () => {}
    );
  };
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <Icon
            style={{
              fontSize: "40px",
              color: theme.palette.primary.main,
            }}
          >
            groups
          </Icon>
        </Avatar>
        <Stack>
          <Typography variant="h4">Add Crew</Typography>
          {/* <Typography variant="caption">{user?.username}</Typography> */}
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Crew Color</Typography>
        <Stack gap={1}>
          <Typography>
            Designate a crew banner color to help visually differientiate your
            new team.
          </Typography>
        </Stack>
        <Stack gap={1}>
          <ColorPicker {...{ color, setColor }}>Brand</ColorPicker>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Crew Name</Typography>
        <Stack gap={1}>
          <Typography>Identify your crew's new name.</Typography>
        </Stack>
        <Stack gap={1}>
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            placeholder="My New Crew"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Crew Description</Typography>
        <Stack gap={1}>
          <Typography>
            Define your crew's identity with a description.
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            placeholder="My Crew's Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            minRows={3}
            maxRows={3}
          />
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto" }}>
        <ActionButton
          icon="save"
          dark
          handler={async () => {
            await saveCrew();
          }}
        >
          Save Crew
        </ActionButton>
      </div>
    </Stack>
  );
}
