import React from "react";

const useInputForm = () => {
  const { current: inputs } = React.useRef([]);
  const Wrapper = ({ id, el, dataLabel, dataForm, ...rest }) => {
    return (
      <div
        {...{ id }}
        key={id}
        style={{ width: "100%" }}
        data-form={dataForm}
        data-label={dataLabel}
      >
        {React.createElement(el, { ...rest })}
      </div>
    );
  };
  const collect = (form = "default") => {
    let result = {};
    inputs.forEach(([i]) =>
      document.querySelectorAll(`#input-${i}`).forEach((i) => {
        if (i.dataset.form === form) {
          let key = !/\_/g.test(i.dataset.label)
            ? i.dataset.label
            : i.dataset.label.split("_")[1];
          let value = (i.querySelector("input") || i.querySelector("textarea"))
            ?.value;
          if (!key || !value) return;
          result[key] = value;
        }
      })
    );
    return result;
  };
  const getValues = (form) => collect(form);
  const getValue = (key) => {
    let el = document.querySelector(`#input-${key}`);
    let input = el.querySelector("input") || el.querySelector("textarea");
    return input.value;
  };
  const setValue = (name, value) => {
    let el = document.querySelector(
      `#input-${inputs.find(([k]) => k === name)[0]}`
    );
    let input = el.querySelector("input") || el.querySelector("textarea");
    if (!input) return;
    input.value = value;
  };
  const generate = ({ el, key, props }) => {
    let form = !/\_/g.test(key) ? "default" : key.split("_")[0];
    let exist = inputs.find(([k]) => k === key);
    if (exist) return exist[1];
    const comp = React.createElement(Wrapper, {
      dataForm: form,
      dataLabel: key,
      el,
      id: `input-${key}`,
      ...props,
    });
    inputs.push([key, comp]);
    return comp;
  };
  return { getValues, getValue, setValue, generate };
};

export default useInputForm;
