import React, { useState } from "react";
import {
  Modal,
  Typography,
  Button,
  Stack,
  IconButton,
  Icon,
  Box,
} from "@mui/material";
import Input from "common/components/Input";
import { LoadingButton } from "@mui/lab";
import ActionButton from "common/components/ActionButton";
const style = () => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#171717",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 3,
  p: 3,
  maxHeight: "90vh",
  overflow: "hidden",
});

export default function useConfirm({ title, message, callback, onCancel }) {
  const [open, setOpen] = useState(false);
  const [context, setContext] = useState(null);

  const openModal = (boolean, ...props) => {
    setOpen(boolean);
    setContext(props[0] ?? null);
  };
  const close = () => {
    setContext(null);
    setOpen(false);
  };

  const ConfirmModal = ({
    children,
    type = "error",
    noConfirm = false,
    ...props
  }) => {
    const [typeIn, setTypeIn] = useState("");
    const handleCallback = async (props) => {
      await callback(props, context);
      setTypeIn("");
      close();
    };
    return (
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal"
        aria-describedby="modal"
      >
        <Box
          className={type === "error" ? "redglowborder" : "glowborder"}
          sx={{
            ...style(),
            width: 500,
          }}
        >
          <IconButton
            onClick={() => {
              if (onCancel) onCancel();
              close();
            }}
            sx={{
              position: "absolute",
              right: "5px",
              top: "5px",
              zIndex: 2000,
            }}
            color={"error"}
          >
            <Icon color="error">close</Icon>
          </IconButton>

          <Stack gap={2}>
            <Stack alignItems={"center"}>
              <Icon sx={{ fontSize: "60px !important" }} color={type}>
                {type}
              </Icon>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                variant="h5"
              >
                {title}
              </Typography>
            </Stack>
            <Typography textAlign={"center"} variant="body1">
              {message}
            </Typography>
            {!noConfirm ? (
              <Input
                autoFocus
                size="small"
                sx={{ width: "100%" }}
                placeholder={`enter "confirm"`}
                value={typeIn}
                onChange={(e) => setTypeIn(e.target.value)}
              />
            ) : null}
            <Stack
              direction="row"
              justifyContent={"center"}
              sx={{ marginTop: "10px !important" }}
              spacing={2}
            >
              {noConfirm || typeIn === "confirm" ? (
                <ActionButton
                  danger
                  icon="play_arrow"
                  dark
                  handler={async () => {
                    await handleCallback(props);
                  }}
                >
                  Continue
                </ActionButton>
              ) : null}
              <ActionButton
                icon="done"
                dark
                handler={async () => {
                  if (onCancel) {
                    onCancel();
                  }
                  close();
                }}
              >
                Cancel
              </ActionButton>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    );
  };
  return [openModal, ConfirmModal];
}
