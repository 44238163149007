import React from "react";
import Page from "common/components/Page";
import {
  Icon,
  Grid,
  Typography,
  Stack,
  InputAdornment,
  IconButton,
  Chip,
} from "@mui/material";
import Input from "common/components/Input";
import MultiSelect from "common/components/MultiSelect";
import { useNavigate, useSearchParams } from "react-router-dom";

import useState from "common/hooks/useState";
import useFetcher from "common/hooks/useFetcher";
import useConfirm from "common/hooks/useConfirm";
import ActionButton from "common/components/ActionButton";
import SidePanel from "common/components/SidePanel";
import CreateSystem from "./components/CreateSystem";
import useFolderView from "common/hooks/useFolderView";

const Tile = ({
  system,
  deleteSystem,
  role,
  moveFolderButton: MoveFolderButton,
}) => {
  const navigate = useNavigate();
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete System`,
    message: `Are you sure you want to delete ${system?.name} system? This action cannot be undone.`,
    callback: () => {
      deleteSystem(system?.pk);
    },
  });
  return (
    <Grid
      md={3.9}
      sx={{
        flex: 1,
        position: "relative",
      }}
      className="user-card-title-ctn"
      item
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          alignItems: "flex-start",
          flex: 1,
        }}
        className="floating user-card-tile"
      >
        <DeleteModal />
        <Stack
          sx={{ height: "100%", justifyContent: "space-between" }}
          flex={1}
          gap={1}
          direction="column"
        >
          <Stack direction="row" gap={1} alignItems={"center"}>
            <Stack>
              <span className="user-card-tile__name">{system?.name}</span>
              <Typography variant="caption">{system?.owner_name}</Typography>
            </Stack>
            <div
              style={{
                marginLeft: "auto",
              }}
            >
              <Chip
                sx={{ fontWeight: "bold" }}
                color="secondary"
                label={system?.count > 0 ? "+" + system.count : 0}
              />
            </div>
          </Stack>
          <Typography variant="body2">{system?.description}</Typography>
          <Stack sx={{ marginLeft: "auto" }}>
            {system.tags.length ? (
              <Typography
                sx={{ height: "20px", fontWeight: "bold" }}
                variant="body2"
              >
                {system.tags.join(", ")}
              </Typography>
            ) : (
              <Typography
                sx={{ height: "20px", fontWeight: "bold" }}
                variant="body2"
              ></Typography>
            )}
          </Stack>
        </Stack>
      </div>
      <div className="user-card-tile__options">
        <IconButton
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            backgroundColor: "#28ebc0",
          }}
          onClick={() => navigate(`/app/sys/${system.id}/dash`)}
        >
          <Icon
            sx={{
              color: "#fff !important",
            }}
          >
            dashboard
          </Icon>
        </IconButton>
        {/* <IconButton
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            backgroundColor: "#ff711a",
          }}
          onClick={() => navigate(system?.id)}
        >
          <Icon
            sx={{
              color: "#fff !important",
            }}
          >
            tune
          </Icon>
        </IconButton> */}

        {!["operator", "auditor"].includes(role) ? (
          <>
            <MoveFolderButton id={system.id} />
            <IconButton
              color="error"
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "20px",
                backgroundColor: "#FF6961",
              }}
              onClick={() => openDeleteModal(true)}
            >
              <Icon
                sx={{
                  color: "#fff !important",
                }}
              >
                close
              </Icon>
            </IconButton>
          </>
        ) : null}
      </div>
    </Grid>
  );
};

export default function Systems({ role }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { systems, loading, error, refresh } = useState("/api/sys");
  const [newSystem, setNewSystem] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const [tag, setTag] = React.useState(searchParams.get("tag") || "");
  const [crewSearch, setCrewSearch] = React.useState("");

  const {
    FolderView,
    FolderHeader,
    folder,
    setFolder,
    TagUpdateDialog,
    moveFolder,
    setMoveFolder,
    MoveFolderButton,
    filterFolders,
  } = useFolderView();
  const fetcher = useFetcher();
  const onUpdateTag = async (tag) => {
    await fetcher.post(
      "updating system tags",
      `/api/sys/${moveFolder}/tag`,
      { oldTag: folder, newTag: tag },
      (data) => {
        if (data?.error) return toast.error(data?.error);
        refresh();
      },
      () => {}
    );
  };
  const deleteSystem = async (id) => {
    await fetcher.delete("deleting system", `/api/sys/${id}`, () => {
      refresh();
    });
  };
  React.useEffect(() => {
    window.e1_last_sys_search = window.location.search;
  }, [searchParams]);
  const searchFilter = () => {
    if (!systems) return [];
    if (!search && !tag && !crewSearch && !folder) return systems;
    let filter = [...systems];
    if (tag) {
      filter = filter.filter(({ tags }) => tags.includes(tag));
    }
    if (crewSearch) {
      filter = filter.filter(
        ({ owner_name, owner }) =>
          owner_name === crewSearch || owner === crewSearch
      );
    }
    if (search) {
      filter = filter.filter(
        ({ name, description }) =>
          name.toLowerCase().includes(search.toLowerCase()) ||
          description.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (folder) {
      if (folder === "default") return filter;
      filter = filter.filter(({ name, tags }) =>
        (tags || [])?.map((t) => t.toLowerCase())?.includes(folder)
      );
    }
    return filter;
  };
  let filteredSys = searchFilter();
  let [tags, tag_options] = filterFolders(filteredSys, systems);
  return (
    <Page
      actions={
        !["auditor", "operator"].includes(role)
          ? [
              <ActionButton
                icon="add"
                noload
                handler={async () => {
                  setNewSystem(true);
                }}
              >
                New System
              </ActionButton>,
            ]
          : null
      }
      loading={[loading]}
      header="Systems"
      refresh={refresh}
    >
      <TagUpdateDialog
        title="System"
        onUpdateTag={onUpdateTag}
        tags={tag_options}
        open={!!moveFolder}
        onClose={() => setMoveFolder(null)}
      />
      <SidePanel
        width="500px"
        open={newSystem}
        closeDrawer={() => setNewSystem(false)}
        props={{}}
      >
        {CreateSystem}
      </SidePanel>
      <Stack p={2} direction={"row"}>
        <FolderHeader />
        <Stack
          sx={{ marginLeft: "auto", justifyContent: "flex-end" }}
          gap={1}
          direction={"row"}
        >
          <div style={{ marginBottom: "20px" }}>
            <Input
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")} edge="end">
                      {search ? (
                        <Icon
                          sx={{ fontSize: "18px !important" }}
                          color="error"
                        >
                          close
                        </Icon>
                      ) : null}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="search..."
              style={{ marginLeft: "auto", width: "300px" }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <MultiSelect
            size="small"
            options={Array.from(
              new Set(
                systems?.reduce(
                  (a, t) => [...a, ...(t?.owner_name ? [t?.owner_name] : [])],
                  []
                )
              )
            )}
            loading={loading}
            label="Crew Filter"
            multiple={false}
            placeholder="Select a crew"
            value={crewSearch}
            setValue={setCrewSearch}
            sx={{ width: "200px" }}
          />
          <MultiSelect
            size="small"
            options={Array.from(
              new Set(systems?.reduce((a, t) => [...a, ...t.tags], []))
            )}
            loading={loading}
            label="Tag Filter"
            multiple={false}
            placeholder="Select a tag"
            value={tag}
            setValue={(v) => {
              setTag(v);
              if (!v) {
                if (searchParams.has("tag")) {
                  searchParams.delete("tag");
                }
                setSearchParams(searchParams);
                return;
              }
              setSearchParams({ ...searchParams, tag: v });
            }}
            sx={{ width: "200px" }}
          />
        </Stack>
      </Stack>
      {!folder ? (
        <FolderView tags={tags} />
      ) : (
        // <Grid container spacing={2}>
        //   {tags.map(([tag, value], index) => (
        //     <AnimatedGridItem
        //       onClick={() => setFolder(tag.toLowerCase())}
        //       p={2}
        //       item
        //       xs={6}
        //       sm={3}
        //       md={2}
        //       key={index}
        //     >
        //       <Box
        //         sx={{
        //           width: "100%",
        //           height: "200px", // Adjust the height as needed
        //           borderRadius: "8px",
        //           border: `1px solid ${theme.palette.primary.light}`,
        //           display: "flex",
        //           alignItems: "center",
        //           justifyContent: "center",
        //           padding: 2,
        //         }}
        //       >
        //         <Stack gap={1}>
        //           <Stack alignItems={"center"}>
        //             <Badge
        //               max={999}
        //               badgeContent={value}
        //               color="secondary"
        //               class
        //               anchorOrigin={{ vertical: "top", horizontal: "right" }}
        //               sx={{
        //                 "& .MuiBadge-badge": {
        //                   transition: ".3s",
        //                   fontWeight: "bold",
        //                   top: "20px",
        //                   zIndex: "-1",
        //                 },
        //               }}
        //             >
        //               <AnimatedIcon className="animated-icon">
        //                 folder
        //               </AnimatedIcon>
        //             </Badge>
        //             <span className="folder-text">{tag?.toLowerCase()}</span>
        //           </Stack>
        //         </Stack>
        //       </Box>
        //     </AnimatedGridItem>
        //   ))}
        // </Grid>
        <Stack spacing={2}>
          <Stack alignItems="start" spacing={2}>
            {filteredSys.length ? (
              <Grid gap={1} container>
                {filteredSys?.map((data, idx) => {
                  return (
                    <Tile
                      moveFolderButton={MoveFolderButton}
                      role={role}
                      deleteSystem={deleteSystem}
                      system={data}
                      key={data.pk}
                    />
                  );
                })}
              </Grid>
            ) : null}
          </Stack>
        </Stack>
      )}
    </Page>
  );
}
