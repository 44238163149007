import React from "react";
import { Stack, Avatar, Typography } from "@mui/material";
import Input from "common/components/Input";
import ActionButton from "common/components/ActionButton";
import useScopeAttributes from "common/hooks/useScopeAttributes";
import validate from "constants/validate";
import { VariablesIcon } from "routes";
import useFetcher from "common/hooks/useFetcher";
import MultiSelect from "common/components/MultiSelect";
import { TypeMap } from "constants";
import Switch from "common/components/Switch";
import toast from "react-hot-toast";
import { MiniLoader } from "common/components/Loader";
import copyContent from "common/utils/copyContent";
export default function ModifyVariable({ refresh, variable, onClose }) {
  const fetcher = useFetcher();
  const [loading, setLoading] = React.useState(false);
  const { ScopeInputs, getScope, setInit } = useScopeAttributes();
  const [name, setName] = React.useState(variable?.name || "");
  const [type, setType] = React.useState(variable?.type || "");
  const [secret, setSecret] = React.useState(variable?.secret || false);
  const [value, setValue] = React.useState(variable?.value || "");
  const [description, setDescription] = React.useState(
    variable?.description || ""
  );
  const saveVariable = async (close) => {
    if (type === "list") {
      try {
        let test = JSON.parse(value);
        if (typeof test === "object" && Array.isArray(test) && test !== null) {
        } else {
          return toast.error("value is not a valid JSON list");
        }
      } catch {
        return toast.error("value is not a valid JSON list");
      }
    }
    if (type === "object") {
      try {
        let test = JSON.parse(value);
        if (typeof test === "object" && !Array.isArray(test) && test !== null) {
        } else {
          return toast.error("value is not valid JSON");
        }
      } catch {
        return toast.error("value is not valid JSON");
      }
    }

    let payload = {
      value,
      secret,
      type,
      name,
      description,
      ...getScope(),
    };
    if (variable?.id) payload.id = variable.id;
    if (!validate("variable_edit", payload)) return setLoading(false);
    await fetcher.post("saving variable", `/api/vars`, payload, ({ error }) => {
      if (error) {
        toast.error(error);
        return;
      }
      refresh();
      onClose();
    });
  };
  const getVariableValue = async () => {
    setLoading(true);
    try {
      await fetcher.get(
        "getting variable value",
        `/api/vars/${variable?.id}/value`,
        ({ value }) => setValue(value)
      );
    } catch {}
    setLoading(false);
  };
  React.useEffect(() => {
    if (!variable?.pk) return;
    setInit(variable?.tags, variable?.acl);
    getVariableValue();
  }, [variable]);
  if (loading) {
    return (
      <div
        style={{
          marginTop: "50px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MiniLoader />
        <Typography sx={{ marginTop: "70px" }}>loading variable</Typography>
      </div>
    );
  }
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <VariablesIcon fill="#171717" size="50px" />
        </Avatar>
        <Stack>
          <Typography variant="h4">
            {variable?.pk ? "Update" : "Create"} Variable
          </Typography>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Information</Typography>
        <Stack gap={1}>
          <Typography>Identifying information for your variable.</Typography>
        </Stack>
        <Stack gap={1}>
          <Input
            size="small"
            dark
            disabled={variable.pk}
            sx={{ width: "100%" }}
            placeholder="Programmatic Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            placeholder="Description"
            value={description}
            multiline
            minRows={3}
            maxRows={3}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Type</Typography>
        <Stack gap={1}>
          <Typography>
            Establish what value type this variable will hold.
          </Typography>
        </Stack>
        <Stack gap={1}>
          <MultiSelect
            size="small"
            dark
            disabled={variable.pk}
            options={Object.keys(TypeMap).filter(
              (k) => !["bool", "float", "int", "date", "lstr"].includes(k)
            )}
            loading={false}
            label="Type"
            multiple={false}
            placeholder="Select a type"
            value={type}
            setValue={setType}
            sx={{ width: "100%" }}
          />
          <div style={{ width: "105px" }}>
            {type == "str" ? (
              <Switch
                disabled={variable.pk}
                label="Secret"
                checked={secret}
                onChange={() => setSecret(!secret)}
              />
            ) : null}
          </div>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Value</Typography>
        <Stack gap={1}>
          <Typography>Define your variable value.</Typography>
        </Stack>
        <Stack gap={1}>
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            placeholder="Value"
            value={value}
            type={type === "str" && secret ? "password" : "str"}
            onChange={(e) => setValue(e.target.value)}
            {...(type === "str" && secret
              ? {}
              : {
                  multiline: true,
                  minRows: 3,
                  maxRows: 20,
                })}
          />
        </Stack>
        {variable?.pk ? (
          <div style={{ marginLeft: "auto" }}>
            <ActionButton
              icon="content_copy"
              dark
              noload
              handler={async () => {
                toast.success("copied variable");
                copyContent(value);
              }}
            >
              Copy Value
            </ActionButton>
          </div>
        ) : null}
      </Stack>

      <Stack className="floating" gap={3}>
        <Typography variant="h5">Access Control</Typography>
        <Stack gap={1}>
          <ScopeInputs />
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto", display: "flex" }}>
        <ActionButton
          icon="save"
          dark
          disabled={!value}
          handler={async () => {
            await saveVariable();
          }}
        >
          Save Variable
        </ActionButton>
      </div>
    </Stack>
  );
}
