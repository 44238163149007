import React, { useState } from "react";
import { Icon } from "@mui/material";
import { CSSTransition } from "react-transition-group";
import useClickOutside from "common/hooks/useClickOutside";
const NavItem = (props) => {
  const [open, setOpen] = useState(false);
  const ref = useClickOutside(() => setOpen(false));
  const ButtonComponent = props.button;
  return (
    <li ref={ref} className="nav-item">
      <ButtonComponent onClick={() => setOpen(!open)} />
      {open && props.children(() => setOpen(false))}
    </li>
  );
};
const NavBar = (props) => {
  return (
    <nav className="dd navbar">
      <ul className="navbar-nav">{props.children}</ul>
    </nav>
  );
};
const DropdownMenu = ({ menu, close, noOffset }) => {
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);
  const calcHeight = (el) => {
    const height = el.offsetHeight + 30;
    setMenuHeight(height);
  };
  const DropdownItem = (props) => {
    return (
      <a
        className="menu-item"
        onClick={() => {
          if (props.goToMenu) {
            return setActiveMenu(props.goToMenu);
          }
          props.onClick();
          close();
        }}
      >
        {props.leftIcon && (
          <span className="icon-button">{props.leftIcon}</span>
        )}
        {props.children}
        {props.rightIcon && (
          <span className="icon-button icon-right">{props.rightIcon}</span>
        )}
      </a>
    );
  };
  const menus = Object.entries(menu).map(([currentMenu, items], index) => {
    return (
      <CSSTransition
        key={index}
        in={activeMenu === currentMenu}
        unmountOnExit
        timeout={500}
        classNames={currentMenu === "main" ? "menu-primary" : "menu-secondary"}
        onEnter={calcHeight}
      >
        <div onBlur={() => close()} className="menu">
          {currentMenu !== "main" && (
            <DropdownItem goToMenu="main" leftIcon={<Icon>arrow_back</Icon>}>
              Go back
            </DropdownItem>
          )}
          {Object.entries(items).map(([item, props], index) => (
            <DropdownItem
              key={index}
              rightIcon={props?.goToMenu && <Icon>chevron_right</Icon>}
              close={close}
              {...props}
            >
              {item}
            </DropdownItem>
          ))}
        </div>
      </CSSTransition>
    );
  });
  return (
    <div
      className="dropdown"
      style={{
        height: menuHeight,
        color: "purple",
        transform: "none",
        ...(noOffset
          ? { transform: "none" }
          : { transform: "translateX(-85%)" }),
      }}
    >
      {menus}
    </div>
  );
};

export default function MultiLevelMenu({ menu, button, noOffset }) {
  return (
    <NavBar>
      <NavItem button={button}>
        {(close) => (
          <DropdownMenu noOffset={noOffset} menu={menu} close={close} />
        )}
      </NavItem>
    </NavBar>
  );
}
