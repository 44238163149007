export const TypeMap = {
  str: {
    name: "Short String",
    icon: "format_size",
    desc: "String variable for passwords and connection strings",
    type: "str",
    default: "",
  },
  lstr: {
    name: "Long String",
    icon: "rtt",
    desc: "Designed for long strings",
    type: "str",
    default: "",
  },
  int: {
    name: "Integer",
    icon: "123",
    desc: "Interger variable for metrics and counts",
    type: "int",
    default: 0,
  },
  float: {
    name: "Float",
    icon: "numbers",
    desc: "Integer with decimals",
    type: "float",
    default: 1.1,
  },
  list: {
    name: "List",
    icon: "data_array",
    desc: "JSON variable for configurations and lists",
    type: "list",
    default: [],
  },
  object: {
    name: "Object",
    icon: "data_object",
    desc: "JSON variable for configurations and lists",
    type: "dict",
    default: {},
  },
  // drop: {
  //   name: "Dropdown",
  //   icon: "text_fields",
  //   desc: "Dropdown selection of strings",
  //   type: "string",
  // },
  date: {
    name: "Date",
    icon: "calendar_month",
    desc: "Date string",
    type: "date",
  },
  bool: {
    name: "Boolean",
    icon: "toggle_off",
    desc: " Boolean variable for switches and binary values",
    type: "bool",
    default: "False",
  },
};

export const TYPE_COERCE = {
  str: (v) => String(v),
  json: (v) => JSON.parse(v),
  int: (v) => Number(v),
  bool: (v) => String(v).toLowerCase() === "true",
};
export const TYPE_INPUT_NORMALIZE = {
  str: (v) => String(v),
  lstr: (v) => String(v),
  object: (v) => JSON.stringify(v, null, 4),
  list: (v) => JSON.stringify(v, null, 4),
  int: (v) => Number(v),
  float: (v) => Float(v),
  bool: (v) => String(v).toLowerCase() === "true",
  password: (v) => String(v),
};

export const RESERVED_PROPERTIES = ["triggers", "detections", "__superview"];

export const DEFAULT_PAGE_COUNT = 25;

export const PLUGIN_OPTIONS = [
  [
    "Data Backed",
    "Makes the system data aware and your actions are now able to reference and update a persistent state",
    "data",
    (x, dataBacked) => dataBacked,
    (x) => ({
      data: !x.data,
      graphs: false,
      detectors: false,
    }),
  ],
  [
    "Widgets",
    "Adds the ability for a system to create visualizations of your data. Must have Data Backed enabled.",
    "graphs",
    (x) => !x.data,
    (x) => ({ ...x, graphs: !x.graphs }),
  ],
  [
    "Detectors",
    "Adds the ability for a system to make actionable inferences on the dataset and create triggers. Must have Data Backed enabled.",
    "detectors",
    (x) => !x.data,
    (x) => ({ ...x, detectors: !x.detectors, triggers: false }),
  ],
  // [
  //   "Triggers",
  //   "Adds the ability for a system to automate the execution of actions based on a detector. Must have Detectors enabled.",
  //   "triggers",
  //   (x) => !x.data || !x.detectors,
  //   (x) => ({ ...x, triggers: !x.triggers }),
  // ],
];
