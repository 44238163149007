import React from "react";
import { Stack, Icon, Typography, Avatar } from "@mui/material";
import rootFetcher from "common/utils/fetcher";
import ReactMarkdown from "react-markdown";
import { MiniLoader } from "common/components/Loader";
import gmtToLocal from "common/utils/gmtToLocal";
import { themeOptions } from "theme";
import { theme } from "theme";
import isValidJSON from "common/utils/isValidJSON";
export default function Log({ event }) {
  const log = event?.key;
  let name = event?.name?.split("_")?.at(-1);
  let cmd_name = event?.name?.split("_")?.at(-2);
  let system_name = event?.name?.split("_")?.at(-3);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);
  const fetchHandler = async () => {
    if (!data) {
      setLoading(true);
    }
    let res = await rootFetcher(`/api/log?key=${encodeURI(log)}`);
    if (res?.log) {
      setData(JSON.parse(res.log));
      setLoading(false);
      return res;
    }

    return null;
  };
  const fetchLog = async () => {
    let res = await fetchHandler();
    if (res?.log) return;
    while (true) {
      await new Promise((r) => setTimeout(() => r(), 10000));
      const res = await fetchHandler();
      if (res?.log) {
        break;
      }
    }
  };

  React.useEffect(() => {
    if (!log || data?.output || data?.children) return;
    fetchLog();
  }, []);
  if (!loading && !data) return null;

  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <Icon
            style={{
              fontSize: "40px",
              color: theme.palette.primary.main,
            }}
          >
            description
          </Icon>
        </Avatar>
        <Stack>
          <Typography variant="h4">{name}</Typography>
          <Typography variant="body2">
            {`${system_name} | ${cmd_name}${
              event?.date ? ` | ${event.date}` : ""
            }`}
          </Typography>
        </Stack>
      </Stack>
      {loading ? (
        <div
          style={{
            marginTop: "100px",
            flex: 1,
            justifyContent: "center",
            display: "flex",
          }}
        >
          <MiniLoader />
        </div>
      ) : null}
      <Stack
        sx={{ position: "relative", maxWidth: "100vw" }}
        alignItems="start"
        gap={1}
        mt={2}
      >
        {data?.map(({ time, type, message }, i) => {
          return (
            <Stack
              sx={{
                position: "relative",
                width: "100%",
                background: themeOptions.palette.primary.dark,
                borderRadius: "20px",
                border: `1px solid ${themeOptions.palette.text.hint}`,
                transition: "0.1s ease-in-out",
              }}
              p={2}
              gap={1}
              key={i}
              direction="row"
              alignItems={"flex-start"}
            >
              <Icon color={type === "error" ? "error" : "success"}>
                {type === "error" ? "crisis_alert" : "info"}
              </Icon>
              <div style={{ overflow: "hidden" }}>
                <div>
                  {gmtToLocal(
                    new Date(
                      time.replace(
                        /(\d{4})\/(\d{2})\/(\d{2}) (\d{2}):(\d{2}):(\d{2})/,
                        "$1-$2-$3T$4:$5:$6Z"
                      )
                    )
                  )}
                </div>
                <div>
                  <ReactMarkdown
                    linkTarget="_blank"
                    className="react-markdown-custom"
                  >
                    {isValidJSON(message)
                      ? JSON.stringify(JSON.parse(message), null, 4)
                      : message}
                  </ReactMarkdown>
                </div>
              </div>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
