import * as React from "react";
import ReactDOM from "react-dom";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import toast from "react-hot-toast";

const RefreshButton = ({ refresh }) => {
  const parent = React.useRef();
  const el = React.useRef(document.createElement("div"));
  React.useEffect(() => {
    parent.current = document.getElementById("page-refresh");
    parent.current.appendChild(el.current);
    return () => {
      parent.current.removeChild(el.current);
    };
  }, []);
  const Refresh = () => (
    <IconButton
      onClick={() => {
        refresh();
      }}
      color="secondary"
    >
      <Icon sx={{ fontSize: "30px !important" }} color="secondary">
        refresh
      </Icon>
    </IconButton>
  );
  return ReactDOM.createPortal(<Refresh />, el.current);
};

export default RefreshButton;
