export default [
    "Target Groups allow you to limit a Command's scope within your environment.",
    "Variables allow you to easily manage and reference secrets or other data points within Commands.",
    "Commands can be executed independently or in a System's Discovery, Detector, and Actions config.",
    "Configure System Detectors with Commands to auto-respond on flagged resources.",
    "Archives contains logs for all executed Commands.",
    "Code snippets are available in the E1 Code Editor.",
    "Commands can be executed during editing for testing purposes.",
    "Code Scanning Reports can be downloaded from the Command page menu.",
    "There are 4 roles for access control: Auditor, Operator, Engineer, and Admin.",
    "Nexus Views are customizable dashboards that enable single-pane visibility.",
    "Add Commands to Nexus Views to help organize workflows across Systems.",
    "Code Scanning Reports can be downloaded from the Command page menu.",
  ];