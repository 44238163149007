import { useState } from "react";
import { Icon } from "@mui/material";
import { theme } from "theme";
import { MiniLoader } from "../Loader";
export default function ActionButton({
  dark,
  icon = "save",
  danger,
  noload,
  disabled,
  handler,
  children,
  circle,
  sx = {},
}) {
  const [status, setStatus] = useState(null);
  const circleOptions = circle
    ? {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "40px",
        width: "40px",
        borderRadius: "50%",
      }
    : {};
  const clickHandler = async () => {
    if (noload) {
      return handler();
    }
    setStatus("loading");
    try {
      await handler();
    } catch (e) {
      console.log(e);
      return setStatus("error");
    }
    setStatus("done");
  };
  return (
    <button
      disabled={!!status || disabled}
      style={{
        cursor: !disabled ? "pointer" : "not-allowed",
        backgroundColor: dark
          ? theme.palette.primary.dark
          : theme.palette.primary.main,
        ...circleOptions,
        ...sx,
      }}
      onClick={clickHandler}
      className="cssbuttons-io-button"
    >
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          fontFamily: '"Exo"',
          opacity: !!status ? 0 : 1,
          transition: ".3s",
        }}
      >
        <Icon
          className={
            "cssbutton-icon" + (danger ? " cssbutton-icon-danger" : "")
          }
          sx={{
            ...(disabled
              ? {
                  animation: "none !important",
                  color: `blue !important`,
                }
              : {}),
            fontSize: "20px !important",
            paddingBottom: "21px",
          }}
        >
          {icon}
        </Icon>
        {children ? <span style={{ fontSize: "15px" }}>{children}</span> : null}
      </div>
      <div
        key={
          status === "error" ? setTimeout(() => setStatus(null), 3000) : "error"
        }
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          alignItems: "center",
          opacity: status !== "error" ? 0 : 1,
          transition: ".3s",
        }}
      >
        <Icon
          sx={{
            color: "#FF6961 !important",
            fontSize: "30px !important",
          }}
        >
          crisis_alert
        </Icon>
      </div>
      <MiniLoader
        sx={{
          opacity: status !== "loading" ? 0 : 1,
          transition: ".3s",
        }}
      />

      <div
        key={
          status === "done" ? setTimeout(() => setStatus(null), 2000) : "done"
        }
        style={{
          opacity: status !== "done" ? 0 : 1,
          transition: ".3s",
        }}
        className="checkbox-wrapper-12"
      >
        <div className="cbx">
          <input defaultChecked type="checkbox" id="cbx-12" />
          <label htmlFor="cbx-12" />
          <svg fill="none" viewBox="0 0 15 14" height={14} width={15}>
            <path d="M2 8.36364L6.23077 12L13 2" />
          </svg>
        </div>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="goo-12">
              <feGaussianBlur
                result="blur"
                stdDeviation={4}
                in="SourceGraphic"
              />
              <feColorMatrix
                result="goo-12"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                mode="matrix"
                in="blur"
              />
              <feBlend in2="goo-12" in="SourceGraphic" />
            </filter>
          </defs>
        </svg>
      </div>

      {disabled ? (
        <div
          style={{
            position: "absolute",
            inset: 0,
            cursor: "not-allowed",
            zIndex: 30,
          }}
        />
      ) : null}
    </button>
  );
}
