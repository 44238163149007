import React from "react";
import Page from "common/components/Page";
import {
  Stack,
  IconButton,
  Icon,
  Typography,
  Button,
  Grid,
  Avatar,
  InputAdornment,
} from "@mui/material";
import MultiSelect from "common/components/MultiSelect";
import MultiLevelMenu from "common/components/MultilevelMenu";
import { useNavigate, useSearchParams } from "react-router-dom";
import useState from "common/hooks/useState";
import useFolderView from "common/hooks/useFolderView";
import { ReactComponent as AwsLogo } from "assets/icons/aws-logo.svg";
import { ReactComponent as GcpLogo } from "assets/icons/gcp-logo.svg";
import { ReactComponent as AzureLogo } from "assets/icons/azure-logo.svg";
import useFetcher from "common/hooks/useFetcher";
import AssetImage from "common/components/AssetImage";
import useConfirm from "common/hooks/useConfirm";
import Input from "common/components/Input";
import SidePanel from "common/components/SidePanel";
import ModifyTarget from "./components/ModifyTarget";
import ActionButton from "common/components/ActionButton";
import CustomPlatforms from "./components/CustomPlatforms";
export const PlatformIconMap = {
  aws: AwsLogo,
  gcp: GcpLogo,
  azure: AzureLogo,
};

const Tile = ({
  target,
  deleteTarget,
  setModifyPanel,
  onClose,
  moveFolderButton: MoveFolderButton,
}) => {
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete Target`,
    message: `Are you sure you want to delete ${target?.name} target? This action cannot be undone.`,
    callback: () => {
      deleteTarget(target?.id);
    },
  });
  return (
    <Grid
      md={3.9}
      sx={{
        flex: 1,
        position: "relative",
      }}
      className="user-card-title-ctn"
      item
    >
      <div
        style={{ width: "100%", flex: 1 }}
        className="floating user-card-tile"
      >
        <DeleteModal />
        <Stack flex={1} gap={1} direction="column">
          <Stack direction="row" gap={1} alignItems={"center"}>
            <Avatar
              sx={{
                background: "#262626",
                width: 40,
                height: 40,
              }}
            >
              {React.createElement(PlatformIconMap[target.type] ?? AssetImage, {
                ...{
                  style: { width: "40px" },
                  path: `/api/assets/instance/platforms/${target.type}/logo.png`,
                },
              })}
            </Avatar>
            <span className="user-card-tile__name">{target.name}</span>
          </Stack>
          <Typography variant="body2">
            {`${target.tc} tenant${Number(target.tc) === 1 ? "" : "s"} and ${
              target.gc
            } geograph${Number(target.gc) === 1 ? "y" : "ies"}`}
          </Typography>
          <Stack sx={{ marginLeft: "auto" }}>
            {target.tags.length ? (
              <Typography
                sx={{ height: "20px", fontWeight: "bold" }}
                variant="body2"
              >
                {target.tags.join(" ,")}
              </Typography>
            ) : (
              <Typography
                sx={{ height: "20px", fontWeight: "bold" }}
                variant="body2"
              ></Typography>
            )}
          </Stack>
        </Stack>
      </div>
      <div className="user-card-tile__options">
        <IconButton
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            backgroundColor: "#ff711a",
          }}
          onClick={() => setModifyPanel(target)}
        >
          <Icon
            sx={{
              color: "#fff !important",
            }}
          >
            tune
          </Icon>
        </IconButton>
        <MoveFolderButton id={target.pk} />
        <IconButton
          color="error"
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
          }}
          onClick={() => openDeleteModal(true)}
        >
          <Icon color="error">close</Icon>
        </IconButton>
      </div>
    </Grid>
  );
};

export default function Targets() {
  const { targets, loading, error, refresh } = useState("/api/targeting");
  const [modifyPanel, setModifyPanel] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [tag, setTag] = React.useState("");
  const [refreshModal, setRefreshModal] = React.useState(0);
  const [targetModal, setTargetModal] = React.useState(false);
  const [customModal, setCustomModal] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [filter, setFilter] = React.useState("");
  const [searchParams] = useSearchParams();
  const fetcher = useFetcher();
  const {
    FolderView,
    FolderHeader,
    folder,
    setFolder,
    TagUpdateDialog,
    moveFolder,
    setMoveFolder,
    MoveFolderButton,
    filterFolders,
  } = useFolderView();
  const searchFilter = () => {
    if (!targets) return [];
    if (!search && !tag && !folder) return targets;
    let filter = [...targets];
    if (tag) {
      filter = filter.filter(({ tags }) => tags.includes(tag));
    }
    if (search) {
      filter = filter.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (folder) {
      if (folder === "default") return filter;
      filter = filter.filter(({ name, tags }) =>
        (tags || [])?.map((t) => t.toLowerCase())?.includes(folder)
      );
    }
    return filter;
  };
  const deleteTarget = async (id) => {
    await fetcher.delete("deleting target", `/api/targeting/${id}`, () => {
      refresh();
    });
  };

  const navigate = useNavigate();
  React.useEffect(() => {
    let id = searchParams.get("id");
    if (id) {
      setFilter(id);
    }
  }, [searchParams]);
  let filteredTargets = searchFilter();
  let [tags, tag_options] = filterFolders(filteredTargets, targets);
  const onUpdateTag = async (tag) => {
    await fetcher.post(
      "updating target tags",
      `/api/targeting/${moveFolder}/tag`,
      { oldTag: folder, newTag: tag },
      (data) => {
        if (data?.error) return toast.error(data?.error);
        refresh();
      },
      () => {}
    );
  };
  return (
    <Page
      actions={[
        <ActionButton
          icon="add"
          noload
          handler={async () => {
            setModifyPanel(true);
          }}
        >
          Add Target
        </ActionButton>,
        <div style={{ position: "relative" }}>
          <MultiLevelMenu
            button={(props) => (
              <Button {...props}>
                <Icon>more_vert</Icon>
              </Button>
            )}
            menu={{
              main: {
                "View Tenants": {
                  leftIcon: <Icon>list</Icon>,
                  onClick: () => navigate("/app/tenants"),
                },
                "View Custom Platforms": {
                  leftIcon: <Icon>list</Icon>,
                  onClick: () => setCustomModal(true),
                },
              },
            }}
          />
        </div>,
      ]}
      loading={[loading]}
      header="Targets"
      refresh={refresh}
    >
      <TagUpdateDialog
        title="Target"
        onUpdateTag={onUpdateTag}
        tags={tag_options}
        open={!!moveFolder}
        onClose={() => setMoveFolder(null)}
      />
      <SidePanel
        open={!!customModal}
        closeDrawer={() => setCustomModal(false)}
        props={{ refresh, target: modifyPanel }}
      >
        {CustomPlatforms}
      </SidePanel>
      <SidePanel
        open={!!modifyPanel}
        closeDrawer={() => setModifyPanel(false)}
        props={{ refresh, target: modifyPanel }}
      >
        {ModifyTarget}
      </SidePanel>
      <Stack p={2} direction={"row"}>
        <FolderHeader />
        <Stack
          sx={{ marginLeft: "auto", width: "500px" }}
          gap={1}
          mb={3}
          direction={"row"}
        >
          <div>
            <Input
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")} edge="end">
                      {search ? (
                        <Icon
                          sx={{ fontSize: "18px !important" }}
                          color="error"
                        >
                          close
                        </Icon>
                      ) : null}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="search..."
              sx={{ marginLeft: "auto", width: "200px" }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <MultiSelect
            size="small"
            options={Array.from(
              new Set(targets?.reduce((a, t) => [...a, ...t.tags], []))
            )}
            loading={loading}
            label="Tag Filter"
            multiple={false}
            placeholder="Select a tag"
            value={tag}
            setValue={setTag}
            sx={{ width: "200px" }}
          />
        </Stack>
      </Stack>
      {!folder ? (
        <FolderView tags={tags} />
      ) : (
        <Stack alignItems="start" spacing={2}>
          {filteredTargets.length ? (
            <Grid gap={1} container>
              {filteredTargets?.map((data, idx) => {
                return (
                  <Tile
                    moveFolderButton={MoveFolderButton}
                    setModifyPanel={setModifyPanel}
                    deleteTarget={deleteTarget}
                    target={data}
                    key={data.id}
                  />
                );
              })}
            </Grid>
          ) : null}
        </Stack>
      )}
      <div style={{ height: "100px" }} />
    </Page>
  );
}
