import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiTextField from "@mui/material/TextField";
import { theme } from "theme";

const CssTextField = styled(MuiTextField)({
  background: "#262626",
  borderRadius: "7px",
  color: "rgba(239,237,239,0.87) !important",
  "& label.Mui-focused": {
    color: theme.palette.secondary.main,
  },
  "& .MuiInputLabel-root": {
    color: "rgba(239,237,239,0.87) !important",
  },
  "& .MuiOutlinedInput-input": {
    color: "rgba(239,237,239,0.87) !important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.secondary.main,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.primary.main,
    },
    // "&:hover fieldset": {
    //   borderColor: theme.palette.secondary.main,
    // },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.secondary.main,
    },
  },
  // New styles for date input
  '& input[type="date"]::-webkit-calendar-picker-indicator': {
    filter: "invert(1)",
  },
  '& input[type="date"]::-webkit-inner-spin-button': {
    display: "none",
  },
  '& input[type="date"]::-webkit-clear-button': {
    display: "none",
  },
});

export default React.forwardRef(function Input({ dark, ...props }, ref) {
  if (
    props.inputProps &&
    !props.inputProps["data-form"] &&
    props["data-form"]
  ) {
    props.inputProps["data-form"] = props["data-form"];
    props.inputProps["name"] = props["name"] || "";
  }
  return (
    <CssTextField
      InputLabelProps={{ shrink: true }}
      autoComplete="off"
      {...props}
      sx={{ ...(dark ? { background: "#171717" } : {}) }}
      ref={ref}
    />
  );
});
