import React, { useRef } from "react";
import Drawer from "common/components/Drawer";
import { Stack, Grid, Icon, IconButton, Button } from "@mui/material";
import Switch from "common/components/Switch";
import Input from "common/components/Input";
import CommandInput from "../Inputs";
import CommandEnvironment from "../Environment";
import CommandPackages from "../Packages";
import CommandTargeting from "../Targeting";
import CommandGeneral from "../General";
import SectionHeader from "common/components/SectionHeader";
import AccordionSection from "common/components/Accordian";
import { TargetsIcon, PackageIcon, VariablesIcon } from "routes";
export default function ConfigDrawer({
  data,
  setData,
  dataBacked,
  setE1Pkg,
  system,
  menu = () => null,
}) {
  const Menu = menu;
  let Sections = [
    {
      title: "General",
      icon: "article",
      content: <CommandGeneral {...{ data, setData, dataBacked }} />,
    },
    {
      title: "Inputs",
      icon: "input_circle",
      content: <CommandInput {...{ data, setData }} />,
    },
    {
      title: "Environment",
      component: <VariablesIcon size={28} fill="#ff914c" />,
      content: <CommandEnvironment {...{ data, setData }} />,
    },
    {
      title: "Packages",
      component: <PackageIcon size={28} fill="#ff914c" />,
      content: <CommandPackages {...{ data, system, setData, setE1Pkg }} />,
    },
    {
      title: "Targeting",
      component: <TargetsIcon size={28} fill="#ff914c" />,
      content: <CommandTargeting {...{ data, setData }} />,
    },
  ];
  return (
    <Stack sx={{ overflow: "hidden", height: "100vh" }} p={2} gap={1}>
      <Stack
        mb={2}
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <SectionHeader large icon={data?.icon}>
          {data?.name}
        </SectionHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Menu />
        </div>
      </Stack>
      <Stack sx={{ overflowY: "scroll", height: "100%" }}>
        <AccordionSection sections={Sections} />
      </Stack>
      <div style={{ height: "50px" }} />
    </Stack>
  );
}
