import Drawer from "common/components/Drawer";
import { Stack, Icon } from "@mui/material";
import SectionHeader from "common/components/SectionHeader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Tile from "common/components/Tile";
import ActionButton from "common/components/ActionButton";
import { themeOptions } from "theme";
export default function CodeAnalyzer({ data }) {
  return (
    <Drawer
      buttonComponent={(props) => (
        <ActionButton
          handler={props.onClick}
          icon="tips_and_updates"
          circle
          noload
        />
      )}
      width={500}
      key="tips_and_updates"
      icon="tips_and_updates"
      button="tips_and_updates"
    >
      <Stack p={2} gap={2}>
        <SectionHeader icon="code" large>
          Code Review
        </SectionHeader>

        <Stack alignItems={"center"}>
          <List
            sx={{
              width: "100%",
              maxWidth: 480,
              borderRadius: "8px",
              gap: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {data.map(({ line, type, message }, index) => {
              return (
                <Tile
                  key={index}
                  type={type}
                  icon={type === "security" ? "security" : "healing"}
                  text1={message}
                  text2={line ? `line ${line}` : ""}
                />
              );
            })}
          </List>
        </Stack>
      </Stack>
    </Drawer>
  );
}
