ace.define(
  "ace/theme/dracula",
  ["require", "exports", "module", "ace/lib/dom"],
  function (require, exports, module) {
    exports.isDark = true;
    exports.cssClass = "ace-dracula";
    exports.cssText =
      "\
      ::-webkit-scrollbar {\
        background: none;\
        width: 16px;\
        height: 16px;\
     }\
     ::-webkit-scrollbar-thumb {\
         border: solid 0 rgba(0, 0, 0, 0);\
         border-right-width: 4px;\
         border-left-width: 4px;\
         -webkit-border-radius: 9px 4px;\
         -webkit-box-shadow: inset 0 0 0 1px rgba(128, 128, 128, 0.2), inset 0 0 0 4px rgba(128, 128, 128, 0.2);\
     }\
     ::-webkit-scrollbar-track-piece {\
         margin: 4px 0;\
     }\
     ::-webkit-scrollbar-thumb:horizontal {\
         border-right-width: 0;\
         border-left-width: 0;\
         border-top-width: 4px;\
         border-bottom-width: 4px;\
         -webkit-border-radius: 4px 9px;\
     }\
     ::-webkit-scrollbar-thumb:hover {\
         -webkit-box-shadow:\
           inset 0 0 0 1px rgba(128,128,128,0.9),\
           inset 0 0 0 4px rgba(128,128,128,0.9);\
     }\
     ::-webkit-scrollbar-corner {\
         background: transparent;\
     }\
     .ace_scrollbar-h{margin: 0 2px}\
.ace-dracula .ace_gutter {\
background: #171717;\
color: rgb(144,145,148)\
}\
.ace-dracula .ace_print-margin {\
width: 1px;\
background: none !important;\
background: #333333;\
border: none;\
}\
.ace-dracula {\
background-color: #171717;\
color: rgba(239,237,239,1)\
}\
.ace-dracula .ace_cursor {\
color: #f8f8f0\
}\
.ace-dracula .ace_marker-layer .ace_selection {\
background: #333333\
}\
.ace-dracula.ace_multiselect .ace_selection.ace_start {\
box-shadow: 0 0 3px 0px #171717;\
border-radius: 2px\
}\
.ace-dracula .ace_marker-layer .ace_step {\
background: rgb(198, 219, 174)\
}\
.ace-dracula .ace_marker-layer .ace_bracket {\
margin: -1px 0 0 -1px;\
border: 1px solid #ff914c\
}\
.ace-dracula .ace_marker-layer .ace_active-line {\
background: #333333\
}\
.ace-dracula .ace_gutter-active-line {\
background-color: #333333\
}\
.ace-dracula .ace_marker-layer .ace_selected-word {\
box-shadow: 0px 0px 0px 1px #ff914c;\
border-radius: 3px;\
}\
.ace-dracula .ace_fold {\
background-color: #50fa7b;\
border-color: rgba(239,237,239,1)\
}\
.ace-dracula .ace_keyword {\
color: #ff79c6\
}\
.ace-dracula .ace_constant.ace_language {\
color: #bd93f9\
}\
.ace-dracula .ace_constant.ace_numeric {\
color: #bd93f9\
}\
.ace-dracula .ace_constant.ace_character {\
color: #bd93f9\
}\
.ace-dracula .ace_constant.ace_character.ace_escape {\
color: #ff79c6\
}\
.ace-dracula .ace_constant.ace_other {\
color: #bd93f9\
}\
.ace-dracula .ace_support.ace_function {\
color: #8be9fd\
}\
.ace-dracula .ace_support.ace_constant {\
color: #6be5fd\
}\
.ace-dracula .ace_support.ace_class {\
font-style: italic;\
color: #66d9ef\
}\
.ace-dracula .ace_support.ace_type {\
font-style: italic;\
color: #66d9ef\
}\
.ace-dracula .ace_storage {\
color: #ff79c6\
}\
.ace-dracula .ace_storage.ace_type {\
font-style: italic;\
color: #8be9fd\
}\
.ace-dracula .ace_invalid {\
color: #F8F8F0;\
background-color: #ff79c6\
}\
.ace-dracula .ace_invalid.ace_deprecated {\
color: #F8F8F0;\
background-color: #bd93f9\
}\
.ace-dracula .ace_string {\
color: #f1fa8c\
}\
.ace-dracula .ace_comment {\
color: #6272a4\
}\
.ace-dracula .ace_variable {\
color: #50fa7b\
}\
.ace-dracula .ace_variable.ace_parameter {\
font-style: italic;\
color: #ffb86c\
}\
.ace-dracula .ace_entity.ace_other.ace_attribute-name {\
color: #50fa7b\
}\
.ace-dracula .ace_entity.ace_name.ace_function {\
color: #50fa7b\
}\
.ace-dracula .ace_entity.ace_name.ace_tag {\
color: #ff79c6\
}\
.ace-dracula .ace_invisible {\
color: #626680;\
}\
.ace-dracula .ace_indent-guide {\
background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAEklEQVQImWNgYGBgYHB3d/8PAAOIAdULw8qMAAAAAElFTkSuQmCC) right repeat-y\
}";
    exports.$selectionColorConflict = true;

    var dom = require("../lib/dom");
    dom.importCssString(exports.cssText, exports.cssClass, false);
  }
);
(function () {
  ace.require(["ace/theme/dracula"], function (m) {
    if (typeof module == "object" && typeof exports == "object" && module) {
      module.exports = m;
    }
  });
})();
