import React from "react";
import { Box, Icon, Stack, Typography } from "@mui/material";
import tooltips from "common/components/Loader/config";
import LogoIcon from "assets/icons/logo";

const Size = {
  false: {
    size: "300px",
    fontSize: 16,
  },
  true: {
    size: "200px",
    fontSize: 12,
  },
};
export default function Loader({ small = false }) {
  const { size, fontSize } = Size[small];
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [tooltip, setTooltip] = React.useState(null);
  const interval = React.useRef();
  const randomIntFromInterval = (min, max) =>
    Math.floor(Math.random() * (max - min + 1) + min);
  React.useEffect(() => {
    const tooltipIndex = randomIntFromInterval(0, tooltips.length - 1);
    setTooltip(tooltips[tooltipIndex]);
    setTimeout(() => {
      setShowTooltip(true);
      interval.current = setInterval(() => {
        const tooltipIndex = randomIntFromInterval(0, tooltips.length - 1);
        setTooltip(tooltips[tooltipIndex]);
      }, 7000);
    }, 2000);
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, []);
  return (
    <Box
      sx={{
        overflow: "hidden",
        display: "flex",
        height: "80%",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Icon sx={{ height: size, width: size }}>
        <LogoIcon />
      </Icon>
      <Stack key={tooltip} sx={{ height: "80px", p: 2 }}>
        {showTooltip && !small ? (
          <>
            <Typography
              className="scale-up-center"
              variant="overline"
              sx={{ fontSize, p: 0, m: 0 }}
            >
              Crew Tip
            </Typography>
            <Typography
              className="scale-up-center"
              variant="body1"
              sx={{ fontSize, m: 0, p: 0 }}
            >
              {tooltip}
            </Typography>
          </>
        ) : null}
      </Stack>
    </Box>
  );
}

export const MiniLoader = ({ sx = { transition: ".3s" } }) => {
  return (
    <div className={`loader`} style={sx}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </div>
  );
};
