import React from "react";
import { Stack, Typography } from "@mui/material";
import { MiniLoader } from "common/components/Loader";
import Table from "common/components/Table";
import useFetcher from "common/hooks/useFetcher";
import ActionButton from "common/components/ActionButton";
export default function SelectGeos({ geos, platform, setGeos, onClose }) {
  const fetcher = useFetcher();
  const [loading, setLoading] = React.useState(true);
  const [availableGeos, setAvailableGeos] = React.useState([]);
  const [unselectedGeos, setUnselectedGeos] = React.useState(null);

  const getGeos = async () => {
    setLoading(true);
    setAvailableGeos([]);
    await fetcher.get(
      "fetching geographies",
      `/api/geo/${platform}`,
      ({ geos }) => {
        setAvailableGeos(geos);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  React.useEffect(() => {
    getGeos();
  }, []);
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Stack gap={1}>
          <Typography variant="h4">Select Geographies</Typography>
          <Typography variant="body2">
            Pick geographies that should be assoicated with the target.
          </Typography>
        </Stack>
      </Stack>
      <div>
        <ActionButton
          icon="check"
          sx={{ marginLeft: "auto" }}
          dark
          noload
          handler={async () => {
            onClose();
          }}
        >
          Done
        </ActionButton>
      </div>
      {loading ? (
        <div style={{ position: "relative" }}>
          <MiniLoader />
        </div>
      ) : availableGeos?.length ? (
        <Stack gap={1}>
          <Table
            width="600px"
            data={availableGeos || []}
            loading={loading}
            idProperty={"code"}
            checkboxColumn
            onSelectionChange={({ selected, unselected }) => {
              setUnselectedGeos(unselected);
              if (typeof selected == "boolean") return setGeos(selected);
              setGeos(Object.keys(selected));
            }}
            selected={
              typeof geos == "boolean"
                ? geos
                : geos?.reduce((a, c) => ({ ...a, [c]: true }), {}) || {}
            }
            unselected={unselectedGeos}
            cols={[
              {
                name: "name",
                header: "Name",
                defaultFlex: 1,
              },
              {
                name: "code",
                header: "Code",
                defaultFlex: 1,
              },
            ]}
          />
        </Stack>
      ) : null}
    </Stack>
  );
}
