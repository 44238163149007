import React from "react";
import Page from "common/components/Page";
import {
  Stack,
  Avatar,
  IconButton,
  Icon,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import MultiSelect from "common/components/MultiSelect";
import ActionButton from "common/components/ActionButton";
import SidePanel from "common/components/SidePanel";
import Input from "common/components/Input";
import useState from "common/hooks/useState";
import useFetcher from "common/hooks/useFetcher";
import { TypeMap } from "constants";
import useConfirm from "common/hooks/useConfirm";
import ModifyVariable from "./components/ModifyVariable";
import useFolderView from "common/hooks/useFolderView";
const Tile = ({
  variable,
  deleteVariable,
  setModVariable,
  moveFolderButton: MoveFolderButton,
}) => {
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete Variable`,
    message: `Are you sure you want to delete ${variable?.name} variable? This action cannot be undone.`,
    callback: () => {
      deleteVariable(variable?.id);
    },
  });
  return (
    <Grid
      md={2.9}
      sx={{
        flex: 1,
        position: "relative",
      }}
      className="user-card-title-ctn"
      item
    >
      <div
        style={{ width: "100%", flex: 1 }}
        className="floating user-card-tile"
      >
        <DeleteModal />
        <Stack flex={1} gap={1} direction="column">
          <Stack direction="row" gap={1} alignItems={"center"}>
            <Avatar
              sx={{
                background: "#262626",
                width: 40,
                height: 40,
              }}
            >
              <Icon color="secondary" sx={{ fontSize: "30px !important" }}>
                {TypeMap[variable.type]?.icon}
              </Icon>
            </Avatar>
            <Stack>
              <span className="user-card-tile__name">{variable.name}</span>
              <Typography variant="caption">{variable.owner_name}</Typography>
            </Stack>
          </Stack>
          <Typography variant="body2">{variable.description}</Typography>
          <Stack sx={{ marginLeft: "auto" }}>
            {variable.tags.length ? (
              <Typography
                sx={{ height: "20px", fontWeight: "bold" }}
                variant="body2"
              >
                {variable.tags.join(", ")}
              </Typography>
            ) : (
              <Typography
                sx={{ height: "20px", fontWeight: "bold" }}
                variant="body2"
              ></Typography>
            )}
          </Stack>
        </Stack>
      </div>
      <div className="user-card-tile__options">
        <IconButton
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            backgroundColor: "#ff711a",
          }}
          onClick={() => setModVariable(variable)}
        >
          <Icon
            sx={{
              color: "#fff !important",
            }}
          >
            tune
          </Icon>
        </IconButton>
        <MoveFolderButton id={variable.pk} />
        <IconButton
          color="error"
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
          }}
          onClick={() => openDeleteModal(true)}
        >
          <Icon color="error">close</Icon>
        </IconButton>
      </div>
    </Grid>
  );
};

export default function Variables() {
  const { variables, loading, error, refresh } = useState("/api/vars");
  const [variableModal, setVariableModal] = React.useState(false);
  const [modVariable, setModVariable] = React.useState(false);
  const [editReset, setEditReset] = React.useState(1);
  const [init, setInit] = React.useState(null);
  const [filter, setFilter] = React.useState("");
  const fetcher = useFetcher();
  const [search, setSearch] = React.useState("");
  const [tag, setTag] = React.useState("");
  const [type, setType] = React.useState("");
  const [crewSearch, setCrewSearch] = React.useState("");
  const {
    FolderView,
    FolderHeader,
    folder,
    setFolder,
    TagUpdateDialog,
    moveFolder,
    setMoveFolder,
    MoveFolderButton,
    filterFolders,
  } = useFolderView();
  const deleteVariable = async (id) => {
    await fetcher.delete("deleting variable", `/api/vars/${id}`, () => {
      refresh();
    });
  };
  const onUpdateTag = async (tag) => {
    await fetcher.post(
      "updating variable tags",
      `/api/vars/${moveFolder}/tag`,
      { oldTag: folder, newTag: tag },
      (data) => {
        if (data?.error) return toast.error(data?.error);
        refresh();
      },
      () => {}
    );
  };
  const searchFilter = () => {
    if (!variables) return [];
    if (!search && !tag && !type && !crewSearch && !folder) return variables;
    let filter = [...variables];
    if (type) {
      filter = filter.filter((v) => v.type === type);
    }
    if (crewSearch) {
      filter = filter.filter((v) => v.owner_name === crewSearch);
    }
    if (tag) {
      filter = filter.filter(({ tags }) => tags.includes(tag));
    }
    if (search) {
      filter = filter.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (folder) {
      if (folder === "default") return filter;
      filter = filter.filter(({ name, tags }) =>
        (tags || [])?.map((t) => t.toLowerCase())?.includes(folder)
      );
    }
    return filter;
  };
  let filteredVars = searchFilter();
  let [tags, tag_options] = filterFolders(filteredVars, variables);
  return (
    <Page
      actions={[
        <ActionButton
          icon="add"
          noload
          handler={async () => {
            setModVariable(true);
          }}
        >
          Add Variable
        </ActionButton>,
      ]}
      loading={[loading]}
      header="Variables"
      refresh={refresh}
    >
      <TagUpdateDialog
        title="Variable"
        onUpdateTag={onUpdateTag}
        tags={tag_options}
        open={!!moveFolder}
        onClose={() => setMoveFolder(null)}
      />
      <SidePanel
        width="600px"
        open={!!modVariable}
        closeDrawer={() => setModVariable(false)}
        props={{ refresh, variable: modVariable }}
      >
        {ModifyVariable}
      </SidePanel>
      <Stack p={2} direction={"row"}>
        <FolderHeader />
        <Stack
          sx={{ marginLeft: "auto", justifyContent: "flex-end" }}
          gap={1}
          direction={"row"}
        >
          <div style={{ display: "flex", marginBottom: "20px" }}>
            <Input
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")} edge="end">
                      {search ? (
                        <Icon
                          sx={{ fontSize: "18px !important" }}
                          color="error"
                        >
                          close
                        </Icon>
                      ) : null}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="search..."
              sx={{ marginLeft: "auto", width: "300px" }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <MultiSelect
            size="small"
            options={Array.from(
              new Set(
                variables?.reduce(
                  (a, t) => [...a, ...(t.owner_name ? [t.owner_name] : [])],
                  []
                )
              )
            )}
            loading={loading}
            label="Crew Filter"
            multiple={false}
            placeholder="Select a crew"
            value={crewSearch}
            setValue={setCrewSearch}
            sx={{ width: "200px" }}
          />
          <MultiSelect
            size="small"
            options={Array.from(
              new Set(variables?.reduce((a, t) => [...a, ...t.tags], []))
            )}
            loading={loading}
            label="Tag Filter"
            multiple={false}
            placeholder="Select a tag"
            value={tag}
            setValue={setTag}
            sx={{ width: "200px" }}
          />
          <MultiSelect
            size="small"
            options={Array.from(
              new Set(variables?.reduce((a, t) => [...a, t.type], []))
            )}
            loading={loading}
            label="Type Filter"
            multiple={false}
            placeholder="Select a type"
            value={type}
            setValue={setType}
            sx={{ width: "200px" }}
          />
        </Stack>
      </Stack>
      <Stack spacing={2}>
        {!folder ? (
          <FolderView tags={tags} />
        ) : (
          <Stack alignItems="start" spacing={2}>
            {filteredVars.length ? (
              <Grid gap={1} container>
                {filteredVars?.map((data, idx) => {
                  return (
                    <Tile
                      moveFolderButton={MoveFolderButton}
                      setModVariable={setModVariable}
                      deleteVariable={deleteVariable}
                      variable={data}
                      key={data.pk}
                    />
                  );
                })}
              </Grid>
            ) : null}
          </Stack>
        )}
      </Stack>
    </Page>
  );
}
