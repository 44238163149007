import React from "react";
import { Stack, Typography, Icon, IconButton, Button } from "@mui/material";
import Checkbox from "common/components/Checkbox";
import Input from "common/components/Input";
import DnDList from "common/components/DndList";
import useFetcher from "common/hooks/useFetcher";
import SidePanel from "common/components/SidePanel";
import { PropertyPanel } from "pages/System/components/PropertyManager";
import ActionButton from "common/components/ActionButton";
import validate from "constants/validate";
import { themeOptions } from "theme";
import { ChartIcons } from "common/components/Widget";
import { TypeMap } from "constants";
import { nanoid } from "nanoid";
import GraphBuilder from "common/components/GraphBuilder";
import { NewCommandPanel } from "pages/System/components/GeneralManagement";
import { DetectorPanel } from "pages/System/components/GeneralManagement";
import { TriggerPanel } from "pages/System/components/GeneralManagement";
import { useNavigate } from "react-router-dom";
import { ImportJSONPanel } from "pages/System/components/PropertyManager";
import MultiLevelMenu from "common/components/MultilevelMenu";
import toast from "react-hot-toast";
import copyContent from "common/utils/copyContent";
import SystemCopy from "pages/System/components/Copy";
import useConfirm from "common/hooks/useConfirm";
import { MiniLoader } from "common/components/Loader";
import AstroHubPublish from "pages/System/components/AstroHubPublish";
import useScopeAttributes from "common/hooks/useScopeAttributes";
import NewDetectorModal from "./NewDetector";
import CliCommand from "common/components/CliCommand";
import AccordionSection from "common/components/Accordian";
export default function EditPanel({ id, refresh }) {
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [dirty, setDirty] = React.useState(false);
  const [state, setState] = React.useState(null);
  const [dataBacked, setDataBacked] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [properties, setProperties] = React.useState([]);
  const [actions, setActions] = React.useState([]);
  const [widgets, setWidgets] = React.useState([]);
  const [detectors, setDetectors] = React.useState([]);
  const [propertyEditPanel, setPropertyEditPanel] = React.useState(false);
  const [importJSONPanel, setImportJSONPanel] = React.useState(false);
  const [widgetEditPanel, setWidgetEditPanel] = React.useState(false);
  const [sampleCache, setSampleCache] = React.useState("");
  const [detectorEditPanel, setDetectorEditPanel] = React.useState(false);
  const [newDetectorModal, setNewDetectorModal] = React.useState(false);
  const [triggerEditPanel, setTriggerEditPanel] = React.useState(false);
  const [newCommandPanel, setNewCommandPanel] = React.useState(false);
  const [publishPanel, setPublishPanel] = React.useState(false);
  const [copyModal, setCopyModal] = React.useState(false);
  const [cliModal, setCliModal] = React.useState(false);
  const {
    ScopeInputs,
    getScope,
    setInit,
    loading: scopeLoading,
  } = useScopeAttributes();
  const [random, setRandom] = React.useState(1);
  const deleteSystem = async (id) => {
    await fetcher.delete("deleting system", `/api/sys/${id}`, () => {
      navigate("/app/sys");
    });
  };
  React.useEffect(() => {
    setInit(state?.tags || [], state?.acl || []);
  }, [state, loading, scopeLoading]);
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete System`,
    message: `Are you sure you want to delete ${state?.name} system? This action cannot be undone.`,
    callback: () => {
      deleteSystem(id);
    },
  });
  const update = () => {
    setRandom((x) => x + 1);
    setDirty(true);
  };
  const onSaveGraph = async (payload) => {
    let graphs;
    if (widgets.find(({ id }) => id === payload.id)) {
      graphs = widgets?.map((g) => {
        if (g.id !== payload.id) return g;
        return payload;
      });
    } else {
      graphs = [...(widgets || []), payload];
    }
    setWidgets(graphs);
    update();
  };
  const fetchSystem = async () => {
    setLoading(true);
    setDirty(false);
    await fetcher.get(
      "fetching system",
      `/api/sys/${id}`,
      ({ system }) => {
        if (system.plugins.data) {
          setDataBacked(true);
        }
        setState(system);
        setDescription(system.description);
        setWidgets(system.graphs);
        setProperties(system.properties);
        setDetectors(system.detectors);
        setActions(system.actions);
        setInit(system.tags, system.acl);
      },
      () => {}
    );
    setLoading(false);
  };
  const saveSystem = async () => {
    const payload = {
      ...state,
      description,
      properties,
      graphs: widgets,
      detectors,
      actions,
      uid: properties.find((p) => p.uid)?.name || state?.uid,
      ...getScope(),
    };
    if (!validate("system_general_edit", payload)) return;
    await fetcher.post(
      "saving system",
      `/api/sys`,
      payload,
      (data) => {
        if (data?.error) return toast.error(data?.error);
        fetchSystem();
        refresh();
      },
      () => {}
    );
  };
  React.useEffect(() => {
    fetchSystem();
  }, []);
  if (loading) {
    return (
      <div
        style={{
          marginTop: "50px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MiniLoader />
        <Typography sx={{ marginTop: "70px" }}>
          loading system configuration
        </Typography>
      </div>
    );
  }
  let Sections = [
    {
      title: "General",
      icon: "article",
      content: (
        <Stack m={0} gap={1}>
          <Input
            sx={{ width: "100%" }}
            label="System Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline={true}
            minRows={3}
            dark
            maxRows={3}
          />
          <Checkbox
            text="Data Backed"
            disabled={dataBacked}
            key={random}
            value={!!state?.plugins?.data}
            onChange={(value) => {
              setState((state) => {
                if (value) {
                  setProperties([
                    {
                      colors: [],
                      id: nanoid(),
                      uid: true,
                      name: "id",
                      type: "str",
                      width: "",
                    },
                  ]);
                }
                state.plugins.data = value;
                return state;
              });
              update();
            }}
            id="data-backed-toggle"
          />
        </Stack>
      ),
    },
    {
      title: "Commands",
      icon: "terminal",
      content: (
        <Stack m={0} gap={1}>
          <Stack p={0} m={0} direction="row" alignItems={"center"}>
            <IconButton
              sx={{ marginLeft: "auto" }}
              onClick={() => setNewCommandPanel(true)}
            >
              <Icon>add</Icon>
            </IconButton>
          </Stack>
          {actions?.length ? null : (
            <Typography
              sx={{ textAlign: "center", fontSize: ".9rem" }}
              variant="caption"
            >
              No commands defined
            </Typography>
          )}
          <DnDList
            state={[actions, setActions]}
            itemComponent={({ item }) => (
              <Stack
                key={item.id}
                direction={"row"}
                alignItems={"center"}
                style={{ width: "100%" }}
                className="floating"
              >
                <Stack gap={1}>
                  <Stack direction={"row"} gap={1}>
                    <Icon>{item.icon}</Icon>
                    <Typography>{item.name}</Typography>
                  </Stack>
                  <Typography
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "normal",
                    }}
                    variant="subtitle1"
                  >
                    {item.description}
                  </Typography>
                </Stack>
                <div style={{ marginLeft: "auto" }}>
                  <IconButton
                    onClick={() =>
                      navigate(
                        `/app/sys/${state?.id}/action/${item?.id}?system=${state?.name}`
                      )
                    }
                  >
                    <Icon>navigate_next</Icon>
                  </IconButton>
                </div>
              </Stack>
            )}
          />
        </Stack>
      ),
    },
    {
      title: "Access Control",
      icon: "vpn_key",
      content: (
        <Stack sx={{ minHeight: "200px" }} p={1} m={0} gap={1}>
          <Stack p={0} m={0} direction="row" alignItems={"center"}></Stack>
          {scopeLoading ? (
            <div style={{ marginLeft: "20px", position: "relative" }}>
              <MiniLoader />
            </div>
          ) : (
            <>
              <ScopeInputs />
            </>
          )}
        </Stack>
      ),
    },
  ];
  if (state?.plugins?.data) {
    Sections.splice(1, 0, {
      title: "Properties",
      icon: "list_alt",
      content: (
        <Stack p={1} m={0} gap={1}>
          <Stack p={0} m={0} direction="row" alignItems={"center"}>
            <IconButton
              sx={{ marginLeft: "auto" }}
              onClick={() => setPropertyEditPanel(true)}
            >
              <Icon>add</Icon>
            </IconButton>
            <IconButton onClick={() => setImportJSONPanel(true)}>
              <Icon>data_object</Icon>
            </IconButton>
          </Stack>
          {properties?.length ? null : (
            <Typography
              sx={{ textAlign: "center", fontSize: ".9rem" }}
              variant="caption"
            >
              No properties defined
            </Typography>
          )}
          <DnDList
            state={[properties, setProperties]}
            itemComponent={({ item }) => (
              <Stack
                key={item.id}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                style={{ width: "100%", height: "50px" }}
                className="floating"
              >
                <Stack sx={{ width: "200px" }} direction={"row"} gap={1}>
                  <Icon>{TypeMap[item.type]?.icon}</Icon>
                  <Typography>{item.name}</Typography>
                </Stack>
                <div>
                  <IconButton onClick={() => setPropertyEditPanel(item)}>
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      let props = properties.filter((d) => d.id !== item.id);
                      setProperties(props);
                    }}
                  >
                    <Icon color="error">delete</Icon>
                  </IconButton>
                </div>
              </Stack>
            )}
          />
        </Stack>
      ),
    });
    Sections.splice(2, 0, {
      title: "Widgets",
      icon: "donut_large",
      content: (
        <Stack p={1} m={0} gap={1}>
          <Stack p={0} m={0} direction="row" alignItems={"center"}>
            <IconButton
              sx={{ marginLeft: "auto" }}
              onClick={() => setWidgetEditPanel(true)}
            >
              <Icon>add</Icon>
            </IconButton>
          </Stack>
          {widgets?.length ? null : (
            <Typography
              sx={{ textAlign: "center", fontSize: ".9rem" }}
              variant="caption"
            >
              No widgets defined
            </Typography>
          )}
          <DnDList
            state={[widgets, setWidgets]}
            itemComponent={({ item }) => (
              <Stack
                key={item.id}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                style={{ width: "100%", height: "50px" }}
                className="floating"
              >
                <Stack sx={{ width: "200px" }} direction={"row"} gap={1}>
                  <Icon>{ChartIcons[item.type || "Metric"]}</Icon>
                  <Typography>{item.name}</Typography>
                </Stack>
                <div>
                  <IconButton onClick={() => setWidgetEditPanel(item)}>
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      let graphs = widgets.filter((d) => d.id !== item.id);
                      setWidgets(graphs);
                    }}
                  >
                    <Icon color="error">delete</Icon>
                  </IconButton>
                </div>
              </Stack>
            )}
          />
        </Stack>
      ),
    });
    Sections.splice(Sections.length - 1, 0, {
      title: "Detectors",
      icon: "radar",
      content: (
        <Stack p={1} m={0} gap={1}>
          <Stack p={0} m={0} direction="row" alignItems={"center"}>
            <IconButton
              sx={{ marginLeft: "auto" }}
              onClick={() => setNewDetectorModal(true)}
            >
              <Icon>add</Icon>
            </IconButton>
          </Stack>
          <NewDetectorModal
            open={newDetectorModal}
            setOpen={setNewDetectorModal}
            setDetectors={setDetectors}
          />
          {detectors?.length ? null : (
            <Typography
              sx={{ textAlign: "center", fontSize: ".9rem" }}
              variant="caption"
            >
              No detectors defined
            </Typography>
          )}
          {detectors?.map((item) => {
            return (
              <Stack
                key={item.id}
                direction={"row"}
                alignItems={"center"}
                style={{ width: "100%" }}
                className="floating"
              >
                <Stack gap={1}>
                  <Stack direction={"row"} gap={1}>
                    <Icon>{item.icon}</Icon>
                    <Typography>{item.name}</Typography>
                  </Stack>
                  <Typography
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "normal",
                    }}
                    variant="subtitle1"
                  >
                    {item.description}
                  </Typography>
                </Stack>
                <div style={{ marginLeft: "auto" }}>
                  <IconButton onClick={() => setDetectorEditPanel(item)}>
                    <Icon>navigate_next</Icon>
                  </IconButton>

                  <IconButton
                    onClick={() => {
                      setDetectors(detectors.filter((d) => d.id !== item.id));
                    }}
                  >
                    <Icon color="error">delete</Icon>
                  </IconButton>
                </div>
              </Stack>
            );
          })}
        </Stack>
      ),
    });
  }

  return (
    <Stack sx={{ overflow: "hidden", height: "100vh" }} p={2} gap={1}>
      <CliCommand
        context={
          "Initialize this system's commands into your local development environment"
        }
        code={`e1 cmd init -s ${id}`}
        open={cliModal}
        onClose={() => setCliModal(false)}
      />
      <Stack
        direction="row"
        alignItems="center"
        pl={1}
        pr={1}
        mb={1}
        justifyContent={"space-between"}
      >
        <ActionButton
          dark
          noload
          // disabled={!dirty}
          icon={"save"}
          handler={async () => {
            saveSystem();
          }}
        >
          Save
        </ActionButton>
        <div style={{ position: "relative" }}>
          <MultiLevelMenu
            button={(props) => (
              <Button {...props}>
                <Icon>more_vert</Icon>
              </Button>
            )}
            menu={{
              main: {
                "Local Development": {
                  leftIcon: <Icon>terminal</Icon>,
                  onClick: () => {
                    setCliModal(true);
                  },
                },
                "Copy System": {
                  leftIcon: <Icon>content_copy</Icon>,
                  onClick: () => setCopyModal(true),
                },
                "Copy System Id": {
                  leftIcon: <Icon>content_copy</Icon>,
                  onClick: () => {
                    copyContent(id);
                    toast.success("copied system id");
                  },
                },
                "Publish to AstroHub": {
                  leftIcon: <Icon>publish</Icon>,
                  onClick: () => {
                    setPublishPanel(true);
                  },
                },
                "Delete System": {
                  leftIcon: <Icon color="error">delete</Icon>,
                  onClick: () => {
                    openDeleteModal(true);
                  },
                },
              },
            }}
          />
        </div>
      </Stack>
      <Stack sx={{ overflowY: "scroll", height: "100%" }}>
        <AccordionSection sections={Sections} />

        {/* {state?.plugins?.data ? (
          <Stack className="floating" p={1} m={0} gap={1}>
            <Stack p={0} m={0} direction="row" alignItems={"center"}>
              <Typography sx={{ fontSize: "1.1rem" }} variant="overline">
                Properties
              </Typography>
              <IconButton
                sx={{ marginLeft: "auto" }}
                onClick={() => setPropertyEditPanel(true)}
              >
                <Icon>add</Icon>
              </IconButton>
              <IconButton onClick={() => setImportJSONPanel(true)}>
                <Icon>data_object</Icon>
              </IconButton>
            </Stack>
            {properties?.length ? null : (
              <Typography sx={{ fontSize: ".9rem" }} variant="caption">
                No properties defined
              </Typography>
            )}
            <DnDList
              state={[properties, setProperties]}
              itemComponent={({ item }) => (
                <Stack
                  key={item.id}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  style={{ width: "100%", height: "50px" }}
                  className="floating"
                >
                  <Stack sx={{ width: "200px" }} direction={"row"} gap={1}>
                    <Icon>{TypeMap[item.type]?.icon}</Icon>
                    <Typography>{item.name}</Typography>
                  </Stack>
                  <div>
                    <IconButton onClick={() => setPropertyEditPanel(item)}>
                      <Icon>edit</Icon>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        let props = properties.filter((d) => d.id !== item.id);
                        setProperties(props);
                      }}
                    >
                      <Icon color="error">delete</Icon>
                    </IconButton>
                  </div>
                </Stack>
              )}
            />
          </Stack>
        ) : null} */}
        {/* {state?.plugins?.data ? (
          <Stack className="floating" p={1} m={0} gap={1}>
            <Stack p={0} m={0} direction="row" alignItems={"center"}>
              <Typography sx={{ fontSize: "1.1rem" }} variant="overline">
                Widgets
              </Typography>
              <IconButton
                sx={{ marginLeft: "auto" }}
                onClick={() => setWidgetEditPanel(true)}
              >
                <Icon>add</Icon>
              </IconButton>
            </Stack>
            {widgets?.length ? null : (
              <Typography sx={{ fontSize: ".9rem" }} variant="caption">
                No widgets defined
              </Typography>
            )}
            <DnDList
              state={[widgets, setWidgets]}
              itemComponent={({ item }) => (
                <Stack
                  key={item.id}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  style={{ width: "100%", height: "50px" }}
                  className="floating"
                >
                  <Stack sx={{ width: "200px" }} direction={"row"} gap={1}>
                    <Icon>{ChartIcons[item.type || "Metric"]}</Icon>
                    <Typography>{item.name}</Typography>
                  </Stack>
                  <div>
                    <IconButton onClick={() => setWidgetEditPanel(item)}>
                      <Icon>edit</Icon>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        let graphs = widgets.filter((d) => d.id !== item.id);
                        setWidgets(graphs);
                      }}
                    >
                      <Icon color="error">delete</Icon>
                    </IconButton>
                  </div>
                </Stack>
              )}
            />
          </Stack>
        ) : null} */}
        {/* <Stack className="floating" p={1} m={0} gap={1}>
          <Stack p={0} m={0} direction="row" alignItems={"center"}>
            <Typography sx={{ fontSize: "1.1rem" }} variant="overline">
              Commands
            </Typography>
            <IconButton
              sx={{ marginLeft: "auto" }}
              onClick={() => setNewCommandPanel(true)}
            >
              <Icon>add</Icon>
            </IconButton>
          </Stack>
          {actions?.length ? null : (
            <Typography sx={{ fontSize: ".9rem" }} variant="caption">
              No commands defined
            </Typography>
          )}
          <DnDList
            state={[actions, setActions]}
            itemComponent={({ item }) => (
              <Stack
                key={item.id}
                direction={"row"}
                alignItems={"center"}
                style={{ width: "100%" }}
                className="floating"
              >
                <Stack gap={1}>
                  <Stack direction={"row"} gap={1}>
                    <Icon>{item.icon}</Icon>
                    <Typography>{item.name}</Typography>
                  </Stack>
                  <Typography
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "normal",
                    }}
                    variant="subtitle1"
                  >
                    {item.description}
                  </Typography>
                </Stack>
                <div style={{ marginLeft: "auto" }}>
                  <IconButton
                    onClick={() =>
                      navigate(
                        `/app/sys/${state?.id}/action/${item?.id}?system=${state?.name}`
                      )
                    }
                  >
                    <Icon>navigate_next</Icon>
                  </IconButton>
                </div>
              </Stack>
            )}
          />
        </Stack> */}
        {/* {state?.plugins?.data ? (
          <Stack className="floating" p={1} m={0} gap={1}>
            <Stack p={0} m={0} direction="row" alignItems={"center"}>
              <Typography sx={{ fontSize: "1.1rem" }} variant="overline">
                Detectors
              </Typography>
              <IconButton
                sx={{ marginLeft: "auto" }}
                onClick={() => setNewDetectorModal(true)}
              >
                <Icon>add</Icon>
              </IconButton>
            </Stack>
            <NewDetectorModal
              open={newDetectorModal}
              setOpen={setNewDetectorModal}
              setDetectors={setDetectors}
            />
            {detectors?.length ? null : (
              <Typography sx={{ fontSize: ".9rem" }} variant="caption">
                No detectors defined
              </Typography>
            )}
            {detectors?.map((item) => {
              return (
                <Stack
                  key={item.id}
                  direction={"row"}
                  alignItems={"center"}
                  style={{ width: "100%" }}
                  className="floating"
                >
                  <Stack gap={1}>
                    <Stack direction={"row"} gap={1}>
                      <Icon>{item.icon}</Icon>
                      <Typography>{item.name}</Typography>
                    </Stack>
                    <Typography
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "normal",
                      }}
                      variant="subtitle1"
                    >
                      {item.description}
                    </Typography>
                  </Stack>
                  <div style={{ marginLeft: "auto" }}>
                    <IconButton onClick={() => setDetectorEditPanel(item)}>
                      <Icon>navigate_next</Icon>
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        setDetectors(detectors.filter((d) => d.id !== item.id));
                      }}
                    >
                      <Icon color="error">delete</Icon>
                    </IconButton>
                  </div>
                </Stack>
              );
            })}
          </Stack>
        ) : null} */}
        {/* <Stack
          sx={{ minHeight: "200px" }}
          className="floating"
          p={1}
          m={0}
          gap={1}
        >
          <Stack p={0} m={0} direction="row" alignItems={"center"}>
            <Typography sx={{ fontSize: "1.1rem" }} variant="overline">
              Access Control
            </Typography>
          </Stack>
          {scopeLoading ? (
            <div style={{ marginLeft: "20px", position: "relative" }}>
              <MiniLoader />
            </div>
          ) : (
            <>
              <ScopeInputs />
            </>
          )}
        </Stack> */}
      </Stack>
      <SidePanel
        width={500}
        open={propertyEditPanel}
        closeDrawer={() => setPropertyEditPanel(null)}
        props={{
          prop: propertyEditPanel,
          setProperties: (...args) => {
            setProperties(...args);
            update();
          },
        }}
      >
        {PropertyPanel}
      </SidePanel>
      <SidePanel
        width={500}
        open={importJSONPanel}
        closeDrawer={() => setImportJSONPanel(null)}
        props={{ setProperties }}
      >
        {ImportJSONPanel}
      </SidePanel>
      <SidePanel
        width={900}
        open={widgetEditPanel}
        closeDrawer={() => setWidgetEditPanel(null)}
        props={{
          data: state,
          onSave: onSaveGraph,
          existing: widgetEditPanel,
          sampleCache,
          setSampleCache,
        }}
      >
        {GraphBuilder}
      </SidePanel>
      <SidePanel
        width={500}
        open={newCommandPanel}
        closeDrawer={() => setNewCommandPanel(null)}
        props={{ system: state }}
      >
        {NewCommandPanel}
      </SidePanel>
      <SidePanel
        width={500}
        open={detectorEditPanel}
        closeDrawer={() => setDetectorEditPanel(null)}
        props={{
          setDetectors: (...args) => {
            setDetectors(...args);
            update();
          },
          detector: detectorEditPanel,
          setTriggerPanel: setTriggerEditPanel,
        }}
      >
        {DetectorPanel}
      </SidePanel>
      <SidePanel
        width={500}
        open={triggerEditPanel}
        closeDrawer={() => setTriggerEditPanel(null)}
        props={{ data: triggerEditPanel, setDetectors, system: state }}
      >
        {TriggerPanel}
      </SidePanel>
      <SidePanel
        width={500}
        open={publishPanel}
        closeDrawer={() => setPublishPanel(null)}
        props={{ system: state }}
      >
        {AstroHubPublish}
      </SidePanel>
      <DeleteModal />
      <SystemCopy
        {...{
          open: copyModal,
          onClose: () => setCopyModal(false),
          data: state,
        }}
      />
      <div style={{ height: "100px" }} />
    </Stack>
  );
}
