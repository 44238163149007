import React, { useEffect, useState } from "react";
import { Stack, Icon, Typography, IconButton } from "@mui/material";
import ReactMarkdown from "react-markdown";
import TieredList from "common/components/TieredList";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
import "react18-json-view/src/dark.css";
import remarkGfm from "remark-gfm";
import SidePanel from "common/components/SidePanel";
import ActionButton from "common/components/ActionButton";
import Switch from "common/components/Switch";
import Checkbox from "common/components/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "common/components/Input";
import E1Input from "common/components/E1Input";

const JsonVerticalTable = ({ data }) => {
  const [current, setCurrent] = useState(data[0]);
  const [nav, setNav] = useState([]);
  const headers = Object.keys(current)?.filter(
    (k) => !["__superview"].includes(k)
  );
  useEffect(() => {
    setCurrent(data[0]);
    setNav([]);
  }, [data]);
  const NavLink = ({ item, index }) => {
    return (
      <span
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (index === "root") {
            setNav([]);
            setCurrent(data[0]);
          }
          let place = nav.slice(0, index + 1);
          setNav(place);
          setCurrent(place.reduce((a, c) => a[c], data[0]));
        }}
        index={index}
      >{`${index === "root" ? "" : " / "}${item}`}</span>
    );
  };
  const renderCell = (item) => {
    const urlRegex = /^(https?:\/\/[^\s$.?#].[^\s]*)$/;
    if (typeof item === "boolean") {
      return <Checkbox text="" disabled value={item} />;
    } else if (typeof item === "string" && urlRegex.test(item)) {
      return (
        <a href={item} target="_blank" rel="noopener noreferrer">
          {item}
        </a>
      );
    }
    return <span style={{ wordWrap: "break-word" }}>{item}</span>;
  };
  return (
    <div className="floating">
      <Typography variant="h6">
        <NavLink item="Resource" index="root" />
        {nav.map((n, i) => (
          <NavLink item={n} index={i} />
        ))}
      </Typography>
      <table
        className="resourceTable"
        style={{
          width: "100%",
          minWidth: "600px",
          tableLayout: "fixed",
          borderRadius: "7px",
          borderCollapse: "separate",
          borderSpacing: "10px 10px",
        }}
      >
        <tbody>
          {headers.map((header) => (
            <tr key={header}>
              <td
                style={{
                  width: "250px",
                  minWidth: "250px",
                  wordWrap: "break-word",
                  padding: "10px",
                  background: "#1e1e1e",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  position: "sticky",
                  left: 0,
                }}
              >
                {header}
              </td>
              <td
                style={{
                  wordWrap: "break-word",
                  padding: "10px",
                  borderRadius: "10px",
                  minWidth: "350px",
                }}
              >
                {current[header] !== Object(current[header]) ? (
                  renderCell(current[header])
                ) : (
                  <Icon
                    onClick={() => {
                      setNav([...nav, header]);
                      setCurrent(current[header]);
                    }}
                  >
                    arrow_forward
                  </Icon>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
// const xJsonVerticalTable = ({ data }) => {
//   const [collapsedRows, setCollapsedRows] = useState({});

//   const toggleCollapse = (rowId) => {
//     setCollapsedRows((prev) => ({
//       ...prev,
//       [rowId]: !prev[rowId],
//     }));
//   };

//   const renderCell = (value, key) => {
//     const urlRegex = /^(https?:\/\/[^\s$.?#].[^\s]*)$/;

//     if (Array.isArray(value)) {
//       return (
//         <ul style={{ paddingLeft: "20px", margin: 0 }}>
//           {value.map((item, index) => (
//             <li key={index}>{renderCell(item, `${key}-${index}`)}</li>
//           ))}
//         </ul>
//       );
//     } else if (typeof value === "object" && value !== null) {
//       return (
//         <div
//           className="floating"
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             width: "100%",
//           }}
//         >
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               paddingLeft: "10px",
//               position: "sticky",
//               left: 0,
//               backgroundColor: "#1e1e1e",
//               zIndex: 1,
//             }}
//           >
//             {collapsedRows[key] ? (
//               <span style={{ fontWeight: "bold" }}>...</span>
//             ) : null}
//             <IconButton
//               sx={{ marginLeft: "auto" }}
//               onClick={() => toggleCollapse(key)}
//             >
//               <Icon>
//                 {collapsedRows[key]
//                   ? "keyboard_arrow_down"
//                   : "keyboard_arrow_up"}
//               </Icon>
//             </IconButton>
//           </div>
//           {!collapsedRows[key] && (
//             <div>
//               <table
//                 className="resourceTable"
//                 style={{
//                   width: "100%",
//                   minWidth: "600px",
//                   tableLayout: "fixed",
//                   borderRadius: "7px",
//                   borderCollapse: "separate",
//                   borderSpacing: "10px 10px",
//                 }}
//               >
//                 <tbody>
//                   {Object.entries(value).map(([subKey, val]) => (
//                     <tr key={subKey}>
//                       <td
//                         style={{
//                           width: "250px",
//                           minWidth: "250px",
//                           wordWrap: "break-word",
//                           padding: "5px",
//                           paddingLeft: "10px",
//                           background: "#1e1e1e",
//                           fontWeight: "bold",
//                           borderRadius: "10px",
//                           borderWidth: "0",
//                           position: "sticky",
//                           left: 0,
//                         }}
//                       >
//                         {subKey}
//                       </td>
//                       <td
//                         style={{
//                           wordWrap: "break-word",
//                           padding: "5px",
//                           paddingLeft: "10px",
//                           borderRadius: "10px",
//                           borderWidth: "0",
//                           minWidth: "350px",
//                         }}
//                       >
//                         {renderCell(val, `${key}-${subKey}`)}
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           )}
//         </div>
//       );
//     } else if (typeof value === "boolean") {
//       return <Checkbox text="" disabled value={value} />;
//     } else if (typeof value === "string" && urlRegex.test(value)) {
//       return (
//         <a href={value} target="_blank" rel="noopener noreferrer">
//           {value}
//         </a>
//       );
//     } else {
//       return <span style={{ wordWrap: "break-word" }}>{value}</span>;
//     }
//   };

//   const headers = Object.keys(data[0])?.filter(
//     (k) => !["__superview"].includes(k)
//   );

//   return (
//     <div className="floating" style={{ overflowX: "auto" }}>
//       <Typography variant="h6">Resource</Typography>
//       <table
//         className="resourceTable"
//         style={{
//           width: "100%",
//           minWidth: "600px",
//           tableLayout: "fixed",
//           borderRadius: "7px",
//           borderCollapse: "separate",
//           borderSpacing: "10px 10px",
//         }}
//       >
//         <tbody>
//           {headers.map((header) => (
//             <tr key={header}>
//               <td
//                 style={{
//                   width: "250px",
//                   minWidth: "250px",
//                   wordWrap: "break-word",
//                   padding: "10px",
//                   background: "#1e1e1e",
//                   fontWeight: "bold",
//                   borderRadius: "10px",
//                   position: "sticky",
//                   left: 0,
//                 }}
//               >
//                 {header}
//               </td>
//               {data.map((item, index) => (
//                 <td
//                   key={index}
//                   style={{
//                     wordWrap: "break-word",
//                     padding: "10px",
//                     borderRadius: "10px",
//                     minWidth: "350px",
//                   }}
//                 >
//                   {renderCell(item[header], `${header}-${index}`)}
//                 </td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

export default function ResourceModal({
  refresh,
  system,
  open,
  setOpen,
  colors,
  params,
  selectAndOpenCommand,
}) {
  return (
    <SidePanel
      open={open}
      width={900}
      closeDrawer={() => {
        setOpen(null);
      }}
    >
      {({ onClose }) => {
        const [json, setJson] = React.useState({});
        const [search, setSearch] = React.useState("");
        React.useEffect(() => {
          if (!open) return;
          setJson(open);
        }, [open]);
        const detectorTriggerTier = (open?.detections ?? []).map((d) => {
          let detector = system.detectors.find(({ id }) => id === d);
          let triggers =
            detector?.triggers?.filter((t) => {
              return open?.triggers?.includes(t.id);
            }) ?? [];
          let subItems = triggers.map((t) => ({
            id: t.id,
            component: (
              <div
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon color="success">chevron_right</Icon>
                <span>{t.name}</span>
              </div>
            ),
          }));
          return {
            id: d,
            component: (
              <div
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon>{detector.icon}</Icon>
                <span style={{ marginLeft: "10px" }}>{detector.name}</span>
              </div>
            ),
            items: subItems,
          };
        });
        const filtered = Object.fromEntries(
          Object.entries(json).filter(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              return (
                Object.values(value).some((v) =>
                  String(v)
                    .toLowerCase()
                    .includes(search.toLowerCase())
                ) || key.toLowerCase().includes(search.toLowerCase())
              );
            }
            return (
              String(value)
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              key.toLowerCase().includes(search.toLowerCase())
            );
          })
        );
        if (!open) return null;
        return (
          <Stack spacing={2} padding={3}>
            <Stack alignItems={"center"} direction={"row"} gap={1}>
              <Icon>fingerprint</Icon>
              <Typography variant="body1" sx={{ width: "calc(100% - 250px)" }}>
                {open[system.uid]}
              </Typography>
              {system?.actions?.filter(({ selection }) => selection)?.length ? (
                <span style={{ width: "200px", marginLeft: "auto" }}>
                  <ActionButton
                    icon="bolt"
                    dark
                    noload
                    handler={() => {
                      selectAndOpenCommand(open[system.uid]);
                      onClose();
                      // await updateLogoPicture();
                    }}
                  >
                    Run Command
                  </ActionButton>
                </span>
              ) : null}
            </Stack>
            {open["__superview"] ? (
              <Stack className="floating">
                <div>
                  <ReactMarkdown
                    linkTarget="_blank"
                    className="react-markdown-custom"
                    remarkPlugins={[remarkGfm]}
                  >
                    {open["__superview"]
                      ?.split("\n")
                      ?.map((x) => x.trim())
                      ?.join("\n") ?? ""}
                  </ReactMarkdown>
                </div>
              </Stack>
            ) : null}
            <div>
              <Input
                dark
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>search</Icon>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearch("")} edge="end">
                        {search ? (
                          <Icon
                            sx={{ fontSize: "18px !important" }}
                            color="error"
                          >
                            close
                          </Icon>
                        ) : null}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="search..."
                style={{ marginLeft: "auto", width: "100%" }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            {Object.keys(filtered).length ? (
              <>
                <JsonVerticalTable data={[filtered]} />
                <Stack
                  sx={{ wordBreak: "break-all", overflowWrap: "break-word" }}
                  spacing={1}
                  className="floating"
                >
                  <Typography variant="h6">JSON</Typography>
                  <JsonView displaySize src={filtered} />
                </Stack>
              </>
            ) : (
              <Typography variant="body1">
                No data matches the search string
              </Typography>
            )}
            {system?.detectors?.length ? (
              <Stack className="floating" gap={2}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Icon>query_stats</Icon>
                  <Typography sx={{ marginLeft: "10px" }} variant="h6">
                    Detectors
                  </Typography>
                </div>
                {!detectorTriggerTier?.length ? (
                  <Typography variant="body2">No detections found</Typography>
                ) : (
                  <TieredList width="450px" items={detectorTriggerTier} />
                )}
              </Stack>
            ) : null}
          </Stack>
        );
      }}
    </SidePanel>
  );
}
