// import create from "zustand";
import { useState, useEffect, useMemo } from "react";
// import { createAuthProvider } from "react-token-auth";
// import parseJwt from "common/utils/parseJwt";
import DefaultLayout from "layouts/default";
import { authFetch, login, logout, useAuthentication } from "auth/auth";

export { authFetch, login, logout };
export default useAuthentication;

export function useRoleForRoutes(user, definedRoutes) {
  let routes = useMemo(() => {
    const getAllowedChildren = (route, role, crew_name) => {
      if (!route?.children) {
        return null;
      }
      let allowedRoutes = [];
      route.children.forEach((c) => {
        const Element = c.element;
        let updatedRoute = {
          ...c,
          element: <Element {...{ role, crew_name }} />,
        };
        if (c?.roles) {
          if (c.roles.includes(role)) {
            allowedRoutes.push(updatedRoute);
          }
        } else {
          allowedRoutes.push(updatedRoute);
        }
      });
      return allowedRoutes;
    };
    let allowedRoutes = definedRoutes.reduce((a, r) => {
      const Element = r?.element;
      let role = user?.rls;
      let crew_name = user?.crew_name;
      const elementOptions = {};
      if (Element) {
        elementOptions.element = <Element {...{ role, crew_name }} />;
      }

      //if no role for user
      if (!role) {
        return a;
      }
      //if roles defined for route
      if (r?.roles) {
        if (!r.roles.includes(role)) {
          //if not permitted
          return a;
        } else {
          //if permitted
          // const Element = r.element;
          // let updatedRoute = {
          //   ...r,
          //   element: <Element {...{ role }} />,
          // };
          let children = getAllowedChildren(r, role, crew_name);
          a.push({ ...r, ...elementOptions, children });
        }
      } else {
        //no role defined for route
        let children = getAllowedChildren(r, role, crew_name);
        a.push({ ...r, ...elementOptions, children });
      }
      return a;
    }, []);
    let allowedNavs = allowedRoutes.reduce((a, b) => {
      if (b?.title) {
        a.push(b);
      }
      if (!b?.title && b?.children) {
        b.children.forEach((c) => {
          if (c?.title) {
            a.push(c);
          }
        });
      }
      return a;
    }, []);
    allowedRoutes = allowedRoutes.map((r) => {
      if (r?.layout === "default") {
        let modifiedRoute = { ...r };
        delete modifiedRoute.layout;
        modifiedRoute.element = <DefaultLayout navs={allowedNavs} />;
        return modifiedRoute;
      }
      return r;
    });
    return allowedRoutes;
  }, [user]);
  return routes;
}
