import * as React from "react";
const LogoIcon = (props) => {
  return (
    <svg
      id="ehWrypZQTqz1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 602 600"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <defs>
        <filter
          id="ehWrypZQTqz4-filter"
          x="-150%"
          width="400%"
          y="-150%"
          height="400%"
        >
          <feColorMatrix
            id="ehWrypZQTqz4-filter-hue-rotate-0"
            type="hueRotate"
            values="19.8"
            result="result"
          />
        </filter>
        <linearGradient
          id="ehWrypZQTqz4-fill"
          x1="0"
          y1="-0.000041"
          x2="1"
          y2="-0.000041"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="ehWrypZQTqz4-fill-0" offset="0%" stopColor="#ce4297" />
          <stop id="ehWrypZQTqz4-fill-1" offset="100%" stopColor="#f57d35" />
        </linearGradient>
        <filter
          id="ehWrypZQTqz5-filter"
          x="-150%"
          width="400%"
          y="-150%"
          height="400%"
        >
          <feColorMatrix
            id="ehWrypZQTqz5-filter-hue-rotate-0"
            type="hueRotate"
            values="0"
            result="result"
          />
        </filter>
        <linearGradient
          id="ehWrypZQTqz6-fill"
          x1="0"
          y1="0.000006"
          x2="1"
          y2="0.000006"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="ehWrypZQTqz6-fill-0" offset="0%" stopColor="#a927fa" />
          <stop id="ehWrypZQTqz6-fill-1" offset="100%" stopColor="#ce4297" />
        </linearGradient>
        <linearGradient
          id="ehWrypZQTqz8-fill"
          x1="0"
          y1="0.000005"
          x2="1"
          y2="0.000005"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="ehWrypZQTqz8-fill-0" offset="0%" stopColor="#a927fa" />
          <stop id="ehWrypZQTqz8-fill-1" offset="100%" stopColor="#ce4297" />
        </linearGradient>
        <filter
          id="ehWrypZQTqz10-filter"
          x="-150%"
          width="400%"
          y="-150%"
          height="400%"
        >
          <feColorMatrix
            id="ehWrypZQTqz10-filter-hue-rotate-0"
            type="hueRotate"
            values="0"
            result="result"
          />
        </filter>
      </defs>
      <g
        id="ehWrypZQTqz2_ts"
        transform="translate(301.001209,299.923042) scale(1,1)"
      >
        <g transform="translate(-400.001209,-299.923042)" clipRule="evenodd">
          <g
            transform="matrix(0-346.248-346.248 0 399.984 456.771)"
            clipRule="evenodd"
          >
            <path
              d="M1,0c0,.146-.057.283-.16.387-.103.103-.241.16-.387.16-.182,0-.351-.091-.453-.239c0-.001.001-.001.001-.001.022-.029.032-.065.03-.101.023-.021.038-.049.042-.081.005-.004.009-.009.013-.015h.059c.002.005.003.009.005.013l-.087.11c.012.01.025.019.039.027l.016.01c.042.03.104.045.168.041.063-.004.124-.027.172-.065.09.001.18-.021.269-.066.092-.046.159-.105.191-.136L0.964,0l-.046-.044c-.064-.062-.233-.202-.459-.202-.048-.038-.109-.061-.172-.065-.064-.004-.127.011-.169.041l-.055.037.087.11c-.002.004-.003.008-.005.013h-.059c-.004-.005-.009-.01-.013-.014-.004-.032-.019-.061-.042-.081.002-.036-.008-.072-.03-.101C0,-0.307,0,-0.307,0,-0.307c.101-.149.271-.24.453-.24.146,0,.284.057.387.16.103.104.16.241.16.387v0Z"
              clipRule="evenodd"
              filter="url(#ehWrypZQTqz4-filter)"
              fill="url(#ehWrypZQTqz4-fill)"
              strokeLinejoin="round"
              strokeMiterlimit="2"
            />
          </g>
          <g
            transform="matrix(0-80.9161-80.9161 0 399.915 489.756)"
            clipRule="evenodd"
            filter="url(#ehWrypZQTqz5-filter)"
          >
            <path
              d="M1,-0.212v.427h-.381c-.003,0-.004,0-.007,0-.004.09-.071.163-.158.176.008.026.014.054.014.084c0,.135-.094.248-.22.279.025.052.039.109.039.17c0,.051-.01.1-.028.144C0.094,0.748,0,0.384,0,-0.001s.093-.747.258-1.067c.018.045.029.094.029.146c0,.061-.014.118-.039.17.126.031.22.144.22.279c0,.03-.006.058-.014.085.087.013.154.085.158.175.003,0,.004.001.007.001h.381Z"
              clipRule="evenodd"
              fill="url(#ehWrypZQTqz6-fill)"
              strokeLinejoin="round"
              strokeMiterlimit="2"
            />
          </g>
          <g
            transform="matrix(0-600.006-600.006 0 400.001 600.001)"
            clipRule="evenodd"
          >
            <g id="ehWrypZQTqz8_tr" transform="translate(0.500125,0) rotate(0)">
              <path
                d="M0.993,0.085L0.896,0.1l-.003.011c-.009.031-.021.06-.037.088l-.006.01.058.08c-.016.023-.034.045-.054.065s-.042.038-.065.054l-.08-.058-.01.006c-.028.016-.057.028-.088.037L0.6,0.396l-.015.097c-.028.005-.057.007-.085.007s-.057-.002-.085-.007L0.4,0.396l-.011-.003c-.031-.009-.06-.021-.088-.037l-.01-.006-.08.058c-.023-.016-.045-.034-.065-.054-.019-.02-.038-.042-.054-.065l.058-.08-.007-.011c-.003-.006-.006-.013-.01-.019c0,0,0,0,0,0-.005-.01-.009-.021-.013-.031-.001-.002-.002-.005-.003-.007-.003-.01-.007-.02-.01-.03L0.104,0.1l-.097-.015c-.009-.056-.009-.114,0-.17l.097-.015.003-.011c.004-.011.007-.021.011-.032.001-.001.001-.002.001-.003.005-.011.009-.022.014-.033c0,0,0,0,0,0l.011-.02.006-.01-.058-.08c.016-.023.035-.045.054-.065.02-.019.042-.038.065-.054l.08.058.01-.006c.028-.016.057-.028.088-.037l.011-.003.015-.097c.028-.005.057-.007.085-.007s.057.002.085.007l.015.097.011.003c.031.009.06.021.088.037l.01.006.08-.058c.023.016.045.034.065.054s.038.042.054.065l-.058.08.006.01c.016.028.028.057.037.088l.003.011.097.015c.005.028.007.057.007.085s-.002.057-.007.085Zm-.142-.086v0c0-.093-.036-.181-.102-.247-.067-.067-.155-.103-.249-.103h-.022c-.184.012-.33.164-.33.351s.146.339.33.351h.022c.094,0,.182-.036.249-.103.066-.066.102-.154.102-.247v0-.001c0,0,0,0,0,0s0-.001,0-.001Z"
                transform="translate(-0.500125,0)"
                clipRule="evenodd"
                fill="url(#ehWrypZQTqz8-fill)"
                strokeLinejoin="round"
                strokeMiterlimit="2"
              />
            </g>
          </g>
          <g
            transform="matrix(8.56881 0 0 8.56881 430.434 291.017)"
            clipRule="evenodd"
          >
            <path
              d="M0,-2.844c-.411-1.585-1.838-2.761-3.552-2.761-1.713,0-3.141,1.176-3.552,2.761-.077-.296-.13-.602-.13-.922c0-2.034,1.648-3.682,3.682-3.682s3.683,1.648,3.683,3.682c0,.32-.054.626-.131.922M3.852,1.673c.414-8.822-5.398-15.611-7.407-17.683-1.097,1.127-3.323,3.647-5.05,7.102-1.776,3.551-2.567,7.098-2.381,10.609-3.447,3.724-3.085,9.092-1.436,11.465l3.866-3.068c.55.721,1.341,1.153,2.187,1.153h5.63c.846,0,1.636-.432,2.187-1.152l3.864,3.067c1.654-2.378,2.015-7.768-1.46-11.493"
              clipRule="evenodd"
              filter="url(#ehWrypZQTqz10-filter)"
              fill="#a927fa"
              strokeLinejoin="round"
              strokeMiterlimit="2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default LogoIcon;
