import React from "react";
import { Stack, Avatar, Typography } from "@mui/material";
import Input from "common/components/Input";
import ActionButton from "common/components/ActionButton";
import useScopeAttributes from "common/hooks/useScopeAttributes";
import useFetcher from "common/hooks/useFetcher";
import { SystemsIcon, PackageIcon } from "routes";

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
export default function CreatePackage({ onClose }) {
  const fetcher = useFetcher();
  const { ScopeInputs, getScope, setInit } = useScopeAttributes();
  const [name, setName] = React.useState("");
  const navigate = useNavigate();
  const [description, setDescription] = React.useState("");
  const savePackage = async () => {
    const payload = {
      pk: null,
      name,
      description,
      latest: 0,
      ...getScope(),
    };
    if (!/^[A-Za-z_0-9]{1,50}$/.test(name))
      return toast.error(
        "Name must be a string containing only letters and underscores, with a limit of 50 characters"
      );
    if (!/^.{1,50}$/.test(description))
      return toast.error(
        "Description must be a string containing only letters and underscores, with a limit of 50 characters"
      );
    await fetcher.post(
      "creating package",
      `/api/pkg`,
      payload,
      (data) => {
        if (data?.error) {
          toast.error(data?.error);
          return;
        }
        if (data?.name) {
          navigate(`/app/packages/${data?.name}`);
        }
        onClose();
      },
      () => {}
    );
  };
  return (
    <Stack p={2} gap={3}>
      <Stack alignItems={"center"} direction="row" gap={1}>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            marginRight: "10px",
          }}
        >
          <PackageIcon fill="#171717" size="50" />
        </Avatar>
        <Stack>
          <Typography variant="h4">Create Package</Typography>
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Information</Typography>
        <Stack gap={1}>
          <Typography>Identifying information for your package.</Typography>
        </Stack>
        <Stack gap={1}>
          <Input
            dark
            size="small"
            sx={{ width: "100%" }}
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            dark
            size="small"
            sx={{ width: "100%" }}
            placeholder="Description"
            value={description}
            multiline
            minRows={3}
            maxRows={3}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Stack>
      </Stack>
      <Stack className="floating" gap={3}>
        <Typography variant="h5">Access Control</Typography>
        <Stack gap={1}>
          <ScopeInputs {...{ dark: true, size: "small" }} />
        </Stack>
      </Stack>
      <div style={{ marginLeft: "auto", display: "flex" }}>
        <ActionButton
          icon="save"
          dark
          handler={async () => {
            await savePackage();
          }}
        >
          Create Package
        </ActionButton>
      </div>
    </Stack>
  );
}
