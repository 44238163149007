import { useEffect } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { definedRoutes } from "routes";
const useTabTitle = () => {
  const location = useLocation();
  const updateTab = () => {
    const matches = matchRoutes(definedRoutes, window.location);
    if (matches?.length > 1) {
      if (["/app", "/log"].includes(matches[0]?.pathname)) {
        const title = matches[1]?.route?.tab;
        if (title) {
          document.title = `${title}`;
        }
      }
    } else if (matches?.length === 1) {
      const title = matches[0]?.route?.tab;
      if (title) {
        document.title = `${title}`;
      }
    }
  };
  useEffect(() => {
    updateTab();
  }, [location]);
};

export default useTabTitle;
