import React from "react";
import { Stack, Typography, Icon } from "@mui/material";
import getTimeOffset from "common/utils/getTimeOffset";
export const Text = {
  crews_desc: [
    "group",
    "Crews are groups of Auditors, Operators, Engineers, and Administrators that support and manage Systems within their domain.",
  ],
  crews_add: ["group_add", "Give your crew a name, description, and color."],
  crew_desc: ["group", "Manage crew members and their permissions."],
  user_add: ["person_add", "Add a new Crew member."],
  user_pass: [
    "lock",
    "Password must be between 8 and 64 characters and contain at least 1 special character, 1 uppercase character, 1 lowercase character and an integer",
  ],
  targets_desc: [
    "public",
    "Target groups of accounts and other environments for Commands to run against.",
  ],
  targets_add_platform: ["cloud", "Select the target platform."],
  targets_add_general: "Give your target group a name.",
  targets_add_tenants: [
    "my_location",
    "Select the tenants to include in the target group.",
  ],
  targets_add_geo: [
    "map",
    "Select the geographies to include in the target group.",
  ],
  tenants_desc: [
    "my_location",
    "Tenants are individual accounts, subscriptions, etc.",
  ],
  tenants_add_platform: ["cloud", "Select the tenant platform"],
  acl_tags: "Add tags to your resource",
  acl_crew: "Select Crews to share the resource with",
  cron_selector: `Specify a cron job (UTC)`,
  system_report_loading: [
    "engineering",
    "Generating System report. Page will update once download is ready.",
  ],
  system_report_download: "System report is ready to download!",
  log_general: [
    "newspaper",
    "Logging displays a sample of the returned data and other execution details.",
  ],
  archive_general: ["inventory_2", "Review logs for executed System Commands."],
  variable_general:
    "Variables centralize your secret data points and environment variables which are used within Systems.",
  command_code: [
    "code",
    "Define Command functionality here using Python syntax.",
  ],
  command_general: "General information of your Command.",
  command_inputs: "Define user inputs required to execute your Command.",
  command_inputs_help: [
    "support",
    "Help text will be displayed when prompted for input.",
  ],
  command_inputs_type: "Values will be coerced to the selected type.",
  command_env: "Select Variables to be added to the Command context.",
  command_pkg:
    "Define package names that must be installed when executing your Command.",
  command_pkg_info: "Supported syntax mirrors Python requirement specifiers.",
  command_scan:
    "Scans Command code to identify areas of concern based on industry standards.",
  command_restore: [
    "settings_backup_restore",
    "Restore your Command to one of the 5 previously saved versions. This action is irreversible and the current version will be lost.",
  ],
  command_targeting:
    "Enable Commands to execute across defined environment groups.",
  command_targeting_enabled:
    "Run once by default, or enable Targeting to execute Command against defined environment groups.",
  command_copy: ["content_copy", "Copies the entire Command configuration."],
  systems_general:
    "A System contains automations and data for a specific domain, enabling creation of internal apps and dashboards.",
  system_property_import:
    "Paste a JSON object to easily import table properties. Each property value will be used automatically to set the initial property type. Nested properties are currently unsupported.",
  system_edit_prop: [
    "table",
    "Properties highlight data fields of a resource and are seen as columns in the System Dashboard data table.",
  ],
  system_edit_color_exp: "Define an expression to match against.",
  system_edit_prop_uid:
    "Only one property can be selected as the unique identifier for a System.",
  system_edit_prop_type: "Define the property value type.",
  system_resource_view:
    "Resource view window can be toggled between table and JSON views.",
  system_edit_general: "General information of your System.",
  system_edit_general_header: ["badge", "General", "h5"],
  system_edit_detectors: "Flag resources using logical expressions.",
  system_edit_graphs_new_header: ["analytics", "Widget", "h5"],
  system_edit_graphs_header: ["analytics", "Widgets", "h5"],
  system_edit_graphs: "Create visualizations of your dataset.",
  system_edit_detectors_general_header: ["query_stats", "Detectors", "h5"],
  system_edit_detectors_general_new_header: ["query_stats", "Detector", "h5"],
  system_edit_detectors_general: "General information of your Detector.",
  system_edit_detectors_exp:
    "Define a logical expression to evaluate against System resources.",
  system_edit_new_actions_header: ["rocket", "Command", "h5"],
  system_edit_actions_header: ["rocket", "Commands", "h5"],
  system_edit_actions: "Execute Command code on your system.",
  system_edit_triggers_header: ["dynamic_forms", "Triggers", "h5"],
  system_edit_triggers_new_header: ["dynamic_forms", "Trigger", "h5"],
  system_edit_triggers:
    "Automatically execute Commands on Detector-flagged resources.",
  system_edit_triggers_general: "General information of your Trigger.",
  system_edit_triggers_detector:
    "Select the Detector this Trigger will respond to.",
  system_edit_triggers_action:
    "Select the Command to be automatically executed.",
  system_edit_acl_header: ["group", "Access Control", "h5"],
  system_edit_acl:
    "Share this System with other Crews and add Tags for organization.",
  system_edit_action_general: "General information of your Command.",
  empty_view:
    "You don't have any Nexus Views yet. Click the Create View button below to get started.",
  view_general: [
    "panorama_photosphere",
    "Nexus Views consolidate many Systems into a single pane for resource counts and easier navigation. Clicking a tile arrow will navigate you to that System.",
  ],
  sys_url_edit: ["webhook", "Create a webhook to read the System data."],
};

const Blurb = ({ children, margin = 2 }) => {
  let blurb = Text[children];
  let [icon, text, type = "subtitle2"] =
    typeof blurb === "object" ? blurb : [null, blurb];
  return (
    <Stack
      spacing={1}
      sx={{ marginBottom: margin, marginTop: 1 }}
      direction="row"
      alignItems="center"
    >
      {icon ? (
        <Icon sx={{ fontSize: "2rem !important" }} color="secondary">
          {icon}
        </Icon>
      ) : null}
      <Typography sx={{ fontWeight: "bold" }} variant={type}>
        {type === "h5" ? text : <em>{text}</em>}
      </Typography>
    </Stack>
  );
};

export default Blurb;
