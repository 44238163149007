import React from "react";
import { Stack, Typography } from "@mui/material";
import { themeOptions } from "theme";
export default function Checkbox({
  id,
  disabled,
  text,
  value,
  onChange,
  sx = {},
  inputProps = {},
}) {
  return (
    <Stack sx={sx} alignItems={"center"} gap={1} p={0} m={0} direction="row">
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
        className="container"
      >
        <input
          disabled={!!disabled}
          type="checkbox"
          className="cbx"
          checked={value}
          onChange={() => onChange(!value)}
          id={id}
          style={{ display: "none" }}
          {...inputProps}
        />
        <label style={{ userSelect: "none" }} htmlFor={id} className="check">
          <svg width="18px" height="18px" viewBox="0 0 18 18">
            <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
            <polyline points="1 9 7 14 15 4" />
          </svg>
        </label>
      </div>
      <Typography
        sx={{
          color: disabled
            ? themeOptions.palette.text.disabled
            : themeOptions.palette.text.primary,
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
}
