import React from "react";
import Modal from "common/components/Modal";
import Input from "common/components/Input";
import {
  Avatar,
  Stack,
  Typography,
  Badge,
  Icon,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useInputForm from "common/hooks/useInputForm";
import useFetcher from "common/hooks/useFetcher";
import Radios from "common/components/Radios";
import AuditorIcon from "assets/crew/auditor.png";
import OperatorIcon from "assets/crew/operator.png";
import EngineerIcon from "assets/crew/engineer.png";
import AdminIcon from "assets/crew/admin.png";
import BackgroundImage from "assets/crew/background.jpg";
import Switch from "common/components/Switch";
import Blurb from "constants/blurbs";
import validate from "constants/validate";
import toast from "react-hot-toast";
import Triangle from "assets/icons/triangle.png";

export const RolesMap = {
  auditor: {
    name: "Auditor",
    type: "sentinel",
    icon: AuditorIcon,
    description:
      "They watch with hawk-like precision, their piercing gaze identifying anomalies. They are the guardians and glitch-busters.",
    access: [
      ["Nexus", "read only"],
      ["Systems", "read only"],
      ["Archives", "read only"],
    ],
  },
  operator: {
    name: "Operator",
    type: "pilot",
    icon: OperatorIcon,
    description:
      "They pilot intricate tapestries of automation, weaving data through automation systems, steering the ship with pre-programmed precision. No piloting joysticks here, just the smooth sailing of pre-charted courses and algorithmic helmsmanship.",
    access: [
      ["Nexus", "read access"],
      ["Systems", "read/run access for existing resources"],
    ],
  },
  engineer: {
    name: "Engineer",
    type: "builder",
    icon: EngineerIcon,
    description:
      "They craft bots made of code not steel, taking over the physical drudgery while the crew focuses on grander schemes. Forget grease-stained overalls, it's all about crafting algorithms that grease the gears of progress.",
    access: [
      ["Nexus", "full access"],
      ["Systems", "full access"],
      ["Variables", "full access"],
      ["Archives", "full access"],
      ["Targets", "full access"],
    ],
  },
  admin: {
    name: "Administrator",
    type: "architect",
    icon: AdminIcon,
    access: [["Full Access", "all resources in crew scope"]],
    description:
      "They wield the baton of algorithms, programming routines that dance like celestial bodies, optimizing workflow with the grace of a zero-gravity ballet. No manual adjustments needed, just pure logic conducting the symphony of tasks.",
  },
};

export default function AddUser({ crew, init, reset, open, setOpen, refresh }) {
  const [role, setRole] = React.useState("auditor");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState(false);
  const fetcher = useFetcher();
  const inputForm = React.useRef();

  React.useEffect(() => {
    if (inputForm.current) {
      let input = inputForm.current.querySelector("input");
      setTimeout(() => {
        input.focus();
      }, 601);
    }
  }, [selected]);
  React.useEffect(() => {
    if (!init) return;
    setRole(init.role);
  }, [init]);

  const saveUser = async (close) => {
    setLoading(true);
    let payload = { role, crew, name: email, password };
    if (!validate("user_edit", payload)) return setLoading(false);
    await fetcher.post(
      "saving user",
      `/api/users`,
      payload,
      ({ error }) => {
        if (error) return toast.error(error);
        refresh();
        setOpen(false);
      },
      () => {}
    );
    setLoading(false);
    setOpen(false);
  };
  return (
    <Modal
      hideClose
      transparent
      width={1300}
      forceOpen={open}
      onClose={() => {
        setOpen(false);
        reset();
      }}
    >
      {({ handleClose }) => (
        <>
          <div className={`player-ctn`}>
            <div className="selected-ctn">
              <ul className="char-abilities">
                {RolesMap[role].access.map(([resource, desc], idx) => {
                  return (
                    <li key={idx} className="char-ability">
                      <img src={Triangle} />
                      <strong>{resource}</strong>
                      <p>{desc}</p>
                    </li>
                  );
                })}
              </ul>
              <div className="char-img__ctn">
                <img className="char-img__img" src={RolesMap[role].icon} />
              </div>
              <div className="char-info">
                <em className="char-slogan">{RolesMap[role].type}</em>
                <h2 className="char-name">{RolesMap[role].name}</h2>
                <p className="char-desc">{RolesMap[role].description}</p>
              </div>
            </div>
            <div className="player-pool">
              {["admin", "engineer", "operator", "auditor"].map((r) => {
                return (
                  <div
                    onClick={() => setRole(r)}
                    key={r}
                    className={`player${r === role ? " active" : ""}`}
                  >
                    <img className="player-img" src={RolesMap[r].icon} />
                    {/* <h3 className="player-name">{r}</h3> */}
                  </div>
                );
              })}
            </div>
            <div className="player-actions">
              <div
                onClick={() => {
                  setOpen(false);
                  reset();
                }}
                className="btn red"
              >
                <a href="#">Cancel</a>
              </div>
              <div
                onClick={() => {
                  setSelected(true);
                }}
                className="btn blue"
              >
                <a href="#">Select</a>
              </div>
            </div>
          </div>
          {selected ? (
            <>
              <div className="player-form-overlay" />
              <div className="player-form">
                <div className="player-form-clear">
                  <IconButton onClick={() => setSelected(false)}>
                    <Icon sx={{ fontSize: "20px !important" }} color="error">
                      clear
                    </Icon>
                  </IconButton>
                </div>
                <Typography
                  sx={{ fontFamily: "EXO", padding: 0, margin: 0 }}
                  variant={"body"}
                >
                  Crew Member Info
                </Typography>
                <Input
                  size="small"
                  ref={inputForm}
                  style={{ width: "100%" }}
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  size="small"
                  style={{ width: "100%" }}
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div
                  onClick={() => {
                    saveUser();
                  }}
                  className="btn green"
                >
                  <a href="#">Add Member</a>
                </div>
              </div>
            </>
          ) : null}
        </>
      )}
    </Modal>
  );
}
