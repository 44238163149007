import React from "react";
import Page from "common/components/Page";
import {
  Stack,
  Icon,
  IconButton,
  Typography,
  InputAdornment,
  Grid,
  Avatar,
} from "@mui/material";
import useState from "common/hooks/useState";
import { ReactComponent as AwsLogo } from "assets/icons/aws-logo.svg";
import { ReactComponent as GcpLogo } from "assets/icons/gcp-logo.svg";
import { ReactComponent as AzureLogo } from "assets/icons/azure-logo.svg";
import useFetcher from "common/hooks/useFetcher";
import AssetImage from "common/components/AssetImage";
import SidePanel from "common/components/SidePanel";
import MultiSelect from "common/components/MultiSelect";
import ActionButton from "common/components/ActionButton";
import Input from "common/components/Input";
import useConfirm from "common/hooks/useConfirm";
import AddTenant from "./components/AddTenant";
import ModifyTenant from "./components/ModifyTenant";
import useFolderView from "common/hooks/useFolderView";

const PlatformIconMap = {
  aws: AwsLogo,
  gcp: GcpLogo,
  azure: AzureLogo,
};

const Tile = ({
  target,
  deleteTarget,
  setModTenant,
  onClose,
  moveFolderButton: MoveFolderButton,
}) => {
  const [openDeleteModal, DeleteModal] = useConfirm({
    title: `Delete Tenant`,
    message: `Are you sure you want to delete ${target?.name} tenant? This action cannot be undone.`,
    callback: () => {
      deleteTarget(target?.pk);
    },
  });
  return (
    <Grid
      md={3.9}
      sx={{
        flex: 1,
        position: "relative",
      }}
      className="user-card-title-ctn"
      item
    >
      <div
        style={{ width: "100%", flex: 1 }}
        className="floating user-card-tile"
      >
        <DeleteModal />
        <Stack flex={1} gap={1} direction="column">
          <Stack direction="row" gap={1} alignItems={"center"}>
            <Avatar
              sx={{
                background: "#262626",
                width: 40,
                height: 40,
              }}
            >
              {React.createElement(PlatformIconMap[target.type] ?? AssetImage, {
                ...{
                  style: { width: "40px" },
                  path: `/api/assets/instance/platforms/${target.type}/logo.png`,
                },
              })}
            </Avatar>
            <span className="user-card-tile__name">{target.name}</span>
          </Stack>
          <Typography variant="body2">{target.id}</Typography>
          <Stack sx={{ marginLeft: "auto" }}>
            {target.tags.length ? (
              <Typography
                sx={{ height: "20px", fontWeight: "bold" }}
                variant="body2"
              >
                {target.tags.join(" ,")}
              </Typography>
            ) : (
              <Typography
                sx={{ height: "20px", fontWeight: "bold" }}
                variant="body2"
              ></Typography>
            )}
          </Stack>
        </Stack>
      </div>
      <div className="user-card-tile__options">
        <IconButton
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            backgroundColor: "#ff711a",
          }}
          onClick={() => setModTenant(target)}
        >
          <Icon
            sx={{
              color: "#fff !important",
            }}
          >
            tune
          </Icon>
        </IconButton>
        <MoveFolderButton id={target.pk} />
        <IconButton
          color="error"
          sx={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
          }}
          onClick={() => openDeleteModal(true)}
        >
          <Icon color="error">close</Icon>
        </IconButton>
      </div>
    </Grid>
  );
};

export default function Tenants() {
  const { tenants, loading, error, refresh } = useState("/api/tenants");
  const { platforms } = useState("/api/platforms");
  const [addTenant, setAddTenant] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [tag, setTag] = React.useState("");
  const [filter, setFilter] = React.useState("");
  const [newTenant, setNewTenant] = React.useState(false);
  const [modTenant, setModTenant] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [refreshModal, setRefreshModal] = React.useState(0);
  const fetcher = useFetcher();
  const {
    FolderView,
    FolderHeader,
    folder,
    setFolder,
    TagUpdateDialog,
    moveFolder,
    setMoveFolder,
    MoveFolderButton,
    filterFolders,
  } = useFolderView();
  const deleteTenant = async (id) => {
    await fetcher.delete(
      `deleting tenant`,
      `/api/tenants/${id}`,
      () => refresh(),
      () => {}
    );
  };

  const searchFilter = () => {
    if (!tenants) return [];
    if (!search && !tag && !folder) return tenants;
    let filter = [...tenants];
    if (tag) {
      filter = filter.filter(({ tags }) => tags.includes(tag));
    }
    if (search) {
      filter = filter.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (folder) {
      if (folder === "default") return filter;
      filter = filter.filter(({ name, tags }) =>
        (tags || [])?.map((t) => t.toLowerCase())?.includes(folder)
      );
    }
    return filter;
  };
  let filteredTenants = searchFilter();
  let [tags, tag_options] = filterFolders(filteredTenants, tenants);
  const onUpdateTag = async (tag) => {
    await fetcher.post(
      "updating tenant tags",
      `/api/tenants/${moveFolder}/tag`,
      { oldTag: folder, newTag: tag },
      (data) => {
        if (data?.error) return toast.error(data?.error);
        refresh();
      },
      () => {}
    );
  };
  return (
    <Page
      loading={loading}
      back="/app/targets"
      header="Tenants"
      refresh={refresh}
      actions={[
        <ActionButton
          icon="add"
          noload
          handler={async () => {
            setNewTenant(true);
          }}
        >
          Add Tenant
        </ActionButton>,
      ]}
    >
      <TagUpdateDialog
        title="Tenant"
        onUpdateTag={onUpdateTag}
        tags={tag_options}
        open={!!moveFolder}
        onClose={() => setMoveFolder(null)}
      />
      <SidePanel
        width="600px"
        open={modTenant}
        closeDrawer={() => setModTenant(false)}
        props={{ tenant: modTenant, refresh }}
      >
        {ModifyTenant}
      </SidePanel>
      <SidePanel
        width="600px"
        open={newTenant}
        closeDrawer={() => setNewTenant(false)}
        props={{ refresh }}
      >
        {AddTenant}
      </SidePanel>
      <Stack p={2} direction={"row"}>
        <FolderHeader />
        <Stack
          sx={{ marginLeft: "auto", width: "500px" }}
          gap={1}
          direction={"row"}
        >
          <div>
            <Input
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")} edge="end">
                      {search ? (
                        <Icon
                          sx={{ fontSize: "18px !important" }}
                          color="error"
                        >
                          close
                        </Icon>
                      ) : null}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="search..."
              sx={{ marginLeft: "auto", width: "200px" }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <MultiSelect
            size="small"
            options={Array.from(
              new Set(tenants?.reduce((a, t) => [...a, ...t.tags], []))
            )}
            loading={loading}
            label="Tag Filter"
            multiple={false}
            placeholder="Select a tag"
            value={tag}
            setValue={setTag}
            sx={{ width: "200px" }}
          />
        </Stack>
      </Stack>
      <Stack spacing={2}>
        {/* <Modal
          key={refreshModal}
          forceOpen={newTenant}
          onClose={() => {
            setRefreshModal(refreshModal + 1);
            setNewTenant(false);
          }}
        >
          {({ handleClose }) => {
            const [type, setType] = React.useState(null);
            return (
              <Stack alignItems="start" spacing={2}>
                <Typography variant="h5">Create a Tenant</Typography>
                <Blurb>tenants_add_platform</Blurb>
                <Radios
                  value={type}
                  setValue={(e) => setType(e.target.value)}
                  options={[
                    { value: "aws", label: "Amazon Web Services" },
                    {
                      value: "gcp",
                      label: "Google Cloud Platform",
                    },
                    {
                      value: "azure",
                      label: "Azure",
                    },
                    ...(platforms ?? []).map((p) => ({
                      value: p.name,
                      label: p.name,
                    })),
                  ]}
                  label="Platform"
                />
                {type
                  ? React.createElement(TenantMap[type] ?? CustomTenant, {
                      ...{ handleClose, type },
                    })
                  : null}
              </Stack>
            );
          }}
        </Modal> */}
        {!folder ? (
          <FolderView tags={tags} />
        ) : (
          <Stack alignItems="start" spacing={2}>
            {filteredTenants.length ? (
              <Grid gap={1} container>
                {filteredTenants?.map((data, idx) => {
                  return (
                    <Tile
                      moveFolderButton={MoveFolderButton}
                      setModTenant={setModTenant}
                      deleteTarget={deleteTenant}
                      target={data}
                      key={data.pk}
                    />
                  );
                })}
              </Grid>
            ) : null}
          </Stack>
        )}
      </Stack>
    </Page>
  );
}
