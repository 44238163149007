import React from "react";
import Modal from "common/components/Modal";
import Input from "common/components/Input";
import { Stack, Typography, Badge, Icon, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useFetcher from "common/hooks/useFetcher";
import SectionHeader from "common/components/SectionHeader";
import Blurb from "constants/blurbs";
import ActionButton from "common/components/ActionButton";
import toast from "react-hot-toast";
import validate from "constants/validate";
import copyContent from "common/utils/copyContent";
const NewReadWebhook = ({ handleClose, system }) => {
  const [name, setName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [token, setToken] = React.useState(null);
  const fetcher = useFetcher();
  const getNewUrl = () => {
    const payload = {
      name,
    };
    if (!validate("shareable_url_edit", payload)) return;
    setLoading(true);
    fetcher.post(
      "creating webhook",
      `/api/sys/${system}/hooks`,
      payload,
      (data) => {
        setLoading(false);
        if (data?.error || !data?.hook) return toast.error(data?.error);
        setName("");
        setToken(data.hook);
      },
      () => {}
    );
  };
  return (
    <Stack sx={{ p: 1 }} spacing={3}>
      <SectionHeader icon="webhook">Read Webhook</SectionHeader>

      {token ? (
        <Stack
          sx={{ width: "100%" }}
          alignItems={"center"}
          justifyContent="center"
        >
          <ActionButton
            noload
            handler={() => {
              copyContent(
                `https://${window.location.host}/api/sys/${system}/hook/${token}`
              );
              toast.success("copied webhook!");
            }}
            icon="content_copy"
          >
            Copy Webhook
          </ActionButton>
        </Stack>
      ) : (
        <>
          <Input
            size="small"
            dark
            sx={{ width: "100%" }}
            label="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div style={{ marginLeft: "auto" }}>
            <ActionButton
              icon="add"
              noload
              handler={async () => {
                if (token) {
                  handleClose();
                } else {
                  getNewUrl();
                }
              }}
            >
              Create Webhook
            </ActionButton>
          </div>
          {/* <LoadingButton
            loading={loading}
            variant={token ? "outline" : "contained"}
            color="success"
            onClick={() => (token ? handleClose() : getNewUrl())}
          >
            {"Create"}
          </LoadingButton> */}
        </>
      )}
    </Stack>
  );
};

export default function NewReadWebhookModal({
  open,
  setOpen,
  system,
  refresh,
}) {
  return (
    <Modal
      width={500}
      forceOpen={open}
      onClose={() => {
        refresh();
        setOpen(false);
      }}
    >
      {({ handleClose }) => (
        <NewReadWebhook handleClose={handleClose} system={system} />
      )}
    </Modal>
  );
}
