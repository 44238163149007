import React from "react";
import { Box, IconButton, Icon } from "@mui/material";
import PeaceIcon from "@mui/icons-material/EmojiEmotions";
import BrushIcon from "@mui/icons-material/Brush";
import BookIcon from "@mui/icons-material/MenuBook";
import AlignIcon from "@mui/icons-material/FormatAlignCenter";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material/styles";
const PillButtonGroup = ({ options, dark }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "60px",
        backgroundColor: dark
          ? theme.palette.primary.dark
          : theme.palette.primary.main,
        borderRadius: "30px",
        boxShadow: 3,
        padding: "10px",
      }}
    >
      {options.map((item, index) => {
        return (
          <IconButton
            onClick={item.onClick}
            sx={{
              color: "#fff",
              "&:hover": {
                color: theme.palette.secondary.main,
              },
            }}
            key={index}
            color="primary"
          >
            <Icon
              sx={{
                color: "#fff",
                "&:hover": {
                  color: theme.palette.secondary.main,
                },
              }}
            >
              {item.icon}
            </Icon>
          </IconButton>
        );
      })}
    </Box>
  );
};

export default PillButtonGroup;
