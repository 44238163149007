import { Navigate, useRoutes } from "react-router-dom";
//icons
// import NexusIcon from "assets/icons/nexus";
// import TargetsIcon from "assets/icons/groups";
// import CrewsIcon from "assets/icons/crew";
// import VariablesIcon from "assets/icons/variables";
// import ArchivesIcon from "assets/icons/archives";
// import SystemsIcon from "assets/icons/systems";

//pages
import FourOhFour from "pages/404";
import Nexus from "pages/Nexus";
import NexusView from "pages/NexusView";
import Targets from "pages/Targets";
import Tenants from "pages/Tenants";
import Crews from "pages/Crews";
import Crew from "pages/Crew";
import Variables from "pages/Variables";
import Archive from "pages/Archive";
import Systems from "pages/Systems";
import System from "pages/System";
import SystemDash from "pages/SystemDash";
import Action from "pages/Action";
// import Log from "pages/Log";
import Store from "pages/Store";
import Settings from "pages/Settings";
import Activity from "pages/Activity";
import Packages from "pages/Packages";
import Package from "pages/Package";
const ArchivesIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    fill={fill}
    viewBox="0 0 256 256"
  >
    <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-45.54-48.85a36.05,36.05,0,1,0-11.31,11.31l11.19,11.2a8,8,0,0,0,11.32-11.32ZM104,148a20,20,0,1,1,20,20A20,20,0,0,1,104,148Z"></path>
  </svg>
);
export const TargetsIcon = ({ fill, size = "28" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fill}
    viewBox="0 0 256 256"
  >
    <path d="M232,120h-8.34A96.14,96.14,0,0,0,136,32.34V24a8,8,0,0,0-16,0v8.34A96.14,96.14,0,0,0,32.34,120H24a8,8,0,0,0,0,16h8.34A96.14,96.14,0,0,0,120,223.66V232a8,8,0,0,0,16,0v-8.34A96.14,96.14,0,0,0,223.66,136H232a8,8,0,0,0,0-16Zm-96,87.6V200a8,8,0,0,0-16,0v7.6A80.15,80.15,0,0,1,48.4,136H56a8,8,0,0,0,0-16H48.4A80.15,80.15,0,0,1,120,48.4V56a8,8,0,0,0,16,0V48.4A80.15,80.15,0,0,1,207.6,120H200a8,8,0,0,0,0,16h7.6A80.15,80.15,0,0,1,136,207.6ZM128,88a40,40,0,1,0,40,40A40,40,0,0,0,128,88Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,128,152Z"></path>
  </svg>
);
const CrewsIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    fill={fill}
    viewBox="0 0 256 256"
  >
    <path d="M117.25,157.92a60,60,0,1,0-66.5,0A95.83,95.83,0,0,0,3.53,195.63a8,8,0,1,0,13.4,8.74,80,80,0,0,1,134.14,0,8,8,0,0,0,13.4-8.74A95.83,95.83,0,0,0,117.25,157.92ZM40,108a44,44,0,1,1,44,44A44.05,44.05,0,0,1,40,108Zm210.14,98.7a8,8,0,0,1-11.07-2.33A79.83,79.83,0,0,0,172,168a8,8,0,0,1,0-16,44,44,0,1,0-16.34-84.87,8,8,0,1,1-5.94-14.85,60,60,0,0,1,55.53,105.64,95.83,95.83,0,0,1,47.22,37.71A8,8,0,0,1,250.14,206.7Z"></path>
  </svg>
);
export const VariablesIcon = ({ fill, size = "28" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fill}
    viewBox="0 0 256 256"
  >
    <path d="M216,40H40A16,16,0,0,0,24,56V192a16,16,0,0,0,16,16H56v16a8,8,0,0,0,16,0V208H184v16a8,8,0,0,0,16,0V208h16a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,152H40V56H216v64H199.32a48,48,0,1,0,0,16H216v56Zm-50.16-72a16,16,0,1,0,0,16H183a32,32,0,1,1,0-16Z"></path>
  </svg>
);
export const SystemsIcon = ({ fill, size = "28" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fill}
    viewBox="0 0 256 256"
  >
    <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200ZM80,84A12,12,0,1,1,68,72,12,12,0,0,1,80,84Zm40,0a12,12,0,1,1-12-12A12,12,0,0,1,120,84Z"></path>
  </svg>
);
const NexusIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    fill={fill}
    viewBox="0 0 256 256"
  >
    <path d="M104,40H56A16,16,0,0,0,40,56v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V56A16,16,0,0,0,104,40Zm0,64H56V56h48v48Zm96-64H152a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V56A16,16,0,0,0,200,40Zm0,64H152V56h48v48Zm-96,32H56a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V152A16,16,0,0,0,104,136Zm0,64H56V152h48v48Zm96-64H152a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V152A16,16,0,0,0,200,136Zm0,64H152V152h48v48Z"></path>
  </svg>
);
const StoreIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    fill={fill}
    viewBox="0 0 256 256"
  >
    <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200ZM176,88a48,48,0,0,1-96,0,8,8,0,0,1,16,0,32,32,0,0,0,64,0,8,8,0,0,1,16,0Z"></path>
  </svg>
);
const SettingsIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill={fill}
    viewBox="0 0 256 256"
  >
    <path d="M128,80a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Zm109.94-52.79a8,8,0,0,0-3.89-5.4l-29.83-17-.12-33.62a8,8,0,0,0-2.83-6.08,111.91,111.91,0,0,0-36.72-20.67,8,8,0,0,0-6.46.59L128,41.85,97.88,25a8,8,0,0,0-6.47-.6A112.1,112.1,0,0,0,54.73,45.15a8,8,0,0,0-2.83,6.07l-.15,33.65-29.83,17a8,8,0,0,0-3.89,5.4,106.47,106.47,0,0,0,0,41.56,8,8,0,0,0,3.89,5.4l29.83,17,.12,33.62a8,8,0,0,0,2.83,6.08,111.91,111.91,0,0,0,36.72,20.67,8,8,0,0,0,6.46-.59L128,214.15,158.12,231a7.91,7.91,0,0,0,3.9,1,8.09,8.09,0,0,0,2.57-.42,112.1,112.1,0,0,0,36.68-20.73,8,8,0,0,0,2.83-6.07l.15-33.65,29.83-17a8,8,0,0,0,3.89-5.4A106.47,106.47,0,0,0,237.94,107.21Zm-15,34.91-28.57,16.25a8,8,0,0,0-3,3c-.58,1-1.19,2.06-1.81,3.06a7.94,7.94,0,0,0-1.22,4.21l-.15,32.25a95.89,95.89,0,0,1-25.37,14.3L134,199.13a8,8,0,0,0-3.91-1h-.19c-1.21,0-2.43,0-3.64,0a8.08,8.08,0,0,0-4.1,1l-28.84,16.1A96,96,0,0,1,67.88,201l-.11-32.2a8,8,0,0,0-1.22-4.22c-.62-1-1.23-2-1.8-3.06a8.09,8.09,0,0,0-3-3.06l-28.6-16.29a90.49,90.49,0,0,1,0-28.26L61.67,97.63a8,8,0,0,0,3-3c.58-1,1.19-2.06,1.81-3.06a7.94,7.94,0,0,0,1.22-4.21l.15-32.25a95.89,95.89,0,0,1,25.37-14.3L122,56.87a8,8,0,0,0,4.1,1c1.21,0,2.43,0,3.64,0a8.08,8.08,0,0,0,4.1-1l28.84-16.1A96,96,0,0,1,188.12,55l.11,32.2a8,8,0,0,0,1.22,4.22c.62,1,1.23,2,1.8,3.06a8.09,8.09,0,0,0,3,3.06l28.6,16.29A90.49,90.49,0,0,1,222.9,142.12Z" />
  </svg>
);
export const PackageIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill={fill}
    viewBox="0 0 256 256"
  >
    <path d="M223.68,66.15,135.68,18a15.88,15.88,0,0,0-15.36,0l-88,48.17a16,16,0,0,0-8.32,14v95.64a16,16,0,0,0,8.32,14l88,48.17a15.88,15.88,0,0,0,15.36,0l88-48.17a16,16,0,0,0,8.32-14V80.18A16,16,0,0,0,223.68,66.15ZM128,32l80.34,44-29.77,16.3-80.35-44ZM128,120,47.66,76l33.9-18.56,80.34,44ZM40,90l80,43.78v85.79L40,175.82Zm176,85.78h0l-80,43.79V133.82l32-17.51V152a8,8,0,0,0,16,0V107.55L216,90v85.77Z" />
  </svg>
);
const ActivityIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill={fill}
    viewBox="0 0 256 256"
  >
    <path d="M240,128a8,8,0,0,1-8,8H204.94l-37.78,75.58A8,8,0,0,1,160,216h-.4a8,8,0,0,1-7.08-5.14L95.35,60.76,63.28,131.31A8,8,0,0,1,56,136H24a8,8,0,0,1,0-16H50.85L88.72,36.69a8,8,0,0,1,14.76.46l57.51,151,31.85-63.71A8,8,0,0,1,200,120h32A8,8,0,0,1,240,128Z"></path>
  </svg>
);

const DocsIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill={fill}
    viewBox="0 0 256 256"
  >
    <path d="M251.76,88.94l-120-64a8,8,0,0,0-7.52,0l-120,64a8,8,0,0,0,0,14.12L32,117.87v48.42a15.91,15.91,0,0,0,4.06,10.65C49.16,191.53,78.51,216,128,216a130,130,0,0,0,48-8.76V240a8,8,0,0,0,16,0V199.51a115.63,115.63,0,0,0,27.94-22.57A15.91,15.91,0,0,0,224,166.29V117.87l27.76-14.81a8,8,0,0,0,0-14.12ZM128,200c-43.27,0-68.72-21.14-80-33.71V126.4l76.24,40.66a8,8,0,0,0,7.52,0L176,143.47v46.34C163.4,195.69,147.52,200,128,200Zm80-33.75a97.83,97.83,0,0,1-16,14.25V134.93l16-8.53ZM188,118.94l-.22-.13-56-29.87a8,8,0,0,0-7.52,14.12L171,128l-43,22.93L25,96,128,41.07,231,96Z"></path>
  </svg>
);

export const definedRoutes = [
  // {
  //   path: "/log",
  //   children: [
  //     {
  //       element: Log,
  //       path: "/log/view",
  //       roles: ["admin", "engineer", "operator", "auditor"],
  //       tab: "E1 Log",
  //     },
  //   ],
  // },
  {
    path: "/app",
    layout: "default",
    children: [
      { path: "/app", element: () => <Navigate to="/app/nexus" /> },
      {
        element: NexusView,
        path: "/app/nexus/:id",
        tab: "Nexus",
        roles: ["admin", "engineer", "operator", "auditor"],
      },
      {
        element: Nexus,
        icon: NexusIcon,
        path: "/app/nexus",
        title: "Nexus",
        tab: "Nexus",
        roles: ["admin", "engineer", "operator", "auditor"],
      },
      {
        element: Activity,
        icon: ActivityIcon,
        path: "/app/activity",
        title: "Activity",
        tab: "Activity",
        roles: ["admin", "engineer", "operator", "auditor"],
      },
      {
        element: SystemDash,
        path: "/app/sys/:id/dash",
        roles: ["admin", "engineer", "operator", "auditor"],
        tab: "System Dashboard",
      },
      {
        element: Action,
        path: "/app/sys/:id/action/:action",
        roles: ["admin", "engineer"],
        tab: "Action",
      },
      // {
      //   element: System,
      //   path: "/app/sys/:id",
      //   roles: ["admin", "engineer"],
      //   tab: "System",
      // },
      {
        element: System,
        path: "/app/sys/new",
        roles: ["admin", "engineer"],
        tab: "System",
      },
      {
        element: Systems,
        icon: SystemsIcon,
        path: "/app/sys",
        roles: ["admin", "engineer", "auditor", "operator"],
        title: "Systems",
        tab: "Systems",
      },
      {
        element: Package,
        path: "/app/packages/:id",
        roles: ["admin", "engineer"],
        tab: "Package",
      },
      {
        element: Packages,
        icon: PackageIcon,
        path: "/app/packages",
        roles: ["admin", "engineer"],
        title: "Packages",
        tab: "Packages",
      },
      {
        element: Variables,
        icon: VariablesIcon,
        path: "/app/variables",
        roles: ["admin", "engineer"],
        title: "Variables",
        tab: "Variables",
      },
      {
        element: Targets,
        icon: TargetsIcon,
        path: "/app/targets",
        title: "Targets",
        roles: ["admin", "engineer"],
        tab: "Targets",
      },
      {
        element: Archive,
        icon: ArchivesIcon,
        path: "/app/archive",
        title: "Archives",
        tab: "Archives",
        roles: ["admin", "engineer", "operator", "auditor"],
      },

      {
        element: Tenants,
        path: "/app/tenants",
        roles: ["admin", "engineer"],
        tab: "Tenants",
      },
      {
        element: Crew,
        path: "/app/crews/:id",
        roles: ["admin"],
        tab: "Crew",
      },
      {
        element: Crews,
        icon: CrewsIcon,
        path: "/app/crews",
        roles: ["admin"],
        title: "Crews",
        tab: "Crews",
      },
      {
        element: Store,
        icon: StoreIcon,
        path: "/app/store",
        roles: ["admin", "engineer", "operator", "auditor"],
        tab: "Astro Hub",
        title: "Astro Hub",
      },
      {
        element: Settings,
        icon: SettingsIcon,
        path: "/app/settings",
        roles: ["admin"],
        tab: "Settings",
        title: "Settings",
      },
    ],
  },
  {
    path: "/",
    element: () => <Navigate to="/app/nexus" />,
  },
  {
    path: "404",
    roles: ["admin", "engineer", "operator", "auditor"],
    element: FourOhFour,
    tab: "404",
  },
  { path: "*", element: () => <Navigate to="/app/nexus" /> },
];

export default function Router({ routes }) {
  return useRoutes(routes);
}
