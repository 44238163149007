import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Input from "common/components/Input";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import Container from "@mui/material/Container";
import { useForm, Controller } from "react-hook-form";
import { login } from "auth";
import fetcher from "common/utils/fetcher";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import LogoPic from "../../assets/icons/logo.png";
import { themeOptions } from "theme";
import AssetImage from "common/components/AssetImage";
import Skeleton from "@mui/lab";
import AzureSSOIcon from "../../assets/sso/azure.png";
import useState from "common/hooks/useState";
import { useSearchParams } from "react-router-dom";
import { MiniLoader } from "common/components/Loader";
export default function LoginPage() {
  const { type, error, loading: SSOLoading } = useState(
    "/api/settings/sso/option"
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  React.useEffect(() => {
    let error = searchParams.get("error");
    if (error) {
      return toast.error(error);
    }
    let jwt = searchParams.get("jwt");
    if (jwt) {
      setLoading(true);
      //login user
      setTimeout(() => {
        login({ access_token: jwt });
        setLoading(false);
      }, 1000);
    }
  }, [searchParams]);
  const submitHandler = ({ username, password }) => {
    setLoading(true);
    let opts = {
      username,
      password,
    };
    fetcher("/api/login", {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((token) => {
        if (token.access_token) {
          login(token);
          // if (outside) {
          //   navigate("/");
          // }
          setTimeout(() => {
            setLoading(false);
          }, 100);
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        toast.error("Failed Login Attempt");
        setLoading(false);
      });
  };
  const handleEntra = () => {
    window.location.href = `${window.location.protocol}//${window.location.host}/api/sso/entra`;
  };
  const isLoading = SSOLoading || loading;
  return (
    <Container
      sx={{
        height: "100vh",
        backgroundImage: "url(/static/login-background.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        width: "100vw !important",
        maxWidth: "100vw !important",
        overflow: "hidden",
        transformOrigin: "top",
      }}
      component="main"
    >
      <CssBaseline />
      <Stack
        sx={{ height: "100%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Card
          sx={{
            width: "400px",
            background: "hsla(0,0%,1%,.8)",
          }}
          className="glowborder"
          variant="outlined"
        >
          <Stack p={3} alignItems="center" spacing={2}>
            <AssetImage
              skeletonProps={{ width: "200px", height: "200px" }}
              style={{ width: "200px" }}
              path="/api/settings/brand/logo"
              fallback={() => (
                <>
                  <img
                    height="180px"
                    width="180px"
                    style={{ marginBottom: "10px" }}
                    src={LogoPic}
                    alt="logo"
                  />
                  <Typography
                    sx={{
                      fontSize: "40px",
                      fontFamily:
                        '"ECHELONONE", "Helvetica", "Arial", sans-serif',
                      color: "#fff",
                    }}
                    variant="h3"
                    noWrap
                    component="div"
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      ECHELON
                    </span>
                    <span>ONE</span>
                  </Typography>
                </>
              )}
            />

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(submitHandler)}
            >
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <Input
                    margin="normal"
                    fullWidth
                    label="User Name"
                    autoComplete="off"
                    autoFocus
                    {...field}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input
                    margin="normal"
                    fullWidth
                    label="Password"
                    type="password"
                    autoComplete="off"
                    {...field}
                  />
                )}
              />
              {isLoading ? null : (
                <LoadingButton
                  fullWidth
                  sx={{
                    height: "50px",
                    background: themeOptions.palette.secondary.main,
                    mt: 3,
                    color: "#000",
                    fontSize: "18px",
                    fontWeight: "bold",
                    letterSpacing: "2px",
                    fontFamily:
                      '"ECHELONONE", "Helvetica", "Arial", sans-serif',
                  }}
                  size="large"
                  color="secondary"
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Login
                </LoadingButton>
              )}
            </Box>
          </Stack>
          {isLoading ? (
            <div
              style={{
                position: "relative",
                flex: 1,
                justifyContent: "center",
                display: "flex",
                height: "100px",
              }}
            >
              <MiniLoader />
            </div>
          ) : type ? (
            <Stack alignItems={"center"} mb={3} gap={1}>
              <div
                style={{ display: "flex", alignItems: "center", width: "80%" }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    height: "1px",
                    backgroundColor: "grey",
                  }}
                ></div>
                <span style={{ margin: "0 10px", color: "grey" }}>
                  <Typography sx={{ fontFamily: "Titillium Web" }}>
                    Continue with SSO
                  </Typography>
                </span>
                <div
                  style={{
                    flexGrow: 1,
                    height: "1px",
                    backgroundColor: "grey",
                  }}
                ></div>
              </div>

              <div
                onClick={handleEntra}
                className="login__entra"
                style={{
                  cursor: "pointer",
                  height: "60px",
                  width: "60px",
                  paddingTop: "10px",
                  background: "#599ddf",
                  borderRadius: "8px",
                  mt: 3,
                  color: "#000",
                  fontSize: "12px",
                  fontWeight: "bold",
                  letterSpacing: "2px",
                  fontFamily: '"ECHELONONE", "Helvetica", "Arial", sans-serif',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  height="40px"
                  width="40px"
                  style={{ marginBottom: "10px" }}
                  src={AzureSSOIcon}
                  alt="logo"
                />
              </div>
            </Stack>
          ) : null}
        </Card>
      </Stack>
    </Container>
  );
}
