import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet } from "react-router-dom";
import Icon from "@mui/material/Icon";
import ProfileMenu from "layouts/default/components/ProfileMenu";
import { useLocation, matchPath } from "react-router-dom";
import Nav from "layouts/default/components/Nav";
import AssetImage from "common/components/AssetImage";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const NavOpenContext = React.createContext(() => {});

export default function DefaultLayout({ navs }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const match = (path) => {
    return path ? !!matchPath({ path, end: false }, pathname) : false;
  };
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    window.e1NavOpen = true;
    setOpen(true);
  };

  const handleDrawerClose = () => {
    window.e1NavOpen = false;
    setOpen(false);
  };
  return (
    <NavOpenContext.Provider value={open}>
      <Box sx={{ display: "flex", position: "relative" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <AssetImage
                skeletonProps={{ width: "200px", height: "40px" }}
                style={{ height: "40px" }}
                path="/api/settings/brand/logo"
                fallback={() => (
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Typography
                      sx={{ fontSize: "32px", marginTop: "5px !important" }}
                      variant="h3"
                      noWrap
                      component="div"
                    >
                      <span style={{ fontWeight: "bold" }}>ECHELON</span>
                      <span>ONE</span>
                    </Typography>
                  </Stack>
                )}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "20px" }} id="page-refresh" />
              <ProfileMenu sx={{ marginLeft: "auto", flex: 1 }} />
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <Icon>chevron_right</Icon>
              ) : (
                <Icon>chevron_left</Icon>
              )}
            </IconButton>
          </DrawerHeader>
          <Divider sx={{ marginTop: 2 }} />
          <Nav navs={navs} drawerOpen={open} />
        </Drawer>
        <Box
          component="main"
          id="main"
          sx={{
            width: open ? "calc(100vw - 240px)" : "100vw",
            height: "100vh",
            flexGrow: 1,
            p: 3,
          }}
        >
          <DrawerHeader />
          <Outlet />
        </Box>
      </Box>
    </NavOpenContext.Provider>
  );
}
